import React from 'react';
import Api from "../services/Api";
import TechGateLogo from "../images/TechgateHorizontal.svg";
import GSLogo from "../images/GP/logos_login.jpg";
import "../css/main.css";
import "../css/util.css";
import axios from 'axios';
import Swal from 'sweetalert2';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: "",
            password: "",
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { usuario, password } = this.state;
        var errores = [];
        if (!usuario) {
            errores.push("error_usuario");
        }
        if (!password) {
            errores.push("error_password");
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        Swal.showLoading();
        var datosEnviar = { email: usuario, password: password }
        axios
            .post(Api + "auth/login", datosEnviar, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(
                (response) => {
                    console.log(response.data)
                    if (response.data.State) {
                        window.localStorage.setItem('email', response.data.datos.email);
                        window.localStorage.setItem('name', response.data.datos.name);
                        window.localStorage.setItem('identification_type', response.data.datos.identification_type);
                        window.localStorage.setItem('identification', response.data.datos.identification);
                        window.localStorage.setItem('id_usuario', response.data.datos.id);
                        window.localStorage.setItem('id_empresa', response.data.datos.id_company);
                        window.localStorage.setItem('id_rol', response.data.datos.id_rol);
                        window.localStorage.setItem('token', response.data.token);
                        window.localStorage.setItem('tipo_tercero', response.data.datos.third_type);
                        const time = Date.now()
                        window.localStorage.setItem('timeout', time + (60 * 60 * 225))
                        window.location.href = '/MasterTerceros';
                    } else {
                        Swal.fire(response.data.msj, '', 'error');
                    }
                },
                (error) => {
                    Swal.fire('Email o contraseña invalida', '', 'error');
                }
            );
    }
    componentDidMount() {
        document.getElementById('usuario').focus();
    }
    render() {
        const { usuario, password } = this.state
        return (
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <div className="login100-help">
                            <div style={{ marginBottom: '0px'}} className="card">
                                <div className="card-header text-center">
                                    AYUDA
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row g-3">
                                                <div style={{ textAlign: 'justify', fontSize: '11px' }} className='col-md-12'>
                                                    <p>
                                                        Bienvenido al sistema de información de terceros.
                                                    </p>
                                                    <p>
                                                        Este servicio se ha creado para facilitar el cumplimiento de la normatividad colombiana y nuestros sistemas SAGRILAFT (Sistema de Autocontrol y Gestión del Riesgo de Lavado de Activos, Financiación del Terrorismo y financiación de la Proliferación de Armas de Destrucción Masiva) y PAEC (Programa de Anticorrupción, Ética y Cumplimiento) y relacionados con su identificación y registro de contrapartes de las empresas: TERMOMORICHAL S.A.S. con NIT. 900.563.232-2, GENSER POWER S.A.S. E.S.P. con NIT. 900.579.800-6, GENSER POWER COLOMBIA con NIT. 900.067.444-1, PROELECTRICA S.A.S. E.S.P. con NIT. 800.149.537-6, CENTRAL HIDROELECTRICA EL EDEN S.A.S. E.S.P. con NIT. 900.448.956-4 y ENELCA S.A.S. E.S.P. con NIT. 900.921.416-7
                                                    </p>
                                                    <p>
                                                        Si es la primera vez usted o su organización ingresan, debe activar el usuario principal a través del enlace enviado por la empresa.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={this.enviarDatos} className="login100-form validate-form">
                            <span className="login100-form-title">
                                <img style={{ maxWidth: '100%', paddingBottom: '30px' }} src={GSLogo} alt='' className='imgglobal' />
                                <h1>
                                    <b>Bienvenidos</b>
                                </h1>
                                <br />
                                <p style={{ fontSize: '14px' }}>Para ingresar utilice el usuario y contraseña generados en la invitación.</p>
                                <br />
                            </span>
                            <div className="input-group mb-3">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user" />
                                    </div>
                                </div>
                                <input className={((this.verificarError("error_usuario")) ? "is-invalid" : "") + " form-control"} type="text" name="usuario" id="usuario" placeholder="USUARIO" aria-describedby="helpId" onChange={this.cambioValor} />
                                <small id="helpId" className="invalid-feedback">Digite el Usuario</small>
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                                <input className={((this.verificarError("error_password")) ? "is-invalid" : "") + " form-control"} type="password" name="password" id="password" placeholder="CONTRASEÑA" aria-describedby="helpId" onChange={this.cambioValor} />
                                <small id="helpId" className="invalid-feedback">Digite la Contraseña</small>
                            </div>
                            <div className="container-login100-form-btn">
                                <button type='submit' className='btn btn-primary btn-block'>Ingresar</button>
                            </div>
                        </form>
                        <div className="login100-more"></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Login;