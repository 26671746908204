import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faCogs, faChevronRight, faChartBar, faFolder, faHandHoldingUsd, faHandshake, faNewspaper, faBookOpen, faCoins, faShieldAlt, faBars, faHeadset, faCalendar, faCity, faCreditCard, faDownload, faFilter, faSearch, faFileSignature, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import TechGateLogo from "../images/TechgateSolo.svg";
import GSLogo from "../images/GP/logo_solo.png";
class Menu extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        dolarText: '',
        Modulos: [],
        SubModulos: [],
        names: [],
        menuOpen: {
            terceros: false,
            certificados: false,
            configuracion: false,
        },
    }
    handleMenuToggle = (menu) => {
        const updatedMenuOpen = {};
        Object.keys(this.state.menuOpen).forEach((key) => {
            updatedMenuOpen[key] = false;
        });
        this.setState((prevState) => ({
            menuOpen: {
                ...updatedMenuOpen,
                [menu]: !prevState.menuOpen[menu],
            },
        }));
    };
    render() {
        const { Modulos, SubModulos, names, dolarText, menuOpen } = this.state;
        return (
            <div>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    <Link to="/MasterTerceros" className="brand-link">
                        <img src={GSLogo} className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">Portal Terceros</span>
                    </Link>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                {/* <img src={process.env.PUBLIC_URL + '/dist/img/user2-160x160.jpg'} className="img-circle elevation-2" alt="User Image" /> */}
                                <img src={process.env.PUBLIC_URL + '/dist/img/user_1144709.png'} className="img-circle elevation-2" alt="User Image" />
                            </div>
                            <div style={{ color: "#C2C7D0" }} className="info">
                                {window.localStorage.getItem('name')}
                            </div>
                        </div>
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-bs-widget="treeview" role="menu">
                                {
                                    window.localStorage.getItem('id_rol') === '1' || window.localStorage.getItem('id_rol') === '2' ?
                                        <li className={`nav-item ${menuOpen.terceros ? 'menu-open' : 'menu-close'}`}>
                                            <a href="#!" onClick={() => this.handleMenuToggle('terceros')} className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faFileSignature} />
                                                <p>
                                                    Registro de Terceros
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </a>
                                            {
                                                menuOpen.terceros && (
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/MasterTerceros" className="nav-link">
                                                                <FontAwesomeIcon style={{ marginRight: 17, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                                <p>Registro Terceros</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </li>
                                    : ''
                                }
                                {
                                    window.localStorage.getItem('id_rol') === '1' || window.localStorage.getItem('id_rol') === '3' ?
                                        <li className={`nav-item ${menuOpen.certificados ? 'menu-open' : 'menu-close'}`}>
                                            <a href="#!" onClick={() => this.handleMenuToggle('certificados')} className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 21 }} icon={faFileInvoice} />
                                                <p>
                                                    Certificados Tributarios
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </a>
                                            {
                                                menuOpen.certificados && (
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/MasterCertificados" className="nav-link">
                                                                <FontAwesomeIcon style={{ marginRight: 17, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                                <p>Certificados Tributarios</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </li>
                                    : ''
                                }
                                {
                                    window.localStorage.getItem('id_rol') === '1' ?
                                        <li className={`nav-item ${menuOpen.configuracion ? 'menu-open' : 'menu-close'}`}>
                                            <a href="#!" onClick={() => this.handleMenuToggle('configuracion')} className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 13 }} icon={faCogs} />
                                                <p>
                                                    Configuración
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </a>
                                            {
                                                menuOpen.configuracion && (
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/MasterUsuarios" className="nav-link">
                                                                <FontAwesomeIcon style={{ marginRight: 17, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                                <p>Usuarios</p>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/MasterRoles" className="nav-link">
                                                                <FontAwesomeIcon style={{ marginRight: 17, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                                <p>Roles</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </li>
                                    :
                                        ''
                                }
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>
        )
    }
}

export default Menu;