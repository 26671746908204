import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> 2.0
      </div>
      <strong>Copyright © 2024 <a href="">Techgate Evolución Digital</a>.</strong> All rights reserved.
    </footer>
  );
}
