import React from 'react';
import Swal from 'sweetalert2';
import { Link, Redirect } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        empresa_grupo: '',
        ano_certificado: '',
        nit_certificado: '',
        pdfDGBase64: null,
        empresas_grupo: [],
        ano_folders: [],
        tipo_certificados: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    cargarAnos = (e) => {
        Swal.showLoading();
        let id_empresa = window.localStorage.getItem('id_empresa');
        var empresa_grupo = e.target.value;
        this.setState({ ano_folders:[], tipo_certificados: [] })
        authAxios.get("formulario/cargar_anos/" + empresa_grupo + "/" + id_empresa)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    ano_folders: datosRespuesta.data.ano_folders });
                Swal.close();
            })
            .catch(console.log());
    }
    cargarTipoCertificados = (e) => {
        Swal.showLoading();
        let id_empresa = window.localStorage.getItem('id_empresa');
        var ano_certificado = e.target.value;
        var empresa_grupo = document.getElementById('empresa_grupo').value;
        this.setState({ tipo_certificados: [] });
        authAxios.get("formulario/cargar_certificados/" + empresa_grupo + "/" + id_empresa + "/" + ano_certificado)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    tipo_certificados: datosRespuesta.data.tipo_certificados });
                Swal.close();
            })
            .catch(console.log());
    }
    viewFile = async (fileName, tipoCertificado) => {
        Swal.showLoading();
        let id_empresa = window.localStorage.getItem('id_empresa');
        var ano_certificado = document.getElementById('ano_certificado').value;
        var empresa_grupo = document.getElementById('empresa_grupo').value;
        this.setState({ pdfDGBase64: null });
        await authAxios.get("formulario/view_certificate/" + empresa_grupo + "/" + id_empresa + "/" + ano_certificado + "/" + tipoCertificado + "/" + fileName, { responseType: 'blob' })
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                const pdfBase64 = URL.createObjectURL(datosRespuesta.data);
                this.setState({ pdfDGBase64: pdfBase64 });
                Swal.close();
            })
            .catch(console.log);
    }
    cancelarConsulta = () => {
        this.setState({
            empresa_grupo: '',
            ano_certificado: '',
            ano_folders: [],
            tipo_certificados: [],
        });
        document.getElementById("empresa_grupo").selectedIndex = "0";
    }
    componentDidMount() {
        const timeout = window.localStorage.getItem('timeout');
        // alert("Now: " + Date.now() + " - TimeOut: " + timeout);
        if (Date.now() > timeout) {
            // alert("Entra");
            window.location.href = "/";
            window.localStorage.clear();
            Swal.fire({
                title: 'Formularios',
                text: 'Sesión Expirada',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                    window.localStorage.clear();
                }
            })
        }
        const date = new Date();
        const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        Swal.showLoading();
        let id_empresa = window.localStorage.getItem('id_empresa');
        authAxios.get("formulario/nit/" + id_empresa)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    nit_certificado: datosRespuesta.data.nit });
            })
            .catch(console.log());
        authAxios.get("formulario/empresas_grupo")
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    empresas_grupo: datosRespuesta.data });
                Swal.close();
            })
            .catch(console.log());
    }
    render() {
        const { load, empresas_grupo, ano_folders, tipo_certificados, nit_certificado, pdfDGBase64, errores } = this.state;
        return (
            <>
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header">
                                &nbsp;
                            </div>
                            <div className="card-body">
                                <h4>Certificados Tributarios</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-xl-4">
                                        <div className="card">
                                            <div className="card-header text-center">
                                                FILTROS BUSQUEDA
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className='row g-3'>
                                                            <div className="col-md-12">
                                                                <label htmlFor="nit_certificado">NIT</label>
                                                                <input type="number" name="nit_certificado" id="nit_certificado" placeholder="NIT" aria-describedby="helpId" value={nit_certificado}
                                                                    className={((this.verificarError("error_nit_certificado")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} 
                                                                    onChange={this.cambioValor} min="0" length="10" readOnly />
                                                                <small id="helpId" className="invalid-feedback">Digite el NIT</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-12">
                                                                <label htmlFor="empresa_grupo">Empresa Emisora</label>
                                                                <select name="empresa_grupo"
                                                                    id="empresa_grupo"
                                                                    className={((this.verificarError("error_empresa_grupo")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={(e) => {
                                                                        this.cambioValor(e);
                                                                        this.cargarAnos(e);
                                                                    }}>
                                                                        <option value="">Seleccione la Empresa Emisora</option>
                                                                        {
                                                                            empresas_grupo.map((itemEmpresasGrupo) => (
                                                                                <option key={itemEmpresasGrupo.id} value={itemEmpresasGrupo.codigo}>{itemEmpresasGrupo.nombre}</option>
                                                                            ))
                                                                        }
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Empresa Emisora</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-12">
                                                                <label htmlFor="ano_certificado">Año Certificado</label>
                                                                <select name="ano_certificado"
                                                                    id="ano_certificado"
                                                                    className={((this.verificarError("error_ano_certificado")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={(e) => {
                                                                        this.cambioValor(e);
                                                                        this.cargarTipoCertificados(e);
                                                                    }}>
                                                                        <option value="">Seleccione el Año Certificado</option>
                                                                        {
                                                                            (() => {
                                                                                const folders = [];
                                                                                for (let i=0; i < ano_folders.length; i++) {
                                                                                    const folder_name = ano_folders[i];
                                                                                    folders.push(
                                                                                        <option key={i} value={folder_name}>{folder_name}</option>
                                                                                    );
                                                                                }
                                                                                return folders;
                                                                            })()
                                                                        }
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Año Certificado</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0, textAlign: 'center' }} className='row g-3'>
                                                            <div className='col-md-12'>
                                                                {/* <button type='button' id="consultarNIC" name="consultarNIC" className="btn btn-secondary btn-sm" onClick={this.consultarNit}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Consultar</button>&nbsp;&nbsp; */}
                                                                <button type="button" className="btn btn-danger btn-sm" onClick={this.cancelarConsulta}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8">
                                        <div className="card">
                                            <div className="card-header text-center">
                                                CERTIFICADOS DISPONIBLES
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            {
                                                                (() => {
                                                                    const tabTipoCertificado = [];
                                                                    for (let i=0; i < tipo_certificados.length; i++) {
                                                                        const tipoCertificadoData = tipo_certificados[i];
                                                                        if (i == 0) {
                                                                            tabTipoCertificado.push(
                                                                                <button className="nav-link active" id={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase() + "-tab"} data-bs-toggle="tab" data-bs-target={"#nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase()} type="button" role="tab" aria-controls={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase()} aria-selected="false">{tipoCertificadoData.tipo_certificado[0]}</button>
                                                                            );
                                                                        } else {
                                                                            tabTipoCertificado.push(
                                                                                <button className="nav-link" id={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase() + "-tab"} data-bs-toggle="tab" data-bs-target={"#nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase()} type="button" role="tab" aria-controls={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase()} aria-selected="false">{tipoCertificadoData.tipo_certificado[0]}</button>
                                                                            );
                                                                        }
                                                                    }
                                                                    return tabTipoCertificado;
                                                                })()
                                                            }
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        {
                                                            (() => {
                                                                const divTipoCertificado = [];
                                                                for (let i=0; i < tipo_certificados.length; i++) {
                                                                    const tdDocuments = [];
                                                                    const tipoCertificadoData = tipo_certificados[i];
                                                                    if (i == 0) {
                                                                        divTipoCertificado.push(
                                                                            <>
                                                                                <div className="tab-pane fade show active" id={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase()} role="tabpanel" aria-labelledby={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase() + "-tab"}>
                                                                                    <div style={{ marginTop: 15 }} className="row">
                                                                                        <div className="col-xl-12">
                                                                                            <div className='row g-3'>
                                                                                                <div className="col-md-12">
                                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                                        <thead className="thead-inverse">
                                                                                                            <tr>
                                                                                                                <th className="align-middle">NOMBRE DOCUMENTO</th>
                                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                (() => {
                                                                                                                    for (let j=0; j<tipoCertificadoData.documents.length; j++) {
                                                                                                                        const documentsData = tipoCertificadoData.documents[j];
                                                                                                                        tdDocuments.push(
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td key={j}>{documentsData}</td>
                                                                                                                                    <td>
                                                                                                                                        <div className="btn-group" role="group" aria-label="">
                                                                                                                                            <button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-secondary btn-sm"
                                                                                                                                                data-bs-toggle='modal'
                                                                                                                                                data-bs-target="#PDFFileModal"
                                                                                                                                                slot='end'
                                                                                                                                                onClick={() =>
                                                                                                                                                    this.viewFile(documentsData, tipoCertificadoData.tipo_certificado[0])
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <FontAwesomeIcon icon={faFilePdf} />
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    return tdDocuments;
                                                                                                                })()
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        divTipoCertificado.push(
                                                                            <>
                                                                                <div className='tab-pane fade' id={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase()} role="tabpanel" aria-labelledby={"nav-" + tipoCertificadoData.tipo_certificado[0].toLowerCase() + "-tab"}>
                                                                                    <div style={{ marginTop: 15 }} className="row">
                                                                                        <div className="col-xl-12">
                                                                                            <div className='row g-3'>
                                                                                                <div className="col-md-12">
                                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                                        <thead className="thead-inverse">
                                                                                                            <tr>
                                                                                                                <th className="align-middle">NOMBRE DOCUMENTO</th>
                                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                (() => {
                                                                                                                    for (let j=0; j<tipoCertificadoData.documents.length; j++) {
                                                                                                                        const documentsData = tipoCertificadoData.documents[j];
                                                                                                                        tdDocuments.push(
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td key={j}>{documentsData}</td>
                                                                                                                                    <td>
                                                                                                                                        <div className="btn-group" role="group" aria-label="">
                                                                                                                                            <button
                                                                                                                                                type="button"
                                                                                                                                                className="btn btn-secondary btn-sm"
                                                                                                                                                data-bs-toggle='modal'
                                                                                                                                                data-bs-target="#PDFFileModal"
                                                                                                                                                slot='end'
                                                                                                                                                onClick={() =>
                                                                                                                                                    this.viewFile(documentsData, tipoCertificadoData.tipo_certificado[0])
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <FontAwesomeIcon icon={faFilePdf} />
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    return tdDocuments;
                                                                                                                })()
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    }
                                                                    
                                                                }
                                                                return divTipoCertificado;
                                                            })()
                                                        }
                                                        {/* <div className="tab-pane fade show active" id="nav-rtefte" role="tabpanel" aria-labelledby="nav-rtefte-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-12">
                                                                            1
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tab-pane fade' id="nav-rteica" role="tabpanel" aria-labelledby="nav-rteica-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-12">
                                                                            2
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tab-pane fade' id="nav-rteiva" role="tabpanel" aria-labelledby="nav-rteiva-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-12">
                                                                            3
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-muted">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="PDFFileModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="PDFFileModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="PDFFileModalLabel">Certificado Tributario</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-xl-12' style={{marginBottom: '30px'}}>
                                        {/* <iframe style={{ width: '100%', height: '600px'}} src={`data:application/pdf;base64,${pdfDGBase64}`}></iframe> */}
                                        <iframe style={{ width: '100%', height: '600px'}} src={pdfDGBase64}></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Listar;