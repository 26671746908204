import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAssistiveListeningSystems, faAt, faCalendarAlt, faDollarSign, faEdit, faFilePdf, faFolderOpen, faFont, faGlobe, faHashtag, faPaperPlane, faPercentage, faPlus, faPray, faPrint, faSave, faSearch, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import AWS from 'aws-sdk';

class CrearTerceros extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: false,
        //Parámetros Sección Guardada\\
        seccion_guardada_dg: '',
        seccion_guardada_cc: '',
        seccion_guardada_b: '',
        seccion_guardada_i: '',
        seccion_guardada_f: '',
        seccion_guardada_pj: '',
        seccion_guardada_pn: '',
        seccion_guardada_vd: '',
        seccion_guardada_bd: '',
        seccion_guardada_ib: '',
        seccion_guardada_esal_dg: '',
        seccion_guardada_rep_legal_esal: '',
        seccion_guardada_ingresos_esal: '',
        seccion_guardada_pep_esal: '',
        seccion_guardada_pj_esal: '',
        //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
        //Parámetros para activar Campos\\
        activar_dv: false,
        activar_nombre: false,
        activar_dpto_mpio: false,
        activar_otros_programas: false,
        activar_otros_programas_esal: false,
        activar_info_empresa: false,
        activar_resolucion: false,
        activar_formulario: false,
        activar_pep_beneficiario: false,
        activar_vinculacion_beneficiario: false,
        activar_sociedad_beneficiario_pep_nacional: false,
        activar_otros_tipos_control_beneficiario_pep_nacional: false,
        activar_cuenta_beneficiario_pep_nacional: false,
        activar_pep_beneficiario_extranjero: false,
        activar_sociedad_beneficiario_pep_extranjero: false,
        activar_otros_tipos_control_beneficiario_pep_extranjero: false,
        activar_cuenta_beneficiario_pep_extranjero: false,
        activar_pep: false,
        activar_vinculacion: false,
        activar_sociedad_pep_nacional: false,
        activar_otros_tipos_control_pep_nacional: false,
        activar_cuenta_pep_nacional: false,
        activar_pep_extranjero: false,
        activar_sociedad_pep_extranjero: false,
        activar_otros_tipos_control_pep_extranjero: false,
        activar_cuenta_pep_extranjero: false,
        activar_enviar: false,

        activar_pep_beneficiario_editar: false,
        activar_vinculacion_beneficiario_editar: false,
        activar_sociedad_beneficiario_pep_nacional_editar: false,
        activar_cuenta_beneficiario_pep_nacional_editar: false,
        activar_sociedad_beneficiario_pep_extranjero_editar: false,
        activar_cuenta_beneficiario_pep_extranjero_editar: false,
        activar_crear_familiar_beneficiario_pep_nacional_edicion: false,
        activar_crear_sociedad_beneficiario_pep_nacional_edicion: false,
        activar_crear_cuenta_beneficiario_pep_nacional_edicion: false,
        activar_crear_familiar_beneficiario_pep_extranjero_edicion: false,
        activar_crear_sociedad_beneficiario_pep_extranjero_edicion: false,
        activar_crear_cuenta_beneficiario_pep_extranjero_edicion: false,

        activar_pep_editar: false,
        activar_vinculacion_editar: false,
        activar_sociedad_pep_nacional_editar: false,
        activar_cuenta_pep_nacional_editar: false,
        activar_sociedad_pep_extranjero_editar: false,
        activar_cuenta_pep_extranjero_editar: false,
        activar_crear_familiar_pep_nacional_edicion: false,
        activar_crear_sociedad_pep_nacional_edicion: false,
        activar_crear_cuenta_pep_nacional_edicion: false,
        activar_crear_familiar_pep_extranjero_edicion: false,
        activar_crear_sociedad_pep_extranjero_edicion: false,
        activar_crear_cuenta_pep_extranjero_edicion: false,
        //Parámetros Información del Formulario\\
        tipo_formulario: '',
        tipo_tercero: '',
        tipo_sociedad: '',
        //Parámetros Pestaña Información General\\
        tipoPersonaDG: '',
        tipo_documento_dg: '',
        numero_identificacion2_dg: '',
        digito_verificacion_dg: '',
        fecha_expedicion_dg: '',
        pais_procedencia_dg: '',
        departamento_expedicion_dg: '',
        municipio_expedicion_dg: '',
        primer_apellido_dg: '',
        segundo_apellido_dg: '',
        primer_nombre_dg: '',
        segundo_nombre_dg: '',
        tamano_empresa_dg: '',
        razon_social_dg: '',
        tipo_empresa_dg: '',
        nombre_comercial_dg: '',
        siglas_dg: '',
        departamento_funcionamiento_dg: '',
        municipio_funcionamiento_dg: '',
        id_direccion_empresa1_dg: '',
        direccion_empresa1_dg: '',
        complemento_direccion_empresa1_dg: '',
        // id_direccion_empresa2_dg: '',
        direccion_empresa2_dg: '',
        complemento_direccion_empresa2_dg: '',
        direccion_empresa3_dg: '',
        complemento_direccion_empresa_dg: '',
        correo_electronico_empresa_dg: '',
        telefono1_dg: '',
        ext_telefono1_dg: '',
        telefono2_dg: '',
        pagina_web_dg: '',
        programa_gestion_riesgo_dg: '',
        otros_programa_gestion_riesgo_dg: '',
        detalle_gestion_riesgo_dg: '',
        files_dg: '',
        //Parámetros Pestaña Información Beneficiarios\\
        nombre_beneficiario_b: '',
        apellido_beneficiario_b: '',
        numero_documento_beneficiario_b: '',
        correo_electronico_beneficiario_b: '',
        telefono_beneficiario_b: '',
        telefono_movil_beneficiario_b: '',
        pep_b: '',
        declaracion_veracidad_pep_b: '',
        vinculo_actual_pep_b: '',
        cargo_actividad_pep_b: '',
        vinculacion_activa_pep_b: '',
        fecha_vinculacion_pep_b: '',
        fecha_desvinculacion_pep_b: '',
        pregunta_participacion_nacional_pep_b: '',
        pregunta_cuenta_extranjera_nacional_pep_b: '',
        pregunta_beneficiario_extranjero_pep_b: '',
        // nombre_beneficiario_extranjero_pep_b: '',
        // numero_documento_beneficiario_extranjero_pep_b: '',
        // vinculo_actual_beneficiario_extranjero_pep_b: '',
        // cargo_actividad_beneficiario_extranjero_pep_b: '',
        // fecha_vinculacion_beneficiario_extranjero_pep_b: '',
        // fecha_desvinculacion_beneficiario_extranjero_pep_b: '',
        pregunta_participacion_extranjero_pep_b: '',
        pregunta_cuenta_extranjera_extranjero_pep_b: '',
        pregunta1_pep_b: '',
        pregunta2_pep_b: '',
        pregunta3_pep_b: '',
        pregunta4_pep_b: '',
        pregunta5_pep_b: '',
        pregunta6_pep_b: '',
        pregunta7_pep_b: '',
        pregunta8_pep_b: '',
        pregunta9_pep_b: '',
        pregunta10_pep_b: '',
        pregunta11_pep_b: '',
        //Parámetros Familiar Beneficiario P.E.P. Nacional
        nombre_familiar_nacional_pep_b : '',
        parentesco_familiar_nacional_pep_b: '',
        tipo_documento_familiar_nacional_pep_b: '',
        numero_documento_familiar_nacional_pep_b: '',
        //Parámetros Sociedad Beneficiario P.E.P. Nacional
        razon_social_participacion_nacional_pep_b: '',
        numero_documento_participacion_nacional_pep_b: '',
        porcentaje_participacion_nacional_pep_b: '',
        tipo_control_participacion_nacional_pep_b: '',
        otros_tipo_control_participacion_nacional_pep_b: '',
        detalle_tipo_control_participacion_nacional_pep_b: '',
        //Parámetros Cuenta Beneficiario P.E.P. Nacional
        razon_social_cuenta_extranjera_nacional_pep_b: '',
        pais_cuenta_extranjera_nacional_pep_b: '',
        numero_cuenta_extranjera_nacional_pep_b: '',
        clase_propiedad_cuenta_extranjera_nacional_pep_b: '',
        //Parámetros Familiar Beneficiario P.E.P. Extranjero
        nombre_familiar_extranjero_pep_b: '',
        parentesco_familiar_extranjero_pep_b: '',
        tipo_documento_familiar_extranjero_pep_b: '',
        numero_documento_familiar_extranjero_pep_b: '',
        //Parámetros Sociedad Beneficiario P.E.P. Extranjero
        razon_social_participacion_extranjero_pep_b: '',
        numero_documento_participacion_extranjero_pep_b: '',
        porcentaje_participacion_extranjero_pep_b: '',
        tipo_control_participacion_extranjero_pep_b: '',
        otros_tipo_control_participacion_extranjero_pep_b: '',
        detalle_tipo_control_participacion_extranjero_pep_b: '',
        //Parámetros Cuenta Beneficiario P.E.P. Extranjero
        razon_social_cuenta_extranjera_extranjero_pep_b: '',
        pais_cuenta_extranjera_extranjero_pep_b: '',
        numero_cuenta_extranjera_extranjero_pep_b: '',
        clase_propiedad_cuenta_extranjera_extranjero_pep_b: '',
        //Parámetros Pestaña Información Contactos\\
        tipo_contacto_c: '',
        nombre_contacto_c: '',
        apellido_contacto_c: '',
        cargo_contacto_c: '',
        correo_electronico_c: '',
        telefono_c: '',
        ext_telefono_c: '',
        telefono_movil_c: '',
        //Parámetros Pestaña Información Compra\\
        proveedor_compra_cc: '',
        bien_servicio_compra_cc: '',
        terminos_compra_cc: '',
        moneda_compra_cc: '',
        poliza_garantia_cc: '',
        //Parámetros Pestaña Información Banco\\
        nombre_banco_b: '',
        numero_cuenta_b: '',
        tipo_cuenta_b: '',
        valido_desde_b: '',
        valido_hasta_b: '',
        nombre_titular_b: '',
        tipo_documento_titular_b: '',
        documento_titular_b: '',
        //Parámetros Pestaña Información Impuestos\\
        calidad_contribuyente_i: '',
        gran_contribuyente_i: '',
        regimen_renta_i: '',
        usuario_zona_franca_i: '',
        retencion_autoretenedor_i: '',
        retencion_resolucion_i: '',
        retencion_fecha_i: '',
        retencion_concepto_i: '',
        retencion_condicion_i: '',
        retencion_factura_electronica_i: '',
        retencion_correo_electronico_envio_i: '',
        retencion_correo_electronico_rechazo_i: '',
        //Parámetros Pestaña Información Financiera\\
        fecha_reporte_financiera_f: '',
        origen_ingresos_f: '',
        total_activos_f: '',
        total_pasivos_f: '',
        patrimonio_f: '',
        ingresos_f: '',
        egresos_f: '',
        otros_ingresos_f: '',
        origen_otros_ingresos_f: '',
        //Parámetros Pestaña Información Gobierno\\
        tipo_rol_r: '',
        capital_representante_r: '',
        pep_r: '',
        nombre_representante_r: '',
        apellido_representante_r: '',
        tipo_documento_representante_r: '',
        numero_documento_representante_r: '',
        digito_verificacion_r: '',
        declaracion_veracidad_pep_r: '',
        vinculo_actual_pep_r: '',
        cargo_actividad_pep_r: '',
        vinculacion_activa_pep_r: '',
        fecha_vinculacion_pep_r: '',
        fecha_desvinculacion_pep_r: '',
        pregunta_participacion_nacional_pep_r: '',
        pregunta_cuenta_extranjera_nacional_pep_r: '',
        pregunta_beneficiario_extranjero_pep_r: '',
        // nombre_beneficiario_extranjero_pep_r: '',
        // numero_documento_beneficiario_extranjero_pep_r: '',
        // vinculo_actual_beneficiario_extranjero_pep_r: '',
        // cargo_actividad_beneficiario_extranjero_pep_r: '',
        // fecha_vinculacion_beneficiario_extranjero_pep_r: '',
        // fecha_desvinculacion_beneficiario_extranjero_pep_r: '',
        pregunta_participacion_extranjero_pep_r: '',
        pregunta_cuenta_extranjera_extranjero_pep_r: '',
        pregunta1_pep_r: '',
        pregunta2_pep_r: '',
        pregunta3_pep_r: '',
        pregunta4_pep_r: '',
        pregunta5_pep_r: '',
        pregunta6_pep_r: '',
        pregunta7_pep_r: '',
        pregunta8_pep_r: '',
        pregunta9_pep_r: '',
        pregunta10_pep_r: '',
        pregunta11_pep_r: '',
        //Parámetros Familiar P.E.P. Nacional
        nombre_familiar_nacional_pep_r : '',
        parentesco_familiar_nacional_pep_r: '',
        tipo_documento_familiar_nacional_pep_r: '',
        numero_documento_familiar_nacional_pep_r: '',
        //Parámetros Sociedad P.E.P. Nacional
        razon_social_participacion_nacional_pep_r: '',
        numero_documento_participacion_nacional_pep_r: '',
        porcentaje_participacion_nacional_pep_r: '',
        tipo_control_participacion_nacional_pep_r: '',
        otros_tipo_control_participacion_nacional_pep_r: '',
        detalle_tipo_control_participacion_nacional_pep_r: '',
        //Parámetros Cuenta P.E.P. Nacional
        razon_social_cuenta_extranjera_nacional_pep_r: '',
        pais_cuenta_extranjera_nacional_pep_r: '',
        numero_cuenta_extranjera_nacional_pep_r: '',
        clase_propiedad_cuenta_extranjera_nacional_pep_r: '',
        //Parámetros Familiar P.E.P. Extranjero
        nombre_familiar_extranjero_pep_r: '',
        parentesco_familiar_extranjero_pep_r : '',
        tipo_documento_familiar_extranjero_pep_r: '',
        numero_documento_familiar_extranjero_pep_r: '',
        //Parámetros Sociedad P.E.P. Extranjero
        razon_social_participacion_extranjero_pep_r: '',
        numero_documento_participacion_extranjero_pep_r: '',
        porcentaje_participacion_extranjero_pep_r: '',
        tipo_control_participacion_extranjero_pep_r: '',
        otros_tipo_control_participacion_extranjero_pep_r: '',
        detalle_tipo_control_participacion_extranjero_pep_r: '',
        //Parámetros Cuenta P.E.P. Extranjero
        razon_social_cuenta_extranjera_extranjero_pep_r: '',
        pais_cuenta_extranjera_extranjero_pep_r: '',
        numero_cuenta_extranjera_extranjero_pep_r: '',
        clase_propiedad_cuenta_extranjera_extranjero_pep_r: '',
        //Parámetros Pestaña Declaración Transparencia Persona Jurídica\\
        pregunta1_pj: '',
        pregunta2_pj: '',
        pregunta3_pj: '',
        pregunta4_pj: '',
        pregunta5_pj: '',
        pregunta6_pj: '',
        pregunta7_pj: '',
        pregunta8_pj: '',
        pregunta9_pj: '',
        pregunta10_pj: '',
        pregunta11_pj: '',
        pregunta12_pj: '',
        pregunta13_pj: '',
        pregunta14_pj: '',
        //Parámetros Pestaña Declaración Transparencia Persona Natural\\
        pregunta1_pn: '',
        pregunta2_pn: '',
        pregunta3_pn: '',
        pregunta4_pn: '',
        pregunta5_pn: '',
        pregunta6_pn: '',
        pregunta7_pn: '',
        pregunta8_pn: '',
        pregunta9_pn: '',
        pregunta10_pn: '',
        pregunta11_pn: '',
        pregunta12_pn: '',
        pregunta13_pn: '',
        //** FORMULARIOS PROVEEDOR EXTERIOR **\\
        //Parámetros Pestaña Vendor Details\\
        nombre_beneficiario_ex: '',
        numero_identificacion_ex: '',
        pais_ex: '',
        estado_ex: '',
        ciudad_ex: '',
        direccion_ex: '',
        telefono1_ex: '',
        telefono2_ex: '',
        fax_ex: '',
        correo_electronico_ex: '',
        nombre_contacto_ex: '',
        codigo_postal_ex: '',
         //Parámetros Pestaña Bank Details\\
        nombre_banco_ex: '',
        codigo_banco_ex: '',
        nombre_sucursal_ex: '',
        numero_sucursal_ex: '',
        direccion_sucursal_banco_ex: '',
        numero_cuenta_banco_ex: '',
        codigo_moneda_banco_ex: '',
        codigo_swift_banco_ex: '',
        numero_aba_banco_ex: '',
        numero_iban_banco_ex: '',
         //Parámetros Pestaña Intermediary Bank Details\\
        nombre_banco_intermediario_ex: '',
        numero_cuenta_banco_intermediario_ex: '',
        codigo_swift_banco_intermediario_ex: '',
        numero_aba_banco_intermediario_ex: '',
        //** FORMULARIOS EMPRESAS E.S.A.L. **\\
        //Parámetros Pestaña Información General\\
        ciudad_esal_dg: '',
        razon_social_esal_dg: '',
        tipo_persona_esal_dg: '',
        tipo_documento_esal_dg: '',
        numero_documento_esal_dg: '',
        digito_verificacion_esal_dg: '',
        fecha_constitucion_esal_dg: '',
        pais_procedencia_esal_dg: '',
        departamento_esal_dg: '',
        municipio_esal_dg: '',
        direccion_empresa_principal_esal_dg: '',
        telefono_empresa_esal_dg: '',
        extension_telefono_empresa_esal_dg: '',
        pagina_web_empresa_esal_dg: '',
        factura_electronica_retencion_esal_dg: '',
        correo_electronico_envio_retencion_esal_dg: '',
        correo_electronico_rechazo_retencion_esal_dg: '',
        contacto_contabilidad_esal_dg: '',
        correo_electronico_contacto_contabilidad_esal_dg: '',
        descripcion_bienes_servicios_esal_dg: '',
        terminos_negociacion_esal_dg: '',
        plazo_pago_facturas_esal_dg: '',
        contacto_proveedor_esal_dg: '',
        correo_electronico_contacto_proveedor_esal_dg: '',
        telefono_contacto_proveedor_esal_dg: '',
        extension_telefono_contacto_proveedor_esal_dg: '',
        celular1_contacto_proveedor_esal_dg: '',
        celular2_contacto_proveedor_esal_dg: '',
        poliza_garantia_esal_dg: '',
        programa_gestion_riesgo_esal_dg: '',
        otros_programa_gestion_riesgo_esal_dg: '',
        detalle_gestion_riesgo_esal_dg: '',
        files_esal_dg: '',
        //Parámetros Pestaña Beneficiarios\\
        banco_beneficiario_esal: '',
        tipo_cuenta_beneficiario_esal: '',
        numero_cuenta_beneficiario_esal: '',
        beneficiario_esal: '',
        numero_cuenta_beneficiario_esal: '',
        numero_documento_beneficiario_esal: '',
        //Parámetros Pestaña Representante Legal\\
        rep_legal_esal: '',
        socio_rep_legal_esal: '',
        tipo_documento_rep_legal_esal: '',
        numero_documento_rep_legal_esal: '',
        expedicion_documento_rep_legal_esal: '',
        cargo_rep_legal_esal: '',
        correo_electronico_rep_legal_esal: '',
        //Parámetros Pestaña Financiera\\
        fecha_reporte_esal: '',
        total_activos_esal: '',
        total_pasivos_esal: '',
        patrimonio_esal: '',
        ingresos_esal:'',
        otros_ingresos_esal: '',
        egresos_esal: '',
        origen_otros_ingresos_esal: '',
        //Parámetros Pestaña Gobierno\\
        tipo_relacion_representante_esal: '',
        representante_esal: '',
        tipo_documento_representante_esal: '',
        numero_documento_representante_esal: '',
        //Parámetros Pestaña P.E.P.\\
        empleado_publico_esal: '',
        beneficiario_final_esal: '',
        //Parámetros Pestaña Declaración Transparencia\\
        pregunta1_esal_pj: '',
        pregunta2_esal_pj: '',
        pregunta3_esal_pj: '',
        pregunta4_esal_pj: '',
        pregunta5_esal_pj: '',
        pregunta6_esal_pj: '',
        pregunta7_esal_pj: '',
        pregunta8_esal_pj: '',
        pregunta9_esal_pj: '',
        pregunta10_esal_pj: '',
        pregunta11_esal_pj: '',
        pregunta12_esal_pj: '',
        pregunta13_esal_pj: '',
        pregunta14_esal_pj: '',
        //Parámetros Vectores\\
        formulario: [],
        formularioDetalle: [],
        beneficiarios: [],
        familiaresBeneficiarioPEPNacional: [],
        sociedadesBeneficiarioPEPNacional: [],
        cuentasBeneficiarioPEPNacional: [],
        familiaresBeneficiarioPEPExtranjero: [],
        sociedadesBeneficiarioPEPExtranjero: [],
        cuentasBeneficiarioPEPExtranjero: [],
        contactos: [],
        representantes: [],
        familiaresPEPNacional: [],
        sociedadesPEPNacional: [],
        cuentasPEPNacional: [],
        familiaresPEPExtranjero: [],
        sociedadesPEPExtranjero: [],
        cuentasPEPExtranjero: [],
        accionistas: [],
        junta_directiva: [],
        beneficiariosESAL: [],
        representantesESAL: [],
        bienes_servicios: [],
        bancos: [],
        bancos_esal: [],
        paises_dg: [],
        paises_esal_dg: [],
        departamentos_expedicion_dg: [],
        municipios_expedicion_dg: [],
        departamentos_funcionamiento_dg: [],
        municipios_funcionamiento_dg: [],
        departamentos_esal_dg: [],
        municipios_esal_dg: [],
        filesUploadedRut: [],
        nameFilesUploadedRut: [],
        filesDG: [],
        pdfDGBase64: null,
        selectedFiles: [],
        filesUploadedESAL: [],
        nameFilesUploadedESAL: [],
        selectedFilesESAL: [],
        filesESAL: [],
        pdfESALBase64: null,
        errores: [],
        //Parametros Vectores Edición\\
        editBeneficiarios: [],
        editFamiliaresBeneficiarioPEPNacional: [],
        editFamiliarBeneficiarioPEPNacional: [],
        editSociedadesBeneficiarioPEPNacional: [],
        editSociedadBeneficiarioPEPNacional: [],
        editCuentasBeneficiarioPEPNacional: [],
        editCuentaBeneficiarioPEPNacional: [],
        editFamiliaresBeneficiarioPEPExtranjero: [],
        editFamiliarBeneficiarioPEPExtranjero: [],
        editSociedadesBeneficiarioPEPExtranjero: [],
        editSociedadBeneficiarioPEPExtranjero: [],
        editCuentasBeneficiarioPEPExtranjero: [],
        editCuentaBeneficiarioPEPExtranjero: [],
        editRepresentantes: [],
        editFamiliaresPEPNacional: [],
        editFamiliarPEPNacional: [],
        editSociedadesPEPNacional: [],
        editSociedadPEPNacional: [],
        editCuentasPEPNacional: [],
        editCuentaPEPNacional: [],
        editFamiliaresPEPExtranjero: [],
        editFamiliarPEPExtranjero: [],
        editSociedadesPEPExtranjero: [],
        editSociedadPEPExtranjero: [],
        editCuentasPEPExtranjero: [],
        editCuentaPEPExtranjero: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    cambioValorFiles = (e) => {
        if (e.target.id === 'files_dg') {
            this.setState({ selectedFiles: e.target.files })
        }
        if (e.target.id === 'files_esal') {
            this.setState({ selectedFiles: e.target.files })
        }
        this.setState({ files: e.target.files });
    }
    cambioValorEditar = (e, tipo_persona) => {
        switch (tipo_persona) {
            case 'beneficiario':
                const stateB = this.state.editBeneficiarios;
                stateB[e.target.name] = e.target.value;
                this.setState({ editBeneficiarios: stateB });
                break;
            case 'familiar_beneficiario_pep_nacional':
                const stateFBN = this.state.editFamiliarBeneficiarioPEPNacional;
                stateFBN[e.target.name] = e.target.value;
                this.setState({ editFamiliarBeneficiarioPEPNacional: stateFBN });
                break;
            case 'sociedad_beneficiario_pep_nacional':
                const stateSBN = this.state.editSociedadBeneficiarioPEPNacional;
                stateSBN[e.target.name] = e.target.value;
                this.setState({ editSociedadBeneficiarioPEPNacional: stateSBN });
                break;
            case 'cuenta_beneficiario_pep_nacional':
                const stateCBN = this.state.editCuentaBeneficiarioPEPNacional;
                stateCBN[e.target.name] = e.target.value;
                this.setState({ editCuentaBeneficiarioPEPNacional: stateCBN });
                break;
            case 'familiar_beneficiario_pep_extranjero':
                const stateFBE = this.state.editFamiliarBeneficiarioPEPExtranjero;
                stateFBE[e.target.name] = e.target.value;
                this.setState({ editFamiliarBeneficiarioPEPExtranjero: stateFBE });
                break;
            case 'sociedad_beneficiario_pep_extranjero':
                const stateSBE = this.state.editSociedadBeneficiarioPEPExtranjero;
                stateSBE[e.target.name] = e.target.value;
                this.setState({ editSociedadBeneficiarioPEPExtranjero: stateSBE });
                break;
            case 'cuenta_beneficiario_pep_extranjero':
                const stateCBE = this.state.editCuentaBeneficiarioPEPExtranjero;
                stateCBE[e.target.name] = e.target.value;
                this.setState({ editCuentaBeneficiarioPEPExtranjero: stateCBE });
                break;
            case 'representante':
                const stateR = this.state.editRepresentantes;
                stateR[e.target.name] = e.target.value;
                this.setState({ editRepresentantes: stateR });
                break;
            case 'familiar_pep_nacional':
                const stateFN = this.state.editFamiliarPEPNacional;
                stateFN[e.target.name] = e.target.value;
                this.setState({ editFamiliarPEPNacional: stateFN });
                break;
            case 'sociedad_pep_nacional':
                const stateSN = this.state.editSociedadPEPNacional;
                stateSN[e.target.name] = e.target.value;
                this.setState({ editSociedadPEPNacional: stateSN });
                break;
            case 'cuenta_pep_nacional':
                const stateCN = this.state.editCuentaPEPNacional;
                stateCN[e.target.name] = e.target.value;
                this.setState({ editCuentaPEPNacional: stateCN });
                break;
            case 'familiar_pep_extranjero':
                const stateFE = this.state.editFamiliarPEPExtranjero;
                stateFE[e.target.name] = e.target.value;
                this.setState({ editFamiliarPEPExtranjero: stateFE });
                break;
            case 'sociedad_pep_extranjero':
                const stateSE = this.state.editSociedadPEPExtranjero;
                stateSE[e.target.name] = e.target.value;
                this.setState({ editSociedadPEPExtranjero: stateSE });
                break;
            case 'cuenta_pep_extranjero':
                const stateCE = this.state.editCuentaPEPExtranjero;
                stateCE[e.target.name] = e.target.value;
                this.setState({ editCuentaPEPExtranjero: stateCE });
                break;
        }
    }
    aceptarTerminos = (e, tipo_campo) => {
        switch (tipo_campo) {
            case 'declaracion_veracidad':
                if (e.target.value === 'NO') {
                    Swal.fire({
                        title: 'Está seguro de No aceptar la Declaración de Veracidad?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'No Aceptar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var id_usuario = document.getElementById('id_usuario').value;
                            authAxios.get("formulario/close_session/" + id_usuario)
                                .then((datosRespuesta) => {
                                    console.log(datosRespuesta);
                                    Swal.fire('Sesión Cerrada!', datosRespuesta.data.msj, 'success')
                                    window.location.href = "/";
                                    window.localStorage.clear();
                                })
                                .catch(console.log);
                        } else {
                            document.getElementById("declaracion_veracidad_pep_r").selectedIndex = "0";
                        }
                    })
                }
                break;
            case 'declaracion_veracidad_beneficiario':
                if (e.target.value === 'NO') {
                    Swal.fire({
                        title: 'Está seguro de No aceptar la Declaración de Veracidad?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'No Aceptar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var id_usuario = document.getElementById('id_usuario').value;
                            authAxios.get("formulario/close_session/" + id_usuario)
                                .then((datosRespuesta) => {
                                    console.log(datosRespuesta);
                                    Swal.fire('Sesión Cerrada!', datosRespuesta.data.msj, 'success')
                                    window.location.href = "/";
                                    window.localStorage.clear();
                                })
                                .catch(console.log);
                        } else {
                            document.getElementById("declaracion_veracidad_pep_b").selectedIndex = "0";
                        }
                    })
                }
                break;
        }
    }
    activarNombre = (e) => {
        if (e.target.value === 'PERSONA_NATURAL') {
            this.setState({ activar_nombre: true })
        } else {
            this.setState({ activar_nombre: false })
        }
    }
    activarDV = (e) => {
        if (e.target.value === 'PERSONA_JURIDICA') {
            this.setState({ activar_dv: true })
        } else {
            this.setState({ activar_dv: false })
        }
    }
    activarDptoMpio = (e) => {
        if (e.target.value === 'Colombia') {
            this.setState({ activar_dpto_mpio: false })
        } else {
            this.setState({ activar_dpto_mpio: true })
        }
    }
    activarInfoEmpresa = (e) => {
        if (e.target.value === 'PERSONA_JURIDICA') {
            this.setState({ activar_info_empresa: true })
        } else {
            this.setState({ activar_info_empresa: false })
        }
    }
    activarOtrosProgramas = (e) => {
        if (e.target.value === 'OTROS_PROGRAMAS') {
            this.setState({ activar_otros_programas: true })
        } else {
            this.setState({ activar_otros_programas: false })
        }
    }
    activarOtrosProgramasEsal = (e) => {
        if (e.target.value === 'OTROS_PROGRAMAS') {
            this.setState({ activar_otros_programas_esal: true })
        } else {
            this.setState({ activar_otros_programas_esal: false })
        }
    }
    activarResolucion = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_resolucion: true })
        } else {
            this.setState({ activar_resolucion: false })
        }
    }
    activarPepBeneficiario = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_pep_beneficiario: true })
        } else {
            this.setState({ activar_pep_beneficiario: false })
        }
    }
    activarVinculacionBeneficiario = (e) => {
        if (e.target.value === 'NO') {
            this.setState({ activar_vinculacion_beneficiario: true })
        } else {
            this.setState({ activar_vinculacion_beneficiario: false })
        }
    }
    activarSociedadBeneficiarioPepNacional = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_beneficiario_pep_nacional: true })
        } else {
            this.setState({ activar_sociedad_beneficiario_pep_nacional: false })
        }
    }
    activarOtrosTipoControlBeneficiarioPepNacional = (e) => {
        if (e.target.value === 'OTROS_TIPO_CONTROL') {
            this.setState({ activar_otros_tipos_control_beneficiario_pep_nacional: true })
        } else {
            this.setState({ activar_otros_tipos_control_beneficiario_pep_nacional: false })
        }
    }
    activarCuentaBeneficiarioPepNacional = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_beneficiario_pep_nacional: true })
        } else {
            this.setState({ activar_cuenta_beneficiario_pep_nacional: false })
        }
    }
    activarPepBeneficiarioExtranjero = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_pep_beneficiario_extranjero: true })
        } else {
            this.setState({ activar_pep_beneficiario_extranjero: false })
        }
    }
    activarSociedadBeneficiarioPepExtranjero = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_beneficiario_pep_extranjero: true })
        } else {
            this.setState({ activar_sociedad_beneficiario_pep_extranjero: false })
        }
    }
    activarOtrosTipoControlBeneficiarioPepExtranjero = (e) => {
        if (e.target.value === 'OTROS_TIPO_CONTROL') {
            this.setState({ activar_otros_tipos_control_beneficiario_pep_extranjero: true })
        } else {
            this.setState({ activar_otros_tipos_control_beneficiario_pep_extranjero: false })
        }
    }
    activarCuentaBeneficiarioPepExtranjero = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_beneficiario_pep_extranjero: true })
        } else {
            this.setState({ activar_cuenta_beneficiario_pep_extranjero: false })
        }
    }
    activarPep = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_pep: true })
        } else {
            this.setState({ activar_pep: false })
        }
    }
    activarVinculacion = (e) => {
        if (e.target.value === 'NO') {
            this.setState({ activar_vinculacion: true })
        } else {
            this.setState({ activar_vinculacion: false })
        }
    }
    activarSociedadPepNacional = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_pep_nacional: true })
        } else {
            this.setState({ activar_sociedad_pep_nacional: false })
        }
    }
    activarOtrosTipoControlPepNacional = (e) => {
        if (e.target.value === 'OTROS_TIPO_CONTROL') {
            this.setState({ activar_otros_tipos_control_pep_nacional: true })
        } else {
            this.setState({ activar_otros_tipos_control_pep_nacional: false })
        }
    }
    activarCuentaPepNacional = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_pep_nacional: true })
        } else {
            this.setState({ activar_cuenta_pep_nacional: false })
        }
    }
    activarPepExtranjero = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_pep_extranjero: true })
        } else {
            this.setState({ activar_pep_extranjero: false })
        }
    }
    activarSociedadPepExtranjero = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_pep_extranjero: true })
        } else {
            this.setState({ activar_sociedad_pep_extranjero: false })
        }
    }
    activarOtrosTipoControlPepExtranjero = (e) => {
        if (e.target.value === 'OTROS_TIPO_CONTROL') {
            this.setState({ activar_otros_tipos_control_pep_extranjero: true })
        } else {
            this.setState({ activar_otros_tipos_control_pep_extranjero: false })
        }
    }
    activarCuentaPepExtranjero = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_pep_extranjero: true })
        } else {
            this.setState({ activar_cuenta_pep_extranjero: false })
        }
    }
    activarPepBeneficiarioEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_pep_beneficiario_editar: true })
        } else {
            this.setState({ activar_pep_beneficiario_editar: false })
        }
    }
    activarVinculacionBeneficiarioEditar = (e) => {
        if (e.target.value === 'NO') {
            this.setState({ activar_vinculacion_beneficiario_editar: true })
        } else {
            this.setState({ activar_vinculacion_beneficiario_editar: false })
        }
    }
    activarSociedadBeneficiarioPepNacionalEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_beneficiario_pep_nacional_editar: true })
        } else {
            this.setState({ activar_sociedad_beneficiario_pep_nacional_editar: false })
        }
    }
    activarCuentaBeneficiarioPepNacionalEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_beneficiario_pep_nacional_editar: true })
        } else {
            this.setState({ activar_cuenta_beneficiario_pep_nacional_editar: false })
        }
    }
    activarSociedadBeneficiarioPepExtranjeroEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_beneficiario_pep_extranjero_editar: true })
        } else {
            this.setState({ activar_sociedad_beneficiario_pep_extranjero_editar: false })
        }
    }
    activarCuentaBeneficiarioPepExtranjeroEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_beneficiario_pep_extranjero_editar: true })
        } else {
            this.setState({ activar_cuenta_beneficiario_pep_extranjero_editar: false })
        }
    }
    activarPepEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_pep_editar: true })
        } else {
            this.setState({ activar_pep_editar: false })
        }
    }
    activarVinculacionEditar = (e) => {
        if (e.target.value === 'NO') {
            this.setState({ activar_vinculacion_editar: true })
        } else {
            this.setState({ activar_vinculacion_editar: false })
        }
    }
    activarSociedadPepNacionalEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_pep_nacional_editar: true })
        } else {
            this.setState({ activar_sociedad_pep_nacional_editar: false })
        }
    }
    activarCuentaPepNacionalEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_pep_nacional_editar: true })
        } else {
            this.setState({ activar_cuenta_pep_nacional_editar: false })
        }
    }
    activarSociedadPepExtranjeroEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_sociedad_pep_extranjero_editar: true })
        } else {
            this.setState({ activar_sociedad_pep_extranjero_editar: false })
        }
    }
    activarCuentaPepExtranjeroEditar = (e) => {
        if (e.target.value === 'SI') {
            this.setState({ activar_cuenta_pep_extranjero_editar: true })
        } else {
            this.setState({ activar_cuenta_pep_extranjero_editar: false })
        }
    }
    validateEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email);
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    verifyFileSize = (e) => {
        let file = e.target.files[0];
        if (file && !file.name) {
            window.alert("Please select a file");
            return false;
        }
        if (file.size > 10e6) {
            Swal.fire(
                'Cargue Archivo',
                'El tamaño del archivo sobrepasa los 10MB. Favor verificar.',
                'error'
            )
            e.target.value = null;
            return false;
        }
    }
    aceptarPreguntas = (e, tipo_persona, campo) => {
        if (e.target.value === 'NO') {
            Swal.fire({
                title: 'Está seguro de responder No a la Pregunta?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'No Aceptar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                    window.localStorage.clear();
                } else {
                    document.getElementById(campo).selectedIndex = "0";
                }
            })
        }
    }
    borrarRegistrosPersona = (tipo_persona, id) => {
        switch (tipo_persona) {
            case 'beneficiario':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Beneficiario?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_beneficiario/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_beneficiario/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, beneficiarios: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'familiar_beneficiario_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar Beneficiario P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_beneficiario_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_beneficiario_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'sociedad_beneficiario_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad Beneficiario P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_beneficiario_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_beneficiario_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'cuenta_beneficiario_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta Beneficiario P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_beneficiario_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_beneficiario_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'familiar_beneficiario_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar Beneficiario P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_beneficiario_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_beneficiario_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'sociedad_beneficiario_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad Beneficiario P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_beneficiario_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_beneficiario_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'cuenta_beneficiario_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta Beneficiario P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_beneficiario_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_beneficiario_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_familiar_beneficiario_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar Beneficiario P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_beneficiario_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_beneficiario_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                                authAxios.get("contraparte/view_familiares_beneficiario_nacional/" + e_id_beneficiario_b)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_sociedad_beneficiario_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad Beneficiario P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_beneficiario_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_beneficiario_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                                authAxios.get("contraparte/view_sociedades_beneficiario_nacional/" + e_id_beneficiario_b)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_cuenta_beneficiario_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta Beneficiario P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_beneficiario_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_beneficiario_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                                authAxios.get("contraparte/view_cuentas_beneficiario_nacional/" + e_id_beneficiario_b)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_familiar_beneficiario_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar Beneficiario P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_beneficiario_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_beneficiario_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                                authAxios.get("contraparte/view_familiares_beneficiario_extranjero/" + e_id_beneficiario_b)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_sociedad_beneficiario_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad Beneficiario P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_beneficiario_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_beneficiario_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                                authAxios.get("contraparte/view_sociedades_beneficiario_extranjero/" + e_id_beneficiario_b)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_cuenta_beneficiario_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta Beneficiario P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_beneficiario_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_beneficiario_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                                authAxios.get("contraparte/view_cuentas_beneficiario_extranjero/" + e_id_beneficiario_b)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'contacto':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Contacto?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_contacto/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_contacto/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, contactos: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'representante':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Representante?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_representante/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_representante/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, representantes: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'familiar_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'sociedad_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'cuenta_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'familiar_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'sociedad_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'cuenta_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_familiar_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                                authAxios.get("contraparte/view_familiares_nacional/" + e_id_representante_r)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editFamiliaresPEPNacional: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_sociedad_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                                authAxios.get("contraparte/view_sociedades_nacional/" + e_id_representante_r)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editSociedadesPEPNacional: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_cuenta_pep_nacional':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta P.E.P. Nacional?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_nacional/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_nacional/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasPEPNacional: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                                authAxios.get("contraparte/view_cuentas_nacional/" + e_id_representante_r)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editCuentasPEPNacional: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_familiar_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Familiar P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_familiar_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_familiar_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, familiaresPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                                authAxios.get("contraparte/view_familiares_extranjero/" + e_id_representante_r)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editFamiliaresPEPExtranjero: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_sociedad_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Sociedad P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_sociedad_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_sociedad_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, sociedadesPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                                authAxios.get("contraparte/view_sociedades_extranjero/" + e_id_representante_r)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editSociedadesPEPExtranjero: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'edit_cuenta_pep_extranjero':
                Swal.fire({
                    title: 'Está seguro de Eliminar la Cuenta P.E.P. Extranjero?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_cuenta_extranjero/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_cuenta_extranjero/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, cuentasPEPExtranjero: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                                var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                                authAxios.get("contraparte/view_cuentas_extranjero/" + e_id_representante_r)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, editCuentasPEPExtranjero: datosRespuesta.data.data });
                                        Swal.close();
                                    })
                                .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'beneficiadios_ESAL':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Beneficiario?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_beneficiario_esal/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_beneficiario_esal/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, beneficiariosESAL: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'representantesESAL':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Representante?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_representante_esal/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_representante_esal/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, representantesESAL: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'accionista':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Accionista?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_accionista/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_accionista/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, accionistas: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'junta_directiva':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Miembro de la Junta Directiva?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_junta_directiva/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_junta_directiva/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, junta_directiva: datosRespuesta.data.data });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            default:
                break;
        }
    };
    borrarFiles = (archivo, nombre_archivo) => {
        switch (archivo) {
            case 'files_dg':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Archivo?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("formulario/delete_file/" + this.props.match.params.id + "/" + nombre_archivo)
                            .then((datosRespuesta) => {
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                this.setState({ filesDG: datosRespuesta.data.files })
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'files_esal':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Archivo?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("formulario/delete_file/" + this.props.match.params.id + "/" + nombre_archivo)
                            .then((datosRespuesta) => {
                                Swal.fire('Borrado!', 'Se ha eliminado con exito.', 'success')
                                this.setState({ filesDG: datosRespuesta.data.files })
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
        }
    }
    arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }
    viewFile = async (fileName) => {
        const file_name = decodeURIComponent(fileName);
        Swal.showLoading();
        this.setState({ pdfDGBase64: null });
        await authAxios.get("formulario/view_file/" + this.props.match.params.id + "/" + fileName, { responseType: 'blob' })
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                const pdfBase64 = URL.createObjectURL(datosRespuesta.data);
                this.setState({ pdfDGBase64: pdfBase64 });
                Swal.close();
            })
            .catch(console.log);
    }
    preGuardarDatos = async (e, tab) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
        })
        e.preventDefault();
        var tipo_tercero = document.getElementById('tipo_tercero').value;
        var tipo_formulario = document.getElementById('tipo_formulario').value;
        var tipo_sociedad = document.getElementById('tipo_sociedad').value;
        let form = document.getElementById('form-tercero');
        let formData = new FormData(form);
        switch (tipo_tercero) {
            case '1':
            case '3':
            case '4':
                console.log(tipo_tercero, tab);
                switch (tab) {
                    case 'datos_generales':
                        var errores = [];
                        var seccion_guardada_dg = document.getElementById('seccion_guardada_dg').value;
                        var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                        var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                        var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                        console.log(tipoPersonaDG);
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                                var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                                var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                                var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                                break;
                            case 'PERSONA_JURIDICA':
                                var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                            case 'EXTERIOR':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                        }
                        var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                        var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                        var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                        switch (tipo_tercero) {
                            case '1':
                                switch (tipoPersonaDG) {
                                    case 'PERSONA_JURIDICA':
                                        var pais_procedencia_dg = document.getElementById('pais_procedencia_dg').value;
                                        var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                                        var razon_social_dg = document.getElementById('razon_social_dg').value;
                                        var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                                        var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                                        var siglas_dg = document.getElementById('siglas_dg').value;
                                        break;
                                }
                                break;
                            case '3':
                            case '4':
                                var pais_procedencia_dg = document.getElementById('pais_procedencia_dg').value;
                                var tamano_empresa_dg = 1;
                                var razon_social_dg = '-';
                                var tipo_empresa_dg = '-';
                                var nombre_comercial_dg = '-';
                                var siglas_dg = '-';
                                break;
                            default:
                                var pais_procedencia_dg = '-';
                                var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                                var razon_social_dg = document.getElementById('razon_social_dg').value;
                                var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                                var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                                var siglas_dg = document.getElementById('siglas_dg').value;
                                break;
                        }
                        var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                        var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                        var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                        var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                        var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                        // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                        var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                        var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                        var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                        var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                        var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                        var telefono1_dg = document.getElementById('telefono1_dg').value;
                        var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                        var telefono2_dg = document.getElementById('telefono2_dg').value;
                        var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                        var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                        } else {
                            var otros_programa_gestion_riesgo_dg = '-';
                        }
                        var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                        if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                        if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                        if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                                if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                                if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                                // if (!segundo_nombre_dg) { errores.push("error_segundo_nombre_dg"); }
                                break;
                            case 'PERSONA_JURIDICA':
                                if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                                break;
                        }
                        if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                        if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                        if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                        switch (tipo_tercero) {
                            case '1':
                                switch (tipoPersonaDG) {
                                    case 'PERSONA_JURIDICA':
                                        if (!pais_procedencia_dg) { errores.push("error_pais_procedencia_dg"); }
                                        if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                                        if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                                        if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                                        break;
                                }
                                break;
                            case '3':
                            case '4':
                                if (!pais_procedencia_dg) { errores.push("error_pais_procedencia_dg"); }
                                break;
                            case '2':
                                if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                                if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                                if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                                // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                                // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                                break;
                        }
                        if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                        if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                        if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                        if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                        // if (!complemento_direccion_empresa1_dg) { errores.push("error_complemento_direccion_empresa1_dg"); }
                        // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                        if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                        // if (!complemento_direccion_empresa2_dg) { errores.push("error_complemento_direccion_empresa2_dg"); }
                        if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                        // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                        if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                        if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                        // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                        // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                        // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                        if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }   
                        }
                        // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                        if (!this.validateEmail(correo_electronico_empresa_dg)) {
                            errores.push("error_correo_electronico_empresa_dg");
                            Swal.fire({
                                title: 'Formularios',
                                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    errores.push("error_correo_electronico_empresa_dg");
                                }
                            })
                        }
                        // for (let i = 0; i < this.state.selectedFiles.length; i++) {
                        //     formData.append('files_dg[]', selectedFiles[i]);
                        // }
                        for (let file of this.state.selectedFiles) {
                            formData.append('files_dg[]', file);
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_dg === '') {
                                formData.append('seccion_guardada_dg', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_dg').value = 'OK';
                            }
                        }
                        break;
                    // case 'condiciones_compra':
                    //     var errores = [];
                    //     var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                    //     var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                    //     var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                    //     var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                    //     var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                    //     if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                    //     if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                    //     if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                    //     if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                    //     if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                    //     break;
                    case 'banco':
                        var errores = [];
                        var seccion_guardada_b = document.getElementById('seccion_guardada_b').value;
                        var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                        var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                        var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                        // var valido_desde_b = document.getElementById('valido_desde_b').value;
                        // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                        var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                        var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                        var documento_titular_b = document.getElementById('documento_titular_b').value;
                        if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                        if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                        if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                        // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                        // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                        if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                        if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                        if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_b === '') {
                                formData.append('seccion_guardada_b', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_b').value = 'OK';
                            }
                        }
                        break;
                    case 'impuestos':
                        var errores = [];
                        var seccion_guardada_i = document.getElementById('seccion_guardada_i').value;
                        var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                        var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                        var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                        var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                        var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                        if (retencion_autoretenedor_i === 'SI') {
                            var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                            var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                            var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                            var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                        } else {
                            var retencion_condicion_i = '-';
                            var retencion_resolucion_i = '-';
                            var retencion_fecha_i = '1901-01-01';
                            var retencion_concepto_i = '-';
                        }
                        var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                        if (retencion_factura_electronica_i === 'SI') {
                            var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                            var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                        } else {
                            var retencion_correo_electronico_envio_i = '-';
                            var retencion_correo_electronico_rechazo_i = '-';
                        }
                        if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                        if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                        if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                        if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                        if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                        if (retencion_autoretenedor_i === 'SI') {
                            if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                            if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                            if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                            if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                        }
                        if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                        if (retencion_factura_electronica_i === 'SI') {
                            if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                            if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                            if (!this.validateEmail(retencion_correo_electronico_envio_i)) {
                                errores.push("error_retencion_correo_electronico_envio_i");
                                Swal.fire({
                                    title: 'Formularios',
                                    text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        errores.push("error_retencion_correo_electronico_envio_i");
                                    }
                                })
                            }
                            if (!this.validateEmail(retencion_correo_electronico_rechazo_i)) {
                                errores.push("error_retencion_correo_electronico_rechazo_i");
                                Swal.fire({
                                    title: 'Formularios',
                                    text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        errores.push("error_retencion_correo_electronico_rechazo_i");
                                    }
                                })
                            }
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_i === '') {
                                formData.append('seccion_guardada_i', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_i').value = 'OK';
                            }
                        }
                        break;
                    case 'financiera':
                        var errores = [];
                        var seccion_guardada_f = document.getElementById('seccion_guardada_f').value;
                        var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                        var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                        var total_activos_f = document.getElementById('total_activos_f').value;
                        var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                        var patrimonio_f = document.getElementById('patrimonio_f').value;
                        var ingresos_f = document.getElementById('ingresos_f').value;
                        var egresos_f = document.getElementById('egresos_f').value;
                        var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                        // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                        if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                        if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                        if (!total_activos_f) { errores.push("error_total_activos_f"); }
                        if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                        if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                        if (!ingresos_f) { errores.push("error_ingresos_f"); }
                        if (!egresos_f) { errores.push("error_egresos_f"); }
                        if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                        // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_f === '') {
                                formData.append('seccion_guardada_f', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_f').value = 'OK';
                            }
                        }
                        break;
                    case 'declaracion_transp_pn':
                        // if (tipo_tercero === '7') {
                            var errores = [];
                            var seccion_guardada_pn = document.getElementById('seccion_guardada_pn').value;
                            var pregunta1_pn = document.getElementById('pregunta1_pn').value;
                            var pregunta2_pn = document.getElementById('pregunta2_pn').value;
                            var pregunta3_pn = document.getElementById('pregunta3_pn').value;
                            var pregunta4_pn = document.getElementById('pregunta4_pn').value;
                            var pregunta5_pn = document.getElementById('pregunta5_pn').value;
                            var pregunta6_pn = document.getElementById('pregunta6_pn').value;
                            var pregunta7_pn = document.getElementById('pregunta7_pn').value;
                            var pregunta8_pn = document.getElementById('pregunta8_pn').value;
                            var pregunta9_pn = document.getElementById('pregunta9_pn').value;
                            var pregunta10_pn = document.getElementById('pregunta10_pn').value;
                            var pregunta11_pn = document.getElementById('pregunta11_pn').value;
                            var pregunta12_pn = document.getElementById('pregunta12_pn').value;
                            var pregunta13_pn = document.getElementById('pregunta13_pn').value;
                            // if (tipo_tercero === '7') {
                                if (!pregunta1_pn) { errores.push("error_pregunta1_pn"); }
                                if (!pregunta2_pn) { errores.push("error_pregunta2_pn"); }
                                if (!pregunta3_pn) { errores.push("error_pregunta3_pn"); }
                                if (!pregunta4_pn) { errores.push("error_pregunta4_pn"); }
                                if (!pregunta5_pn) { errores.push("error_pregunta5_pn"); }
                                if (!pregunta6_pn) { errores.push("error_pregunta6_pn"); }
                                if (!pregunta7_pn) { errores.push("error_pregunta7_pn"); }
                                if (!pregunta8_pn) { errores.push("error_pregunta8_pn"); }
                                if (!pregunta9_pn) { errores.push("error_pregunta9_pn"); }
                                if (!pregunta10_pn) { errores.push("error_pregunta10_pn"); }
                                if (!pregunta11_pn) { errores.push("error_pregunta11_pn"); }
                                if (!pregunta12_pn) { errores.push("error_pregunta12_pn"); }
                                if (!pregunta13_pn) { errores.push("error_pregunta13_pn"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_pn === '') {
                                        formData.append('seccion_guardada_pn', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_pn').value = 'OK';
                                    }
                                }
                            // }
                        // }
                        break;
                }
                break;
            case '2':
                console.log(tipo_tercero, tab);
                switch (tab) {
                    case 'datos_generales':
                        var errores = [];
                        var seccion_guardada_dg = document.getElementById('seccion_guardada_dg').value;
                        var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                        var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                        var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                        console.log(tipoPersonaDG);
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                                var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                                var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                                var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                                break;
                            case 'PERSONA_JURIDICA':
                                var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                            case 'EXTERIOR':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                        }
                        var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                        var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                        var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                        var pais_procedencia_dg = '-';
                        var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                        var razon_social_dg = document.getElementById('razon_social_dg').value;
                        var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                        var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                        var siglas_dg = document.getElementById('siglas_dg').value;
                        var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                        var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                        var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                        var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                        var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                        // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                        var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                        var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                        var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                        var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                        var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                        var telefono1_dg = document.getElementById('telefono1_dg').value;
                        var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                        var telefono2_dg = document.getElementById('telefono2_dg').value;
                        var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                        var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                        } else {
                            var otros_programa_gestion_riesgo_dg = '-';
                        }
                        var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                        if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                        if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                        if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                                if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                                if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                                // if (!segundo_nombre_dg) { errores.push("error_segundo_nombre_dg"); }
                                break;
                            case 'PERSONA_JURIDICA':
                                if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                                break;
                        }
                        if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                        if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                        if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                        if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                        if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                        if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                        // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                        // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                        if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                        if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                        if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                        if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                        // if (!complemento_direccion_empresa1_dg) { errores.push("error_complemento_direccion_empresa1_dg"); }
                        // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                        if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                        // if (!complemento_direccion_empresa2_dg) { errores.push("error_complemento_direccion_empresa2_dg"); }
                        if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                        // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                        if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                        if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                        // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                        // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                        // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                        if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }
                        }
                        // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                        if (!this.validateEmail(correo_electronico_empresa_dg)) {
                            errores.push("error_correo_electronico_empresa_dg");
                            Swal.fire({
                                title: 'Formularios',
                                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    errores.push("error_correo_electronico_empresa_dg");
                                }
                            })
                        }
                        // for (let i = 0; i < this.state.selectedFiles.length; i++) {
                        //     formData.append('files_dg[]', selectedFiles[i]);
                        // }
                        for (let file of this.state.selectedFiles) {
                            formData.append('files_dg[]', file);
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_dg === '') {
                                formData.append('seccion_guardada_dg', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_dg').value = 'OK';
                            }
                        }
                        break;
                    case 'condiciones_compra':
                        var errores = [];
                        var seccion_guardada_cc = document.getElementById('seccion_guardada_cc').value;
                        var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                        var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                        var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                        var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                        var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                        if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                        if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                        if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                        if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                        if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_cc === '') {
                                formData.append('seccion_guardada_cc', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_cc').value = 'OK';
                            }
                        }
                        break;
                    case 'banco':
                        var errores = [];
                        var seccion_guardada_b = document.getElementById('seccion_guardada_b').value;
                        var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                        var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                        var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                        // var valido_desde_b = document.getElementById('valido_desde_b').value;
                        // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                        var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                        var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                        var documento_titular_b = document.getElementById('documento_titular_b').value;
                        if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                        if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                        if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                        // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                        // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                        if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                        if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                        if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_b === '') {
                                formData.append('seccion_guardada_b', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_b').value = 'OK';
                            }
                        }
                        break;
                    case 'impuestos':
                        var errores = [];
                        var seccion_guardada_i = document.getElementById('seccion_guardada_i').value;
                        var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                        var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                        var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                        var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                        var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                        if (retencion_autoretenedor_i === 'SI') {
                            var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                            var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                            var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                            var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                        } else {
                            var retencion_condicion_i = '-';
                            var retencion_resolucion_i = '-';
                            var retencion_fecha_i = '1901-01-01';
                            var retencion_concepto_i = '-';
                        }
                        var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                        if (retencion_factura_electronica_i === 'SI') {
                            var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                            var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                        } else {
                            var retencion_correo_electronico_envio_i = '-';
                            var retencion_correo_electronico_rechazo_i = '-';
                        }
                        if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                        if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                        if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                        if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                        if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                        if (retencion_autoretenedor_i === 'SI') {
                            if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                            if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                            if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                            if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                        }
                        if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                        if (retencion_factura_electronica_i === 'SI') {
                            if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                            if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                            if (!this.validateEmail(retencion_correo_electronico_envio_i)) {
                                errores.push("error_retencion_correo_electronico_envio_i");
                                Swal.fire({
                                    title: 'Formularios',
                                    text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        errores.push("error_retencion_correo_electronico_envio_i");
                                    }
                                })
                            }
                            if (!this.validateEmail(retencion_correo_electronico_rechazo_i)) {
                                errores.push("error_retencion_correo_electronico_rechazo_i");
                                Swal.fire({
                                    title: 'Formularios',
                                    text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        errores.push("error_retencion_correo_electronico_rechazo_i");
                                    }
                                })
                            }
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_i === '') {
                                formData.append('seccion_guardada_i', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_i').value = 'OK';
                            }
                        }
                        break;
                    case 'financiera':
                        var errores = [];
                        var seccion_guardada_f = document.getElementById('seccion_guardada_f').value;
                        var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                        var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                        var total_activos_f = document.getElementById('total_activos_f').value;
                        var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                        var patrimonio_f = document.getElementById('patrimonio_f').value;
                        var ingresos_f = document.getElementById('ingresos_f').value;
                        var egresos_f = document.getElementById('egresos_f').value;
                        var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                        // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                        if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                        if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                        if (!total_activos_f) { errores.push("error_total_activos_f"); }
                        if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                        if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                        if (!ingresos_f) { errores.push("error_ingresos_f"); }
                        if (!egresos_f) { errores.push("error_egresos_f"); }
                        if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                        // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_f === '') {
                                formData.append('seccion_guardada_f', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_f').value = 'OK';
                            }
                        }
                        break;
                    case 'declaracion_transp_pn':
                        // if (tipo_tercero === '7') {
                            var errores = [];
                            var seccion_guardada_pn = document.getElementById('seccion_guardada_pn').value;
                            var pregunta1_pn = document.getElementById('pregunta1_pn').value;
                            var pregunta2_pn = document.getElementById('pregunta2_pn').value;
                            var pregunta3_pn = document.getElementById('pregunta3_pn').value;
                            var pregunta4_pn = document.getElementById('pregunta4_pn').value;
                            var pregunta5_pn = document.getElementById('pregunta5_pn').value;
                            var pregunta6_pn = document.getElementById('pregunta6_pn').value;
                            var pregunta7_pn = document.getElementById('pregunta7_pn').value;
                            var pregunta8_pn = document.getElementById('pregunta8_pn').value;
                            var pregunta9_pn = document.getElementById('pregunta9_pn').value;
                            var pregunta10_pn = document.getElementById('pregunta10_pn').value;
                            var pregunta11_pn = document.getElementById('pregunta11_pn').value;
                            var pregunta12_pn = document.getElementById('pregunta12_pn').value;
                            var pregunta13_pn = document.getElementById('pregunta13_pn').value;
                            // if (tipo_tercero === '7') {
                                if (!pregunta1_pn) { errores.push("error_pregunta1_pn"); }
                                if (!pregunta2_pn) { errores.push("error_pregunta2_pn"); }
                                if (!pregunta3_pn) { errores.push("error_pregunta3_pn"); }
                                if (!pregunta4_pn) { errores.push("error_pregunta4_pn"); }
                                if (!pregunta5_pn) { errores.push("error_pregunta5_pn"); }
                                if (!pregunta6_pn) { errores.push("error_pregunta6_pn"); }
                                if (!pregunta7_pn) { errores.push("error_pregunta7_pn"); }
                                if (!pregunta8_pn) { errores.push("error_pregunta8_pn"); }
                                if (!pregunta9_pn) { errores.push("error_pregunta9_pn"); }
                                if (!pregunta10_pn) { errores.push("error_pregunta10_pn"); }
                                if (!pregunta11_pn) { errores.push("error_pregunta11_pn"); }
                                if (!pregunta12_pn) { errores.push("error_pregunta12_pn"); }
                                if (!pregunta13_pn) { errores.push("error_pregunta13_pn"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_pn === '') {
                                        formData.append('seccion_guardada_pn', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_pn').value = 'OK';
                                    }
                                }
                            // }
                        // }
                        break;
                }
                break;
            case '7':
                console.log(tipo_tercero, tab);
                switch (tab) {
                    case 'datos_generales':
                        var errores = [];
                        var seccion_guardada_dg = document.getElementById('seccion_guardada_dg').value;
                        var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                        var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                        var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                        console.log(tipoPersonaDG);
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                                var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                                var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                                var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                                break;
                            case 'PERSONA_JURIDICA':
                                var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                            case 'EXTERIOR':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                        }
                        var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                        var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                        var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                        switch (tipo_tercero) {
                            case '1':
                            case '3':
                            case '4':
                            case '7':
                                var pais_procedencia_dg = document.getElementById('pais_procedencia_dg').value;
                                var tamano_empresa_dg = 1;
                                var razon_social_dg = '-';
                                var tipo_empresa_dg = '-';
                                var nombre_comercial_dg = '-';
                                var siglas_dg = '-';
                                break;
                            default:
                                var pais_procedencia_dg = '-';
                                var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                                var razon_social_dg = document.getElementById('razon_social_dg').value;
                                var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                                var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                                var siglas_dg = document.getElementById('siglas_dg').value;
                                break;
                        }
                        var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value
                        var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                        var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                        var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                        var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                        // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                        var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                        var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                        var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                        var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                        var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                        var telefono1_dg = document.getElementById('telefono1_dg').value;
                        var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                        var telefono2_dg = document.getElementById('telefono2_dg').value;
                        var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                        var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                        } else {
                            var otros_programa_gestion_riesgo_dg = '-';
                        }
                        var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                        if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                        if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                        if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                                if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                                if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                                // if (!segundo_nombre_dg) { errores.push("error_segundo_nombre_dg"); }
                                break;
                            case 'PERSONA_JURIDICA':
                                if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                                break;
                        }
                        if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                        if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                        if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                        switch (tipo_tercero) {
                            case '1':
                            case '3':
                            case '4':
                            case '7':                                
                                if (!pais_procedencia_dg) { errores.push("error_pais_procedencia_dg"); }
                                break;
                            case '2':
                                if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                                if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                                if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                                // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                                // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                                break;
                        }
                        if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                        if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                        if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                        if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                        // if (!complemento_direccion_empresa1_dg) { errores.push("error_complemento_direccion_empresa1_dg"); }
                        // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                        if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                        // if (!complemento_direccion_empresa2_dg) { errores.push("error_complemento_direccion_empresa2_dg"); }
                        if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                        // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                        if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                        if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                        // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                        // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                        // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                        if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }
                        }
                        // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                        if (!this.validateEmail(correo_electronico_empresa_dg)) {
                            errores.push("error_correo_electronico_empresa_dg");
                            Swal.fire({
                                title: 'Formularios',
                                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    errores.push("error_correo_electronico_empresa_dg");
                                }
                            })
                        }
                        // for (let i = 0; i < this.state.selectedFiles.length; i++) {
                        //     formData.append('files_dg[]', selectedFiles[i]);
                        // }
                        for (let file of this.state.selectedFiles) {
                            formData.append('files_dg[]', file);
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_dg === '') {
                                formData.append('seccion_guardada_dg', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_dg').value = 'OK';
                            }
                        }
                        break;
                    case 'condiciones_compra':
                        var errores = [];
                        var seccion_guardada_cc = document.getElementById('seccion_guardada_cc').value;
                        var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                        var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                        var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                        var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                        var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                        if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                        if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                        if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                        if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                        if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_cc === '') {
                                formData.append('seccion_guardada_cc', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_cc').value = 'OK';
                            }
                        }
                        break;
                    case 'banco':
                        var errores = [];
                        var seccion_guardada_b = document.getElementById('seccion_guardada_b').value;
                        var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                        var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                        var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                        // var valido_desde_b = document.getElementById('valido_desde_b').value;
                        // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                        var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                        var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                        var documento_titular_b = document.getElementById('documento_titular_b').value;
                        if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                        if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                        if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                        // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                        // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                        if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                        if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                        if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_b === '') {
                                formData.append('seccion_guardada_b', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_b').value = 'OK';
                            }
                        }
                        break;
                    case 'impuestos':
                        var errores = [];
                        var seccion_guardada_i = document.getElementById('seccion_guardada_i').value;
                        var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                        var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                        var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                        var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                        var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                        if (retencion_autoretenedor_i === 'SI') {
                            var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                            var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                            var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                            var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                        } else {
                            var retencion_condicion_i = '-';
                            var retencion_resolucion_i = '-';
                            var retencion_fecha_i = '1901-01-01';
                            var retencion_concepto_i = '-';
                        }
                        var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                        if (retencion_factura_electronica_i === 'SI') {
                            var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                            var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                        } else {
                            var retencion_correo_electronico_envio_i = '-';
                            var retencion_correo_electronico_rechazo_i = '-';
                        }
                        if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                        if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                        if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                        if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                        if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                        if (retencion_autoretenedor_i === 'SI') {
                            if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                            if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                            if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                            if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                        }
                        if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                        if (retencion_factura_electronica_i === 'SI') {
                            if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                            if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                            if (!this.validateEmail(retencion_correo_electronico_envio_i)) {
                                errores.push("error_retencion_correo_electronico_envio_i");
                                Swal.fire({
                                    title: 'Formularios',
                                    text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        errores.push("error_retencion_correo_electronico_envio_i");
                                    }
                                })
                            }
                            if (!this.validateEmail(retencion_correo_electronico_rechazo_i)) {
                                errores.push("error_retencion_correo_electronico_rechazo_i");
                                Swal.fire({
                                    title: 'Formularios',
                                    text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                    icon: 'error',
                                    confirmButtonText: 'Aceptar',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        errores.push("error_retencion_correo_electronico_rechazo_i");
                                    }
                                })
                            }
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_i === '') {
                                formData.append('seccion_guardada_i', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_i').value = 'OK';
                            }
                        }
                        break;
                    case 'financiera':
                        var errores = [];
                        var seccion_guardada_f = document.getElementById('seccion_guardada_f').value;
                        var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                        var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                        var total_activos_f = document.getElementById('total_activos_f').value;
                        var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                        var patrimonio_f = document.getElementById('patrimonio_f').value;
                        var ingresos_f = document.getElementById('ingresos_f').value;
                        var egresos_f = document.getElementById('egresos_f').value;
                        var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                        // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                        if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                        if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                        if (!total_activos_f) { errores.push("error_total_activos_f"); }
                        if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                        if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                        if (!ingresos_f) { errores.push("error_ingresos_f"); }
                        if (!egresos_f) { errores.push("error_egresos_f"); }
                        if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                        // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_f === '') {
                                formData.append('seccion_guardada_f', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_f').value = 'OK';
                            }
                        }
                        break;
                    case 'declaracion_transp_pn':
                        if (tipo_tercero === '7') {
                            var errores = [];
                            var seccion_guardada_pn = document.getElementById('seccion_guardada_pn').value;
                            var pregunta1_pn = document.getElementById('pregunta1_pn').value;
                            var pregunta2_pn = document.getElementById('pregunta2_pn').value;
                            var pregunta3_pn = document.getElementById('pregunta3_pn').value;
                            var pregunta4_pn = document.getElementById('pregunta4_pn').value;
                            var pregunta5_pn = document.getElementById('pregunta5_pn').value;
                            var pregunta6_pn = document.getElementById('pregunta6_pn').value;
                            var pregunta7_pn = document.getElementById('pregunta7_pn').value;
                            var pregunta8_pn = document.getElementById('pregunta8_pn').value;
                            var pregunta9_pn = document.getElementById('pregunta9_pn').value;
                            var pregunta10_pn = document.getElementById('pregunta10_pn').value;
                            var pregunta11_pn = document.getElementById('pregunta11_pn').value;
                            var pregunta12_pn = document.getElementById('pregunta12_pn').value;
                            var pregunta13_pn = document.getElementById('pregunta13_pn').value;
                            if (tipo_tercero === '7') {
                                if (!pregunta1_pn) { errores.push("error_pregunta1_pn"); }
                                if (!pregunta2_pn) { errores.push("error_pregunta2_pn"); }
                                if (!pregunta3_pn) { errores.push("error_pregunta3_pn"); }
                                if (!pregunta4_pn) { errores.push("error_pregunta4_pn"); }
                                if (!pregunta5_pn) { errores.push("error_pregunta5_pn"); }
                                if (!pregunta6_pn) { errores.push("error_pregunta6_pn"); }
                                if (!pregunta7_pn) { errores.push("error_pregunta7_pn"); }
                                if (!pregunta8_pn) { errores.push("error_pregunta8_pn"); }
                                if (!pregunta9_pn) { errores.push("error_pregunta9_pn"); }
                                if (!pregunta10_pn) { errores.push("error_pregunta10_pn"); }
                                if (!pregunta11_pn) { errores.push("error_pregunta11_pn"); }
                                if (!pregunta12_pn) { errores.push("error_pregunta12_pn"); }
                                if (!pregunta13_pn) { errores.push("error_pregunta13_pn"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_pn === '') {
                                        formData.append('seccion_guardada_pn', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_pn').value = 'OK';
                                    }
                                }
                            }
                        }
                        break;
                }
                break;
            case '5':
                console.log(tipo_tercero, tab);
                switch (tab) {
                    case 'vendor_detail':
                        var errores = [];
                        var seccion_guardada_vd = document.getElementById('seccion_guardada_vd').value;
                        var nombre_beneficiario_ex = document.getElementById('nombre_beneficiario_ex').value;
                        var numero_identificacion_ex = document.getElementById('numero_identificacion_ex').value;
                        var pais_ex = document.getElementById('pais_ex').value;
                        var estado_ex = document.getElementById('estado_ex').value;
                        var ciudad_ex = document.getElementById('ciudad_ex').value;
                        var direccion_ex = document.getElementById('direccion_ex').value;
                        var telefono1_ex = document.getElementById('telefono1_ex').value;
                        var telefono2_ex = document.getElementById('telefono2_ex').value;
                        var fax_ex = document.getElementById('fax_ex').value;
                        var correo_electronico_ex = document.getElementById('correo_electronico_ex').value;
                        var nombre_contacto_ex = document.getElementById('nombre_contacto_ex').value;
                        var codigo_postal_ex = document.getElementById('codigo_postal_ex').value;
                        if (!nombre_beneficiario_ex) { errores.push("error_nombre_beneficiario_ex"); }
                        if (!numero_identificacion_ex) { errores.push("error_numero_identificacion_ex"); }
                        if (!pais_ex) { errores.push("error_pais_ex"); }
                        if (!estado_ex) { errores.push("error_estado_ex"); }
                        if (!ciudad_ex) { errores.push("error_ciudad_ex"); }
                        if (!direccion_ex) { errores.push("error_direccion_ex"); }
                        if (!telefono1_ex) { errores.push("error_telefono1_ex"); }
                        // if (!telefono2_ex) { errores.push("error_telefono2_ex"); }
                        if (!fax_ex) { errores.push("error_fax_ex"); }
                        if (!correo_electronico_ex) { errores.push("error_correo_electronico_ex"); }
                        if (!nombre_contacto_ex) { errores.push("error_nombre_contacto_ex"); }
                        if (!codigo_postal_ex) { errores.push("error_codigo_postal_ex"); }
                        if (!this.validateEmail(correo_electronico_ex)) {
                            errores.push("error_correo_electronico_ex");
                            Swal.fire({
                                title: 'Forms',
                                text: 'The email is not in the accepted format. Please verify information.',
                                icon: 'error',
                                confirmButtonText: 'Accept',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    errores.push("error_correo_electronico_ex");
                                }
                            })
                        }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_vd === '') {
                                formData.append('seccion_guardada_vd', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_vd').value = 'OK';
                            }
                        }
                        break;
                    case 'bank_detail':
                        var errores = [];
                        var seccion_guardada_bd = document.getElementById('seccion_guardada_bd').value;
                        var nombre_banco_ex = document.getElementById('nombre_banco_ex').value;
                        var codigo_banco_ex = document.getElementById('codigo_banco_ex').value;
                        var nombre_sucursal_ex = document.getElementById('nombre_sucursal_ex').value;
                        var numero_sucursal_ex = document.getElementById('numero_sucursal_ex').value;
                        var direccion_sucursal_banco_ex = document.getElementById('direccion_sucursal_banco_ex').value;
                        var numero_cuenta_banco_ex = document.getElementById('numero_cuenta_banco_ex').value;
                        var codigo_moneda_banco_ex = document.getElementById('codigo_moneda_banco_ex').value;
                        var codigo_swift_banco_ex = document.getElementById('codigo_swift_banco_ex').value;
                        var numero_aba_banco_ex = document.getElementById('numero_aba_banco_ex').value;
                        var numero_iban_banco_ex = document.getElementById('numero_iban_banco_ex').value;
                        if (!nombre_banco_ex) { errores.push("error_nombre_banco_ex"); }
                        if (!codigo_banco_ex) { errores.push("error_codigo_banco_ex"); }
                        if (!nombre_sucursal_ex) { errores.push("error_nombre_sucursal_ex"); }
                        if (!numero_sucursal_ex) { errores.push("error_numero_sucursal_ex"); }
                        if (!direccion_sucursal_banco_ex) { errores.push("error_direccion_sucursal_banco_ex"); }
                        if (!numero_cuenta_banco_ex) { errores.push("error_numero_cuenta_banco_ex"); }
                        if (!codigo_moneda_banco_ex) { errores.push("error_codigo_moneda_banco_ex"); }
                        if (!codigo_swift_banco_ex) { errores.push("error_codigo_swift_banco_ex"); }
                        if (!numero_aba_banco_ex) { errores.push("error_numero_aba_banco_ex"); }
                        if (!numero_iban_banco_ex) { errores.push("error_numero_iban_banco_ex"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_bd === '') {
                                formData.append('seccion_guardada_bd', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_bd').value = 'OK';
                            }
                        }
                        break;
                    case 'intermediary_bank':
                        var errores = [];
                        var seccion_guardada_ib = document.getElementById('seccion_guardada_ib').value;
                        var nombre_banco_intermediario_ex = document.getElementById('nombre_banco_intermediario_ex').value;
                        var numero_cuenta_banco_intermediario_ex = document.getElementById('numero_cuenta_banco_intermediario_ex').value;
                        var codigo_swift_banco_intermediario_ex = document.getElementById('codigo_swift_banco_intermediario_ex').value;
                        var numero_aba_banco_intermediario_ex = document.getElementById('numero_aba_banco_intermediario_ex').value;
                        if (!nombre_banco_intermediario_ex) { errores.push("error_nombre_banco_intermediario_ex"); }
                        if (!numero_cuenta_banco_intermediario_ex) { errores.push("error_numero_cuenta_banco_intermediario_ex"); }
                        if (!codigo_swift_banco_intermediario_ex) { errores.push("error_codigo_swift_banco_intermediario_ex"); }
                        if (!numero_aba_banco_intermediario_ex) { errores.push("error_numero_aba_banco_intermediario_ex"); }
                        this.setState({ errores: errores });
                        console.log(errores);
                        if (errores.length > 0) {
                            return false;
                        } else {
                            if (seccion_guardada_ib === '') {
                                formData.append('seccion_guardada_ib', 'OK');
                            } else {
                                document.getElementById('seccion_guardada_ib').value = 'OK';
                            }
                        }
                        break;
                }
                break;
            case '6':
                console.log(tipo_tercero, tab);
                switch (tipo_sociedad) {
                    case '4':
                        switch (tab) {
                            case 'datos_generales_esal':
                                var errores = [];
                                var seccion_guardada_esal_dg = document.getElementById('seccion_guardada_esal_dg').value;
                                var ciudad_esal_dg = document.getElementById('ciudad_esal_dg').value;
                                var razon_social_esal_dg = document.getElementById('razon_social_esal_dg').value;
                                var tipo_persona_esal_dg = document.getElementById('tipo_persona_esal_dg').value;
                                var tipo_documento_esal_dg = document.getElementById('tipo_documento_esal_dg').value;
                                var numero_documento_esal_dg = document.getElementById('numero_documento_esal_dg').value;
                                var digito_verificacion_esal_dg = document.getElementById('digito_verificacion_esal_dg').value;
                                var fecha_constitucion_esal_dg = document.getElementById('fecha_constitucion_esal_dg').value;
                                var pais_procedencia_esal_dg = document.getElementById('pais_procedencia_esal_dg').value;
                                var departamento_esal_dg = document.getElementById('departamento_esal_dg').value;
                                var municipio_esal_dg = document.getElementById('municipio_esal_dg').value;
                                var direccion_empresa_principal_esal_dg = document.getElementById('direccion_empresa_principal_esal_dg').value;
                                var telefono_empresa_esal_dg = document.getElementById('telefono_empresa_esal_dg').value;
                                var extension_telefono_empresa_esal_dg = document.getElementById('extension_telefono_empresa_esal_dg').value;
                                var pagina_web_empresa_esal_dg = document.getElementById('pagina_web_empresa_esal_dg').value;
                                var factura_electronica_retencion_esal_dg = document.getElementById('factura_electronica_retencion_esal_dg').value;
                                var correo_electronico_envio_retencion_esal_dg = document.getElementById('correo_electronico_envio_retencion_esal_dg').value;
                                var correo_electronico_rechazo_retencion_esal_dg = document.getElementById('correo_electronico_rechazo_retencion_esal_dg').value;
                                var contacto_contabilidad_esal_dg = document.getElementById('contacto_contabilidad_esal_dg').value;
                                var correo_electronico_contacto_contabilidad_esal_dg = document.getElementById('correo_electronico_contacto_contabilidad_esal_dg').value;
                                var descripcion_bienes_servicios_esal_dg = document.getElementById('descripcion_bienes_servicios_esal_dg').value;
                                var terminos_negociacion_esal_dg = document.getElementById('terminos_negociacion_esal_dg').value;
                                var plazo_pago_facturas_esal_dg = document.getElementById('plazo_pago_facturas_esal_dg').value;
                                var contacto_proveedor_esal_dg = document.getElementById('contacto_proveedor_esal_dg').value;
                                var correo_electronico_contacto_proveedor_esal_dg = document.getElementById('correo_electronico_contacto_proveedor_esal_dg').value;
                                var telefono_contacto_proveedor_esal_dg = document.getElementById('telefono_contacto_proveedor_esal_dg').value;
                                var extension_telefono_contacto_proveedor_esal_dg = document.getElementById('extension_telefono_contacto_proveedor_esal_dg').value;
                                var celular1_contacto_proveedor_esal_dg = document.getElementById('celular1_contacto_proveedor_esal_dg').value;
                                var celular2_contacto_proveedor_esal_dg = document.getElementById('celular2_contacto_proveedor_esal_dg').value;
                                var poliza_garantia_esal_dg = document.getElementById('poliza_garantia_esal_dg').value;
                                var programa_gestion_riesgo_esal_dg = document.getElementById('programa_gestion_riesgo_esal_dg').value;
                                if (programa_gestion_riesgo_esal_dg === 'OTROS_PROGRAMAS') {
                                    var otros_programa_gestion_riesgo_esal_dg = document.getElementById('otros_programa_gestion_riesgo_esal_dg').value;
                                } else {
                                    var otros_programa_gestion_riesgo_dg = '-';
                                }
                                var detalle_gestion_riesgo_esal_dg = document.getElementById('detalle_gestion_riesgo_esal_dg').value;
                                if (!ciudad_esal_dg) { errores.push("error_ciudad_esal_dg"); }
                                if (!razon_social_esal_dg) { errores.push("error_razon_social_esal_dg"); }
                                if (!tipo_persona_esal_dg) { errores.push("error_tipo_persona_esal_dg"); }
                                if (!tipo_documento_esal_dg) { errores.push("error_tipo_documento_esal_dg"); }
                                if (!numero_documento_esal_dg) { errores.push("error_numero_documento_esal_dg"); }
                                if (!digito_verificacion_esal_dg) { errores.push("error_digito_verificacion_esal_dg"); }
                                if (!fecha_constitucion_esal_dg) { errores.push("error_fecha_constitucion_esal_dg"); }
                                if (!pais_procedencia_esal_dg) { errores.push("error_pais_procedencia_esal_dg"); }
                                if (!departamento_esal_dg) { errores.push("error_departamento_esal_dg"); }
                                if (!municipio_esal_dg) { errores.push("error_municipio_esal_dg"); }
                                if (!direccion_empresa_principal_esal_dg) { errores.push("error_direccion_empresa_principal_esal_dg"); }
                                if (!telefono_empresa_esal_dg) { errores.push("error_telefono_empresa_esal_dg"); }
                                if (!extension_telefono_empresa_esal_dg) { errores.push("error_extension_telefono_empresa_esal_dg"); }
                                if (!pagina_web_empresa_esal_dg) { errores.push("error_pagina_web_empresa_esal_dg"); }
                                if (!factura_electronica_retencion_esal_dg) { errores.push("error_factura_electronica_retencion_esal_dg"); }
                                if (!correo_electronico_envio_retencion_esal_dg) { errores.push("error_correo_electronico_envio_retencion_esal_dg"); }
                                if (!correo_electronico_rechazo_retencion_esal_dg) { errores.push("error_correo_electronico_rechazo_retencion_esal_dg"); }
                                if (!contacto_contabilidad_esal_dg) { errores.push("error_contacto_contabilidad_esal_dg"); }
                                if (!correo_electronico_contacto_contabilidad_esal_dg) { errores.push("error_correo_electronico_contacto_contabilidad_esal_dg"); }
                                if (!descripcion_bienes_servicios_esal_dg) { errores.push("error_descripcion_bienes_servicios_esal_dg"); }
                                if (!terminos_negociacion_esal_dg) { errores.push("error_terminos_negociacion_esal_dg"); }
                                if (!plazo_pago_facturas_esal_dg) { errores.push("error_plazo_pago_facturas_esal_dg"); }
                                if (!contacto_proveedor_esal_dg) { errores.push("error_contacto_proveedor_esal_dg"); }
                                if (!correo_electronico_contacto_proveedor_esal_dg) { errores.push("error_correo_electronico_contacto_proveedor_esal_dg"); }
                                if (!telefono_contacto_proveedor_esal_dg) { errores.push("error_telefono_contacto_proveedor_esal_dg"); }
                                if (!extension_telefono_contacto_proveedor_esal_dg) { errores.push("error_extension_telefono_contacto_proveedor_esal_dg"); }
                                if (!celular1_contacto_proveedor_esal_dg) { errores.push("error_celular1_contacto_proveedor_esal_dg"); }
                                if (!celular2_contacto_proveedor_esal_dg) { errores.push("error_celular2_contacto_proveedor_esal_dg"); }
                                if (!poliza_garantia_esal_dg) { errores.push("error_poliza_garantia_esal_dg"); }
                                if (!programa_gestion_riesgo_esal_dg) { errores.push("error_programa_gestion_riesgo_esal_dg"); }
                                if (programa_gestion_riesgo_esal_dg === 'SI') {
                                    if (!otros_programa_gestion_riesgo_esal_dg) { errores.push("error_otros_programa_gestion_riesgo_esal_dg"); }
                                }
                                // if (!detalle_gestion_riesgo_esal_dg) { errores.push("error_detalle_gestion_riesgo_esal_dg"); }
                                if (!this.validateEmail(correo_electronico_envio_retencion_esal_dg)) {
                                    errores.push("error_correo_electronico_envio_retencion_esal_dg");
                                    Swal.fire({
                                        title: 'Formularios',
                                        text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                        icon: 'error',
                                        confirmButtonText: 'Aceptar',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            errores.push("error_correo_electronico_envio_retencion_esal_dg");
                                        }
                                    })
                                }
                                if (!this.validateEmail(correo_electronico_rechazo_retencion_esal_dg)) {
                                    errores.push("error_correo_electronico_rechazo_retencion_esal_dg");
                                    Swal.fire({
                                        title: 'Formularios',
                                        text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                        icon: 'error',
                                        confirmButtonText: 'Aceptar',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            errores.push("error_correo_electronico_rechazo_retencion_esal_dg");
                                        }
                                    })
                                }
                                if (!this.validateEmail(correo_electronico_contacto_contabilidad_esal_dg)) {
                                    errores.push("error_correo_electronico_contacto_contabilidad_esal_dg");
                                    Swal.fire({
                                        title: 'Formularios',
                                        text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                        icon: 'error',
                                        confirmButtonText: 'Aceptar',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            errores.push("error_correo_electronico_contacto_contabilidad_esal_dg");
                                        }
                                    })
                                }
                                if (!this.validateEmail(correo_electronico_contacto_proveedor_esal_dg)) {
                                    errores.push("error_correo_electronico_contacto_proveedor_esal_dg");
                                    Swal.fire({
                                        title: 'Formularios',
                                        text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                        icon: 'error',
                                        confirmButtonText: 'Aceptar',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            errores.push("error_correo_electronico_contacto_proveedor_esal_dg");
                                        }
                                    })
                                }
                                // for (let i = 0; i < this.state.selectedFiles.length; i++) {
                                //     formData.append('files_esal[]', selectedFiles[i]);
                                // }
                                for (let file of this.state.selectedFiles) {
                                    formData.append('files_dg[]', file);
                                }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_esal_dg === '') {
                                        formData.append('seccion_guardada_esal_dg', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_esal_dg').value = 'OK';
                                    }
                                }
                                // if (seccion_guardada_esal_dg === '') {
                                //     formData.append('seccion_guardada_esal_dg', 'OK');
                                // }
                                break;
                            case 'representante_legal_esal':
                                var errores = [];
                                var seccion_guardada_rep_legal_esal = document.getElementById('seccion_guardada_rep_legal_esal').value;
                                var rep_legal_esal = document.getElementById('rep_legal_esal').value;
                                var socio_rep_legal_esal = document.getElementById('socio_rep_legal_esal').value;
                                var tipo_documento_rep_legal_esal = document.getElementById('tipo_documento_rep_legal_esal').value;
                                var numero_documento_rep_legal_esal = document.getElementById('numero_documento_rep_legal_esal').value;
                                var expedicion_documento_rep_legal_esal = document.getElementById('expedicion_documento_rep_legal_esal').value;
                                var cargo_rep_legal_esal = document.getElementById('cargo_rep_legal_esal').value;
                                var correo_electronico_rep_legal_esal = document.getElementById('correo_electronico_rep_legal_esal').value;
                                if (!rep_legal_esal) { errores.push("error_rep_legal_esal"); }
                                if (!socio_rep_legal_esal) { errores.push("error_socio_rep_legal_esal"); }
                                if (!tipo_documento_rep_legal_esal) { errores.push("error_tipo_documento_rep_legal_esal"); }
                                if (!numero_documento_rep_legal_esal) { errores.push("error_numero_documento_rep_legal_esal"); }
                                if (!expedicion_documento_rep_legal_esal) { errores.push("error_expedicion_documento_rep_legal_esal"); }
                                if (!cargo_rep_legal_esal) { errores.push("error_cargo_rep_legal_esal"); }
                                if (!correo_electronico_rep_legal_esal) { errores.push("error_correo_electronico_rep_legal_esal"); }
                                if (!this.validateEmail(correo_electronico_rep_legal_esal)) {
                                    errores.push("error_correo_electronico_rep_legal_esal");
                                    Swal.fire({
                                        title: 'Formularios',
                                        text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                        icon: 'error',
                                        confirmButtonText: 'Aceptar',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            errores.push("error_correo_electronico_rep_legal_esal");
                                        }
                                    })
                                }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_rep_legal_esal === '') {
                                        formData.append('seccion_guardada_rep_legal_esal', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_rep_legal_esal').value = 'OK';
                                    }
                                }
                                // if (seccion_guardada_rep_legal_esal === '') {
                                //     formData.append('seccion_guardada_rep_legal_esal', 'OK');
                                // }
                                break;
                            case 'inf_financiera_esal':
                                var errores = [];
                                var seccion_guardada_ingresos_esal = document.getElementById('seccion_guardada_ingresos_esal').value;
                                var fecha_reporte_esal = document.getElementById('fecha_reporte_esal').value;
                                var total_activos_esal = document.getElementById('total_activos_esal').value;
                                var total_pasivos_esal = document.getElementById('total_pasivos_esal').value;
                                var patrimonio_esal = document.getElementById('patrimonio_esal').value;
                                var ingresos_esal = document.getElementById('ingresos_esal').value;
                                var otros_ingresos_esal = document.getElementById('otros_ingresos_esal').value;
                                var egresos_esal = document.getElementById('egresos_esal').value;
                                var origen_otros_ingresos_esal = document.getElementById('origen_otros_ingresos_esal').value;
                                if (!fecha_reporte_esal) { errores.push("error_fecha_reporte_esal"); }
                                if (!total_activos_esal) { errores.push("error_total_activos_esal"); }
                                if (!total_pasivos_esal) { errores.push("error_total_pasivos_esal"); }
                                if (!patrimonio_esal) { errores.push("error_patrimonio_esal"); }
                                if (!ingresos_esal) { errores.push("error_ingresos_esal"); }
                                if (!otros_ingresos_esal) { errores.push("error_otros_ingresos_esal"); }
                                if (!egresos_esal) { errores.push("error_egresos_esal"); }
                                if (!origen_otros_ingresos_esal) { errores.push("error_origen_otros_ingresos_esal"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_ingresos_esal === '') {
                                        formData.append('seccion_guardada_ingresos_esal', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_ingresos_esal').value = 'OK';
                                    }
                                }
                                // if (seccion_guardada_ingresos_esal === '') {
                                //     formData.append('seccion_guardada_ingresos_esal', 'OK');
                                // }
                                break;
                            case 'pep_esal':
                                var errores = [];
                                var seccion_guardada_pep_esal = document.getElementById('seccion_guardada_pep_esal').value;
                                var empleado_publico_esal = document.getElementById('empleado_publico_esal').value;
                                var beneficiario_final_esal = document.getElementById('beneficiario_final_esal').value;
                                if (!empleado_publico_esal) { errores.push("error_empleado_publico_esal"); }
                                if (!beneficiario_final_esal) { errores.push("error_beneficiario_final_esal"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_pep_esal === '') {
                                        formData.append('seccion_guardada_pep_esal', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_pep_esal').value = 'OK';
                                    }
                                }
                                // if (seccion_guardada_pep_esal === '') {
                                //     formData.append('seccion_guardada_pep_esal', 'OK');
                                // }
                                break;
                            case 'transparencia_esal':
                                var errores = [];
                                var seccion_guardada_pj_esal = document.getElementById('seccion_guardada_pj_esal').value;
                                var pregunta1_esal_pj = document.getElementById('pregunta1_esal_pj').value;
                                var pregunta2_esal_pj = document.getElementById('pregunta2_esal_pj').value;
                                var pregunta3_esal_pj = document.getElementById('pregunta3_esal_pj').value;
                                var pregunta4_esal_pj = document.getElementById('pregunta4_esal_pj').value;
                                var pregunta5_esal_pj = document.getElementById('pregunta5_esal_pj').value;
                                var pregunta6_esal_pj = document.getElementById('pregunta6_esal_pj').value;
                                var pregunta7_esal_pj = document.getElementById('pregunta7_esal_pj').value;
                                var pregunta8_esal_pj = document.getElementById('pregunta8_esal_pj').value;
                                var pregunta9_esal_pj = document.getElementById('pregunta9_esal_pj').value;
                                var pregunta10_esal_pj = document.getElementById('pregunta10_esal_pj').value;
                                var pregunta11_esal_pj = document.getElementById('pregunta11_esal_pj').value;
                                var pregunta12_esal_pj = document.getElementById('pregunta12_esal_pj').value;
                                var pregunta13_esal_pj = document.getElementById('pregunta13_esal_pj').value;
                                var pregunta14_esal_pj = document.getElementById('pregunta14_esal_pj').value;
                                if (!pregunta1_esal_pj) { errores.push("error_pregunta1_esal_pj"); }
                                if (!pregunta2_esal_pj) { errores.push("error_pregunta2_esal_pj"); }
                                if (!pregunta3_esal_pj) { errores.push("error_pregunta3_esal_pj"); }
                                if (!pregunta4_esal_pj) { errores.push("error_pregunta4_esal_pj"); }
                                if (!pregunta5_esal_pj) { errores.push("error_pregunta5_esal_pj"); }
                                if (!pregunta6_esal_pj) { errores.push("error_pregunta6_esal_pj"); }
                                if (!pregunta7_esal_pj) { errores.push("error_pregunta7_esal_pj"); }
                                if (!pregunta8_esal_pj) { errores.push("error_pregunta8_esal_pj"); }
                                if (!pregunta9_esal_pj) { errores.push("error_pregunta9_esal_pj"); }
                                if (!pregunta10_esal_pj) { errores.push("error_pregunta10_esal_pj"); }
                                if (!pregunta11_esal_pj) { errores.push("error_pregunta11_esal_pj"); }
                                if (!pregunta12_esal_pj) { errores.push("error_pregunta12_esal_pj"); }
                                if (!pregunta13_esal_pj) { errores.push("error_pregunta13_esal_pj"); }
                                if (!pregunta14_esal_pj) { errores.push("error_pregunta14_esal_pj"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_pj_esal === '') {
                                        formData.append('seccion_guardada_pj_esal', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_pj_esal').value = 'OK';
                                    }
                                }
                                // if (seccion_guardada_pj_esal === '') {
                                //     formData.append('seccion_guardada_pj_esal', 'OK');
                                // }
                                break;
                        }
                        break;
                    default:
                        switch (tab) {
                            case 'datos_generales':
                                var errores = [];
                                var seccion_guardada_dg = document.getElementById('seccion_guardada_dg').value;
                                var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                                var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                                var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                                switch (tipoPersonaDG) {
                                    case 'PERSONA_NATURAL':
                                        var digito_verificacion_dg = 0;
                                        var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                                        var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                                        var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                                        var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                                        break;
                                    case 'PERSONA_JURIDICA':
                                        var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                                        var primer_apellido_dg = '-';
                                        var segundo_apellido_dg = '-';
                                        var primer_nombre_dg = '-';
                                        var segundo_nombre_dg = '-';
                                        break;
                                    case 'EXTERIOR':
                                        var digito_verificacion_dg = 0;
                                        var primer_apellido_dg = '-';
                                        var segundo_apellido_dg = '-';
                                        var primer_nombre_dg = '-';
                                        var segundo_nombre_dg = '-';
                                        break;
                                }
                                var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                                var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                                var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                                var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                                var razon_social_dg = document.getElementById('razon_social_dg').value;
                                var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                                var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                                var siglas_dg = document.getElementById('siglas_dg').value;
                                var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                                var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                                var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                                var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                                var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                                // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                                var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                                var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                                var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                                var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                                var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                                var telefono1_dg = document.getElementById('telefono1_dg').value;
                                var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                                var telefono2_dg = document.getElementById('telefono2_dg').value;
                                var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                                var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                                if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                                    var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                                } else {
                                    var otros_programa_gestion_riesgo_dg = '-';
                                }
                                var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                                if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                                if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                                if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                                switch (tipoPersonaDG) {
                                    case 'PERSONA_NATURAL':
                                        if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                                        if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                                        if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                                        break;
                                    case 'PERSONA_JURIDICA':
                                        if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                                        break;
                                }
                                if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                                if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                                if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                                if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                                if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                                if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                                // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                                // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                                if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                                if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                                if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                                if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                                // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                                if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                                if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                                // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                                if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                                if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                                // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                                // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                                // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                                if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                                if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                                    if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }
                                }
                                // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                                if (!this.validateEmail(correo_electronico_empresa_dg)) {
                                    errores.push("error_correo_electronico_empresa_dg");
                                    Swal.fire({
                                        title: 'Formularios',
                                        text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                        icon: 'error',
                                        confirmButtonText: 'Aceptar',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            errores.push("error_correo_electronico_empresa_dg");
                                        }
                                    })
                                }
                                // for (let i = 0; i < this.state.selectedFiles.length; i++) {
                                //     formData.append('files_dg[]', selectedFiles[i]);
                                // }
                                for (let file of this.state.selectedFiles) {
                                    formData.append('files_dg[]', file);
                                }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_dg === '') {
                                        formData.append('seccion_guardada_dg', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_dg').value = 'OK';
                                    }
                                }
                                break;
                            case 'condiciones_compra':
                                var errores = [];
                                var seccion_guardada_cc = document.getElementById('seccion_guardada_cc').value;
                                var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                                var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                                var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                                var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                                var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                                if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                                if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                                if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                                if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                                if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_cc === '') {
                                        formData.append('seccion_guardada_cc', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_cc').value = 'OK';
                                    }
                                }
                                break;
                            case 'banco':
                                var errores = [];
                                var seccion_guardada_b = document.getElementById('seccion_guardada_b').value;
                                var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                                var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                                var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                                // var valido_desde_b = document.getElementById('valido_desde_b').value;
                                // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                                var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                                var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                                var documento_titular_b = document.getElementById('documento_titular_b').value;
                                if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                                if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                                if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                                // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                                // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                                if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                                if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                                if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_b === '') {
                                        formData.append('seccion_guardada_b', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_b').value = 'OK';
                                    }
                                }
                                break;
                            case 'impuestos':
                                var errores = [];
                                var seccion_guardada_i = document.getElementById('seccion_guardada_i').value;
                                var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                                var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                                var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                                var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                                var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                                if (retencion_autoretenedor_i === 'SI') {
                                    var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                                    var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                                    var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                                    var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                                } else {
                                    var retencion_condicion_i = '-';
                                    var retencion_resolucion_i = '-';
                                    var retencion_fecha_i = '1901-01-01';
                                    var retencion_concepto_i = '-';
                                }
                                var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                                if (retencion_factura_electronica_i === 'SI') {
                                    var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                                    var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                                } else {
                                    var retencion_correo_electronico_envio_i = '-';
                                    var retencion_correo_electronico_rechazo_i = '-';
                                }
                                if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                                if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                                if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                                if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                                if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                                if (retencion_autoretenedor_i === 'SI') {
                                    if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                                    if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                                    if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                                    if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                                }
                                if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                                if (retencion_factura_electronica_i === 'SI') {
                                    if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                                    if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                                    if (!this.validateEmail(retencion_correo_electronico_envio_i)) {
                                        errores.push("error_retencion_correo_electronico_envio_i");
                                        Swal.fire({
                                            title: 'Formularios',
                                            text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                            icon: 'error',
                                            confirmButtonText: 'Aceptar',
                                            allowOutsideClick: false
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                errores.push("error_retencion_correo_electronico_envio_i");
                                            }
                                        })
                                    }
                                    if (!this.validateEmail(retencion_correo_electronico_rechazo_i)) {
                                        errores.push("error_retencion_correo_electronico_rechazo_i");
                                        Swal.fire({
                                            title: 'Formularios',
                                            text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                                            icon: 'error',
                                            confirmButtonText: 'Aceptar',
                                            allowOutsideClick: false
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                errores.push("error_retencion_correo_electronico_rechazo_i");
                                            }
                                        })
                                    }
                                }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_i === '') {
                                        formData.append('seccion_guardada_i', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_i').value = 'OK';
                                    }
                                }
                                break;
                            case 'financiera':
                                var errores = [];
                                var seccion_guardada_f = document.getElementById('seccion_guardada_f').value;
                                var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                                var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                                var total_activos_f = document.getElementById('total_activos_f').value;
                                var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                                var patrimonio_f = document.getElementById('patrimonio_f').value;
                                var ingresos_f = document.getElementById('ingresos_f').value;
                                var egresos_f = document.getElementById('egresos_f').value;
                                var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                                // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                                if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                                if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                                if (!total_activos_f) { errores.push("error_total_activos_f"); }
                                if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                                if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                                if (!ingresos_f) { errores.push("error_ingresos_f"); }
                                if (!egresos_f) { errores.push("error_egresos_f"); }
                                if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                                // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_f === '') {
                                        formData.append('seccion_guardada_f', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_f').value = 'OK';
                                    }
                                }
                                break;
                            case 'declaracion_transp_pj':
                                var errores = [];
                                var seccion_guardada_pj = document.getElementById('seccion_guardada_pj').value;
                                var pregunta1_pj = document.getElementById('pregunta1_pj').value;
                                var pregunta2_pj = document.getElementById('pregunta2_pj').value;
                                var pregunta3_pj = document.getElementById('pregunta3_pj').value;
                                var pregunta4_pj = document.getElementById('pregunta4_pj').value;
                                var pregunta5_pj = document.getElementById('pregunta5_pj').value;
                                var pregunta6_pj = document.getElementById('pregunta6_pj').value;
                                var pregunta7_pj = document.getElementById('pregunta7_pj').value;
                                var pregunta8_pj = document.getElementById('pregunta8_pj').value;
                                var pregunta9_pj = document.getElementById('pregunta9_pj').value;
                                var pregunta10_pj = document.getElementById('pregunta10_pj').value;
                                var pregunta11_pj = document.getElementById('pregunta11_pj').value;
                                var pregunta12_pj = document.getElementById('pregunta12_pj').value;
                                var pregunta13_pj = document.getElementById('pregunta13_pj').value;
                                var pregunta14_pj = document.getElementById('pregunta14_pj').value;
                                if (!pregunta1_pj) { errores.push("error_pregunta1_pj"); }
                                if (!pregunta2_pj) { errores.push("error_pregunta2_pj"); }
                                if (!pregunta3_pj) { errores.push("error_pregunta3_pj"); }
                                if (!pregunta4_pj) { errores.push("error_pregunta4_pj"); }
                                if (!pregunta5_pj) { errores.push("error_pregunta5_pj"); }
                                if (!pregunta6_pj) { errores.push("error_pregunta6_pj"); }
                                if (!pregunta7_pj) { errores.push("error_pregunta7_pj"); }
                                if (!pregunta8_pj) { errores.push("error_pregunta8_pj"); }
                                if (!pregunta9_pj) { errores.push("error_pregunta9_pj"); }
                                if (!pregunta10_pj) { errores.push("error_pregunta10_pj"); }
                                if (!pregunta11_pj) { errores.push("error_pregunta11_pj"); }
                                if (!pregunta12_pj) { errores.push("error_pregunta12_pj"); }
                                if (!pregunta13_pj) { errores.push("error_pregunta13_pj"); }
                                if (!pregunta14_pj) { errores.push("error_pregunta14_pj"); }
                                this.setState({ errores: errores });
                                console.log(errores);
                                if (errores.length > 0) {
                                    return false;
                                } else {
                                    if (seccion_guardada_pj === '') {
                                        formData.append('seccion_guardada_pj', 'OK');
                                    } else {
                                        document.getElementById('seccion_guardada_pj').value = 'OK';
                                    }
                                }
                                break;
                        }
                        break;
                }
                break;
        }
        Swal.showLoading();
        authAxios.post("formulario/pre_save", formData)
        .then((datosRespuesta) => {
            console.log(datosRespuesta)
            if (datosRespuesta.data.Status !== false) {
                swalWithBootstrapButtons.fire({
                    text: datosRespuesta.data.msj,
                    confirmButtonText: 'Continuar',
                    icon: 'success',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("formulario/detail/" + this.props.match.params.id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.showLoading();
                                const datos = datosRespuesta.data.data;
                                const datosDetalle = JSON.parse(datosRespuesta.data.dataDetalle.json_campos);
                                switch (datos.tipo_tercero) {
                                    case 1:
                                        this.setState({
                                            seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                            seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                            seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                            seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                            seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada
                                        })
                                        if (datosDetalle.infoDatosGenerales.tipoPersonaDG === 'PERSONA_JURIDICA') {
                                            this.setState({ activar_info_empresa: true, activar_nombre: false, activar_dv: true, });
                                        } else {
                                            if (datosDetalle.infoDatosGenerales.tipoPersonaDG === 'PERSONA_NATURAL') {
                                                this.setState({ activar_info_empresa: false, activar_nombre: true, activar_dv: false })
                                            }
                                        }
                                        if (datosDetalle.infoDatosGenerales.paisProcedenciaDG === 'Colombia') {
                                            this.setState({ activar_dpto_mpio: false });
                                        } else {
                                            this.setState({ activar_dpto_mpio: true });
                                        }
                                        if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                            datosDetalle.infoBanco.seccionGuardada !== null &&
                                            datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                            datosDetalle.infoFinanciera.seccionGuardada !== null) {
                                                this.setState({ activar_enviar: true });
                                        }
                                        this.setState({ filesDG: datosRespuesta.data.files })
                                        console.log("Entra en 1");
                                        break;
                                    case 3:
                                    case 4:
                                        if (datosDetalle.infoDatosGenerales.paisProcedenciaDG === 'Colombia') {
                                            this.setState({ activar_dpto_mpio: false });
                                        } else {
                                            this.setState({ activar_dpto_mpio: true });
                                        }
                                        this.setState({
                                            seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                            seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                            seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                            seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                            seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada
                                        })
                                        // document.getElementById('seccion_guardada_dg').value = datosDetalle.infoDatosGenerales.seccionGuardada;
                                        // document.getElementById('seccion_guardada_b').value = datosDetalle.infoBanco.seccionGuardada;
                                        // document.getElementById('seccion_guardada_i').value = datosDetalle.infoImpuestos.seccionGuardada;
                                        // document.getElementById('seccion_guardada_f').value = datosDetalle.infoFinanciera.seccionGuardada;
                                        if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                            datosDetalle.infoBanco.seccionGuardada !== null &&
                                            datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                            datosDetalle.infoFinanciera.seccionGuardada !== null) {
                                                this.setState({ activar_enviar: true });
                                        }
                                        this.setState({ filesDG: datosRespuesta.data.files })
                                        console.log("Entra en 3, 4");
                                        break;
                                    case 2:
                                        this.setState({ activar_dpto_mpio: false, activar_info_empresa: true, activar_nombre: false, activar_dv: true })
                                        this.setState({
                                            seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                            seccion_guardada_cc: datosDetalle.infoCompra.seccionGuardada,
                                            seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                            seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                            seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                            seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada
                                        })
                                        // document.getElementById('seccion_guardada_dg').value = datosDetalle.infoDatosGenerales.seccionGuardada;
                                        // document.getElementById('seccion_guardada_cc').value = datosDetalle.infoCompra.seccionGuardada;
                                        // document.getElementById('seccion_guardada_b').value = datosDetalle.infoBanco.seccionGuardada;
                                        // document.getElementById('seccion_guardada_i').value = datosDetalle.infoImpuestos.seccionGuardada;
                                        // document.getElementById('seccion_guardada_f').value = datosDetalle.infoFinanciera.seccionGuardada;
                                        if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                            datosDetalle.infoCompra.seccionGuardada !== null &&
                                            datosDetalle.infoBanco.seccionGuardada !== null &&
                                            datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                            datosDetalle.infoFinanciera.seccionGuardada !== null) {
                                                this.setState({ activar_enviar: true });
                                        }
                                        this.setState({ filesDG: datosRespuesta.data.files })
                                        console.log("Entra en 2");
                                        break;
                                    case 5:
                                        this.setState({
                                            seccion_guardada_vd: datosDetalle.vendorDetails.seccionGuardada,
                                            seccion_guardada_bd: datosDetalle.bankDetails.seccionGuardada,
                                            seccion_guardada_ib: datosDetalle.intermediaryBankDetails.seccionGuardada
                                        })
                                        // document.getElementById('seccion_guardada_vd').value = datosDetalle.vendorDetails.seccionGuardada;
                                        // document.getElementById('seccion_guardada_bd').value = datosDetalle.bankDetails.seccionGuardada;
                                        // document.getElementById('seccion_guardada_ib').value = datosDetalle.intermediaryBankDetails.seccionGuardada;
                                        if (datosDetalle.vendorDetails.seccionGuardada !== null &&
                                            datosDetalle.bankDetails.seccionGuardada !== null &&
                                            datosDetalle.intermediaryBankDetails.seccionGuardada !== null) {
                                                this.setState({ activar_enviar: true });
                                        }
                                        console.log("Entra en 5");
                                        break;
                                    case 6:
                                        switch (datos.tipo_sociedad) {
                                            case 4:
                                                this.setState({
                                                    seccion_guardada_esal_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                                    seccion_guardada_rep_legal_esal: datosDetalle.infoRepresentanteLegal.seccionGuardada,
                                                    seccion_guardada_ingresos_esal: datosDetalle.infoFinanciera.seccionGuardada,
                                                    seccion_guardada_pep_esal: datosDetalle.infoPEP.seccionGuardada,
                                                    seccion_guardada_pj_esal: datosDetalle.declaracionPJ.seccionGuardada
                                                })
                                                // document.getElementById('seccion_guardada_esal_dg').value = datosDetalle.infoDatosGenerales.seccionGuardada;
                                                // document.getElementById('seccion_guardada_rep_legal_esal').value = datosDetalle.infoRepresentanteLegal.seccionGuardada;
                                                // document.getElementById('seccion_guardada_ingresos_esal').value = datosDetalle.infoFinanciera.seccionGuardada;
                                                // document.getElementById('seccion_guardada_pep_esal').value = datosDetalle.infoPEP.seccionGuardada;
                                                // document.getElementById('seccion_guardada_pj_esal').value = datosDetalle.declaracionPJ.seccionGuardada;
                                                if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                                    datosDetalle.infoRepresentanteLegal.seccionGuardada !== null &&
                                                    datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                                    datosDetalle.infoPEP.seccionGuardada !== null &&
                                                    datosDetalle.declaracionPJ.seccionGuardada !== null) {
                                                        this.setState({ activar_enviar: true });
                                                }
                                                this.setState({ filesDG: datosRespuesta.data.files })
                                                console.log("Entra en 6: 4");
                                                break;
                                            default:
                                                this.setState({
                                                    activar_info_empresa: true,
                                                    seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                                    seccion_guardada_cc: datosDetalle.infoCompra.seccionGuardada,
                                                    seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                                    seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                                    seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                                    seccion_guardada_pj: datosDetalle.declaracionPJ.seccionGuardada
                                                })
                                                // document.getElementById('seccion_guardada_dg').value = datosDetalle.infoDatosGenerales.seccionGuardada;
                                                // document.getElementById('seccion_guardada_cc').value = datosDetalle.infoCompra.seccionGuardada;
                                                // document.getElementById('seccion_guardada_b').value = datosDetalle.infoBanco.seccionGuardada;
                                                // document.getElementById('seccion_guardada_i').value = datosDetalle.infoImpuestos.seccionGuardada;
                                                // document.getElementById('seccion_guardada_f').value = datosDetalle.infoFinanciera.seccionGuardada;
                                                // document.getElementById('seccion_guardada_pj').value = datosDetalle.declaracionPJ.seccionGuardada;
                                                if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                                    datosDetalle.infoCompra.seccionGuardada !== null &&
                                                    datosDetalle.infoBanco.seccionGuardada !== null &&
                                                    datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                                    datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                                    datosDetalle.declaracionPJ.seccionGuardada !== null) {
                                                        this.setState({ activar_enviar: true });
                                                }
                                                this.setState({ filesDG: datosRespuesta.data.files })
                                                console.log("Entra en 6: Default");
                                                break;
                                        }
                                        break;
                                    case 7:
                                        this.setState({ activar_dpto_mpio: false, activar_info_empresa: false, activar_nombre: true, activar_dv: false })
                                        this.setState({
                                            seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                            seccion_guardada_cc: datosDetalle.infoCompra.seccionGuardada,
                                            seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                            seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                            seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                            seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada
                                        })
                                        // document.getElementById('seccion_guardada_dg').value = datosDetalle.infoDatosGenerales.seccionGuardada;
                                        // document.getElementById('seccion_guardada_cc').value = datosDetalle.infoCompra.seccionGuardada;
                                        // document.getElementById('seccion_guardada_b').value = datosDetalle.infoBanco.seccionGuardada;
                                        // document.getElementById('seccion_guardada_i').value = datosDetalle.infoImpuestos.seccionGuardada;
                                        // document.getElementById('seccion_guardada_f').value = datosDetalle.infoFinanciera.seccionGuardada;
                                        // document.getElementById('seccion_guardada_pn').value = datosDetalle.declaracionPN.seccionGuardada;
                                        if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                            datosDetalle.infoCompra.seccionGuardada !== null &&
                                            datosDetalle.infoBanco.seccionGuardada !== null &&
                                            datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                            datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                            datosDetalle.declaracionPN.seccionGuardada !== null) {
                                                this.setState({ activar_enviar: true });
                                        }
                                        this.setState({ filesDG: datosRespuesta.data.files })
                                        console.log("Entra en 7");
                                        break;
                                }
                                Swal.close();
                            })
                            .catch(console.log);
                    }
                })
            } else {
                swalWithBootstrapButtons.fire({
                    text: datosRespuesta.data.msj,
                    confirmButtonText: 'Continuar',
                    icon: 'success',
                    allowOutsideClick: false
                })
            }
        })
        .catch(console.log)
    }
    enviarDatos = (e) => {
        e.preventDefault();
        var tipo_formulario = document.getElementById('tipo_formulario').value;
        var tipo_tercero = document.getElementById('tipo_tercero').value;
        var tipo_sociedad = document.getElementById('tipo_sociedad').value;
        switch (tipo_tercero) {
            case '1':
            case '3':
            case '4':
                //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
                //Parámetros Pestaña Información General\\
                var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                console.log(tipoPersonaDG);
                switch (tipoPersonaDG) {
                    case 'PERSONA_NATURAL':
                        var digito_verificacion_dg = 0;
                        var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                        var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                        var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                        var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                        break;
                    case 'PERSONA_JURIDICA':
                        var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                        var primer_apellido_dg = '-';
                        var segundo_apellido_dg = '-';
                        var primer_nombre_dg = '-';
                        var segundo_nombre_dg = '-';
                        break;
                    case 'EXTERIOR':
                        var digito_verificacion_dg = 0;
                        var primer_apellido_dg = '-';
                        var segundo_apellido_dg = '-';
                        var primer_nombre_dg = '-';
                        var segundo_nombre_dg = '-';
                        break;
                }
                var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                switch (tipo_tercero) {
                    case '1':
                        switch (tipoPersonaDG) {
                            case 'PERSONA_JURIDICA':
                                var pais_procedencia_dg = document.getElementById("pais_procedencia_dg").value;
                                var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                                var razon_social_dg = document.getElementById('razon_social_dg').value;
                                var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                                var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                                var siglas_dg = document.getElementById('siglas_dg').value;
                                break;
                        }
                        break;
                    case '3':
                    case '4':
                        var pais_procedencia_dg = document.getElementById("pais_procedencia_dg").value;
                        var tamano_empresa_dg = 1;
                        var razon_social_dg = '-';
                        var tipo_empresa_dg = '-';
                        var nombre_comercial_dg = '-';
                        var siglas_dg = '-';
                        break;
                    default:
                        var pais_procedencia_dg = '-';
                        var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                        var razon_social_dg = document.getElementById('razon_social_dg').value;
                        var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                        var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                        var siglas_dg = document.getElementById('siglas_dg').value;
                        break;
                }
                var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                var telefono1_dg = document.getElementById('telefono1_dg').value;
                var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                var telefono2_dg = document.getElementById('telefono2_dg').value;
                var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                    var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                } else {
                    var otros_programa_gestion_riesgo_dg = '-';
                }
                var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                //Parámetros Pestaña Información Compra\\
                // var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                // var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                // var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                // var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                // var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                //Parámetros Pestaña Información Banco\\
                var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                // var valido_desde_b = document.getElementById('valido_desde_b').value;
                // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                var documento_titular_b = document.getElementById('documento_titular_b').value;
                //Parámetros Pestaña Información Impuestos\\
                var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                if (retencion_autoretenedor_i === 'SI') {
                    var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                    var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                    var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                    var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                } else {
                    var retencion_condicion_i = '-';
                    var retencion_resolucion_i = '-';
                    var retencion_fecha_i = '1901-01-01';
                    var retencion_concepto_i = '-';
                }
                var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                if (retencion_factura_electronica_i === 'SI') {
                    var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                    var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                } else {
                    var retencion_correo_electronico_envio_i = '-';
                    var retencion_correo_electronico_rechazo_i = '-';
                }
                //Parámetros Pestaña Información Financiera\\
                var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                var total_activos_f = document.getElementById('total_activos_f').value;
                var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                var patrimonio_f = document.getElementById('patrimonio_f').value;
                var ingresos_f = document.getElementById('ingresos_f').value;
                var egresos_f = document.getElementById('egresos_f').value;
                var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                //Parámetros Pestaña Declaración Transparencia Persona Natural\\
                // if (tipo_tercero === '7') {
                    var pregunta1_pn = document.getElementById('pregunta1_pn').value;
                    var pregunta2_pn = document.getElementById('pregunta2_pn').value;
                    var pregunta3_pn = document.getElementById('pregunta3_pn').value;
                    var pregunta4_pn = document.getElementById('pregunta4_pn').value;
                    var pregunta5_pn = document.getElementById('pregunta5_pn').value;
                    var pregunta6_pn = document.getElementById('pregunta6_pn').value;
                    var pregunta7_pn = document.getElementById('pregunta7_pn').value;
                    var pregunta8_pn = document.getElementById('pregunta8_pn').value;
                    var pregunta9_pn = document.getElementById('pregunta9_pn').value;
                    var pregunta10_pn = document.getElementById('pregunta10_pn').value;
                    var pregunta11_pn = document.getElementById('pregunta11_pn').value;
                    var pregunta12_pn = document.getElementById('pregunta12_pn').value;
                    var pregunta13_pn = document.getElementById('pregunta13_pn').value;
                // }
                break;
            case '2':
            case '7':
                //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
                //Parámetros Pestaña Información General\\
                var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                console.log(tipoPersonaDG);
                switch (tipoPersonaDG) {
                    case 'PERSONA_NATURAL':
                        var digito_verificacion_dg = 0;
                        var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                        var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                        var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                        var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                        break;
                    case 'PERSONA_JURIDICA':
                        var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                        var primer_apellido_dg = '-';
                        var segundo_apellido_dg = '-';
                        var primer_nombre_dg = '-';
                        var segundo_nombre_dg = '-';
                        break;
                    case 'EXTERIOR':
                        var digito_verificacion_dg = 0;
                        var primer_apellido_dg = '-';
                        var segundo_apellido_dg = '-';
                        var primer_nombre_dg = '-';
                        var segundo_nombre_dg = '-';
                        break;
                }
                var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                switch (tipo_tercero) {
                    case '1':
                    case '3':
                    case '4':
                    case '7':
                        var pais_procedencia_dg = document.getElementById('pais_procedencia_dg').value;
                        var tamano_empresa_dg = 1;
                        var razon_social_dg = '-';
                        var tipo_empresa_dg = '-';
                        var nombre_comercial_dg = '-';
                        var siglas_dg = '-';
                        break;
                    default:
                        var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                        var razon_social_dg = document.getElementById('razon_social_dg').value;
                        var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                        var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                        var siglas_dg = document.getElementById('siglas_dg').value;
                        break;
                }
                var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                var telefono1_dg = document.getElementById('telefono1_dg').value;
                var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                var telefono2_dg = document.getElementById('telefono2_dg').value;
                var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                    var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                } else {
                    var otros_programa_gestion_riesgo_dg = '-';
                }
                var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                //Parámetros Pestaña Información Compra\\
                var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                //Parámetros Pestaña Información Banco\\
                var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                // var valido_desde_b = document.getElementById('valido_desde_b').value;
                // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                var documento_titular_b = document.getElementById('documento_titular_b').value;
                //Parámetros Pestaña Información Impuestos\\
                var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                if (retencion_autoretenedor_i === 'SI') {
                    var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                    var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                    var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                    var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                } else {
                    var retencion_condicion_i = '-';
                    var retencion_resolucion_i = '-';
                    var retencion_fecha_i = '1901-01-01';
                    var retencion_concepto_i = '-';
                }
                var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                if (retencion_factura_electronica_i === 'SI') {
                    var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                    var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                } else {
                    var retencion_correo_electronico_envio_i = '-';
                    var retencion_correo_electronico_rechazo_i = '-';
                }
                //Parámetros Pestaña Información Financiera\\
                var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                var total_activos_f = document.getElementById('total_activos_f').value;
                var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                var patrimonio_f = document.getElementById('patrimonio_f').value;
                var ingresos_f = document.getElementById('ingresos_f').value;
                var egresos_f = document.getElementById('egresos_f').value;
                var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                //Parámetros Pestaña Declaración Transparencia Persona Natural\\
                // if (tipo_tercero === '7') {
                    var pregunta1_pn = document.getElementById('pregunta1_pn').value;
                    var pregunta2_pn = document.getElementById('pregunta2_pn').value;
                    var pregunta3_pn = document.getElementById('pregunta3_pn').value;
                    var pregunta4_pn = document.getElementById('pregunta4_pn').value;
                    var pregunta5_pn = document.getElementById('pregunta5_pn').value;
                    var pregunta6_pn = document.getElementById('pregunta6_pn').value;
                    var pregunta7_pn = document.getElementById('pregunta7_pn').value;
                    var pregunta8_pn = document.getElementById('pregunta8_pn').value;
                    var pregunta9_pn = document.getElementById('pregunta9_pn').value;
                    var pregunta10_pn = document.getElementById('pregunta10_pn').value;
                    var pregunta11_pn = document.getElementById('pregunta11_pn').value;
                    var pregunta12_pn = document.getElementById('pregunta12_pn').value;
                    var pregunta13_pn = document.getElementById('pregunta13_pn').value;
                // }
                break;
            case '6':
                switch (tipo_sociedad) {
                    case '4':
                        //** FORMULARIOS EMPRESAS E.S.A.L. **\\
                        //Parámetros Pestaña Información General\\
                        var ciudad_esal_dg = document.getElementById('ciudad_esal_dg').value;
                        var razon_social_esal_dg = document.getElementById('razon_social_esal_dg').value;
                        var tipo_persona_esal_dg = document.getElementById('tipo_persona_esal_dg').value;
                        var tipo_documento_esal_dg = document.getElementById('tipo_documento_esal_dg').value;
                        var numero_documento_esal_dg = document.getElementById('numero_documento_esal_dg').value;
                        var digito_verificacion_esal_dg = document.getElementById('digito_verificacion_esal_dg').value;
                        var fecha_constitucion_esal_dg = document.getElementById('fecha_constitucion_esal_dg').value;
                        var pais_procedencia_esal_dg = document.getElementById('pais_procedencia_esal_dg').value;
                        var departamento_esal_dg = document.getElementById('departamento_esal_dg').value;
                        var municipio_esal_dg = document.getElementById('municipio_esal_dg').value;
                        var direccion_empresa_principal_esal_dg = document.getElementById('direccion_empresa_principal_esal_dg').value;
                        var telefono_empresa_esal_dg = document.getElementById('telefono_empresa_esal_dg').value;
                        var extension_telefono_empresa_esal_dg = document.getElementById('extension_telefono_empresa_esal_dg').value;
                        var pagina_web_empresa_esal_dg = document.getElementById('pagina_web_empresa_esal_dg').value;
                        var factura_electronica_retencion_esal_dg = document.getElementById('factura_electronica_retencion_esal_dg').value;
                        var correo_electronico_envio_retencion_esal_dg = document.getElementById('correo_electronico_envio_retencion_esal_dg').value;
                        var correo_electronico_rechazo_retencion_esal_dg = document.getElementById('correo_electronico_rechazo_retencion_esal_dg').value;
                        var contacto_contabilidad_esal_dg = document.getElementById('contacto_contabilidad_esal_dg').value;
                        var correo_electronico_contacto_contabilidad_esal_dg = document.getElementById('correo_electronico_contacto_contabilidad_esal_dg').value;
                        var descripcion_bienes_servicios_esal_dg = document.getElementById('descripcion_bienes_servicios_esal_dg').value;
                        var terminos_negociacion_esal_dg = document.getElementById('terminos_negociacion_esal_dg').value;
                        var plazo_pago_facturas_esal_dg = document.getElementById('plazo_pago_facturas_esal_dg').value;
                        var contacto_proveedor_esal_dg = document.getElementById('contacto_proveedor_esal_dg').value;
                        var correo_electronico_contacto_proveedor_esal_dg = document.getElementById('correo_electronico_contacto_proveedor_esal_dg').value;
                        var telefono_contacto_proveedor_esal_dg = document.getElementById('telefono_contacto_proveedor_esal_dg').value;
                        var extension_telefono_contacto_proveedor_esal_dg = document.getElementById('extension_telefono_contacto_proveedor_esal_dg').value;
                        var celular1_contacto_proveedor_esal_dg = document.getElementById('celular1_contacto_proveedor_esal_dg').value;
                        var celular2_contacto_proveedor_esal_dg = document.getElementById('celular2_contacto_proveedor_esal_dg').value;
                        var poliza_garantia_esal_dg = document.getElementById('poliza_garantia_esal_dg').value;
                        var programa_gestion_riesgo_esal_dg = document.getElementById('programa_gestion_riesgo_esal_dg').value;
                        if (programa_gestion_riesgo_esal_dg === 'OTROS_PROGRAMAS') {
                            var otros_programa_gestion_riesgo_esal_dg = document.getElementById('otros_programa_gestion_riesgo_esal_dg').value;
                        } else {
                            var otros_programa_gestion_riesgo_esal_dg = '-';
                        }
                        var detalle_gestion_riesgo_esal_dg = document.getElementById('detalle_gestion_riesgo_esal_dg').value;
                        //Parámetros Pestaña Representante Legal\\
                        var rep_legal_esal = document.getElementById('rep_legal_esal').value;
                        var socio_rep_legal_esal = document.getElementById('socio_rep_legal_esal').value;
                        var tipo_documento_rep_legal_esal = document.getElementById('tipo_documento_rep_legal_esal').value;
                        var numero_documento_rep_legal_esal = document.getElementById('numero_documento_rep_legal_esal').value;
                        var expedicion_documento_rep_legal_esal = document.getElementById('expedicion_documento_rep_legal_esal').value;
                        var cargo_rep_legal_esal = document.getElementById('cargo_rep_legal_esal').value;
                        var correo_electronico_rep_legal_esal = document.getElementById('correo_electronico_rep_legal_esal').value;
                        //Parámetros Pestaña Financiera\\
                        var fecha_reporte_esal = document.getElementById('fecha_reporte_esal').value;
                        var total_activos_esal = document.getElementById('total_activos_esal').value;
                        var total_pasivos_esal = document.getElementById('total_pasivos_esal').value;
                        var patrimonio_esal = document.getElementById('patrimonio_esal').value;
                        var ingresos_esal = document.getElementById('ingresos_esal').value;
                        var otros_ingresos_esal = document.getElementById('otros_ingresos_esal').value;
                        var egresos_esal = document.getElementById('egresos_esal').value;
                        var origen_otros_ingresos_esal = document.getElementById('origen_otros_ingresos_esal').value;
                        //Parámetros Pestaña P.E.P.\\
                        var empleado_publico_esal = document.getElementById('empleado_publico_esal').value;
                        var beneficiario_final_esal = document.getElementById('beneficiario_final_esal').value;
                        //Parámetros Pestaña Declaración Transparencia\\
                        var pregunta1_esal_pj = document.getElementById('pregunta1_esal_pj').value;
                        var pregunta2_esal_pj = document.getElementById('pregunta2_esal_pj').value;
                        var pregunta3_esal_pj = document.getElementById('pregunta3_esal_pj').value;
                        var pregunta4_esal_pj = document.getElementById('pregunta4_esal_pj').value;
                        var pregunta5_esal_pj = document.getElementById('pregunta5_esal_pj').value;
                        var pregunta6_esal_pj = document.getElementById('pregunta6_esal_pj').value;
                        var pregunta7_esal_pj = document.getElementById('pregunta7_esal_pj').value;
                        var pregunta8_esal_pj = document.getElementById('pregunta8_esal_pj').value;
                        var pregunta9_esal_pj = document.getElementById('pregunta9_esal_pj').value;
                        var pregunta10_esal_pj = document.getElementById('pregunta10_esal_pj').value;
                        var pregunta11_esal_pj = document.getElementById('pregunta11_esal_pj').value;
                        var pregunta12_esal_pj = document.getElementById('pregunta12_esal_pj').value;
                        var pregunta13_esal_pj = document.getElementById('pregunta13_esal_pj').value;
                        var pregunta14_esal_pj = document.getElementById('pregunta14_esal_pj').value;
                        break;
                    default:
                        //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
                        //Parámetros Pestaña Información General\\
                        var tipoPersonaDG = document.getElementById('tipoPersonaDG').value;
                        var tipo_documento_dg = document.getElementById('tipo_documento_dg').value;
                        var numero_identificacion2_dg = document.getElementById('numero_identificacion2_dg').value;
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = document.getElementById('primer_apellido_dg').value;
                                var segundo_apellido_dg = document.getElementById('segundo_apellido_dg').value;
                                var primer_nombre_dg = document.getElementById('primer_nombre_dg').value;
                                var segundo_nombre_dg = document.getElementById('segundo_nombre_dg').value;
                                break;
                            case 'PERSONA_JURIDICA':
                                var digito_verificacion_dg = document.getElementById('digito_verificacion_dg').value;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                            case 'EXTERIOR':
                                var digito_verificacion_dg = 0;
                                var primer_apellido_dg = '-';
                                var segundo_apellido_dg = '-';
                                var primer_nombre_dg = '-';
                                var segundo_nombre_dg = '-';
                                break;
                        }
                        var fecha_expedicion_dg = document.getElementById('fecha_expedicion_dg').value;
                        var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                        var municipio_expedicion_dg = document.getElementById('municipio_expedicion_dg').value;
                        var tamano_empresa_dg = document.getElementById('tamano_empresa_dg').value;
                        var razon_social_dg = document.getElementById('razon_social_dg').value;
                        var tipo_empresa_dg = document.getElementById('tipo_empresa_dg').value;
                        var nombre_comercial_dg = document.getElementById('nombre_comercial_dg').value;
                        var siglas_dg = document.getElementById('siglas_dg').value;
                        var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                        var municipio_funcionamiento_dg = document.getElementById('municipio_funcionamiento_dg').value;
                        var id_direccion_empresa1_dg = document.getElementById('id_direccion_empresa1_dg').value;
                        var direccion_empresa1_dg = document.getElementById('direccion_empresa1_dg').value;
                        var complemento_direccion_empresa1_dg = document.getElementById('complemento_direccion_empresa1_dg').value;
                        // var id_direccion_empresa2_dg = document.getElementById('id_direccion_empresa2_dg').value;
                        var direccion_empresa2_dg = document.getElementById('direccion_empresa2_dg').value;
                        var complemento_direccion_empresa2_dg = document.getElementById('complemento_direccion_empresa2_dg').value;
                        var direccion_empresa3_dg = document.getElementById('direccion_empresa3_dg').value;
                        var complemento_direccion_empresa_dg = document.getElementById('complemento_direccion_empresa_dg').value;
                        var correo_electronico_empresa_dg = document.getElementById('correo_electronico_empresa_dg').value;
                        var telefono1_dg = document.getElementById('telefono1_dg').value;
                        var ext_telefono1_dg = document.getElementById('ext_telefono1_dg').value;
                        var telefono2_dg = document.getElementById('telefono2_dg').value;
                        var pagina_web_dg = document.getElementById('pagina_web_dg').value;
                        var programa_gestion_riesgo_dg = document.getElementById('programa_gestion_riesgo_dg').value;
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            var otros_programa_gestion_riesgo_dg = document.getElementById('otros_programa_gestion_riesgo_dg').value;
                        } else {
                            var otros_programa_gestion_riesgo_dg = '-';
                        }
                        var detalle_gestion_riesgo_dg = document.getElementById('detalle_gestion_riesgo_dg').value;
                        //Parámetros Pestaña Información Compra\\
                        var proveedor_compra_cc = document.getElementById('proveedor_compra_cc').value;
                        var bien_servicio_compra_cc = document.getElementById('bien_servicio_compra_cc').value;
                        var terminos_compra_cc = document.getElementById('terminos_compra_cc').value;
                        var moneda_compra_cc = document.getElementById('moneda_compra_cc').value;
                        var poliza_garantia_cc = document.getElementById('poliza_garantia_cc').value;
                        //Parámetros Pestaña Información Banco\\
                        var nombre_banco_b = document.getElementById('nombre_banco_b').value;
                        var numero_cuenta_b = document.getElementById('numero_cuenta_b').value;
                        var tipo_cuenta_b = document.getElementById('tipo_cuenta_b').value;
                        // var valido_desde_b = document.getElementById('valido_desde_b').value;
                        // var valido_hasta_b = document.getElementById('valido_hasta_b').value;
                        var nombre_titular_b = document.getElementById('nombre_titular_b').value;
                        var tipo_documento_titular_b = document.getElementById('tipo_documento_titular_b').value;
                        var documento_titular_b = document.getElementById('documento_titular_b').value;
                        //Parámetros Pestaña Información Impuestos\\
                        var calidad_contribuyente_i = document.getElementById('calidad_contribuyente_i').value;
                        var gran_contribuyente_i = document.getElementById('gran_contribuyente_i').value;
                        var regimen_renta_i = document.getElementById('regimen_renta_i').value;
                        var usuario_zona_franca_i = document.getElementById('usuario_zona_franca_i').value;
                        var retencion_autoretenedor_i = document.getElementById('retencion_autoretenedor_i').value;
                        if (retencion_autoretenedor_i === 'SI') {
                            var retencion_condicion_i = document.getElementById('retencion_condicion_i').value;
                            var retencion_resolucion_i = document.getElementById('retencion_resolucion_i').value;
                            var retencion_fecha_i = document.getElementById('retencion_fecha_i').value;
                            var retencion_concepto_i = document.getElementById('retencion_concepto_i').value;
                        } else {
                            var retencion_condicion_i = '-';
                            var retencion_resolucion_i = '-';
                            var retencion_fecha_i = '1901-01-01';
                            var retencion_concepto_i = '-';
                        }
                        var retencion_factura_electronica_i = document.getElementById('retencion_factura_electronica_i').value;
                        if (retencion_factura_electronica_i === 'SI') {
                            var retencion_correo_electronico_envio_i = document.getElementById('retencion_correo_electronico_envio_i').value;
                            var retencion_correo_electronico_rechazo_i = document.getElementById('retencion_correo_electronico_rechazo_i').value;
                        } else {
                            var retencion_correo_electronico_envio_i = '-';
                            var retencion_correo_electronico_rechazo_i = '-';
                        }
                        //Parámetros Pestaña Información Financiera\\
                        var fecha_reporte_financiera_f = document.getElementById('fecha_reporte_financiera_f').value;
                        var origen_ingresos_f = document.getElementById('origen_ingresos_f').value;
                        var total_activos_f = document.getElementById('total_activos_f').value;
                        var total_pasivos_f = document.getElementById('total_pasivos_f').value;
                        var patrimonio_f = document.getElementById('patrimonio_f').value;
                        var ingresos_f = document.getElementById('ingresos_f').value;
                        var egresos_f = document.getElementById('egresos_f').value;
                        var otros_ingresos_f = document.getElementById('otros_ingresos_f').value;
                        // var origen_otros_ingresos_f = document.getElementById('origen_otros_ingresos_f').value;
                        //Parámetros Pestaña Declaración Transparencia Persona Jurídica\\
                        var pregunta1_pj = document.getElementById('pregunta1_pj').value;
                        var pregunta2_pj = document.getElementById('pregunta2_pj').value;
                        var pregunta3_pj = document.getElementById('pregunta3_pj').value;
                        var pregunta4_pj = document.getElementById('pregunta4_pj').value;
                        var pregunta5_pj = document.getElementById('pregunta5_pj').value;
                        var pregunta6_pj = document.getElementById('pregunta6_pj').value;
                        var pregunta7_pj = document.getElementById('pregunta7_pj').value;
                        var pregunta8_pj = document.getElementById('pregunta8_pj').value;
                        var pregunta9_pj = document.getElementById('pregunta9_pj').value;
                        var pregunta10_pj = document.getElementById('pregunta10_pj').value;
                        var pregunta11_pj = document.getElementById('pregunta11_pj').value;
                        var pregunta12_pj = document.getElementById('pregunta12_pj').value;
                        var pregunta13_pj = document.getElementById('pregunta13_pj').value;
                        var pregunta14_pj = document.getElementById('pregunta14_pj').value;
                        break;
                }
            case '5':
                //** FORMULARIOS PROVEEDOR EXTERIOR **\\
                //Parámetros Pestaña Vendor Details\\
                var nombre_beneficiario_ex = document.getElementById('nombre_beneficiario_ex').value;
                var numero_identificacion_ex = document.getElementById('numero_identificacion_ex').value;
                var pais_ex = document.getElementById('pais_ex').value;
                var estado_ex = document.getElementById('estado_ex').value;
                var ciudad_ex = document.getElementById('ciudad_ex').value;
                var direccion_ex = document.getElementById('direccion_ex').value;
                var telefono1_ex = document.getElementById('telefono1_ex').value;
                var telefono2_ex = document.getElementById('telefono2_ex').value;
                var fax_ex = document.getElementById('fax_ex').value;
                var correo_electronico_ex = document.getElementById('correo_electronico_ex').value;
                var nombre_contacto_ex = document.getElementById('nombre_contacto_ex').value;
                var codigo_postal_ex = document.getElementById('codigo_postal_ex').value;
                //Parámetros Pestaña Bank Details\\
                var nombre_banco_ex = document.getElementById('nombre_banco_ex').value;
                var codigo_banco_ex = document.getElementById('codigo_banco_ex').value;
                var nombre_sucursal_ex = document.getElementById('nombre_sucursal_ex').value;
                var numero_sucursal_ex = document.getElementById('numero_sucursal_ex').value;
                var direccion_sucursal_banco_ex = document.getElementById('direccion_sucursal_banco_ex').value;
                var numero_cuenta_banco_ex = document.getElementById('numero_cuenta_banco_ex').value;
                var codigo_moneda_banco_ex = document.getElementById('codigo_moneda_banco_ex').value;
                var codigo_swift_banco_ex = document.getElementById('codigo_swift_banco_ex').value;
                var numero_aba_banco_ex = document.getElementById('numero_aba_banco_ex').value;
                var numero_iban_banco_ex = document.getElementById('numero_iban_banco_ex').value;
                //Parámetros Pestaña Intermediary Bank Details\\
                var nombre_banco_intermediario_ex = document.getElementById('nombre_banco_intermediario_ex').value;
                var numero_cuenta_banco_intermediario_ex = document.getElementById('numero_cuenta_banco_intermediario_ex').value;
                var codigo_swift_banco_intermediario_ex = document.getElementById('codigo_swift_banco_intermediario_ex').value;
                var numero_aba_banco_intermediario_ex = document.getElementById('numero_aba_banco_intermediario_ex').value;
                break;
        }
        var errores = [];
        switch (tipo_tercero) {
            case '1':
            case '3':
            case '4':
                //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
                //Parámetros Pestaña Información General\\
                if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                switch (tipoPersonaDG) {
                    case 'PERSONA_NATURAL':
                        if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                        if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                        if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                        // if (!segundo_nombre_dg) { errores.push("error_segundo_nombre_dg"); }
                        break;
                    case 'PERSONA_JURIDICA':
                        if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                        break;
                }
                if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                switch (tipo_tercero) {
                    case '1':
                        switch (tipoPersonaDG) {
                            case 'PERSONA_JURIDICA':
                                if (!pais_procedencia_dg) { errores.push("error_pais_procedencia_dg"); }
                                if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                                if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                                if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                                break;
                        }
                        break;
                    case '3':
                    case '4':
                    case '7':
                        if (!pais_procedencia_dg) { errores.push("error_pais_procedencia_dg"); }
                        break;
                    case '2':
                        if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                        if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                        if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                        // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                        // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                        break;
                }
                if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                // if (!complemento_direccion_empresa1_dg) { errores.push("error_complemento_direccion_empresa1_dg"); }
                // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                // if (!complemento_direccion_empresa2_dg) { errores.push("error_complemento_direccion_empresa2_dg"); }
                if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                    if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }
                }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información General sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                //Parámetros Pestaña Información Compra\\
                // if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                // if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                // if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                // if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                // if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                //Parámetros Pestaña Información Banco\\
                if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Banco sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Información Impuestos\\
                if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                if (retencion_autoretenedor_i === 'SI') {
                    if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                    if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                    if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                    if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                }
                if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                if (retencion_factura_electronica_i === 'SI') {
                    if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                    if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Impuestos sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Información Financiera\\
                if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                if (!total_activos_f) { errores.push("error_total_activos_f"); }
                if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                if (!ingresos_f) { errores.push("error_ingresos_f"); }
                if (!egresos_f) { errores.push("error_egresos_f"); }
                if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Financiera sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Declaración Transparencia Persona Natural\\
                // if (tipo_tercero === '7') {
                    if (!pregunta1_pn) { errores.push("error_pregunta1_pn"); }
                    if (!pregunta2_pn) { errores.push("error_pregunta2_pn"); }
                    if (!pregunta3_pn) { errores.push("error_pregunta3_pn"); }
                    if (!pregunta4_pn) { errores.push("error_pregunta4_pn"); }
                    if (!pregunta5_pn) { errores.push("error_pregunta5_pn"); }
                    if (!pregunta6_pn) { errores.push("error_pregunta6_pn"); }
                    if (!pregunta7_pn) { errores.push("error_pregunta7_pn"); }
                    if (!pregunta8_pn) { errores.push("error_pregunta8_pn"); }
                    if (!pregunta9_pn) { errores.push("error_pregunta9_pn"); }
                    if (!pregunta10_pn) { errores.push("error_pregunta10_pn"); }
                    if (!pregunta11_pn) { errores.push("error_pregunta11_pn"); }
                    if (!pregunta12_pn) { errores.push("error_pregunta12_pn"); }
                    if (!pregunta13_pn) { errores.push("error_pregunta13_pn"); }
                    if (errores.length > 0) {
                        Swal.fire({
                            title: 'Formularios',
                            text: 'Existen campos en la Pestaña Declaración Transparencia sin diligenciar. Favor verificar Información.',
                            icon: 'error',
                            confirmButtonText: 'Aceptar',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                return false;
                            }
                        })
                        return false;
                    }
                // }
                break;
            case '2':
            case '7':
                //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
                //Parámetros Pestaña Información General\\
                if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                switch (tipoPersonaDG) {
                    case 'PERSONA_NATURAL':
                        if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                        if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                        if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                        // if (!segundo_nombre_dg) { errores.push("error_segundo_nombre_dg"); }
                        break;
                    case 'PERSONA_JURIDICA':
                        if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                        break;
                }
                if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                switch (tipo_tercero) {
                    case '1':
                    case '3':
                    case '4':
                    case '7':
                        if (!pais_procedencia_dg) { errores.push("error_pais_procedencia_dg"); }
                        break;
                    case '2':
                        if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                        if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                        if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                        // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                        // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                        break;
                }
                if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                // if (!complemento_direccion_empresa1_dg) { errores.push("error_complemento_direccion_empresa1_dg"); }
                // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                // if (!complemento_direccion_empresa2_dg) { errores.push("error_complemento_direccion_empresa2_dg"); }
                if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                    if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }
                }
                // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información General sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Información Compra\\
                if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Compra sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Información Banco\\
                if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Banco sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Información Impuestos\\
                if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                if (retencion_autoretenedor_i === 'SI') {
                    if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                    if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                    if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                    if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                }
                if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                if (retencion_factura_electronica_i === 'SI') {
                    if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                    if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Impuestos sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Información Financiera\\
                if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                if (!total_activos_f) { errores.push("error_total_activos_f"); }
                if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                if (!ingresos_f) { errores.push("error_ingresos_f"); }
                if (!egresos_f) { errores.push("error_egresos_f"); }
                if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Formularios',
                        text: 'Existen campos en la Pestaña Información Financiera sin diligenciar. Favor verificar Información.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                //Parámetros Pestaña Declaración Transparencia Persona Natural\\
                // if (tipo_tercero === '7') {
                    if (!pregunta1_pn) { errores.push("error_pregunta1_pn"); }
                    if (!pregunta2_pn) { errores.push("error_pregunta2_pn"); }
                    if (!pregunta3_pn) { errores.push("error_pregunta3_pn"); }
                    if (!pregunta4_pn) { errores.push("error_pregunta4_pn"); }
                    if (!pregunta5_pn) { errores.push("error_pregunta5_pn"); }
                    if (!pregunta6_pn) { errores.push("error_pregunta6_pn"); }
                    if (!pregunta7_pn) { errores.push("error_pregunta7_pn"); }
                    if (!pregunta8_pn) { errores.push("error_pregunta8_pn"); }
                    if (!pregunta9_pn) { errores.push("error_pregunta9_pn"); }
                    if (!pregunta10_pn) { errores.push("error_pregunta10_pn"); }
                    if (!pregunta11_pn) { errores.push("error_pregunta11_pn"); }
                    if (!pregunta12_pn) { errores.push("error_pregunta12_pn"); }
                    if (!pregunta13_pn) { errores.push("error_pregunta13_pn"); }
                    if (errores.length > 0) {
                        Swal.fire({
                            title: 'Formularios',
                            text: 'Existen campos en la Pestaña Declaración Transparencia sin diligenciar. Favor verificar Información.',
                            icon: 'error',
                            confirmButtonText: 'Aceptar',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                return false;
                            }
                        })
                        return false;
                    }
                // }
                break;
            case '6':
                switch (tipo_sociedad) {
                    case '4':
                        //** FORMULARIOS EMPRESAS E.S.A.L. **\\
                        //Parámetros Pestaña Información General\\
                        if (!ciudad_esal_dg) { errores.push("error_ciudad_esal_dg"); }
                        if (!razon_social_esal_dg) { errores.push("error_razon_social_esal_dg"); }
                        if (!tipo_persona_esal_dg) { errores.push("error_tipo_persona_esal_dg"); }
                        if (!tipo_documento_esal_dg) { errores.push("error_tipo_documento_esal_dg"); }
                        if (!numero_documento_esal_dg) { errores.push("error_numero_documento_esal_dg"); }
                        if (!digito_verificacion_esal_dg) { errores.push("error_digito_verificacion_esal_dg"); }
                        if (!fecha_constitucion_esal_dg) { errores.push("error_fecha_constitucion_esal_dg"); }
                        if (!pais_procedencia_esal_dg) { errores.push("error_pais_procedencia_esal_dg"); }
                        if (!departamento_esal_dg) { errores.push("error_departamento_esal_dg"); }
                        if (!municipio_esal_dg) { errores.push("error_municipio_esal_dg"); }
                        if (!direccion_empresa_principal_esal_dg) { errores.push("error_direccion_empresa_principal_esal_dg"); }
                        if (!telefono_empresa_esal_dg) { errores.push("error_telefono_empresa_esal_dg"); }
                        if (!extension_telefono_empresa_esal_dg) { errores.push("error_extension_telefono_empresa_esal_dg"); }
                        if (!pagina_web_empresa_esal_dg) { errores.push("error_pagina_web_empresa_esal_dg"); }
                        if (!factura_electronica_retencion_esal_dg) { errores.push("error_factura_electronica_retencion_esal_dg"); }
                        if (!correo_electronico_envio_retencion_esal_dg) { errores.push("error_correo_electronico_envio_retencion_esal_dg"); }
                        if (!correo_electronico_rechazo_retencion_esal_dg) { errores.push("error_correo_electronico_rechazo_retencion_esal_dg"); }
                        if (!contacto_contabilidad_esal_dg) { errores.push("error_contacto_contabilidad_esal_dg"); }
                        if (!correo_electronico_contacto_contabilidad_esal_dg) { errores.push("error_correo_electronico_contacto_contabilidad_esal_dg"); }
                        if (!descripcion_bienes_servicios_esal_dg) { errores.push("error_descripcion_bienes_servicios_esal_dg"); }
                        if (!terminos_negociacion_esal_dg) { errores.push("error_terminos_negociacion_esal_dg"); }
                        if (!plazo_pago_facturas_esal_dg) { errores.push("error_plazo_pago_facturas_esal_dg"); }
                        if (!contacto_proveedor_esal_dg) { errores.push("error_contacto_proveedor_esal_dg"); }
                        if (!correo_electronico_contacto_proveedor_esal_dg) { errores.push("error_correo_electronico_contacto_proveedor_esal_dg"); }
                        if (!telefono_contacto_proveedor_esal_dg) { errores.push("error_telefono_contacto_proveedor_esal_dg"); }
                        if (!extension_telefono_contacto_proveedor_esal_dg) { errores.push("error_extension_telefono_contacto_proveedor_esal_dg"); }
                        if (!celular1_contacto_proveedor_esal_dg) { errores.push("error_celular1_contacto_proveedor_esal_dg"); }
                        if (!celular2_contacto_proveedor_esal_dg) { errores.push("error_celular2_contacto_proveedor_esal_dg"); }
                        if (!poliza_garantia_esal_dg) { errores.push("error_poliza_garantia_esal_dg"); }
                        if (!programa_gestion_riesgo_esal_dg) { errores.push("error_programa_gestion_riesgo_esal_dg"); }
                        if (programa_gestion_riesgo_esal_dg === 'OTROS_PROGRAMAS') {
                            if (!otros_programa_gestion_riesgo_esal_dg) { errores.push("error_otros_programa_gestion_riesgo_esal_dg"); }
                        }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Información General sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        // if (!detalle_gestion_riesgo_esal_dg) { errores.push("error_detalle_gestion_riesgo_esal_dg"); }
                        //Parámetros Pestaña Representante Legal\\
                        if (!rep_legal_esal) { errores.push("error_rep_legal_esal"); }
                        if (!socio_rep_legal_esal) { errores.push("error_socio_rep_legal_esal"); }
                        if (!tipo_documento_rep_legal_esal) { errores.push("error_tipo_documento_rep_legal_esal"); }
                        if (!numero_documento_rep_legal_esal) { errores.push("error_numero_documento_rep_legal_esal"); }
                        if (!expedicion_documento_rep_legal_esal) { errores.push("error_expedicion_documento_rep_legal_esal"); }
                        if (!cargo_rep_legal_esal) { errores.push("error_cargo_rep_legal_esal"); }
                        if (!correo_electronico_rep_legal_esal) { errores.push("error_correo_electronico_rep_legal_esal"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Representante Legal sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña Financiera\\
                        if (!fecha_reporte_esal) { errores.push("error_fecha_reporte_esal"); }
                        if (!total_activos_esal) { errores.push("error_total_activos_esal"); }
                        if (!total_pasivos_esal) { errores.push("error_total_pasivos_esal"); }
                        if (!patrimonio_esal) { errores.push("error_patrimonio_esal"); }
                        if (!ingresos_esal) { errores.push("error_ingresos_esal"); }
                        if (!otros_ingresos_esal) { errores.push("error_otros_ingresos_esal"); }
                        if (!egresos_esal) { errores.push("error_egresos_esal"); }
                        if (!origen_otros_ingresos_esal) { errores.push("error_origen_otros_ingresos_esal"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Financiera sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña P.E.P.\\
                        if (!empleado_publico_esal) { errores.push("error_empleado_publico_esal"); }
                        if (!beneficiario_final_esal) { errores.push("error_beneficiario_final_esal"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña P.E.P. sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña Declaración Transparencia\\
                        if (!pregunta1_esal_pj) { errores.push("error_pregunta1_esal_pj"); }
                        if (!pregunta2_esal_pj) { errores.push("error_pregunta2_esal_pj"); }
                        if (!pregunta3_esal_pj) { errores.push("error_pregunta3_esal_pj"); }
                        if (!pregunta4_esal_pj) { errores.push("error_pregunta4_esal_pj"); }
                        if (!pregunta5_esal_pj) { errores.push("error_pregunta5_esal_pj"); }
                        if (!pregunta6_esal_pj) { errores.push("error_pregunta6_esal_pj"); }
                        if (!pregunta7_esal_pj) { errores.push("error_pregunta7_esal_pj"); }
                        if (!pregunta8_esal_pj) { errores.push("error_pregunta8_esal_pj"); }
                        if (!pregunta9_esal_pj) { errores.push("error_pregunta9_esal_pj"); }
                        if (!pregunta10_esal_pj) { errores.push("error_pregunta10_esal_pj"); }
                        if (!pregunta11_esal_pj) { errores.push("error_pregunta11_esal_pj"); }
                        if (!pregunta12_esal_pj) { errores.push("error_pregunta12_esal_pj"); }
                        if (!pregunta13_esal_pj) { errores.push("error_pregunta13_esal_pj"); }
                        if (!pregunta14_esal_pj) { errores.push("error_pregunta14_esal_pj"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Declaración Transparencia sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        break;
                    default:
                        //** FORMULARIOS PROVEEDOR JURÍDICO - NATURAL, ACCIONISTA, EMPLEADO, CLIENTE Y MIEMBRO JUNTA DIRECTIVA **\\
                        //Parámetros Pestaña Información General\\
                        if (!tipoPersonaDG) { errores.push("error_tipoPersonaDG"); }
                        if (!tipo_documento_dg) { errores.push("error_tipo_documento_dg"); }
                        if (!numero_identificacion2_dg) { errores.push("error_numero_identificacion2_dg"); }
                        switch (tipoPersonaDG) {
                            case 'PERSONA_NATURAL':
                                if (!primer_apellido_dg) { errores.push("error_primer_apellido_dg"); }
                                if (!segundo_apellido_dg) { errores.push("error_segundo_apellido_dg"); }
                                if (!primer_nombre_dg) { errores.push("error_primer_nombre_dg"); }
                                // if (!segundo_nombre_dg) { errores.push("error_segundo_nombre_dg"); }
                                break;
                            case 'PERSONA_JURIDICA':
                                if (!digito_verificacion_dg) { errores.push("error_digito_verificacion_dg"); }
                                break;
                        }
                        if (!fecha_expedicion_dg) { errores.push("error_fecha_expedicion_dg"); }
                        if (!departamento_expedicion_dg) { errores.push("error_departamento_expedicion_dg"); }
                        if (!municipio_expedicion_dg) { errores.push("error_municipio_expedicion_dg"); }
                        if (!tamano_empresa_dg) { errores.push("error_tamano_empresa_dg"); }
                        if (!razon_social_dg) { errores.push("error_razon_social_dg"); }
                        if (!tipo_empresa_dg) { errores.push("error_tipo_empresa_dg"); }
                        // if (!nombre_comercial_dg) { errores.push("error_nombre_comercial_dg"); }
                        // if (!siglas_dg) { errores.push("error_siglas_dg"); }
                        if (!departamento_funcionamiento_dg) { errores.push("error_departamento_funcionamiento_dg"); }
                        if (!municipio_funcionamiento_dg) { errores.push("error_municipio_funcionamiento_dg"); }
                        if (!id_direccion_empresa1_dg) { errores.push("error_id_direccion_empresa1_dg"); }
                        if (!direccion_empresa1_dg) { errores.push("error_direccion_empresa1_dg"); }
                        // if (!complemento_direccion_empresa1_dg) { errores.push("error_complemento_direccion_empresa1_dg"); }
                        // if (!id_direccion_empresa2_dg) { errores.push("error_id_direccion_empresa2_dg"); }
                        if (!direccion_empresa2_dg) { errores.push("error_direccion_empresa2_dg"); }
                        // if (!complemento_direccion_empresa2_dg) { errores.push("error_complemento_direccion_empresa2_dg"); }
                        if (!direccion_empresa3_dg) { errores.push("error_direccion_empresa3_dg"); }
                        // if (!complemento_direccion_empresa_dg) { errores.push("error_complemento_direccion_empresa_dg"); }
                        if (!correo_electronico_empresa_dg) { errores.push("error_correo_electronico_empresa_dg"); }
                        if (!telefono1_dg) { errores.push("error_telefono1_dg"); }
                        // if (!ext_telefono1_dg) { errores.push("error_ext_telefono1_dg"); }
                        // if (!telefono2_dg) { errores.push("error_telefono2_dg"); }
                        // if (!pagina_web_dg) { errores.push("error_pagina_web_dg"); }
                        if (!programa_gestion_riesgo_dg) { errores.push("error_programa_gestion_riesgo_dg"); }
                        if (programa_gestion_riesgo_dg === 'OTROS_PROGRAMAS') {
                            if (!otros_programa_gestion_riesgo_dg) { errores.push("error_otros_programa_gestion_riesgo_dg"); }
                        }
                        // if (!detalle_gestion_riesgo_dg) { errores.push("error_detalle_gestion_riesgo_dg"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Información General sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña Información Compra\\
                        if (!proveedor_compra_cc) { errores.push("error_proveedor_compra_cc"); }
                        if (!bien_servicio_compra_cc) { errores.push("error_bien_servicio_compra_cc"); }
                        if (!terminos_compra_cc) { errores.push("error_terminos_compra_cc"); }
                        if (!moneda_compra_cc) { errores.push("error_moneda_compra_cc"); }
                        if (!poliza_garantia_cc) { errores.push("error_poliza_garantia_cc"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Información Compra sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña Información Banco\\
                        if (!nombre_banco_b) { errores.push("error_nombre_banco_b"); }
                        if (!numero_cuenta_b) { errores.push("error_numero_cuenta_b"); }
                        if (!tipo_cuenta_b) { errores.push("error_tipo_cuenta_b"); }
                        // if (!valido_desde_b) { errores.push("error_valido_desde_b"); }
                        // if (!valido_hasta_b) { errores.push("error_valido_hasta_b"); }
                        if (!nombre_titular_b) { errores.push("error_nombre_titular_b"); }
                        if (!tipo_documento_titular_b) { errores.push("error_tipo_documento_titular_b"); }
                        if (!documento_titular_b) { errores.push("error_documento_titular_b"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Información Banco sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña Información Impuestos\\
                        if (!calidad_contribuyente_i) { errores.push("error_calidad_contribuyente_i"); }
                        if (!gran_contribuyente_i) { errores.push("error_gran_contribuyente_i"); }
                        if (!regimen_renta_i) { errores.push("error_regimen_renta_i"); }
                        if (!usuario_zona_franca_i) { errores.push("error_usuario_zona_franca_i"); }
                        if (!retencion_autoretenedor_i) { errores.push("error_retencion_autoretenedor_i"); }
                        if (retencion_autoretenedor_i === 'SI') {
                            if (!retencion_condicion_i) { errores.push("error_retencion_condicion_i"); }
                            if (!retencion_resolucion_i) { errores.push("error_retencion_resolucion_i"); }
                            if (!retencion_fecha_i) { errores.push("error_retencion_fecha_i"); }
                            if (!retencion_concepto_i) { errores.push("error_retencion_concepto_i"); }
                        }
                        if (!retencion_factura_electronica_i) { errores.push("error_retencion_factura_electronica_i"); }
                        if (retencion_factura_electronica_i === 'SI') {
                            if (!retencion_correo_electronico_envio_i) { errores.push("error_retencion_correo_electronico_envio_i"); }
                            if (!retencion_correo_electronico_rechazo_i) { errores.push("error_retencion_correo_electronico_rechazo_i"); }
                        }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Información Impuestos sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        //Parámetros Pestaña Información Financiera\\
                        if (!fecha_reporte_financiera_f) { errores.push("error_fecha_reporte_financiera_f"); }
                        if (!origen_ingresos_f) { errores.push("error_origen_ingresos_f"); }
                        if (!total_activos_f) { errores.push("error_total_activos_f"); }
                        if (!total_pasivos_f) { errores.push("error_total_pasivos_f"); }
                        if (!patrimonio_f) { errores.push("error_patrimonio_f"); }
                        if (!ingresos_f) { errores.push("error_ingresos_f"); }
                        if (!egresos_f) { errores.push("error_egresos_f"); }
                        if (!otros_ingresos_f) { errores.push("error_otros_ingresos_f"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Información Financiera sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        // if (!origen_otros_ingresos_f) { errores.push("error_origen_otros_ingresos_f"); }
                        //Parámetros Pestaña Declaración Transparencia Persona Jurídica\\
                        if (!pregunta1_pj) { errores.push("error_pregunta1_pj"); }
                        if (!pregunta2_pj) { errores.push("error_pregunta2_pj"); }
                        if (!pregunta3_pj) { errores.push("error_pregunta3_pj"); }
                        if (!pregunta4_pj) { errores.push("error_pregunta4_pj"); }
                        if (!pregunta5_pj) { errores.push("error_pregunta5_pj"); }
                        if (!pregunta6_pj) { errores.push("error_pregunta6_pj"); }
                        if (!pregunta7_pj) { errores.push("error_pregunta7_pj"); }
                        if (!pregunta8_pj) { errores.push("error_pregunta8_pj"); }
                        if (!pregunta9_pj) { errores.push("error_pregunta9_pj"); }
                        if (!pregunta10_pj) { errores.push("error_pregunta10_pj"); }
                        if (!pregunta11_pj) { errores.push("error_pregunta11_pj"); }
                        if (!pregunta12_pj) { errores.push("error_pregunta12_pj"); }
                        if (!pregunta13_pj) { errores.push("error_pregunta13_pj"); }
                        if (!pregunta14_pj) { errores.push("error_pregunta14_pj"); }
                        if (errores.length > 0) {
                            Swal.fire({
                                title: 'Formularios',
                                text: 'Existen campos en la Pestaña Declaración Transparencia sin diligenciar. Favor verificar Información.',
                                icon: 'error',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    return false;
                                }
                            })
                            return false;
                        }
                        break;
                }
                break;
            case '5':
                //** FORMULARIOS PROVEEDOR EXTERIOR **\\
                //Parámetros Pestaña Vendor Details\\
                if (!nombre_beneficiario_ex) { errores.push("error_nombre_beneficiario_ex"); }
                if (!numero_identificacion_ex) { errores.push("error_numero_identificacion_ex"); }
                if (!pais_ex) { errores.push("error_pais_ex"); }
                if (!estado_ex) { errores.push("error_estado_ex"); }
                if (!ciudad_ex) { errores.push("error_ciudad_ex"); }
                if (!direccion_ex) { errores.push("error_direccion_ex"); }
                if (!telefono1_ex) { errores.push("error_telefono1_ex"); }
                // if (!telefono2_ex) { errores.push("error_telefono2_ex"); }
                if (!fax_ex) { errores.push("error_fax_ex"); }
                if (!correo_electronico_ex) { errores.push("error_correo_electronico_ex"); }
                if (!nombre_contacto_ex) { errores.push("error_nombre_contacto_ex"); }
                if (!codigo_postal_ex) { errores.push("error_codigo_postal_ex"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Forms',
                        text: 'There are fields in the Vendor Details Tab that have not been completed. Please verify information.',
                        icon: 'error',
                        confirmButtonText: 'Accept',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Bank Details\\
                if (!nombre_banco_ex) { errores.push("error_nombre_banco_ex"); }
                if (!codigo_banco_ex) { errores.push("error_codigo_banco_ex"); }
                if (!nombre_sucursal_ex) { errores.push("error_nombre_sucursal_ex"); }
                if (!numero_sucursal_ex) { errores.push("error_numero_sucursal_ex"); }
                if (!direccion_sucursal_banco_ex) { errores.push("error_direccion_sucursal_banco_ex"); }
                if (!numero_cuenta_banco_ex) { errores.push("error_numero_cuenta_banco_ex"); }
                if (!codigo_moneda_banco_ex) { errores.push("error_codigo_moneda_banco_ex"); }
                if (!codigo_swift_banco_ex) { errores.push("error_codigo_swift_banco_ex"); }
                if (!numero_aba_banco_ex) { errores.push("error_numero_aba_banco_ex"); }
                if (!numero_iban_banco_ex) { errores.push("error_numero_iban_banco_ex"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Forms',
                        text: 'There are fields in the Bank Details Tab that have not been completed. Please verify information.',
                        icon: 'error',
                        confirmButtonText: 'Accept',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                //Parámetros Pestaña Intermediary Bank Details\\
                if (!nombre_banco_intermediario_ex) { errores.push("error_nombre_banco_intermediario_ex"); }
                if (!numero_cuenta_banco_intermediario_ex) { errores.push("error_numero_cuenta_banco_intermediario_ex"); }
                if (!codigo_swift_banco_intermediario_ex) { errores.push("error_codigo_swift_banco_intermediario_ex"); }
                if (!numero_aba_banco_intermediario_ex) { errores.push("error_numero_aba_banco_intermediario_ex"); }
                if (errores.length > 0) {
                    Swal.fire({
                        title: 'Forms',
                        text: 'There are fields in the Intermediary Bank Details Tab that have not been completed. Please verify information.',
                        icon: 'error',
                        confirmButtonText: 'Accept',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    })
                    return false;
                }
                break;
        }
        this.setState({ errores: errores });
        console.log(errores);
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form-tercero');
        let formData = new FormData(form);
        console.log(formData);
        Swal.fire({
            title: 'Está seguro de enviar el formulario?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Enviar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                authAxios.post("formulario/update", formData)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta)
                        if (datosRespuesta.data.Status !== false) {
                            Swal.fire({
                                title: 'Formularios',
                                text: datosRespuesta.data.msj,
                                icon: 'success',
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.props.history.push("/MasterTerceros");
                                }
                            })
                        } else {
                            Swal.fire(
                                'Formularios!',
                                datosRespuesta.data.msj,
                                'error'
                            )
                        }
                    })
                    .catch(console.log)
            }
        })
    }
    resetFields = (e, modal) => {
        e.preventDefault();
        switch (modal) {
            case 'beneficiario':
                var pep_b = document.getElementById('pep_b').value;
                this.setState({
                    nombre_beneficiario_b: '',
                    apellido_beneficiario_b: '',
                    numero_documento_beneficiario_b: '',
                    correo_electronico_beneficiario_b: '',
                    telefono_beneficiario_b: '',
                    telefono_movil_beneficiario_b: '',
                    vinculo_actual_pep_b: '',
                    cargo_actividad_pep_b: '',
                    fecha_vinculacion_pep_b: '',
                    fecha_desvinculacion_pep_b: '',
                    nombre_familiar_nacional_pep_b: '',
                    numero_documento_familiar_nacional_pep_b: '',
                    razon_social_participacion_nacional_pep_b: '',
                    numero_documento_participacion_nacional_pep_b: '',
                    porcentaje_participacion_nacional_pep_b: '',
                    otros_tipo_control_participacion_nacional_pep_b: '',
                    detalle_tipo_control_participacion_nacional_pep_b: '',
                    razon_social_cuenta_extranjera_nacional_pep_b: '',
                    pais_cuenta_extranjera_nacional_pep_b: '',
                    numero_cuenta_extranjera_nacional_pep_b : '',
                    clase_propiedad_cuenta_extranjera_nacional_pep_b: '',
                    // nombre_beneficiario_extranjero_pep_b: '',
                    // numero_documento_beneficiario_extranjero_pep_b: '',
                    // vinculo_actual_beneficiario_extranjero_pep_b: '',
                    // cargo_actividad_beneficiario_extranjero_pep_b: '',
                    // fecha_vinculacion_beneficiario_extranjero_pep_b: '',
                    // fecha_desvinculacion_beneficiario_extranjero_pep_b: '',
                    nombre_familiar_extranjero_pep_b: '',
                    numero_documento_familiar_extranjero_pep_b: '',
                    razon_social_participacion_extranjero_pep_b: '',
                    numero_documento_participacion_extranjero_pep_b: '',
                    porcentaje_participacion_extranjero_pep_b: '',
                    otros_tipo_control_participacion_extranjero_pep_b: '',
                    detalle_tipo_control_participacion_extranjero_pep_b: '',
                    razon_social_cuenta_extranjera_extranjero_pep_b: '',
                    pais_cuenta_extranjera_extranjero_pep_b: '',
                    numero_cuenta_extranjera_extranjero_pep_b: '',
                    clase_propiedad_cuenta_extranjera_extranjero_pep_b: '',
                    familiaresBeneficiarioPEPNacional: [],
                    sociedadesBeneficiarioPEPNacional: [],
                    cuentasBeneficiarioPEPNacional: [],
                    familiaresBeneficiarioPEPExtranjero: [],
                    sociedadesBeneficiarioPEPExtranjero: [],
                    cuentasBeneficiarioPEPExtranjero: [],
                    activar_pep_beneficiario: false,
                    activar_sociedad_beneficiario_pep_nacional: false,
                    activar_cuenta_beneficiario_pep_nacional: false,
                    activar_sociedad_beneficiario_pep_extranjero: false,
                    activar_cuenta_beneficiario_pep_extranjero: false
                });
                document.getElementById('pep_b').value = '';
                document.getElementById('declaracion_veracidad_pep_b').value = '';
                document.getElementById('vinculacion_activa_pep_b').value = '';
                document.getElementById('parentesco_familiar_nacional_pep_b').value = '';
                document.getElementById('tipo_documento_familiar_nacional_pep_b').value = '';
                document.getElementById('pregunta_participacion_nacional_pep_b').value = '';
                document.getElementById('tipo_control_participacion_nacional_pep_b').value = '';
                document.getElementById('pregunta_cuenta_extranjera_nacional_pep_b').value = '';
                document.getElementById('pregunta_beneficiario_extranjero_pep_b').value = '';
                document.getElementById('parentesco_familiar_extranjero_pep_b').value = '';
                document.getElementById('tipo_documento_familiar_extranjero_pep_b').value = '';
                document.getElementById('pregunta_participacion_extranjero_pep_b').value = '';
                document.getElementById('tipo_control_participacion_extranjero_pep_b').value = '';
                document.getElementById('pregunta_cuenta_extranjera_extranjero_pep_b').value = '';
                document.getElementById('pregunta1_pep_b').value = '';
                document.getElementById('pregunta2_pep_b').value = '';
                document.getElementById('pregunta3_pep_b').value = '';
                document.getElementById('pregunta4_pep_b').value = '';
                document.getElementById('pregunta5_pep_b').value = '';
                document.getElementById('pregunta6_pep_b').value = '';
                document.getElementById('pregunta7_pep_b').value = '';
                document.getElementById('pregunta8_pep_b').value = '';
                document.getElementById('pregunta9_pep_b').value = '';
                document.getElementById('pregunta10_pep_b').value = '';
                document.getElementById('pregunta11_pep_b').value = '';
                if (pep_b === 'SI') {
                    document.getElementById('nav-datos_pep_beneficiario-tab').classList.remove('active');
                    document.getElementById('nav-datos_pep_beneficiario').classList.remove('show', 'active');
                    document.getElementById('nav-datos_beneficiario-tab').classList.add('active');
                    document.getElementById('nav-datos_beneficiario').classList.add('show', 'active');
                }
                Swal.showLoading();
                authAxios.get("contraparte/delete_all_beneficiario/"  + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true });
                        Swal.close();
                    })
                    .catch(console.log);
                break;
            case 'familiar_beneficiario_pep_nacional':
                this.setState({
                    nombre_familiar_nacional_pep_b : '',
                    numero_documento_familiar_nacional_pep_b: '',
                });
                document.getElementById('parentesco_familiar_nacional_pep_b').value = '';
                document.getElementById('tipo_documento_familiar_nacional_pep_b').value = '';
                break;
            case 'sociedad_beneficiario_pep_nacional':
                this.setState({
                    razon_social_participacion_nacional_pep_b: '',
                    numero_documento_participacion_nacional_pep_b: '',
                    porcentaje_participacion_nacional_pep_b: '',
                    otros_tipo_control_participacion_nacional_pep_b: '',
                    detalle_tipo_control_participacion_nacional_pep_b: '',
                    activar_otros_tipos_control_beneficiario_pep_nacional: false
                });
                document.getElementById('tipo_control_participacion_nacional_pep_b').value = '';
                break;
            case 'cuenta_beneficiario_pep_nacional':
                this.setState({
                    razon_social_cuenta_extranjera_nacional_pep_b: '',
                    pais_cuenta_extranjera_nacional_pep_b: '',
                    numero_cuenta_extranjera_nacional_pep_b: '',
                    clase_propiedad_cuenta_extranjera_nacional_pep_b: '',
                });
                break;
            case 'familiar_beneficiario_pep_extranjero':
                this.setState({
                    nombre_familiar_extranjero_pep_b : '',
                    numero_documento_familiar_extranjero_pep_b: '',
                });
                document.getElementById('parentesco_familiar_extranjero_pep_b').value = '';
                document.getElementById('tipo_documento_familiar_extranjero_pep_b').value = '';
                break;
            case 'sociedad_beneficiario_pep_extranjero':
                this.setState({
                    razon_social_participacion_extranjero_pep_b: '',
                    numero_documento_participacion_extranjero_pep_b: '',
                    porcentaje_participacion_extranjero_pep_b: '',
                    otros_tipo_control_participacion_extranjero_pep_b: '',
                    detalle_tipo_control_participacion_extranjero_pep_b: '',
                    activar_otros_tipos_control_beneficiario_pep_extranjero: false
                });
                document.getElementById('tipo_control_participacion_extranjero_pep_b').value = '';
                break;
            case 'cuenta_beneficiario_pep_extranjero':
                this.setState({
                    razon_social_cuenta_extranjera_extranjero_pep_b: '',
                    pais_cuenta_extranjera_extranjero_pep_b: '',
                    numero_cuenta_extranjera_extranjero_pep_b: '',
                    clase_propiedad_cuenta_extranjera_extranjero_pep_b: '',
                });
                break;
            case 'contacto':
                this.setState({
                    nombre_contacto_c: '',
                    apellido_contacto_c: '',
                    cargo_contacto_c: '',
                    correo_electronico_c: '',
                    telefono_c: '',
                    ext_telefono_c: '',
                    telefono_movil_c: ''
                });
                document.getElementById('tipo_contacto_c').value = '';
                break;
            case 'gobierno':
                var pep_r = document.getElementById('pep_r').value;
                this.setState({
                    capital_representante_r: '',
                    nombre_representante_r: '',
                    apellido_representante_r: '',
                    numero_documento_representante_r: '',
                    digito_verificacion_r: '',
                    vinculo_actual_pep_r: '',
                    cargo_actividad_pep_r: '',
                    fecha_vinculacion_pep_r: '',
                    fecha_desvinculacion_pep_r: '',
                    nombre_familiar_nacional_pep_r: '',
                    numero_documento_familiar_nacional_pep_r: '',
                    razon_social_participacion_nacional_pep_r: '',
                    numero_documento_participacion_nacional_pep_r: '',
                    porcentaje_participacion_nacional_pep_r: '',
                    otros_tipo_control_participacion_nacional_pep_r: '',
                    detalle_tipo_control_participacion_nacional_pep_r: '',
                    razon_social_cuenta_extranjera_nacional_pep_r: '',
                    pais_cuenta_extranjera_nacional_pep_r: '',
                    numero_cuenta_extranjera_nacional_pep_r : '',
                    clase_propiedad_cuenta_extranjera_nacional_pep_r: '',
                    // nombre_beneficiario_extranjero_pep_r: '',
                    // numero_documento_beneficiario_extranjero_pep_r: '',
                    // vinculo_actual_beneficiario_extranjero_pep_r: '',
                    // cargo_actividad_beneficiario_extranjero_pep_r: '',
                    // fecha_vinculacion_beneficiario_extranjero_pep_r: '',
                    // fecha_desvinculacion_beneficiario_extranjero_pep_r: '',
                    nombre_familiar_extranjero_pep_r: '',
                    numero_documento_familiar_extranjero_pep_r: '',
                    razon_social_participacion_extranjero_pep_r: '',
                    numero_documento_participacion_extranjero_pep_r: '',
                    porcentaje_participacion_extranjero_pep_r: '',
                    otros_tipo_control_participacion_extranjero_pep_r: '',
                    detalle_tipo_control_participacion_extranjero_pep_r: '',
                    razon_social_cuenta_extranjera_extranjero_pep_r: '',
                    pais_cuenta_extranjera_extranjero_pep_r: '',
                    numero_cuenta_extranjera_extranjero_pep_r: '',
                    clase_propiedad_cuenta_extranjera_extranjero_pep_r: '',
                    familiaresPEPNacional: [],
                    sociedadesPEPNacional: [],
                    cuentasPEPNacional: [],
                    familiaresPEPExtranjero: [],
                    sociedadesPEPExtranjero: [],
                    cuentasPEPExtranjero: [],
                    activar_pep: false,
                    activar_sociedad_pep_nacional: false,
                    activar_cuenta_pep_nacional: false,
                    activar_sociedad_pep_extranjero: false,
                    activar_cuenta_pep_extranjero: false
                });
                document.getElementById('tipo_rol_r').value = '';
                document.getElementById('pep_r').value = '';
                document.getElementById('tipo_documento_representante_r').value = '';
                document.getElementById('declaracion_veracidad_pep_r').value = '';
                document.getElementById('vinculacion_activa_pep_r').value = '';
                document.getElementById('parentesco_familiar_nacional_pep_r').value = '';
                document.getElementById('tipo_documento_familiar_nacional_pep_r').value = '';
                document.getElementById('pregunta_participacion_nacional_pep_r').value = '';
                document.getElementById('tipo_control_participacion_nacional_pep_r').value = '';
                document.getElementById('pregunta_cuenta_extranjera_nacional_pep_r').value = '';
                document.getElementById('pregunta_beneficiario_extranjero_pep_r').value = '';
                document.getElementById('parentesco_familiar_extranjero_pep_r').value = '';
                document.getElementById('tipo_documento_familiar_extranjero_pep_r').value = '';
                document.getElementById('pregunta_participacion_extranjero_pep_r').value = '';
                document.getElementById('tipo_control_participacion_extranjero_pep_r').value = '';
                document.getElementById('pregunta_cuenta_extranjera_extranjero_pep_r').value = '';
                document.getElementById('pregunta1_pep_r').value = '';
                document.getElementById('pregunta2_pep_r').value = '';
                document.getElementById('pregunta3_pep_r').value = '';
                document.getElementById('pregunta4_pep_r').value = '';
                document.getElementById('pregunta5_pep_r').value = '';
                document.getElementById('pregunta6_pep_r').value = '';
                document.getElementById('pregunta7_pep_r').value = '';
                document.getElementById('pregunta8_pep_r').value = '';
                document.getElementById('pregunta9_pep_r').value = '';
                document.getElementById('pregunta10_pep_r').value = '';
                document.getElementById('pregunta11_pep_r').value = '';
                if (pep_r === 'SI') {
                    document.getElementById('nav-datos_pep-tab').classList.remove('active');
                    document.getElementById('nav-datos_pep').classList.remove('show', 'active');
                    document.getElementById('nav-datos_representante-tab').classList.add('active');
                    document.getElementById('nav-datos_representante').classList.add('show', 'active')
                }
                break;
            case 'familiar_pep_nacional':
                this.setState({
                    nombre_familiar_nacional_pep_r : '',
                    numero_documento_familiar_nacional_pep_r: '',
                });
                document.getElementById('parentesco_familiar_nacional_pep_r').value = '';
                document.getElementById('tipo_documento_familiar_nacional_pep_r').value = '';
                break;
            case 'sociedad_pep_nacional':
                this.setState({
                    razon_social_participacion_nacional_pep_r: '',
                    numero_documento_participacion_nacional_pep_r: '',
                    porcentaje_participacion_nacional_pep_r: '',
                    otros_tipo_control_participacion_nacional_pep_r: '',
                    detalle_tipo_control_participacion_nacional_pep_r: '',
                    activar_otros_tipos_control_pep_nacional: false
                });
                document.getElementById('tipo_control_participacion_nacional_pep_r').value = '';
                break;
            case 'cuenta_pep_nacional':
                this.setState({
                    razon_social_cuenta_extranjera_nacional_pep_r: '',
                    pais_cuenta_extranjera_nacional_pep_r: '',
                    numero_cuenta_extranjera_nacional_pep_r: '',
                    clase_propiedad_cuenta_extranjera_nacional_pep_r: '',
                });
                break;
            case 'familiar_pep_extranjero':
                this.setState({
                    nombre_familiar_extranjero_pep_r : '',
                    numero_documento_familiar_extranjero_pep_r: '',
                });
                document.getElementById('parentesco_familiar_extranjero_pep_r').value = '';
                document.getElementById('tipo_documento_familiar_extranjero_pep_r').value = '';
                break;
            case 'sociedad_pep_extranjero':
                this.setState({
                    razon_social_participacion_extranjero_pep_r: '',
                    numero_documento_participacion_extranjero_pep_r: '',
                    porcentaje_participacion_extranjero_pep_r: '',
                    otros_tipo_control_participacion_extranjero_pep_r: '',
                    detalle_tipo_control_participacion_extranjero_pep_r: '',
                    activar_otros_tipos_control_pep_extranjero: false
                });
                document.getElementById('tipo_control_participacion_extranjero_pep_r').value = '';
                break;
            case 'cuenta_pep_extranjero':
                this.setState({
                    razon_social_cuenta_extranjera_extranjero_pep_r: '',
                    pais_cuenta_extranjera_extranjero_pep_r: '',
                    numero_cuenta_extranjera_extranjero_pep_r: '',
                    clase_propiedad_cuenta_extranjera_extranjero_pep_r: '',
                });
                break;
            case 'beneficiario_esal':
                this.setState({
                    numero_cuenta_beneficiario_esal: '',
                    beneficiario_esal: '',
                    numero_documento_beneficiario_esal: ''
                });
                document.getElementById('banco_beneficiario_esal').value = '';
                document.getElementById('tipo_cuenta_beneficiario_esal').value = '';
                document.getElementById('tipo_documento_beneficiario_esal').value = '';
                break;
            case 'representante_esal':
                this.setState({
                    representante_esal: '',
                    numero_documento_representante_esal: ''
                });
                document.getElementById('tipo_relacion_representante_esal').value = '';
                document.getElementById('tipo_documento_representante_esal').value = '';
                break;
            case 'editar_beneficiario':
                this.setState({
                    editBeneficiarios: [],
                    editFamiliaresBeneficiarioPEPNacional: [],
                    editFamiliarBeneficiarioPEPNacional: [],
                    editSociedadesBeneficiarioPEPNacional: [],
                    editSociedadBeneficiarioPEPNacional: [],
                    editCuentasBeneficiarioPEPNacional: [],
                    editCuentaBeneficiarioPEPNacional: [],
                    editFamiliaresBeneficiarioPEPExtranjero: [],
                    editFamiliarBeneficiarioPEPExtranjero: [],
                    editSociedadesBeneficiarioPEPExtranjero: [],
                    editSociedadBeneficiarioPEPExtranjero: [],
                    editCuentasBeneficiarioPEPExtranjero: [],
                    editCuentaBeneficiarioPEPExtranjero: [],
                    activar_pep_beneficiario: false,
                    activar_sociedad_beneficiario_pep_nacional: false,
                    activar_cuenta_beneficiario_pep_nacional: false,
                    activar_sociedad_beneficiario_pep_extranjero: false,
                    activar_cuenta_beneficiario_pep_extranjero: false
                })
                document.getElementById('nav-datos_beneficiario-tab').classList.add('active');
                document.getElementById('nav-datos_beneficiario').classList.add('show', 'active');
                document.getElementById('e_nombre_beneficiario_b').value = '';
                document.getElementById('e_apellido_beneficiario_b').value = '';
                document.getElementById('e_numero_documento_beneficiario_b').value = '';
                document.getElementById('e_telefono_beneficiario_b').value = '';
                document.getElementById('e_telefono_movil_beneficiario_b').value = '';
                document.getElementById('e_correo_electronico_beneficiario_b').value = '';
                const e_pep = document.querySelector("#e_pep_b option:first-child");
                document.getElementById('e_pep_b').value = e_pep.value;
                break;
            case 'editar_familiar_beneficiario_pep_nacional':
                this.setState({ editFamiliarBeneficiarioPEPNacional: [] });
                document.getElementById('e_nombre_familiar_nacional_pep_b').value = '';
                const e_parentescobn = document.querySelector("#e_parentesco_familiar_nacional_pep_b option:first-child");
                document.getElementById('e_parentesco_familiar_nacional_pep_b').value = e_parentescobn.value;
                const e_tipo_identificacionbn = document.querySelector("#e_tipo_documento_familiar_nacional_pep_b option:first-child");
                document.getElementById('e_tipo_documento_familiar_nacional_pep_b').value = e_tipo_identificacionbn.value;
                document.getElementById('e_numero_documento_familiar_nacional_pep_b').value = '';
                break;
            case 'editar_sociedad_beneficiario_pep_nacional':
                this.setState({ editSociedadBeneficiarioPEPNacional: [] });
                document.getElementById('e_razon_social_participacion_nacional_pep_b').value = '';
                document.getElementById('e_numero_documento_participacion_nacional_pep_b').value = '';
                document.getElementById('e_porcentaje_participacion_nacional_pep_b').value = '';
                const e_tipo_controlbn = document.querySelector("#e_tipo_control_participacion_nacional_pep_b option:first-child");
                document.getElementById('e_tipo_control_participacion_nacional_pep_b').value = e_tipo_controlbn.value;
                document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_b').value = '';
                break;
            case 'editar_cuenta_beneficiario_pep_nacional':
                this.setState({ editCuentaBeneficiarioPEPNacional: [] });
                document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_b').value = '';
                document.getElementById('e_pais_cuenta_extranjera_nacional_pep_b').value = '';
                document.getElementById('e_numero_cuenta_extranjera_nacional_pep_b').value = '';
                document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_b').value = '';
                break;
            case 'editar_familiar_beneficiario_pep_extranjero':
                this.setState({ editFamiliarBeneficiarioPEPExtranjero: [] });
                document.getElementById('e_nombre_familiar_extranjero_pep_b').value = '';
                const e_parentescobe = document.querySelector("#e_parentesco_familiar_extranjero_pep_b option:first-child");
                document.getElementById('e_parentesco_familiar_extranjero_pep_b').value = e_parentescobe.value;
                const e_tipo_identificacionbe = document.querySelector("#e_tipo_documento_familiar_extranjero_pep_b option:first-child");
                document.getElementById('e_tipo_documento_familiar_extranjero_pep_b').value = e_tipo_identificacionbe.value;
                document.getElementById('e_numero_documento_familiar_extranjero_pep_b').value = '';
                break;
            case 'editar_sociedad_beneficiario_pep_extranjero':
                this.setState({ editSociedadBeneficiarioPEPExtranjero: [] });
                document.getElementById('e_razon_social_participacion_extranjero_pep_b').value = '';
                document.getElementById('e_numero_documento_participacion_extranjero_pep_b').value = '';
                document.getElementById('e_porcentaje_participacion_extranjero_pep_b').value = '';
                const e_tipo_controlbe = document.querySelector("#e_tipo_control_participacion_extranjero_pep_b option:first-child");
                document.getElementById('e_tipo_control_participacion_extranjero_pep_b').value = e_tipo_controlbe.value;
                document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_b').value = '';
                break;
            case 'editar_cuenta_beneficiario_pep_extranjero':
                this.setState({ editCuentaBeneficiarioPEPExtranjero: [] });
                document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_b').value = '';
                document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_b').value = '';
                document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_b').value = '';
                document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_b').value = '';
                break;
            case 'editar_representante':
                this.setState({
                    editRepresentantes: [],
                    editFamiliaresPEPNacional: [],
                    editFamiliarPEPNacional: [],
                    editSociedadesPEPNacional: [],
                    editSociedadPEPNacional: [],
                    editCuentasPEPNacional: [],
                    editCuentaPEPNacional: [],
                    editFamiliaresPEPExtranjero: [],
                    editFamiliarPEPExtranjero: [],
                    editSociedadesPEPExtranjero: [],
                    editSociedadPEPExtranjero: [],
                    editCuentasPEPExtranjero: [],
                    editCuentaPEPExtranjero: [],
                    activar_pep: false,
                    activar_sociedad_pep_nacional: false,
                    activar_cuenta_pep_nacional: false,
                    activar_sociedad_pep_extranjero: false,
                    activar_cuenta_pep_extranjero: false
                })
                document.getElementById('nav-datos_representante-tab').classList.add('active');
                document.getElementById('nav-datos_representante').classList.add('show', 'active');
                const e_tipo_rolr = document.querySelector("#e_tipo_rol_r option:first-child");
                document.getElementById('e_tipo_rol_r').value = e_tipo_rolr.value;
                document.getElementById('e_capital_representante_r').value = '';
                const e_pepr = document.querySelector("#e_pep_r option:first-child");
                document.getElementById('e_pep_r').value = e_pepr.value;
                document.getElementById('e_nombre_representante_r').value = '';
                document.getElementById('e_apellido_representante_r').value = '';
                const e_tipo_identificacionr = document.querySelector("#e_tipo_documento_representante_r option:first-child");
                document.getElementById('e_tipo_documento_representante_r').value = e_tipo_identificacionr.value;
                document.getElementById('e_numero_documento_representante_r').value = '';
                document.getElementById('e_digito_verificacion_r').value = '';
                break;
            case 'editar_familiar_pep_nacional':
                this.setState({ editFamiliarPEPNacional: [] });
                document.getElementById('e_nombre_familiar_nacional_pep_r').value = '';
                const e_parentescon = document.querySelector("#e_parentesco_familiar_nacional_pep_r option:first-child");
                document.getElementById('e_parentesco_familiar_nacional_pep_r').value = e_parentescon.value;
                const e_tipo_identificacionn = document.querySelector("#e_tipo_documento_familiar_nacional_pep_r option:first-child");
                document.getElementById('e_tipo_documento_familiar_nacional_pep_r').value = e_tipo_identificacionn.value;
                document.getElementById('e_numero_documento_familiar_nacional_pep_r').value = '';
                break;
            case 'editar_sociedad_pep_nacional':
                this.setState({ editSociedadPEPNacional: [] });
                document.getElementById('e_razon_social_participacion_nacional_pep_r').value = '';
                document.getElementById('e_numero_documento_participacion_nacional_pep_r').value = '';
                document.getElementById('e_porcentaje_participacion_nacional_pep_r').value = '';
                const e_tipo_controln = document.querySelector("#e_tipo_control_participacion_nacional_pep_r option:first-child");
                document.getElementById('e_tipo_control_participacion_nacional_pep_r').value = e_tipo_controln.value;
                document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_r').value = '';
                break;
            case 'editar_cuenta_pep_nacional':
                this.setState({ editCuentaPEPNacional: [] });
                document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_r').value = '';
                document.getElementById('e_pais_cuenta_extranjera_nacional_pep_r').value = '';
                document.getElementById('e_numero_cuenta_extranjera_nacional_pep_r').value = '';
                document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_r').value = '';
                break;
            case 'editar_familiar_pep_extranjero':
                this.setState({ editFamiliarPEPExtranjero: [] });
                document.getElementById('e_nombre_familiar_extranjero_pep_r').value = '';
                const e_parentescoe = document.querySelector("#e_parentesco_familiar_extranjero_pep_r option:first-child");
                document.getElementById('e_parentesco_familiar_extranjero_pep_r').value = e_parentescoe.value;
                const e_tipo_identificacione = document.querySelector("#e_tipo_documento_familiar_extranjero_pep_r option:first-child");
                document.getElementById('e_tipo_documento_familiar_extranjero_pep_r').value = e_tipo_identificacione.value;
                document.getElementById('e_numero_documento_familiar_extranjero_pep_r').value = '';
                break;
            case 'editar_sociedad_pep_extranjero':
                this.setState({ editSociedadPEPExtranjero: [] });
                document.getElementById('e_razon_social_participacion_extranjero_pep_r').value = '';
                document.getElementById('e_numero_documento_participacion_extranjero_pep_r').value = '';
                document.getElementById('e_porcentaje_participacion_extranjero_pep_r').value = '';
                const e_tipo_controle = document.querySelector("#e_tipo_control_participacion_extranjero_pep_r option:first-child");
                document.getElementById('e_tipo_control_participacion_extranjero_pep_r').value = e_tipo_controle.value;
                document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_r').value = '';
                break;
            case 'editar_cuenta_pep_extranjero':
                this.setState({ editCuentaPEPExtranjero: [] });
                document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_r').value = '';
                document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_r').value = '';
                document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_r').value = '';
                document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_r').value = '';
                break;
        }
    }
    cargarDatosEditar = async (tipo_persona, id) => {
        Swal.showLoading();
        switch (tipo_persona) {
            case 'beneficiario':
                await authAxios.get("contraparte/view_beneficiario/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    document.getElementById('nav-datos_beneficiario-tab').classList.add('active');
                    document.getElementById('nav-datos_beneficiario').classList.add('show', 'active');
                    this.setState({ datosCargados: true, editBeneficiarios: datosRespuesta.data });
                    document.getElementById('e_id_beneficiario_b').value = datosRespuesta.data.id;
                    document.getElementById('e_nombre_beneficiario_b').value = datosRespuesta.data.nombre;
                    document.getElementById('e_apellido_beneficiario_b').value = datosRespuesta.data.apellido;
                    document.getElementById('e_numero_documento_beneficiario_b').value = datosRespuesta.data.identificacion;
                    document.getElementById('e_telefono_beneficiario_b').value = datosRespuesta.data.telefono;
                    document.getElementById('e_telefono_movil_beneficiario_b').value = datosRespuesta.data.telefono_movil;
                    document.getElementById('e_correo_electronico_beneficiario_b').value = datosRespuesta.data.email;
                    if (datosRespuesta.data.pep === 'SI') {
                        this.setState({ activar_pep_beneficiario_editar: true });
                        document.getElementById('e_vinculo_actual_pep_b').value = datosRespuesta.data.vinculo_actual_pep;
                        document.getElementById('e_cargo_actividad_pep_b').value = datosRespuesta.data.cargo_actividad_pep;
                        document.getElementById('e_fecha_vinculacion_pep_b').value = datosRespuesta.data.fecha_vinculacion_pep;
                    }
                    if (datosRespuesta.data.vinculacion_activa_pep === 'NO') {
                        this.setState({ activar_vinculacion_beneficiario_editar: true });
                        document.getElementById('e_fecha_desvinculacion_pep_b').value = datosRespuesta.data.fecha_desvinculacion_pep;
                    }
                    if (datosRespuesta.data.pregunta_participacion_nacional_pep === 'SI') {
                        this.setState({ activar_sociedad_beneficiario_pep_nacional_editar: true });
                    }
                    if (datosRespuesta.data.pregunta_cuenta_extranjera_nacional_pep === 'SI') {
                        this.setState({ activar_cuenta_beneficiario_pep_nacional_editar: true });
                    }
                    if (datosRespuesta.data.pregunta_participacion_extranjero_pep === 'SI') {
                        this.setState({ activar_sociedad_beneficiario_pep_extranjero_editar: true });
                    }
                    if (datosRespuesta.data.pregunta_cuenta_extranjera_extranjero_pep === 'SI') {
                        this.setState({ activar_cuenta_beneficiario_pep_extranjero_editar: true });
                    }
                    Swal.close();
                })
                .catch(console.log);
                authAxios.get("contraparte/view_familiares_beneficiario_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_sociedades_beneficiario_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_cuentas_beneficiario_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_familiares_beneficiario_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_sociedades_beneficiario_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_cuentas_beneficiario_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                break;
            case 'familiar_beneficiario_pep_nacional':
                authAxios.get("contraparte/view_familiar_beneficiario_nacional/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editFamiliarBeneficiarioPEPNacional: datosRespuesta.data.data });
                    document.getElementById('e_nombre_familiar_nacional_pep_b').value = datosRespuesta.data.data.nombre;
                    document.getElementById('e_numero_documento_familiar_nacional_pep_b').value = datosRespuesta.data.data.identificacion;
                    Swal.close();
                })
                break;
            case 'sociedad_beneficiario_pep_nacional':
                await authAxios.get("contraparte/view_sociedad_beneficiario_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadBeneficiarioPEPNacional: datosRespuesta.data.data, activar_otros_tipos_control_beneficiario_pep_nacional: false });
                        document.getElementById('e_razon_social_participacion_nacional_pep_b').value = datosRespuesta.data.data.razon_social;
                        document.getElementById('e_numero_documento_participacion_nacional_pep_b').value = datosRespuesta.data.data.identificacion;
                        document.getElementById('e_porcentaje_participacion_nacional_pep_b').value = datosRespuesta.data.data.capital;
                        document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_b').value = datosRespuesta.data.data.detalle_tipo_control;
                        if (datosRespuesta.data.data.tipo_control === 'OTROS_TIPO_CONTROL') {
                            const e_otros_tipo_control_participacion_nacional_pep_b = document.getElementById('e_otros_tipo_control_participacion_nacional_pep_b');
                            this.setState({ activar_otros_tipos_control_beneficiario_pep_nacional: true })
                            if (e_otros_tipo_control_participacion_nacional_pep_b) {
                                e_otros_tipo_control_participacion_nacional_pep_b.value = datosRespuesta.data.data.otros_tipo_control || '';
                            }
                        }
                        Swal.close();
                })
                .catch(console.log);
                break;
            case 'cuenta_beneficiario_pep_nacional':
                authAxios.get("contraparte/view_cuenta_beneficiario_nacional/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editCuentaBeneficiarioPEPNacional: datosRespuesta.data.data });
                    document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_b').value = datosRespuesta.data.data.razon_social;
                    document.getElementById('e_pais_cuenta_extranjera_nacional_pep_b').value = datosRespuesta.data.data.pais;
                    document.getElementById('e_numero_cuenta_extranjera_nacional_pep_b').value = datosRespuesta.data.data.numero_cuenta;
                    document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_b').value = datosRespuesta.data.data.clase_propiedad;
                    Swal.close();
                })
                .catch(console.log);
                break;
            case 'familiar_beneficiario_pep_extranjero':
                authAxios.get("contraparte/view_familiar_beneficiario_extranjero/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editFamiliarBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                    document.getElementById('e_nombre_familiar_extranjero_pep_b').value = datosRespuesta.data.data.nombre;
                    document.getElementById('e_numero_documento_familiar_extranjero_pep_b').value = datosRespuesta.data.data.identificacion;
                    Swal.close();
                })
                .catch(console.log);
                break;
            case 'sociedad_beneficiario_pep_extranjero':
                await authAxios.get("contraparte/view_sociedad_beneficiario_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadBeneficiarioPEPExtranjero: datosRespuesta.data.data, activar_otros_tipos_control_beneficiario_pep_extranjero: false });
                        document.getElementById('e_razon_social_participacion_extranjero_pep_b').value = datosRespuesta.data.data.razon_social;
                        document.getElementById('e_numero_documento_participacion_extranjero_pep_b').value = datosRespuesta.data.data.identificacion;
                        document.getElementById('e_porcentaje_participacion_extranjero_pep_b').value = datosRespuesta.data.data.capital;
                        document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_b').value = datosRespuesta.data.data.detalle_tipo_control;
                        if (datosRespuesta.data.data.tipo_control === 'OTROS_TIPO_CONTROL') {
                            const e_otros_tipo_control_participacion_extranjero_pep_b = document.getElementById('e_otros_tipo_control_participacion_extranjero_pep_b');
                            this.setState({ activar_otros_tipos_control_beneficiario_pep_extranjero: true })
                            if (e_otros_tipo_control_participacion_extranjero_pep_b) {
                                e_otros_tipo_control_participacion_extranjero_pep_b.value = datosRespuesta.data.data.otros_tipo_control || '';
                            }
                        }
                        Swal.close();
                    })
                .catch(console.log);
                break;
            case 'cuenta_beneficiario_pep_extranjero':
                authAxios.get("contraparte/view_cuenta_beneficiario_extranjero/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editCuentaBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                    document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_b').value = datosRespuesta.data.data.razon_social;
                    document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_b').value = datosRespuesta.data.data.pais;
                    document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_b').value = datosRespuesta.data.data.numero_cuenta;
                    document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_b').value = datosRespuesta.data.data.clase_propiedad;
                    Swal.close();
                })
                .catch(console.log);
                break;
            case 'representante':
                await authAxios.get("contraparte/view_representante/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        document.getElementById('nav-datos_representante-tab').classList.add('active');
                        document.getElementById('nav-datos_representante').classList.add('show', 'active')
                        this.setState({ datosCargados: true, editRepresentantes: datosRespuesta.data });
                        document.getElementById('e_id_representante_r').value = datosRespuesta.data.id;
                        document.getElementById('e_capital_representante_r').value = datosRespuesta.data.capital;
                        document.getElementById('e_nombre_representante_r').value = datosRespuesta.data.nombre;
                        document.getElementById('e_apellido_representante_r').value = datosRespuesta.data.nombre;
                        document.getElementById('e_numero_documento_representante_r').value = datosRespuesta.data.identificacion;
                        document.getElementById('e_digito_verificacion_r').value = datosRespuesta.data.digito_verificacion;
                        if (datosRespuesta.data.pep === 'SI') {
                            this.setState({ activar_pep_editar: true });
                            document.getElementById('e_vinculo_actual_pep_r').value = datosRespuesta.data.vinculo_actual_pep;
                            document.getElementById('e_cargo_actividad_pep_r').value = datosRespuesta.data.cargo_actividad_pep;
                            document.getElementById('e_fecha_vinculacion_pep_r').value = datosRespuesta.data.fecha_vinculacion_pep;
                        }
                        if (datosRespuesta.data.vinculacion_activa_pep === 'NO') {
                            this.setState({ activar_vinculacion_editar: true });
                            document.getElementById('e_fecha_desvinculacion_pep_r').value = datosRespuesta.data.fecha_desvinculacion_pep;
                        }
                        if (datosRespuesta.data.pregunta_participacion_nacional_pep === 'SI') {
                            this.setState({ activar_sociedad_pep_nacional_editar: true });
                        }
                        if (datosRespuesta.data.pregunta_cuenta_extranjera_nacional_pep === 'SI') {
                            this.setState({ activar_cuenta_pep_nacional_editar: true });
                        }
                        if (datosRespuesta.data.pregunta_participacion_extranjero_pep === 'SI') {
                            this.setState({ activar_sociedad_pep_extranjero_editar: true });
                        }
                        if (datosRespuesta.data.pregunta_cuenta_extranjera_extranjero_pep === 'SI') {
                            this.setState({ activar_cuenta_pep_extranjero_editar: true });
                        }
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_familiares_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editFamiliaresPEPNacional: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_sociedades_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadesPEPNacional: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_cuentas_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editCuentasPEPNacional: datosRespuesta.data.data });
                        Swal.close();
                    })
                authAxios.get("contraparte/view_familiares_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editFamiliaresPEPExtranjero: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_sociedades_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadesPEPExtranjero: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                authAxios.get("contraparte/view_cuentas_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editCuentasPEPExtranjero: datosRespuesta.data.data });
                        Swal.close();
                    })
                .catch(console.log);
                break;
            case 'familiar_pep_nacional':
                authAxios.get("contraparte/view_familiar_nacional/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editFamiliarPEPNacional: datosRespuesta.data.data });
                    document.getElementById('e_nombre_familiar_nacional_pep_r').value = datosRespuesta.data.data.nombre;
                    document.getElementById('e_numero_documento_familiar_nacional_pep_r').value = datosRespuesta.data.data.identificacion;
                    Swal.close();
                })
                break;
            case 'sociedad_pep_nacional':
                await authAxios.get("contraparte/view_sociedad_nacional/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadPEPNacional: datosRespuesta.data.data, activar_otros_tipos_control_pep_nacional: false });
                        document.getElementById('e_razon_social_participacion_nacional_pep_r').value = datosRespuesta.data.data.razon_social;
                        document.getElementById('e_numero_documento_participacion_nacional_pep_r').value = datosRespuesta.data.data.identificacion;
                        document.getElementById('e_porcentaje_participacion_nacional_pep_r').value = datosRespuesta.data.data.capital;
                        document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_r').value = datosRespuesta.data.data.detalle_tipo_control;
                        if (datosRespuesta.data.data.tipo_control === 'OTROS_TIPO_CONTROL') {
                            const e_otros_tipo_control_participacion_nacional_pep_r = document.getElementById('e_otros_tipo_control_participacion_nacional_pep_r');
                            this.setState({ activar_otros_tipos_control_pep_nacional: true })
                            if (e_otros_tipo_control_participacion_nacional_pep_r) {
                                e_otros_tipo_control_participacion_nacional_pep_r.value = datosRespuesta.data.data.otros_tipo_control || '';
                            }
                        }
                        Swal.close();
                    })
                .catch(console.log);
                break;
            case 'cuenta_pep_nacional':
                authAxios.get("contraparte/view_cuenta_nacional/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editCuentaPEPNacional: datosRespuesta.data.data });
                    document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_r').value = datosRespuesta.data.data.razon_social;
                    document.getElementById('e_pais_cuenta_extranjera_nacional_pep_r').value = datosRespuesta.data.data.pais;
                    document.getElementById('e_numero_cuenta_extranjera_nacional_pep_r').value = datosRespuesta.data.data.numero_cuenta;
                    document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_r').value = datosRespuesta.data.data.clase_propiedad;
                    Swal.close();
                })
                .catch(console.log);
                break;
            case 'familiar_pep_extranjero':
                authAxios.get("contraparte/view_familiar_extranjero/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editFamiliarPEPExtranjero: datosRespuesta.data.data });
                    document.getElementById('e_nombre_familiar_extranjero_pep_r').value = datosRespuesta.data.data.nombre;
                    document.getElementById('e_numero_documento_familiar_extranjero_pep_r').value = datosRespuesta.data.data.identificacion;
                    Swal.close();
                })
                .catch(console.log);
                break;
            case 'sociedad_pep_extranjero':
                await authAxios.get("contraparte/view_sociedad_extranjero/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, editSociedadPEPExtranjero: datosRespuesta.data.data, activar_otros_tipos_control_pep_extranjero: false });
                        document.getElementById('e_razon_social_participacion_extranjero_pep_r').value = datosRespuesta.data.data.razon_social;
                        document.getElementById('e_numero_documento_participacion_extranjero_pep_r').value = datosRespuesta.data.data.identificacion;
                        document.getElementById('e_porcentaje_participacion_extranjero_pep_r').value = datosRespuesta.data.data.capital;
                        document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_r').value = datosRespuesta.data.data.detalle_tipo_control;
                        if (datosRespuesta.data.data.tipo_control === 'OTROS_TIPO_CONTROL') {
                            const e_otros_tipo_control_participacion_extranjero_pep_r = document.getElementById('e_otros_tipo_control_participacion_extranjero_pep_r');
                            this.setState({ activar_otros_tipos_control_pep_extranjero: true })
                            if (e_otros_tipo_control_participacion_extranjero_pep_r) {
                                e_otros_tipo_control_participacion_extranjero_pep_r.value = datosRespuesta.data.data.otros_tipo_control || '';
                            }
                        }
                        Swal.close();
                    })
                .catch(console.log);
                break;
            case 'cuenta_pep_extranjero':
                authAxios.get("contraparte/view_cuenta_extranjero/" + id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, editCuentaPEPExtranjero: datosRespuesta.data.data });
                    document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_r').value = datosRespuesta.data.data.razon_social;
                    document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_r').value = datosRespuesta.data.data.pais;
                    document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_r').value = datosRespuesta.data.data.numero_cuenta;
                    document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_r').value = datosRespuesta.data.data.clase_propiedad;
                    Swal.close();
                })
                .catch(console.log);
                break;
        }
    }
    activarEdicion = (tipo_persona) => {
        switch (tipo_persona) {
            case 'familiar_beneficiario_pep_nacional':
                this.setState({ activar_crear_familiar_beneficiario_pep_nacional_edicion: true })
                break;
            case 'sociedad_beneficiario_pep_nacional':
                this.setState({ activar_crear_sociedad_beneficiario_pep_nacional_edicion: true })
                break;
            case 'cuenta_beneficiario_pep_nacional':
                this.setState({ activar_crear_cuenta_beneficiario_pep_nacional_edicion: true })
                break;
            case 'familiar_beneficiario_pep_extranjero':
                this.setState({ activar_crear_familiar_beneficiario_pep_extranjero_edicion: true })
                break;
            case 'sociedad_beneficiario_pep_extranjero':
                this.setState({ activar_crear_sociedad_beneficiario_pep_extranjero_edicion: true })
                break;
            case 'cuenta_beneficiario_pep_extranjero':
                this.setState({ activar_crear_cuenta_beneficiario_pep_extranjero_edicion: true })
                break;
            case 'familiar_pep_nacional':
                this.setState({ activar_crear_familiar_pep_nacional_edicion: true })
                break;
            case 'sociedad_pep_nacional':
                this.setState({ activar_crear_sociedad_pep_nacional_edicion: true })
                break;
            case 'cuenta_pep_nacional':
                this.setState({ activar_crear_cuenta_pep_nacional_edicion: true })
                break;
            case 'familiar_pep_extranjero':
                this.setState({ activar_crear_familiar_pep_extranjero_edicion: true })
                break;
            case 'sociedad_pep_extranjero':
                this.setState({ activar_crear_sociedad_pep_extranjero_edicion: true })
                break;
            case 'cuenta_pep_extranjero':
                this.setState({ activar_crear_cuenta_pep_extranjero_edicion: true })
                break;
        }
    }
    editarDatosBeneficiario = async (e) => {
        e.preventDefault();
        var errores = [];
        var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
        var nombre_beneficiario_b = document.getElementById('e_nombre_beneficiario_b').value;
        var apellido_beneficiario_b = document.getElementById('e_apellido_beneficiario_b').value;
        var numero_documento_beneficiario_b = document.getElementById('e_numero_documento_beneficiario_b').value;
        var correo_electronico_beneficiario_b = document.getElementById('e_correo_electronico_beneficiario_b').value;
        var telefono_beneficiario_b = document.getElementById('e_telefono_beneficiario_b').value;
        var telefono_movil_beneficiario_b = document.getElementById('e_telefono_movil_beneficiario_b').value;
        var pep_b = document.getElementById('e_pep_b').value;
        if (pep_b === 'SI') {
            var declaracion_veracidad_pep_b = document.getElementById('e_declaracion_veracidad_pep_b').value;
            var vinculo_actual_pep_b = document.getElementById('e_vinculo_actual_pep_b').value;
            var cargo_actividad_pep_b = document.getElementById('e_cargo_actividad_pep_b').value;
            var vinculacion_activa_pep_b = document.getElementById('e_vinculacion_activa_pep_b').value;
            var fecha_vinculacion_pep_b = document.getElementById('e_fecha_vinculacion_pep_b').value;
            if (vinculacion_activa_pep_b === 'NO') {
                var fecha_desvinculacion_pep_b = document.getElementById('e_fecha_desvinculacion_pep_b').value;
                if (!fecha_desvinculacion_pep_b) { errores.push("error_e_fecha_desvinculacion_pep_b"); }
            }
            var pregunta_participacion_nacional_pep_b = document.getElementById('e_pregunta_participacion_nacional_pep_b').value;
            var pregunta_cuenta_extranjera_nacional_pep_b = document.getElementById('e_pregunta_cuenta_extranjera_nacional_pep_b').value;
            var pregunta_beneficiario_extranjero_pep_b = document.getElementById('e_pregunta_beneficiario_extranjero_pep_b').value;
            if (pregunta_beneficiario_extranjero_pep_b === 'SI') {
                var pregunta_participacion_extranjero_pep_b = document.getElementById('e_pregunta_participacion_extranjero_pep_b').value;
                var pregunta_cuenta_extranjera_extranjero_pep_b = document.getElementById('e_pregunta_cuenta_extranjera_extranjero_pep_b').value;
                if (!pregunta_participacion_extranjero_pep_b) { errores.push("error_e_pregunta_participacion_extranjero_pep_b"); }
                if (!pregunta_cuenta_extranjera_extranjero_pep_b) { errores.push("error_e_pregunta_cuenta_extranjera_extranjero_pep_b"); }
            }
            var pregunta1_pep_b = document.getElementById('e_pregunta1_pep_b').value;
            var pregunta2_pep_b = document.getElementById('e_pregunta2_pep_b').value;
            var pregunta3_pep_b = document.getElementById('e_pregunta3_pep_b').value;
            var pregunta4_pep_b = document.getElementById('e_pregunta4_pep_b').value;
            var pregunta5_pep_b = document.getElementById('e_pregunta5_pep_b').value;
            var pregunta6_pep_b = document.getElementById('e_pregunta6_pep_b').value;
            var pregunta7_pep_b = document.getElementById('e_pregunta7_pep_b').value;
            var pregunta8_pep_b = document.getElementById('e_pregunta8_pep_b').value;
            var pregunta9_pep_b = document.getElementById('e_pregunta9_pep_b').value;
            var pregunta10_pep_b = document.getElementById('e_pregunta10_pep_b').value;
            var pregunta11_pep_b = document.getElementById('e_pregunta11_pep_b').value;
            if (!declaracion_veracidad_pep_b) { errores.push("error_e_declaracion_veracidad_pep_b"); }
            if (!vinculo_actual_pep_b) { errores.push("error_e_vinculo_actual_pep_b"); }
            if (!cargo_actividad_pep_b) { errores.push("error_e_cargo_actividad_pep_b"); }
            if (!vinculacion_activa_pep_b) { errores.push("error_e_vinculacion_activa_pep_b"); }
            if (!fecha_vinculacion_pep_b) { errores.push("error_e_fecha_vinculacion_pep_b"); }
            if (!pregunta_participacion_nacional_pep_b) { errores.push("error_e_pregunta_participacion_nacional_pep_b"); }
            if (!pregunta_cuenta_extranjera_nacional_pep_b) { errores.push("error_e_pregunta_cuenta_extranjera_nacional_pep_b"); }
            if (!pregunta_beneficiario_extranjero_pep_b) { errores.push("error_e_pregunta_beneficiario_extranjero_pep_b"); }
            if (!pregunta1_pep_b) { errores.push("error_e_pregunta1_pep_b"); }
            if (!pregunta2_pep_b) { errores.push("error_e_pregunta2_pep_b"); }
            if (!pregunta3_pep_b) { errores.push("error_e_pregunta3_pep_b"); }
            if (!pregunta4_pep_b) { errores.push("error_e_pregunta4_pep_b"); }
            if (!pregunta5_pep_b) { errores.push("error_e_pregunta5_pep_b"); }
            if (!pregunta6_pep_b) { errores.push("error_e_pregunta6_pep_b"); }
            if (!pregunta7_pep_b) { errores.push("error_e_pregunta7_pep_b"); }
            if (!pregunta8_pep_b) { errores.push("error_e_pregunta8_pep_b"); }
            if (!pregunta9_pep_b) { errores.push("error_e_pregunta9_pep_b"); }
            if (!pregunta10_pep_b) { errores.push("error_e_pregunta10_pep_b"); }
            if (!pregunta11_pep_b) { errores.push("error_e_pregunta11_pep_b"); }
            if (errores.length > 0) {
                Swal.fire({
                    title: 'Beneficiarios',
                    text: 'Existen campos en la Pestaña P.E.P. sin diligenciar. Favor verificar Información.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return false;
                    }
                })
            }
        }
        if (!nombre_beneficiario_b) { errores.push("error_e_nombre_beneficiario_b"); }
        if (!apellido_beneficiario_b) { errores.push("error_e_apellido_beneficiario_b"); }
        if (!numero_documento_beneficiario_b) { errores.push("error_e_numero_documento_beneficiario_b"); }
        if (!correo_electronico_beneficiario_b) { errores.push("error_e_correo_electronico_beneficiario_b"); }
        if (!telefono_beneficiario_b) { errores.push("error_e_telefono_beneficiario_b"); }
        if (!telefono_movil_beneficiario_b) { errores.push("error_e_telefono_movil_beneficiario_b"); }
        if (!pep_b) { errores.push("error_e_pep_b"); }
        if (!this.validateEmail(correo_electronico_beneficiario_b)) {
            errores.push("error_e_correo_electronico_beneficiario_b");
            Swal.fire({
                title: 'Formularios',
                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    errores.push("error_e_correo_electronico_beneficiario_b");
                }
            })
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarBeneficiario = document.getElementById('form-editar_beneficiario');
        let formDataEditarBeneficiario = new FormData(formEditarBeneficiario);
        console.log(formDataEditarBeneficiario);
        Swal.showLoading();
        await authAxios.post("contraparte/edit_beneficiario", formDataEditarBeneficiario)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Beneficiario!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_beneficiario/" + this.props.match.params.id)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({
                                datosCargados: true,
                                beneficiarios: datosRespuesta.data.data,
                            });
                        })
                        .catch(console.log);
                    authAxios.get("contraparte/view_beneficiario/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editBeneficiarios: datosRespuesta.data });
                            if (datosRespuesta.data.pep === 'SI') {
                                this.setState({ activar_pep_beneficiario_editar: true });
                                document.getElementById('nav-datos_beneficiario-tab').classList.add('active');
                                document.getElementById('nav-datos_beneficiario').classList.add('show', 'active');
                            }
                            if (datosRespuesta.data.vinculacion_activa_pep === 'NO') {
                                this.setState({ activar_vinculacion_beneficiario_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_participacion_nacional_pep === 'SI') {
                                this.setState({ activar_sociedad_beneficiario_pep_nacional_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_cuenta_extranjera_nacional_pep === 'SI') {
                                this.setState({ activar_cuenta_beneficiario_pep_nacional_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_participacion_extranjero_pep === 'SI') {
                                this.setState({ activar_sociedad_beneficiario_pep_extranjero_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_cuenta_extranjera_extranjero_pep === 'SI') {
                                this.setState({ activar_cuenta_beneficiario_pep_extranjero_editar: true });
                            }
                            Swal.close();
                        })
                        .catch(console.log);
                        authAxios.get("contraparte/view_familiares_beneficiario_nacional/" + id_beneficiario)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_sociedades_beneficiario_nacional/" + id_beneficiario)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_cuentas_beneficiario_nacional/" + id_beneficiario)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_familiares_beneficiario_extranjero/" + id_beneficiario)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_sociedades_beneficiario_extranjero/" + id_beneficiario)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_cuentas_beneficiario_extranjero/" + id_beneficiario)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Beneficiario!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosFamiliarBeneficiarioPEPNacional = (e) => {
        e.preventDefault();
        var errores = [];
        var nombre_familiar_nacional_pep_b = document.getElementById('e_nombre_familiar_nacional_pep_b').value;
        var parentesco_familiar_nacional_pep_b = document.getElementById('e_parentesco_familiar_nacional_pep_b').value;
        var tipo_documento_familiar_nacional_pep_b = document.getElementById('e_tipo_documento_familiar_nacional_pep_b').value;
        var numero_documento_familiar_nacional_pep_b = document.getElementById('e_numero_documento_familiar_nacional_pep_b').value;
        if (!nombre_familiar_nacional_pep_b) { errores.push("error_e_nombre_familiar_nacional_pep_b"); }
        if (!parentesco_familiar_nacional_pep_b) { errores.push("error_e_parentesco_familiar_nacional_pep_b"); }
        if (!tipo_documento_familiar_nacional_pep_b) { errores.push("error_e_tipo_documento_familiar_nacional_pep_b"); }
        if (!numero_documento_familiar_nacional_pep_b) { errores.push("error_e_numero_documento_familiar_nacional_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarFamiliaresBeneficiarioPEPNacional = document.getElementById('form-editar_familiar_beneficiario_pep_nacional');
        let formDataEditarFamiliaresBeneficiarioPEPNacional = new FormData(formEditarFamiliaresBeneficiarioPEPNacional);
        console.log(formDataEditarFamiliaresBeneficiarioPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/edit_familiar_beneficiario_nacional", formDataEditarFamiliaresBeneficiarioPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_beneficiario_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresBeneficiarioPEPNacional: datosRespuesta.data.data,
                            editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data
                        });
                        document.getElementById('e_nombre_familiar_nacional_pep_b').value = '';
                        const e_parentescobn = document.querySelector("#e_parentesco_familiar_nacional_pep_b option:first-child");
                        document.getElementById('e_parentesco_familiar_nacional_pep_b').value = e_parentescobn.value;
                        const e_tipo_identificacionbn = document.querySelector("#e_tipo_documento_familiar_nacional_pep_b option:first-child");
                        document.getElementById('e_tipo_documento_familiar_nacional_pep_b').value = e_tipo_identificacionbn.value;
                        document.getElementById('e_numero_documento_familiar_nacional_pep_b').value = '';
                    })
                    .catch(console.log);
                    var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
                    authAxios.get("contraparte/view_familiares_beneficiario_nacional/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosSociedadBeneficiarioPEPNacional = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_nacional_pep_b = document.getElementById('e_razon_social_participacion_nacional_pep_b').value;
        var numero_documento_participacion_nacional_pep_b = document.getElementById('e_numero_documento_participacion_nacional_pep_b').value;
        var porcentaje_participacion_nacional_pep_b = document.getElementById('e_porcentaje_participacion_nacional_pep_b').value;
        var tipo_control_participacion_nacional_pep_b = document.getElementById('e_tipo_control_participacion_nacional_pep_b').value;
        if (tipo_control_participacion_nacional_pep_b === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_nacional_pep_b = document.getElementById('e_otros_tipo_control_participacion_nacional_pep_b').value;
            if (!otros_tipo_control_participacion_nacional_pep_b) { errores.push("error_e_otros_tipo_control_participacion_nacional_pep_b"); }
        }
        var detalle_tipo_control_participacion_nacional_pep_b = document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_b').value;
        if (!razon_social_participacion_nacional_pep_b) { errores.push("error_e_razon_social_participacion_nacional_pep_b"); }
        if (!numero_documento_participacion_nacional_pep_b) { errores.push("error_e_numero_documento_participacion_nacional_pep_b"); }
        if (!porcentaje_participacion_nacional_pep_b) { errores.push("error_e_porcentaje_participacion_nacional_pep_b"); }
        if (!tipo_control_participacion_nacional_pep_b) { errores.push("error_e_tipo_control_participacion_nacional_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarSociedadesBeneficiarioPEPNacional = document.getElementById('form-editar_sociedades_beneficiario_pep_nacional');
        let formDataEditarSociedadesBeneficiarioPEPNacional = new FormData(formEditarSociedadesBeneficiarioPEPNacional);
        console.log(formDataEditarSociedadesBeneficiarioPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/edit_sociedad_beneficiario_nacional", formDataEditarSociedadesBeneficiarioPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_beneficiario_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesBeneficiarioPEPNacional: datosRespuesta.data.data,
                            editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data,
                            activar_otros_tipos_control_beneficiario_pep_nacional: false
                        });
                        document.getElementById('e_razon_social_participacion_nacional_pep_b').value = '';
                        document.getElementById('e_numero_documento_participacion_nacional_pep_b').value = '';
                        document.getElementById('e_porcentaje_participacion_nacional_pep_b').value = '';
                        const e_tipo_controlbn = document.querySelector("#e_tipo_control_participacion_nacional_pep_b option:first-child");
                        document.getElementById('e_tipo_control_participacion_nacional_pep_b').value = e_tipo_controlbn.value;
                        document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_b').value = '';
                    })
                    .catch(console.log);
                    var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
                    authAxios.get("contraparte/view_sociedades_beneficiario_nacional/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosCuentaBeneficiarioPEPNacional = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_nacional_pep_b = document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_b').value;
        var pais_cuenta_extranjera_nacional_pep_b = document.getElementById('e_pais_cuenta_extranjera_nacional_pep_b').value;
        var numero_cuenta_extranjera_nacional_pep_b = document.getElementById('e_numero_cuenta_extranjera_nacional_pep_b').value;
        var clase_propiedad_cuenta_extranjera_nacional_pep_b = document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_b').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_nacional_pep_b) { errores.push("error_e_razon_social_cuenta_extranjera_nacional_pep_b"); }
        if (!pais_cuenta_extranjera_nacional_pep_b) { errores.push("error_e_pais_cuenta_extranjera_nacional_pep_b"); }
        if (!numero_cuenta_extranjera_nacional_pep_b) { errores.push("error_e_numero_cuenta_extranjera_nacional_pep_b"); }
        if (!clase_propiedad_cuenta_extranjera_nacional_pep_b) { errores.push("error_e_clase_propiedad_cuenta_extranjera_nacional_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarCuentasBeneficiarioPEPNacional = document.getElementById('form-editar_cuentas_beneficiario_pep_nacional');
        let formDataEditarCuentasBeneficiarioPEPNacional = new FormData(formEditarCuentasBeneficiarioPEPNacional);
        console.log(formDataEditarCuentasBeneficiarioPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/edit_cuenta_beneficiario_nacional", formDataEditarCuentasBeneficiarioPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_beneficiario_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasBeneficiarioPEPNacional: datosRespuesta.data.data,
                            editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data
                        });
                        document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_b').value = '';
                        document.getElementById('e_pais_cuenta_extranjera_nacional_pep_b').value = '';
                        document.getElementById('e_numero_cuenta_extranjera_nacional_pep_b').value = '';
                        document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_b').value = '';
                    })
                    .catch(console.log);
                    var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
                    authAxios.get("contraparte/view_cuentas_beneficiario_nacional/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosFamiliarBeneficiarioPEPExtranjero = (e) => {
        e.preventDefault();
        var nombre_familiar_extranjero_pep_b = document.getElementById('e_nombre_familiar_extranjero_pep_b').value;
        var parentesco_familiar_extranjero_pep_b = document.getElementById('e_parentesco_familiar_extranjero_pep_b').value;
        var tipo_documento_familiar_extranjero_pep_b = document.getElementById('e_tipo_documento_familiar_extranjero_pep_b').value;
        var numero_documento_familiar_extranjero_pep_b = document.getElementById('e_numero_documento_familiar_extranjero_pep_b').value;
        var errores = [];
        if (!nombre_familiar_extranjero_pep_b) { errores.push("error_e_nombre_familiar_extranjero_pep_b"); }
        if (!parentesco_familiar_extranjero_pep_b) { errores.push("error_e_parentesco_familiar_extranjero_pep_b"); }
        if (!tipo_documento_familiar_extranjero_pep_b) { errores.push("error_e_tipo_documento_familiar_extranjero_pep_b"); }
        if (!numero_documento_familiar_extranjero_pep_b) { errores.push("error_e_numero_documento_familiar_extranjero_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarFamiliaresBeneficiarioPEPExtranjero = document.getElementById('form-editar_familiares_beneficiario_pep_extranjero');
        let formDataEditarFamiliaresBeneficiarioPEPExtranjero = new FormData(formEditarFamiliaresBeneficiarioPEPExtranjero);
        console.log(formDataEditarFamiliaresBeneficiarioPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/edit_familiar_beneficiario_extranjero", formDataEditarFamiliaresBeneficiarioPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_beneficiario_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data
                        });
                        document.getElementById('e_nombre_familiar_extranjero_pep_b').value = '';
                        const e_parentescobe = document.querySelector("#e_parentesco_familiar_extranjero_pep_b option:first-child");
                        document.getElementById('e_parentesco_familiar_extranjero_pep_b').value = e_parentescobe.value;
                        const e_tipo_identificacionbe = document.querySelector("#e_tipo_documento_familiar_extranjero_pep_b option:first-child");
                        document.getElementById('e_tipo_documento_familiar_extranjero_pep_b').value = e_tipo_identificacionbe.value;
                        document.getElementById('e_numero_documento_familiar_extranjero_pep_b').value = '';
                    })
                    .catch(console.log);
                    var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
                    authAxios.get("contraparte/view_familiares_beneficiario_extranjero/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosSociedadBeneficiarioPEPExtranjero = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_extranjero_pep_b = document.getElementById('e_razon_social_participacion_extranjero_pep_b').value;
        var numero_documento_participacion_extranjero_pep_b = document.getElementById('e_numero_documento_participacion_extranjero_pep_b').value;
        var porcentaje_participacion_extranjero_pep_b = document.getElementById('e_porcentaje_participacion_extranjero_pep_b').value;
        var tipo_control_participacion_extranjero_pep_b = document.getElementById('e_tipo_control_participacion_extranjero_pep_b').value;
        if (tipo_control_participacion_extranjero_pep_b === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_extranjero_pep_b = document.getElementById('e_otros_tipo_control_participacion_extranjero_pep_b').value;
            if (!otros_tipo_control_participacion_extranjero_pep_b) { errores.push("error_e_otros_tipo_control_participacion_extranjero_pep_b"); }
        }
        var detalle_tipo_control_participacion_extranjero_pep_b = document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_b').value;
        if (!razon_social_participacion_extranjero_pep_b) { errores.push("error_e_razon_social_participacion_extranjero_pep_b"); }
        if (!numero_documento_participacion_extranjero_pep_b) { errores.push("error_e_numero_documento_participacion_extranjero_pep_b"); }
        if (!porcentaje_participacion_extranjero_pep_b) { errores.push("error_e_porcentaje_participacion_extranjero_pep_b"); }
        if (!tipo_control_participacion_extranjero_pep_b) { errores.push("error_e_tipo_control_participacion_extranjero_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarSociedadesBeneficiarioPEPExtranjero = document.getElementById('form-editar_sociedades_beneficiario_pep_extranjero');
        let formDataEditarSociedadesBeneficiarioPEPExtranjero = new FormData(formEditarSociedadesBeneficiarioPEPExtranjero);
        console.log(formDataEditarSociedadesBeneficiarioPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/edit_sociedad_beneficiario_extranjero", formDataEditarSociedadesBeneficiarioPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_beneficiario_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            activar_otros_tipos_control_beneficiario_pep_extranjero: false
                        });
                        document.getElementById('e_razon_social_participacion_extranjero_pep_b').value = '';
                        document.getElementById('e_numero_documento_participacion_extranjero_pep_b').value = '';
                        document.getElementById('e_porcentaje_participacion_extranjero_pep_b').value = '';
                        const e_tipo_controlbe = document.querySelector("#e_tipo_control_participacion_extranjero_pep_b option:first-child");
                        document.getElementById('e_tipo_control_participacion_extranjero_pep_b').value = e_tipo_controlbe.value;
                        document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_b').value = '';
                    })
                    .catch(console.log);
                    var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
                    authAxios.get("contraparte/view_sociedades_beneficiario_extranjero/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosCuentaBeneficiarioPEPExtranjero = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_extranjero_pep_b = document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_b').value;
        var pais_cuenta_extranjera_extranjero_pep_b = document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_b').value;
        var numero_cuenta_extranjera_extranjero_pep_b = document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_b').value;
        var clase_propiedad_cuenta_extranjera_extranjero_pep_b = document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_b').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_extranjero_pep_b) { errores.push("error_e_razon_social_cuenta_extranjera_extranjero_pep_b"); }
        if (!pais_cuenta_extranjera_extranjero_pep_b) { errores.push("error_e_pais_cuenta_extranjera_extranjero_pep_b"); }
        if (!numero_cuenta_extranjera_extranjero_pep_b) { errores.push("error_e_numero_cuenta_extranjera_extranjero_pep_b"); }
        if (!clase_propiedad_cuenta_extranjera_extranjero_pep_b) { errores.push("error_e_clase_propiedad_cuenta_extranjera_extranjero_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarCuentasBeneficiarioPEPExtranjero = document.getElementById('form-editar_cuentas_beneficiario_pep_extranjero');
        let formDataEditarCuentasBeneficiarioPEPExtranjero = new FormData(formEditarCuentasBeneficiarioPEPExtranjero);
        console.log(formDataEditarCuentasBeneficiarioPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/edit_cuenta_beneficiario_extranjero", formDataEditarCuentasBeneficiarioPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_beneficiario_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data
                        });
                        document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_b').value = '';
                        document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_b').value = '';
                        document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_b').value = '';
                        document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_b').value = '';
                    })
                    .catch(console.log);
                    var id_beneficiario = document.getElementById('e_id_beneficiario_b').value;
                    authAxios.get("contraparte/view_cuentas_beneficiario_extranjero/" + id_beneficiario)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosRepresentante = async (e) => {
        e.preventDefault();
        var errores = [];
        var id_representante = document.getElementById('e_id_representante_r').value;
        var tipo_rol_r = document.getElementById('e_tipo_rol_r').value;
        var nombre_representante_r = document.getElementById('e_nombre_representante_r').value;
        var apellido_representante_r = document.getElementById('e_apellido_representante_r').value;
        var tipo_documento_representante_r = document.getElementById('e_tipo_documento_representante_r').value;
        var numero_documento_representante_r = document.getElementById('e_numero_documento_representante_r').value;
        var capital_representante_r = document.getElementById('e_capital_representante_r').value;
        var pep_r = document.getElementById('e_pep_r').value;
        if (pep_r === 'SI') {
            var declaracion_veracidad_pep_r = document.getElementById('e_declaracion_veracidad_pep_r').value;
            var vinculo_actual_pep_r = document.getElementById('e_vinculo_actual_pep_r').value;
            var cargo_actividad_pep_r = document.getElementById('e_cargo_actividad_pep_r').value;
            var vinculacion_activa_pep_r = document.getElementById('e_vinculacion_activa_pep_r').value;
            var fecha_vinculacion_pep_r = document.getElementById('e_fecha_vinculacion_pep_r').value;
            if (vinculacion_activa_pep_r === 'NO') {
                var fecha_desvinculacion_pep_r = document.getElementById('e_fecha_desvinculacion_pep_r').value;
                if (!fecha_desvinculacion_pep_r) { errores.push("error_e_fecha_desvinculacion_pep_r"); }
            }
            var pregunta_participacion_nacional_pep_r = document.getElementById('e_pregunta_participacion_nacional_pep_r').value;
            var pregunta_cuenta_extranjera_nacional_pep_r = document.getElementById('e_pregunta_cuenta_extranjera_nacional_pep_r').value;
            var pregunta_beneficiario_extranjero_pep_r = document.getElementById('e_pregunta_beneficiario_extranjero_pep_r').value;
            if (pregunta_beneficiario_extranjero_pep_r === 'SI') {
                var pregunta_participacion_extranjero_pep_r = document.getElementById('e_pregunta_participacion_extranjero_pep_r').value;
                var pregunta_cuenta_extranjera_extranjero_pep_r = document.getElementById('e_pregunta_cuenta_extranjera_extranjero_pep_r').value;
                if (!pregunta_participacion_extranjero_pep_r) { errores.push("error_e_pregunta_participacion_extranjero_pep_r"); }
                if (!pregunta_cuenta_extranjera_extranjero_pep_r) { errores.push("error_e_pregunta_cuenta_extranjera_extranjero_pep_r"); }
            }
            var pregunta1_pep_r = document.getElementById('e_pregunta1_pep_r').value;
            var pregunta2_pep_r = document.getElementById('e_pregunta2_pep_r').value;
            var pregunta3_pep_r = document.getElementById('e_pregunta3_pep_r').value;
            var pregunta4_pep_r = document.getElementById('e_pregunta4_pep_r').value;
            var pregunta5_pep_r = document.getElementById('e_pregunta5_pep_r').value;
            var pregunta6_pep_r = document.getElementById('e_pregunta6_pep_r').value;
            var pregunta7_pep_r = document.getElementById('e_pregunta7_pep_r').value;
            var pregunta8_pep_r = document.getElementById('e_pregunta8_pep_r').value;
            var pregunta9_pep_r = document.getElementById('e_pregunta9_pep_r').value;
            var pregunta10_pep_r = document.getElementById('e_pregunta10_pep_r').value;
            var pregunta11_pep_r = document.getElementById('e_pregunta11_pep_r').value;
            if (!declaracion_veracidad_pep_r) { errores.push("error_e_declaracion_veracidad_pep_r"); }
            if (!vinculo_actual_pep_r) { errores.push("error_e_vinculo_actual_pep_r"); }
            if (!cargo_actividad_pep_r) { errores.push("error_e_cargo_actividad_pep_r"); }
            if (!vinculacion_activa_pep_r) { errores.push("error_e_vinculacion_activa_pep_r"); }
            if (!fecha_vinculacion_pep_r) { errores.push("error_e_fecha_vinculacion_pep_r"); }
            if (!pregunta_participacion_nacional_pep_r) { errores.push("error_e_pregunta_participacion_nacional_pep_r"); }
            if (!pregunta_cuenta_extranjera_nacional_pep_r) { errores.push("error_e_pregunta_cuenta_extranjera_nacional_pep_r"); }
            if (!pregunta_beneficiario_extranjero_pep_r) { errores.push("error_e_pregunta_beneficiario_extranjero_pep_r"); }
            if (!pregunta1_pep_r) { errores.push("error_e_pregunta1_pep_r"); }
            if (!pregunta2_pep_r) { errores.push("error_e_pregunta2_pep_r"); }
            if (!pregunta3_pep_r) { errores.push("error_e_pregunta3_pep_r"); }
            if (!pregunta4_pep_r) { errores.push("error_e_pregunta4_pep_r"); }
            if (!pregunta5_pep_r) { errores.push("error_e_pregunta5_pep_r"); }
            if (!pregunta6_pep_r) { errores.push("error_e_pregunta6_pep_r"); }
            if (!pregunta7_pep_r) { errores.push("error_e_pregunta7_pep_r"); }
            if (!pregunta8_pep_r) { errores.push("error_e_pregunta8_pep_r"); }
            if (!pregunta9_pep_r) { errores.push("error_e_pregunta9_pep_r"); }
            if (!pregunta10_pep_r) { errores.push("error_e_pregunta10_pep_r"); }
            if (!pregunta11_pep_r) { errores.push("error_e_pregunta11_pep_r"); }
            if (errores.length > 0) {
                Swal.fire({
                    title: 'Representantes',
                    text: 'Existen campos en la Pestaña P.E.P. sin diligenciar. Favor verificar Información.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return false;
                    }
                })
            }
        }
        if (!tipo_rol_r) { errores.push("error_e_tipo_rol_r"); }
        if (!nombre_representante_r) { errores.push("error_e_nombre_representante_r"); }
        if (!apellido_representante_r) { errores.push("error_e_apellido_representante_r"); }
        if (!tipo_documento_representante_r) { errores.push("error_e_tipo_documento_representante_r"); }
        if (!numero_documento_representante_r) { errores.push("error_e_numero_documento_representante_r"); }
        if (!capital_representante_r) { errores.push("error_e_capital_representante_r"); }
        if (!pep_r) { errores.push("error_e_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarRepresentante = document.getElementById('form-editar_representante');
        let formDataEditarRepresentante = new FormData(formEditarRepresentante);
        console.log(formDataEditarRepresentante);
        Swal.showLoading();
        await authAxios.post("contraparte/edit_representante", formDataEditarRepresentante)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Representante!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_representante/" + this.props.match.params.id)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({
                                datosCargados: true,
                                representantes: datosRespuesta.data.data,
                            });
                        })
                        .catch(console.log);
                    authAxios.get("contraparte/view_representante/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editRepresentantes: datosRespuesta.data });
                            if (datosRespuesta.data.pep === 'SI') {
                                this.setState({ activar_pep_editar: true });
                                document.getElementById('nav-datos_representante-tab').classList.add('active');
                                document.getElementById('nav-datos_representante').classList.add('show', 'active');
                            }
                            if (datosRespuesta.data.vinculacion_activa_pep === 'NO') {
                                this.setState({ activar_vinculacion_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_participacion_nacional_pep === 'SI') {
                                this.setState({ activar_sociedad_pep_nacional_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_cuenta_extranjera_nacional_pep === 'SI') {
                                this.setState({ activar_cuenta_pep_nacional_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_participacion_extranjero_pep === 'SI') {
                                this.setState({ activar_sociedad_pep_extranjero_editar: true });
                            }
                            if (datosRespuesta.data.pregunta_cuenta_extranjera_extranjero_pep === 'SI') {
                                this.setState({ activar_cuenta_pep_extranjero_editar: true });
                            }
                            Swal.close();
                        })
                        .catch(console.log);
                        authAxios.get("contraparte/view_familiares_nacional/" + id_representante)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_sociedades_nacional/" + id_representante)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_cuentas_nacional/" + id_representante)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_familiares_extranjero/" + id_representante)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_sociedades_extranjero/" + id_representante)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                        authAxios.get("contraparte/view_cuentas_extranjero/" + id_representante)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Representante!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosFamiliarPEPNacional = (e) => {
        e.preventDefault();
        var errores = [];
        var nombre_familiar_nacional_pep_r = document.getElementById('e_nombre_familiar_nacional_pep_r').value;
        var parentesco_familiar_nacional_pep_r = document.getElementById('e_parentesco_familiar_nacional_pep_r').value;
        var tipo_documento_familiar_nacional_pep_r = document.getElementById('e_tipo_documento_familiar_nacional_pep_r').value;
        var numero_documento_familiar_nacional_pep_r = document.getElementById('e_numero_documento_familiar_nacional_pep_r').value;
        if (!nombre_familiar_nacional_pep_r) { errores.push("error_e_nombre_familiar_nacional_pep_r"); }
        if (!parentesco_familiar_nacional_pep_r) { errores.push("error_e_parentesco_familiar_nacional_pep_r"); }
        if (!tipo_documento_familiar_nacional_pep_r) { errores.push("error_e_tipo_documento_familiar_nacional_pep_r"); }
        if (!numero_documento_familiar_nacional_pep_r) { errores.push("error_e_numero_documento_familiar_nacional_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarFamiliaresPEPNacional = document.getElementById('form-editar_familiar_pep_nacional');
        let formDataEditarFamiliaresPEPNacional = new FormData(formEditarFamiliaresPEPNacional);
        console.log(formDataEditarFamiliaresPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/edit_familiar_nacional", formDataEditarFamiliaresPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresPEPNacional: datosRespuesta.data.data,
                            editFamiliaresPEPNacional: datosRespuesta.data.data
                        });
                        document.getElementById('e_nombre_familiar_nacional_pep_r').value = '';
                        const e_parentescon = document.querySelector("#e_parentesco_familiar_nacional_pep_r option:first-child");
                        document.getElementById('e_parentesco_familiar_nacional_pep_r').value = e_parentescon.value;
                        const e_tipo_identificacionn = document.querySelector("#e_tipo_documento_familiar_nacional_pep_r option:first-child");
                        document.getElementById('e_tipo_documento_familiar_nacional_pep_r').value = e_tipo_identificacionn.value;
                        document.getElementById('e_numero_documento_familiar_nacional_pep_r').value = '';
                    })
                    .catch(console.log);
                    var id_representante = document.getElementById('e_id_representante_r').value;
                    authAxios.get("contraparte/view_familiares_nacional/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editFamiliaresPEPNacional: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosSociedadPEPNacional = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_nacional_pep_r = document.getElementById('e_razon_social_participacion_nacional_pep_r').value;
        var numero_documento_participacion_nacional_pep_r = document.getElementById('e_numero_documento_participacion_nacional_pep_r').value;
        var porcentaje_participacion_nacional_pep_r = document.getElementById('e_porcentaje_participacion_nacional_pep_r').value;
        var tipo_control_participacion_nacional_pep_r = document.getElementById('e_tipo_control_participacion_nacional_pep_r').value;
        if (tipo_control_participacion_nacional_pep_r === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_nacional_pep_r = document.getElementById('e_otros_tipo_control_participacion_nacional_pep_r').value;
            if (!otros_tipo_control_participacion_nacional_pep_r) { errores.push("error_e_otros_tipo_control_participacion_nacional_pep_r"); }
        }
        var detalle_tipo_control_participacion_nacional_pep_r = document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_r').value;
        if (!razon_social_participacion_nacional_pep_r) { errores.push("error_e_razon_social_participacion_nacional_pep_r"); }
        if (!numero_documento_participacion_nacional_pep_r) { errores.push("error_e_numero_documento_participacion_nacional_pep_r"); }
        if (!porcentaje_participacion_nacional_pep_r) { errores.push("error_e_porcentaje_participacion_nacional_pep_r"); }
        if (!tipo_control_participacion_nacional_pep_r) { errores.push("error_e_tipo_control_participacion_nacional_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarSociedadesPEPNacional = document.getElementById('form-editar_sociedades_pep_nacional');
        let formDataEditarSociedadesPEPNacional = new FormData(formEditarSociedadesPEPNacional);
        console.log(formDataEditarSociedadesPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/edit_sociedad_nacional", formDataEditarSociedadesPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesPEPNacional: datosRespuesta.data.data,
                            editSociedadesPEPNacional: datosRespuesta.data.data,
                            activar_otros_tipos_control_pep_nacional: false
                        });
                        document.getElementById('e_razon_social_participacion_nacional_pep_r').value = '';
                        document.getElementById('e_numero_documento_participacion_nacional_pep_r').value = '';
                        document.getElementById('e_porcentaje_participacion_nacional_pep_r').value = '';
                        const e_tipo_controln = document.querySelector("#e_tipo_control_participacion_nacional_pep_r option:first-child");
                        document.getElementById('e_tipo_control_participacion_nacional_pep_r').value = e_tipo_controln.value;
                        document.getElementById('e_detalle_tipo_control_participacion_nacional_pep_r').value = '';
                    })
                    .catch(console.log);
                    var id_representante = document.getElementById('e_id_representante_r').value;
                    authAxios.get("contraparte/view_sociedades_nacional/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editSociedadesPEPNacional: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosCuentaPEPNacional = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_nacional_pep_r = document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_r').value;
        var pais_cuenta_extranjera_nacional_pep_r = document.getElementById('e_pais_cuenta_extranjera_nacional_pep_r').value;
        var numero_cuenta_extranjera_nacional_pep_r = document.getElementById('e_numero_cuenta_extranjera_nacional_pep_r').value;
        var clase_propiedad_cuenta_extranjera_nacional_pep_r = document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_r').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_nacional_pep_r) { errores.push("error_e_razon_social_cuenta_extranjera_nacional_pep_r"); }
        if (!pais_cuenta_extranjera_nacional_pep_r) { errores.push("error_e_pais_cuenta_extranjera_nacional_pep_r"); }
        if (!numero_cuenta_extranjera_nacional_pep_r) { errores.push("error_e_numero_cuenta_extranjera_nacional_pep_r"); }
        if (!clase_propiedad_cuenta_extranjera_nacional_pep_r) { errores.push("error_e_clase_propiedad_cuenta_extranjera_nacional_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarCuentasPEPNacional = document.getElementById('form-editar_cuentas_pep_nacional');
        let formDataEditarCuentasPEPNacional = new FormData(formEditarCuentasPEPNacional);
        console.log(formDataEditarCuentasPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/edit_cuenta_nacional", formDataEditarCuentasPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasPEPNacional: datosRespuesta.data.data,
                            editCuentasPEPNacional: datosRespuesta.data.data
                        });
                        document.getElementById('e_razon_social_cuenta_extranjera_nacional_pep_r').value = '';
                        document.getElementById('e_pais_cuenta_extranjera_nacional_pep_r').value = '';
                        document.getElementById('e_numero_cuenta_extranjera_nacional_pep_r').value = '';
                        document.getElementById('e_clase_propiedad_cuenta_extranjera_nacional_pep_r').value = '';
                    })
                    .catch(console.log);
                    var id_representante = document.getElementById('e_id_representante_r').value;
                    authAxios.get("contraparte/view_cuentas_nacional/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editCuentasPEPNacional: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosFamiliarPEPExtranjero = (e) => {
        e.preventDefault();
        var nombre_familiar_extranjero_pep_r = document.getElementById('e_nombre_familiar_extranjero_pep_r').value;
        var parentesco_familiar_extranjero_pep_r = document.getElementById('e_parentesco_familiar_extranjero_pep_r').value;
        var tipo_documento_familiar_extranjero_pep_r = document.getElementById('e_tipo_documento_familiar_extranjero_pep_r').value;
        var numero_documento_familiar_extranjero_pep_r = document.getElementById('e_numero_documento_familiar_extranjero_pep_r').value;
        var errores = [];
        if (!nombre_familiar_extranjero_pep_r) { errores.push("error_e_nombre_familiar_extranjero_pep_r"); }
        if (!parentesco_familiar_extranjero_pep_r) { errores.push("error_e_parentesco_familiar_extranjero_pep_r"); }
        if (!tipo_documento_familiar_extranjero_pep_r) { errores.push("error_e_tipo_documento_familiar_extranjero_pep_r"); }
        if (!numero_documento_familiar_extranjero_pep_r) { errores.push("error_e_numero_documento_familiar_extranjero_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarFamiliaresPEPExtranjero = document.getElementById('form-editar_familiares_pep_extranjero');
        let formDataEditarFamiliaresPEPExtranjero = new FormData(formEditarFamiliaresPEPExtranjero);
        console.log(formDataEditarFamiliaresPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/edit_familiar_extranjero", formDataEditarFamiliaresPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresPEPExtranjero: datosRespuesta.data.data,
                            editFamiliaresPEPExtranjero: datosRespuesta.data.data
                        });
                        document.getElementById('e_nombre_familiar_extranjero_pep_r').value = '';
                        const e_parentescoe = document.querySelector("#e_parentesco_familiar_extranjero_pep_r option:first-child");
                        document.getElementById('e_parentesco_familiar_extranjero_pep_r').value = e_parentescoe.value;
                        const e_tipo_identificacione = document.querySelector("#e_tipo_documento_familiar_extranjero_pep_r option:first-child");
                        document.getElementById('e_tipo_documento_familiar_extranjero_pep_r').value = e_tipo_identificacione.value;
                        document.getElementById('e_numero_documento_familiar_extranjero_pep_r').value = '';
                    })
                    .catch(console.log);
                    var id_representante = document.getElementById('e_id_representante_r').value;
                    authAxios.get("contraparte/view_familiares_extranjero/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editFamiliaresPEPExtranjero: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosSociedadPEPExtranjero = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_extranjero_pep_r = document.getElementById('e_razon_social_participacion_extranjero_pep_r').value;
        var numero_documento_participacion_extranjero_pep_r = document.getElementById('e_numero_documento_participacion_extranjero_pep_r').value;
        var porcentaje_participacion_extranjero_pep_r = document.getElementById('e_porcentaje_participacion_extranjero_pep_r').value;
        var tipo_control_participacion_extranjero_pep_r = document.getElementById('e_tipo_control_participacion_extranjero_pep_r').value;
        if (tipo_control_participacion_extranjero_pep_r === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_extranjero_pep_r = document.getElementById('e_otros_tipo_control_participacion_extranjero_pep_r').value;
            if (!otros_tipo_control_participacion_extranjero_pep_r) { errores.push("error_e_otros_tipo_control_participacion_extranjero_pep_r"); }
        }
        var detalle_tipo_control_participacion_extranjero_pep_r = document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_r').value;
        if (!razon_social_participacion_extranjero_pep_r) { errores.push("error_e_razon_social_participacion_extranjero_pep_r"); }
        if (!numero_documento_participacion_extranjero_pep_r) { errores.push("error_e_numero_documento_participacion_extranjero_pep_r"); }
        if (!porcentaje_participacion_extranjero_pep_r) { errores.push("error_e_porcentaje_participacion_extranjero_pep_r"); }
        if (!tipo_control_participacion_extranjero_pep_r) { errores.push("error_e_tipo_control_participacion_extranjero_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarSociedadesPEPExtranjero = document.getElementById('form-editar_sociedades_pep_extranjero');
        let formDataEditarSociedadesPEPExtranjero = new FormData(formEditarSociedadesPEPExtranjero);
        console.log(formDataEditarSociedadesPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/edit_sociedad_extranjero", formDataEditarSociedadesPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesPEPExtranjero: datosRespuesta.data.data,
                            editSociedadesPEPExtranjero: datosRespuesta.data.data,
                            activar_otros_tipos_control_pep_extranjero: false
                        });
                        document.getElementById('e_razon_social_participacion_extranjero_pep_r').value = '';
                        document.getElementById('e_numero_documento_participacion_extranjero_pep_r').value = '';
                        document.getElementById('e_porcentaje_participacion_extranjero_pep_r').value = '';
                        const e_tipo_controle = document.querySelector("#e_tipo_control_participacion_extranjero_pep_r option:first-child");
                        document.getElementById('e_tipo_control_participacion_extranjero_pep_r').value = e_tipo_controle.value;
                        document.getElementById('e_detalle_tipo_control_participacion_extranjero_pep_r').value = '';
                    })
                    .catch(console.log);
                    var id_representante = document.getElementById('e_id_representante_r').value;
                    authAxios.get("contraparte/view_sociedades_extranjero/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editSociedadesPEPExtranjero: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    editarDatosCuentaPEPExtranjero = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_extranjero_pep_r = document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_r').value;
        var pais_cuenta_extranjera_extranjero_pep_r = document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_r').value;
        var numero_cuenta_extranjera_extranjero_pep_r = document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_r').value;
        var clase_propiedad_cuenta_extranjera_extranjero_pep_r = document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_r').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_extranjero_pep_r) { errores.push("error_e_razon_social_cuenta_extranjera_extranjero_pep_r"); }
        if (!pais_cuenta_extranjera_extranjero_pep_r) { errores.push("error_e_pais_cuenta_extranjera_extranjero_pep_r"); }
        if (!numero_cuenta_extranjera_extranjero_pep_r) { errores.push("error_e_numero_cuenta_extranjera_extranjero_pep_r"); }
        if (!clase_propiedad_cuenta_extranjera_extranjero_pep_r) { errores.push("error_e_clase_propiedad_cuenta_extranjera_extranjero_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formEditarCuentasPEPExtranjero = document.getElementById('form-editar_cuentas_pep_extranjero');
        let formDataEditarCuentasPEPExtranjero = new FormData(formEditarCuentasPEPExtranjero);
        console.log(formDataEditarCuentasPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/edit_cuenta_extranjero", formDataEditarCuentasPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasPEPExtranjero: datosRespuesta.data.data,
                            editCuentasPEPExtranjero: datosRespuesta.data.data
                        });
                        document.getElementById('e_razon_social_cuenta_extranjera_extranjero_pep_r').value = '';
                        document.getElementById('e_pais_cuenta_extranjera_extranjero_pep_r').value = '';
                        document.getElementById('e_numero_cuenta_extranjera_extranjero_pep_r').value = '';
                        document.getElementById('e_clase_propiedad_cuenta_extranjera_extranjero_pep_r').value = '';
                    })
                    .catch(console.log);
                    var id_representante = document.getElementById('e_id_representante_r').value;
                    authAxios.get("contraparte/view_cuentas_extranjero/" + id_representante)
                        .then((datosRespuesta) => {
                            console.log(datosRespuesta);
                            this.setState({ datosCargados: true, editCuentasPEPExtranjero: datosRespuesta.data.data });
                            Swal.close();
                        })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosBeneficiario = (e) => {
        e.preventDefault();
        var errores = [];
        var nombre_beneficiario_b = document.getElementById('nombre_beneficiario_b').value;
        var apellido_beneficiario_b = document.getElementById('apellido_beneficiario_b').value;
        var numero_documento_beneficiario_b = document.getElementById('numero_documento_beneficiario_b').value;
        var correo_electronico_beneficiario_b = document.getElementById('correo_electronico_beneficiario_b').value;
        var telefono_beneficiario_b = document.getElementById('telefono_beneficiario_b').value;
        var telefono_movil_beneficiario_b = document.getElementById('telefono_movil_beneficiario_b').value;
        var pep_b = document.getElementById('pep_b').value;
        if (pep_b === 'SI') {
            var declaracion_veracidad_pep_b = document.getElementById('declaracion_veracidad_pep_b').value;
            var vinculo_actual_pep_b = document.getElementById('vinculo_actual_pep_b').value;
            var cargo_actividad_pep_b = document.getElementById('cargo_actividad_pep_b').value;
            var vinculacion_activa_pep_b = document.getElementById('vinculacion_activa_pep_b').value;
            var fecha_vinculacion_pep_b = document.getElementById('fecha_vinculacion_pep_b').value;
            if (vinculacion_activa_pep_b === 'NO') {
                var fecha_desvinculacion_pep_b = document.getElementById('fecha_desvinculacion_pep_b').value;
                if (!fecha_desvinculacion_pep_b) { errores.push("error_fecha_desvinculacion_pep_b"); }
            }
            var pregunta_participacion_nacional_pep_b = document.getElementById('pregunta_participacion_nacional_pep_b').value;
            var pregunta_cuenta_extranjera_nacional_pep_b = document.getElementById('pregunta_cuenta_extranjera_nacional_pep_b').value;
            var pregunta_beneficiario_extranjero_pep_b = document.getElementById('pregunta_beneficiario_extranjero_pep_b').value;
            if (pregunta_beneficiario_extranjero_pep_b === 'SI') {
                // var nombre_beneficiario_extranjero_pep_b = document.getElementById('nombre_beneficiario_extranjero_pep_b').value;
                // var numero_documento_beneficiario_extranjero_pep_b = document.getElementById('numero_documento_beneficiario_extranjero_pep_b').value;
                // var vinculo_actual_beneficiario_extranjero_pep_b = document.getElementById('vinculo_actual_beneficiario_extranjero_pep_b').value;
                // var cargo_actividad_beneficiario_extranjero_pep_b = document.getElementById('cargo_actividad_beneficiario_extranjero_pep_b').value;
                // var fecha_vinculacion_beneficiario_extranjero_pep_b = document.getElementById('fecha_vinculacion_beneficiario_extranjero_pep_b').value;
                // var fecha_desvinculacion_beneficiario_extranjero_pep_b = document.getElementById('fecha_desvinculacion_beneficiario_extranjero_pep_b').value;
                var pregunta_participacion_extranjero_pep_b = document.getElementById('pregunta_participacion_extranjero_pep_b').value;
                var pregunta_cuenta_extranjera_extranjero_pep_b = document.getElementById('pregunta_cuenta_extranjera_extranjero_pep_b').value;
                // if (!nombre_beneficiario_extranjero_pep_b) { errores.push("error_nombre_beneficiario_extranjero_pep_b"); }
                // if (!numero_documento_beneficiario_extranjero_pep_b) { errores.push("error_numero_documento_beneficiario_extranjero_pep_b"); }
                // if (!vinculo_actual_beneficiario_extranjero_pep_b) { errores.push("error_vinculo_actual_beneficiario_extranjero_pep_b"); }
                // if (!cargo_actividad_beneficiario_extranjero_pep_b) { errores.push("error_cargo_actividad_beneficiario_extranjero_pep_b"); }
                // if (!fecha_vinculacion_beneficiario_extranjero_pep_b) { errores.push("error_fecha_vinculacion_beneficiario_extranjero_pep_b"); }
                // if (!fecha_desvinculacion_beneficiario_extranjero_pep_b) { errores.push("error_fecha_desvinculacion_beneficiario_extranjero_pep_b"); }
                if (!pregunta_participacion_extranjero_pep_b) { errores.push("error_pregunta_participacion_extranjero_pep_b"); }
                if (!pregunta_cuenta_extranjera_extranjero_pep_b) { errores.push("error_pregunta_cuenta_extranjera_extranjero_pep_b"); }
            }
            var pregunta1_pep_b = document.getElementById('pregunta1_pep_b').value;
            var pregunta2_pep_b = document.getElementById('pregunta2_pep_b').value;
            var pregunta3_pep_b = document.getElementById('pregunta3_pep_b').value;
            var pregunta4_pep_b = document.getElementById('pregunta4_pep_b').value;
            var pregunta5_pep_b = document.getElementById('pregunta5_pep_b').value;
            var pregunta6_pep_b = document.getElementById('pregunta6_pep_b').value;
            var pregunta7_pep_b = document.getElementById('pregunta7_pep_b').value;
            var pregunta8_pep_b = document.getElementById('pregunta8_pep_b').value;
            var pregunta9_pep_b = document.getElementById('pregunta9_pep_b').value;
            var pregunta10_pep_b = document.getElementById('pregunta10_pep_b').value;
            var pregunta11_pep_b = document.getElementById('pregunta11_pep_b').value;
            if (!declaracion_veracidad_pep_b) { errores.push("error_declaracion_veracidad_pep_b"); }
            if (!vinculo_actual_pep_b) { errores.push("error_vinculo_actual_pep_b"); }
            if (!cargo_actividad_pep_b) { errores.push("error_cargo_actividad_pep_b"); }
            if (!vinculacion_activa_pep_b) { errores.push("error_vinculacion_activa_pep_b"); }
            if (!fecha_vinculacion_pep_b) { errores.push("error_fecha_vinculacion_pep_b"); }
            if (!pregunta_participacion_nacional_pep_b) { errores.push("error_pregunta_participacion_nacional_pep_b"); }
            if (!pregunta_cuenta_extranjera_nacional_pep_b) { errores.push("error_pregunta_cuenta_extranjera_nacional_pep_b"); }
            if (!pregunta_beneficiario_extranjero_pep_b) { errores.push("error_pregunta_beneficiario_extranjero_pep_b"); }
            if (!pregunta1_pep_b) { errores.push("error_pregunta1_pep_b"); }
            if (!pregunta2_pep_b) { errores.push("error_pregunta2_pep_b"); }
            if (!pregunta3_pep_b) { errores.push("error_pregunta3_pep_b"); }
            if (!pregunta4_pep_b) { errores.push("error_pregunta4_pep_b"); }
            if (!pregunta5_pep_b) { errores.push("error_pregunta5_pep_b"); }
            if (!pregunta6_pep_b) { errores.push("error_pregunta6_pep_b"); }
            if (!pregunta7_pep_b) { errores.push("error_pregunta7_pep_b"); }
            if (!pregunta8_pep_b) { errores.push("error_pregunta8_pep_b"); }
            if (!pregunta9_pep_b) { errores.push("error_pregunta9_pep_b"); }
            if (!pregunta10_pep_b) { errores.push("error_pregunta10_pep_b"); }
            if (!pregunta11_pep_b) { errores.push("error_pregunta11_pep_b"); }
            if (errores.length > 0) {
                Swal.fire({
                    title: 'Beneficiarios',
                    text: 'Existen campos en la Pestaña P.E.P. sin diligenciar. Favor verificar Información.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return false;
                    }
                })
            }
        }
        if (!nombre_beneficiario_b) { errores.push("error_nombre_beneficiario_b"); }
        if (!apellido_beneficiario_b) { errores.push("error_apellido_beneficiario_b"); }
        if (!numero_documento_beneficiario_b) { errores.push("error_numero_documento_beneficiario_b"); }
        if (!correo_electronico_beneficiario_b) { errores.push("error_correo_electronico_beneficiario_b"); }
        if (!telefono_beneficiario_b) { errores.push("error_telefono_beneficiario_b"); }
        if (!telefono_movil_beneficiario_b) { errores.push("error_telefono_movil_beneficiario_b"); }
        if (!pep_b) { errores.push("error_pep_b"); }
        if (!this.validateEmail(correo_electronico_beneficiario_b)) {
            errores.push("error_correo_electronico_beneficiario_b");
            Swal.fire({
                title: 'Formularios',
                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    errores.push("error_correo_electronico_beneficiario_b");
                }
            })
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formBeneficiario = document.getElementById('form-beneficiario');
        let formDataBeneficiario = new FormData(formBeneficiario);
        console.log(formDataBeneficiario);
        Swal.showLoading();
        authAxios.post("contraparte/register_beneficiario", formDataBeneficiario)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Beneficiario!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_beneficiario/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            beneficiarios: datosRespuesta.data.data,
                            nombre_beneficiario_b: '',
                            apellido_beneficiario_b: '',
                            numero_documento_beneficiario_b: '',
                            correo_electronico_beneficiario_b: '',
                            telefono_beneficiario_b: '',
                            telefono_movil_beneficiario_b: '',
                            vinculo_actual_pep_b: '',
                            cargo_actividad_pep_b: '',
                            fecha_vinculacion_pep_b: '',
                            fecha_desvinculacion_pep_b: '',
                            nombre_familiar_nacional_pep_b: '',
                            numero_documento_familiar_nacional_pep_b: '',
                            razon_social_participacion_nacional_pep_b: '',
                            numero_documento_participacion_nacional_pep_b: '',
                            porcentaje_participacion_nacional_pep_b: '',
                            otros_tipo_control_participacion_nacional_pep_b: '',
                            detalle_tipo_control_participacion_nacional_pep_b: '',
                            razon_social_cuenta_extranjera_nacional_pep_b: '',
                            pais_cuenta_extranjera_nacional_pep_b: '',
                            numero_cuenta_extranjera_nacional_pep_b : '',
                            clase_propiedad_cuenta_extranjera_nacional_pep_b: '',
                            // nombre_beneficiario_extranjero_pep_b: '',
                            // numero_documento_beneficiario_extranjero_pep_b: '',
                            // vinculo_actual_beneficiario_extranjero_pep_b: '',
                            // cargo_actividad_beneficiario_extranjero_pep_b: '',
                            // fecha_vinculacion_beneficiario_extranjero_pep_b: '',
                            // fecha_desvinculacion_beneficiario_extranjero_pep_b: '',
                            nombre_familiar_extranjero_pep_b: '',
                            numero_documento_familiar_extranjero_pep_b: '',
                            razon_social_participacion_extranjero_pep_b: '',
                            numero_documento_participacion_extranjero_pep_b: '',
                            porcentaje_participacion_extranjero_pep_b: '',
                            otros_tipo_control_participacion_extranjero_pep_b: '',
                            detalle_tipo_control_participacion_extranjero_pep_b: '',
                            razon_social_cuenta_extranjera_extranjero_pep_b: '',
                            pais_cuenta_extranjera_extranjero_pep_b: '',
                            numero_cuenta_extranjera_extranjero_pep_b: '',
                            clase_propiedad_cuenta_extranjera_extranjero_pep_b: '',
                            familiaresBeneficiarioPEPNacional: [],
                            sociedadesBeneficiarioPEPNacional: [],
                            cuentasBeneficiarioPEPNacional: [],
                            familiaresBeneficiarioPEPExtranjero: [],
                            sociedadesBeneficiarioPEPExtranjero: [],
                            cuentasBeneficiarioPEPExtranjero: [],
                            activar_pep_beneficiario: false,
                            activar_sociedad_beneficiario_pep_nacional: false,
                            activar_cuenta_beneficiario_pep_nacional: false,
                            activar_sociedad_beneficiario_pep_extranjero: false,
                            activar_cuenta_beneficiario_pep_extranjero: false
                        });
                        document.getElementById('pep_b').value = '';
                        document.getElementById('declaracion_veracidad_pep_b').value = '';
                        document.getElementById('vinculacion_activa_pep_b').value = '';
                        document.getElementById('parentesco_familiar_nacional_pep_b').value = '';
                        document.getElementById('tipo_documento_familiar_nacional_pep_b').value = '';
                        document.getElementById('pregunta_participacion_nacional_pep_b').value = '';
                        document.getElementById('tipo_control_participacion_nacional_pep_b').value = '';
                        document.getElementById('pregunta_cuenta_extranjera_nacional_pep_b').value = '';
                        document.getElementById('pregunta_beneficiario_extranjero_pep_b').value = '';
                        document.getElementById('parentesco_familiar_extranjero_pep_b').value = '';
                        document.getElementById('tipo_documento_familiar_extranjero_pep_b').value = '';
                        document.getElementById('pregunta_participacion_extranjero_pep_b').value = '';
                        document.getElementById('tipo_control_participacion_extranjero_pep_b').value = '';
                        document.getElementById('pregunta_cuenta_extranjera_extranjero_pep_b').value = '';
                        document.getElementById('pregunta1_pep_b').value = '';
                        document.getElementById('pregunta2_pep_b').value = '';
                        document.getElementById('pregunta3_pep_b').value = '';
                        document.getElementById('pregunta4_pep_b').value = '';
                        document.getElementById('pregunta5_pep_b').value = '';
                        document.getElementById('pregunta6_pep_b').value = '';
                        document.getElementById('pregunta7_pep_b').value = '';
                        document.getElementById('pregunta8_pep_b').value = '';
                        document.getElementById('pregunta9_pep_b').value = '';
                        document.getElementById('pregunta10_pep_b').value = '';
                        document.getElementById('pregunta11_pep_b').value = '';
                        document.getElementById('nav-datos_pep_beneficiario-tab').classList.remove('active');
                        document.getElementById('nav-datos_pep_beneficiario').classList.remove('show', 'active');
                        document.getElementById('nav-datos_beneficiario-tab').classList.add('active');
                        document.getElementById('nav-datos_beneficiario').classList.add('show', 'active');
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Beneficiario!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosFamiliarBeneficiarioPEPNacional = (e) => {
        e.preventDefault();
        var nombre_familiar_nacional_pep_b = document.getElementById('nombre_familiar_nacional_pep_b').value;
        var parentesco_familiar_nacional_pep_b = document.getElementById('parentesco_familiar_nacional_pep_b').value;
        var tipo_documento_familiar_nacional_pep_b = document.getElementById('tipo_documento_familiar_nacional_pep_b').value;
        var numero_documento_familiar_nacional_pep_b = document.getElementById('numero_documento_familiar_nacional_pep_b').value;
        var errores = [];
        if (!nombre_familiar_nacional_pep_b) { errores.push("error_nombre_familiar_nacional_pep_b"); }
        if (!parentesco_familiar_nacional_pep_b) { errores.push("error_parentesco_familiar_nacional_pep_b"); }
        if (!tipo_documento_familiar_nacional_pep_b) { errores.push("error_tipo_documento_familiar_nacional_pep_b"); }
        if (!numero_documento_familiar_nacional_pep_b) { errores.push("error_numero_documento_familiar_nacional_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formFamiliaresBeneficiarioPEPNacional = document.getElementById('form-familiares_beneficiario_pep_nacional');
        let formDataFamiliaresBeneficiarioPEPNacional = new FormData(formFamiliaresBeneficiarioPEPNacional);
        if (this.state.activar_crear_familiar_beneficiario_pep_nacional_edicion) {
            var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
            formDataFamiliaresBeneficiarioPEPNacional.append('e_id_beneficiario_b', e_id_beneficiario_b);
            formDataFamiliaresBeneficiarioPEPNacional.append('edicion', 'SI');
        } else {
            formDataFamiliaresBeneficiarioPEPNacional.append('edicion', 'NO');
        }
        console.log(formDataFamiliaresBeneficiarioPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/register_familiar_beneficiario_nacional", formDataFamiliaresBeneficiarioPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_beneficiario_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresBeneficiarioPEPNacional: datosRespuesta.data.data,
                            editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data,
                            nombre_familiar_nacional_pep_b: '',
                            numero_documento_familiar_nacional_pep_b: '',
                            activar_crear_familiar_beneficiario_pep_nacional_edicion: false
                        });
                        document.getElementById('parentesco_familiar_nacional_pep_b').value = '';
                        document.getElementById('tipo_documento_familiar_nacional_pep_b').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_familiar_beneficiario_pep_nacional_edicion) {
                        var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                        authAxios.get("contraparte/view_familiares_beneficiario_nacional/" + e_id_beneficiario_b)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosSociedadBeneficiarioPEPNacional = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_nacional_pep_b = document.getElementById('razon_social_participacion_nacional_pep_b').value;
        var numero_documento_participacion_nacional_pep_b = document.getElementById('numero_documento_participacion_nacional_pep_b').value;
        var porcentaje_participacion_nacional_pep_b = document.getElementById('porcentaje_participacion_nacional_pep_b').value;
        var tipo_control_participacion_nacional_pep_b = document.getElementById('tipo_control_participacion_nacional_pep_b').value;
        if (tipo_control_participacion_nacional_pep_b === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_nacional_pep_b = document.getElementById('otros_tipo_control_participacion_nacional_pep_b').value;
            if (!otros_tipo_control_participacion_nacional_pep_b) { errores.push("error_otros_tipo_control_participacion_nacional_pep_b"); }
        }
        var detalle_tipo_control_participacion_nacional_pep_b = document.getElementById('detalle_tipo_control_participacion_nacional_pep_b').value;
        if (!razon_social_participacion_nacional_pep_b) { errores.push("error_razon_social_participacion_nacional_pep_b"); }
        if (!numero_documento_participacion_nacional_pep_b) { errores.push("error_numero_documento_participacion_nacional_pep_b"); }
        if (!porcentaje_participacion_nacional_pep_b) { errores.push("error_porcentaje_participacion_nacional_pep_b"); }
        if (!tipo_control_participacion_nacional_pep_b) { errores.push("error_tipo_control_participacion_nacional_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formSociedadesBeneficiarioPEPNacional = document.getElementById('form-sociedades_beneficiario_pep_nacional');
        let formDataSociedadesBeneficiarioPEPNacional = new FormData(formSociedadesBeneficiarioPEPNacional);
        if (this.state.activar_crear_sociedad_beneficiario_pep_nacional_edicion) {
            var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
            formDataSociedadesBeneficiarioPEPNacional.append('e_id_beneficiario_b', e_id_beneficiario_b);
            formDataSociedadesBeneficiarioPEPNacional.append('edicion', 'SI');
        } else {
            formDataSociedadesBeneficiarioPEPNacional.append('edicion', 'NO');
        }
        console.log(formDataSociedadesBeneficiarioPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/register_sociedad_beneficiario_nacional", formDataSociedadesBeneficiarioPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_beneficiario_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesBeneficiarioPEPNacional: datosRespuesta.data.data,
                            editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data,
                            razon_social_participacion_nacional_pep_b: '',
                            numero_documento_participacion_nacional_pep_b: '',
                            porcentaje_participacion_nacional_pep_b: '',
                            otros_tipo_control_participacion_nacional_pep_b: '',
                            detalle_tipo_control_participacion_nacional_pep_b: '',
                            activar_otros_tipos_control_beneficiario_pep_nacional: false,
                            activar_crear_sociedad_beneficiario_pep_nacional_edicion: false
                        });
                        document.getElementById('tipo_control_participacion_nacional_pep_b').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_sociedad_beneficiario_pep_nacional_edicion) {
                        var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                        authAxios.get("contraparte/view_sociedades_beneficiario_nacional/" + e_id_beneficiario_b)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosCuentaBeneficiarioPEPNacional = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_nacional_pep_b = document.getElementById('razon_social_cuenta_extranjera_nacional_pep_b').value;
        var pais_cuenta_extranjera_nacional_pep_b = document.getElementById('pais_cuenta_extranjera_nacional_pep_b').value;
        var numero_cuenta_extranjera_nacional_pep_b = document.getElementById('numero_cuenta_extranjera_nacional_pep_b').value;
        var clase_propiedad_cuenta_extranjera_nacional_pep_b = document.getElementById('clase_propiedad_cuenta_extranjera_nacional_pep_b').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_nacional_pep_b) { errores.push("error_razon_social_cuenta_extranjera_nacional_pep_b"); }
        if (!pais_cuenta_extranjera_nacional_pep_b) { errores.push("error_pais_cuenta_extranjera_nacional_pep_b"); }
        if (!numero_cuenta_extranjera_nacional_pep_b) { errores.push("error_numero_cuenta_extranjera_nacional_pep_b"); }
        if (!clase_propiedad_cuenta_extranjera_nacional_pep_b) { errores.push("error_clase_propiedad_cuenta_extranjera_nacional_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formCuentasBeneficiarioPEPNacional = document.getElementById('form-cuentas_beneficiario_pep_nacional');
        let formDataCuentasBeneficiarioPEPNacional = new FormData(formCuentasBeneficiarioPEPNacional);
        if (this.state.activar_crear_cuenta_beneficiario_pep_nacional_edicion) {
            var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
            formDataCuentasBeneficiarioPEPNacional.append('e_id_beneficiario_b', e_id_beneficiario_b);
            formDataCuentasBeneficiarioPEPNacional.append('edicion', 'SI');
        } else {
            formDataCuentasBeneficiarioPEPNacional.append('edicion', 'NO');
        }
        console.log(formDataCuentasBeneficiarioPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/register_cuenta_beneficiario_nacional", formDataCuentasBeneficiarioPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_beneficiario_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasBeneficiarioPEPNacional: datosRespuesta.data.data,
                            editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data,
                            razon_social_cuenta_extranjera_nacional_pep_b: '',
                            pais_cuenta_extranjera_nacional_pep_b: '',
                            numero_cuenta_extranjera_nacional_pep_b: '',
                            clase_propiedad_cuenta_extranjera_nacional_pep_b: '',
                            activar_crear_cuenta_beneficiario_pep_nacional_edicion: false
                        });
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_cuenta_beneficiario_pep_nacional_edicion) {
                        var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                        authAxios.get("contraparte/view_cuentas_beneficiario_nacional/" + e_id_beneficiario_b)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosFamiliarBeneficiarioPEPExtranjero = (e) => {
        e.preventDefault();
        var nombre_familiar_extranjero_pep_b = document.getElementById('nombre_familiar_extranjero_pep_b').value;
        var parentesco_familiar_extranjero_pep_b = document.getElementById('parentesco_familiar_extranjero_pep_b').value;
        var tipo_documento_familiar_extranjero_pep_b = document.getElementById('tipo_documento_familiar_extranjero_pep_b').value;
        var numero_documento_familiar_extranjero_pep_b = document.getElementById('numero_documento_familiar_extranjero_pep_b').value;
        var errores = [];
        if (!nombre_familiar_extranjero_pep_b) { errores.push("error_nombre_familiar_extranjero_pep_b"); }
        if (!parentesco_familiar_extranjero_pep_b) { errores.push("error_parentesco_familiar_extranjero_pep_b"); }
        if (!tipo_documento_familiar_extranjero_pep_b) { errores.push("error_tipo_documento_familiar_extranjero_pep_b"); }
        if (!numero_documento_familiar_extranjero_pep_b) { errores.push("error_numero_documento_familiar_extranjero_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formFamiliaresBeneficiarioPEPExtranjero = document.getElementById('form-familiares_beneficiario_pep_extranjero');
        let formDataFamiliaresBeneficiarioPEPExtranjero = new FormData(formFamiliaresBeneficiarioPEPExtranjero);
        if (this.state.activar_crear_familiar_beneficiario_pep_extranjero_edicion) {
            var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
            formDataFamiliaresBeneficiarioPEPExtranjero.append('e_id_beneficiario_b', e_id_beneficiario_b);
            formDataFamiliaresBeneficiarioPEPExtranjero.append('edicion', 'SI');
        } else {
            formDataFamiliaresBeneficiarioPEPExtranjero.append('edicion', 'NO');
        }
        console.log(formDataFamiliaresBeneficiarioPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/register_familiar_beneficiario_extranjero", formDataFamiliaresBeneficiarioPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_beneficiario_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            nombre_familiar_extranjero_pep_b: '',
                            numero_documento_familiar_extranjero_pep_b: '',
                            activar_crear_familiar_beneficiario_pep_extranjero_edicion: false
                        });
                        document.getElementById('parentesco_familiar_extranjero_pep_b').value = '';
                        document.getElementById('tipo_documento_familiar_extranjero_pep_b').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_familiar_beneficiario_pep_extranjero_edicion) {
                        var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                        authAxios.get("contraparte/view_familiares_beneficiario_extranjero/" + e_id_beneficiario_b)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosSociedadBeneficiarioPEPExtranjero = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_extranjero_pep_b = document.getElementById('razon_social_participacion_extranjero_pep_b').value;
        var numero_documento_participacion_extranjero_pep_b = document.getElementById('numero_documento_participacion_extranjero_pep_b').value;
        var porcentaje_participacion_extranjero_pep_b = document.getElementById('porcentaje_participacion_extranjero_pep_b').value;
        var tipo_control_participacion_extranjero_pep_b = document.getElementById('tipo_control_participacion_extranjero_pep_b').value;
        if (tipo_control_participacion_extranjero_pep_b === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_extranjero_pep_b = document.getElementById('otros_tipo_control_participacion_extranjero_pep_b').value;
            if (!otros_tipo_control_participacion_extranjero_pep_b) { errores.push("error_otros_tipo_control_participacion_extranjero_pep_b"); }
        }
        var detalle_tipo_control_participacion_extranjero_pep_b = document.getElementById('detalle_tipo_control_participacion_extranjero_pep_b').value;
        if (!razon_social_participacion_extranjero_pep_b) { errores.push("error_razon_social_participacion_extranjero_pep_b"); }
        if (!numero_documento_participacion_extranjero_pep_b) { errores.push("error_numero_documento_participacion_extranjero_pep_b"); }
        if (!porcentaje_participacion_extranjero_pep_b) { errores.push("error_porcentaje_participacion_extranjero_pep_b"); }
        if (!tipo_control_participacion_extranjero_pep_b) { errores.push("error_tipo_control_participacion_extranjero_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formSociedadesBeneficiarioPEPExtranjero = document.getElementById('form-sociedades_beneficiario_pep_extranjero');
        let formDataSociedadesBeneficiarioPEPExtranjero = new FormData(formSociedadesBeneficiarioPEPExtranjero);
        if (this.state.activar_crear_sociedad_beneficiario_pep_extranjero_edicion) {
            var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
            formDataSociedadesBeneficiarioPEPExtranjero.append('e_id_beneficiario_b', e_id_beneficiario_b);
            formDataSociedadesBeneficiarioPEPExtranjero.append('edicion', 'SI');
        } else {
            formDataSociedadesBeneficiarioPEPExtranjero.append('edicion', 'NO');
        }
        console.log(formDataSociedadesBeneficiarioPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/register_sociedad_beneficiario_extranjero", formDataSociedadesBeneficiarioPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_beneficiario_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            razon_social_participacion_extranjero_pep_b: '',
                            numero_documento_participacion_extranjero_pep_b: '',
                            porcentaje_participacion_extranjero_pep_b: '',
                            activar_otros_tipos_control_beneficiario_pep_extranjero: false,
                            activar_crear_sociedad_beneficiario_pep_extranjero_edicion: false
                        });
                        document.getElementById('tipo_control_participacion_extranjero_pep_b').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_sociedad_beneficiario_pep_extranjero_edicion) {
                        var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                        authAxios.get("contraparte/view_sociedades_beneficiario_extranjero/" + e_id_beneficiario_b)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosCuentaBeneficiarioPEPExtranjero = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_extranjero_pep_b = document.getElementById('razon_social_cuenta_extranjera_extranjero_pep_b').value;
        var pais_cuenta_extranjera_extranjero_pep_b = document.getElementById('pais_cuenta_extranjera_extranjero_pep_b').value;
        var numero_cuenta_extranjera_extranjero_pep_b = document.getElementById('numero_cuenta_extranjera_extranjero_pep_b').value;
        var clase_propiedad_cuenta_extranjera_extranjero_pep_b = document.getElementById('clase_propiedad_cuenta_extranjera_extranjero_pep_b').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_extranjero_pep_b) { errores.push("error_razon_social_cuenta_extranjera_extranjero_pep_b"); }
        if (!pais_cuenta_extranjera_extranjero_pep_b) { errores.push("error_pais_cuenta_extranjera_extranjero_pep_b"); }
        if (!numero_cuenta_extranjera_extranjero_pep_b) { errores.push("error_numero_cuenta_extranjera_extranjero_pep_b"); }
        if (!clase_propiedad_cuenta_extranjera_extranjero_pep_b) { errores.push("error_clase_propiedad_cuenta_extranjera_extranjero_pep_b"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formCuentasBeneficiarioPEPExtranjero = document.getElementById('form-cuentas_beneficiario_pep_extranjero');
        let formDataCuentasBeneficiarioPEPExtranjero = new FormData(formCuentasBeneficiarioPEPExtranjero);
        if (this.state.activar_crear_cuenta_beneficiario_pep_extranjero_edicion) {
            var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
            formDataCuentasBeneficiarioPEPExtranjero.append('e_id_beneficiario_b', e_id_beneficiario_b);
            formDataCuentasBeneficiarioPEPExtranjero.append('edicion', 'SI');
        } else {
            formDataCuentasBeneficiarioPEPExtranjero.append('edicion', 'NO');
        }
        console.log(formDataCuentasBeneficiarioPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/register_cuenta_beneficiario_extranjero", formDataCuentasBeneficiarioPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_beneficiario_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data,
                            razon_social_cuenta_extranjera_extranjero_pep_b: '',
                            pais_cuenta_extranjera_extranjero_pep_b: '',
                            numero_cuenta_extranjera_extranjero_pep_b: '',
                            clase_propiedad_cuenta_extranjera_extranjero_pep_b: '',
                            activar_crear_cuenta_beneficiario_pep_extranjero_edicion: false
                        });
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_cuenta_beneficiario_pep_extranjero_edicion) {
                        var e_id_beneficiario_b = document.getElementById('e_id_beneficiario_b').value;
                        authAxios.get("contraparte/view_cuentas_beneficiario_extranjero/" + e_id_beneficiario_b)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta Beneficiario P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosContacto = (e) => {
        e.preventDefault();
        var errores = [];
        var tipo_contacto_c = document.getElementById('tipo_contacto_c').value;
        var nombre_contacto_c = document.getElementById('nombre_contacto_c').value;
        var apellido_contacto_c = document.getElementById('apellido_contacto_c').value;
        var cargo_contacto_c = document.getElementById('cargo_contacto_c').value;
        var correo_electronico_c = document.getElementById('correo_electronico_c').value;
        var telefono_c = document.getElementById('telefono_c').value;
        var telefono_movil_c = document.getElementById('telefono_movil_c').value;
        if (!tipo_contacto_c) { errores.push("error_tipo_contacto_c"); }
        if (!nombre_contacto_c) { errores.push("error_nombre_contacto_c"); }
        if (!apellido_contacto_c) { errores.push("error_apellido_contacto_c"); }
        if (!cargo_contacto_c) { errores.push("error_cargo_contacto_c"); }
        if (!correo_electronico_c) { errores.push("error_correo_electronico_c"); }
        if (!telefono_c) { errores.push("error_telefono_c"); }
        if (!telefono_movil_c) { errores.push("error_telefono_movil_c"); }
        if (!this.validateEmail(correo_electronico_c)) {
            errores.push("error_correo_electronico_c");
            Swal.fire({
                title: 'Formularios',
                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    errores.push("error_correo_electronico_c");
                }
            })
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formContacto = document.getElementById('form-contacto');
        let formDataContacto = new FormData(formContacto);
        console.log(formDataContacto);
        Swal.showLoading();
        authAxios.post("contraparte/register_contacto", formDataContacto)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Contacto!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_contacto/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            contactos: datosRespuesta.data.data,
                            nombre_contacto_c: '',
                            apellido_contacto_c: '',
                            cargo_contacto_c: '',
                            correo_electronico_c: '',
                            telefono_c: '',
                            ext_telefono_c: '',
                            telefono_movil_c: ''
                        });
                        document.getElementById('tipo_contacto_c').value = '';
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Contacto!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosRepresentante = (e) => {
        e.preventDefault();
        var tipo_rol_r = document.getElementById('tipo_rol_r').value;
        var nombre_representante_r = document.getElementById('nombre_representante_r').value;
        var apellido_representante_r = document.getElementById('apellido_representante_r').value;
        var tipo_documento_representante_r = document.getElementById('tipo_documento_representante_r').value;
        var numero_documento_representante_r = document.getElementById('numero_documento_representante_r').value;
        var capital_representante_r = document.getElementById('capital_representante_r').value;
        var pep_r = document.getElementById('pep_r').value;
        var errores = [];
        if (tipo_documento_representante_r === 'NIT') {
            var digito_verificacion_r = document.getElementById('digito_verificacion_r').value;
            if (!digito_verificacion_r) { errores.push("error_digito_verificacion_r"); }
        }
        if (pep_r === 'SI') {
            var declaracion_veracidad_pep_r = document.getElementById('declaracion_veracidad_pep_r').value;
            var vinculo_actual_pep_r = document.getElementById('vinculo_actual_pep_r').value;
            var cargo_actividad_pep_r = document.getElementById('cargo_actividad_pep_r').value;
            var vinculacion_activa_pep_r = document.getElementById('vinculacion_activa_pep_r').value;
            var fecha_vinculacion_pep_r = document.getElementById('fecha_vinculacion_pep_r').value;
            if (vinculacion_activa_pep_r === 'NO') {
                var fecha_desvinculacion_pep_r = document.getElementById('fecha_desvinculacion_pep_r').value;
                if (!fecha_desvinculacion_pep_r) { errores.push("error_fecha_desvinculacion_pep_r"); }
            }
            var pregunta_participacion_nacional_pep_r = document.getElementById('pregunta_participacion_nacional_pep_r').value;
            var pregunta_cuenta_extranjera_nacional_pep_r = document.getElementById('pregunta_cuenta_extranjera_nacional_pep_r').value;
            var pregunta_beneficiario_extranjero_pep_r = document.getElementById('pregunta_beneficiario_extranjero_pep_r').value;
            if (pregunta_beneficiario_extranjero_pep_r === 'SI') {
                // var nombre_beneficiario_extranjero_pep_r = document.getElementById('nombre_beneficiario_extranjero_pep_r').value;
                // var numero_documento_beneficiario_extranjero_pep_r = document.getElementById('numero_documento_beneficiario_extranjero_pep_r').value;
                // var vinculo_actual_beneficiario_extranjero_pep_r = document.getElementById('vinculo_actual_beneficiario_extranjero_pep_r').value;
                // var cargo_actividad_beneficiario_extranjero_pep_r = document.getElementById('cargo_actividad_beneficiario_extranjero_pep_r').value;
                // var fecha_vinculacion_beneficiario_extranjero_pep_r = document.getElementById('fecha_vinculacion_beneficiario_extranjero_pep_r').value;
                // var fecha_desvinculacion_beneficiario_extranjero_pep_r = document.getElementById('fecha_desvinculacion_beneficiario_extranjero_pep_r').value;
                var pregunta_participacion_extranjero_pep_r = document.getElementById('pregunta_participacion_extranjero_pep_r').value;
                var pregunta_cuenta_extranjera_extranjero_pep_r = document.getElementById('pregunta_cuenta_extranjera_extranjero_pep_r').value;
                // if (!nombre_beneficiario_extranjero_pep_r) { errores.push("error_nombre_beneficiario_extranjero_pep_r"); }
                // if (!numero_documento_beneficiario_extranjero_pep_r) { errores.push("error_numero_documento_beneficiario_extranjero_pep_r"); }
                // if (!vinculo_actual_beneficiario_extranjero_pep_r) { errores.push("error_vinculo_actual_beneficiario_extranjero_pep_r"); }
                // if (!cargo_actividad_beneficiario_extranjero_pep_r) { errores.push("error_cargo_actividad_beneficiario_extranjero_pep_r"); }
                // if (!fecha_vinculacion_beneficiario_extranjero_pep_r) { errores.push("error_fecha_vinculacion_beneficiario_extranjero_pep_r"); }
                // if (!fecha_desvinculacion_beneficiario_extranjero_pep_r) { errores.push("error_fecha_desvinculacion_beneficiario_extranjero_pep_r"); }
                if (!pregunta_participacion_extranjero_pep_r) { errores.push("error_pregunta_participacion_extranjero_pep_r"); }
                if (!pregunta_cuenta_extranjera_extranjero_pep_r) { errores.push("error_pregunta_cuenta_extranjera_extranjero_pep_r"); }
            }
            var pregunta1_pep_r = document.getElementById('pregunta1_pep_r').value;
            var pregunta2_pep_r = document.getElementById('pregunta2_pep_r').value;
            var pregunta3_pep_r = document.getElementById('pregunta3_pep_r').value;
            var pregunta4_pep_r = document.getElementById('pregunta4_pep_r').value;
            var pregunta5_pep_r = document.getElementById('pregunta5_pep_r').value;
            var pregunta6_pep_r = document.getElementById('pregunta6_pep_r').value;
            var pregunta7_pep_r = document.getElementById('pregunta7_pep_r').value;
            var pregunta8_pep_r = document.getElementById('pregunta8_pep_r').value;
            var pregunta9_pep_r = document.getElementById('pregunta9_pep_r').value;
            var pregunta10_pep_r = document.getElementById('pregunta10_pep_r').value;
            var pregunta11_pep_r = document.getElementById('pregunta11_pep_r').value;
            if (!declaracion_veracidad_pep_r) { errores.push("error_declaracion_veracidad_pep_r"); }
            if (!vinculo_actual_pep_r) { errores.push("error_vinculo_actual_pep_r"); }
            if (!cargo_actividad_pep_r) { errores.push("error_cargo_actividad_pep_r"); }
            if (!vinculacion_activa_pep_r) { errores.push("error_vinculacion_activa_pep_r"); }
            if (!fecha_vinculacion_pep_r) { errores.push("error_fecha_vinculacion_pep_r"); }
            if (!pregunta_participacion_nacional_pep_r) { errores.push("error_pregunta_participacion_nacional_pep_r"); }
            if (!pregunta_cuenta_extranjera_nacional_pep_r) { errores.push("error_pregunta_cuenta_extranjera_nacional_pep_r"); }
            if (!pregunta_beneficiario_extranjero_pep_r) { errores.push("error_pregunta_beneficiario_extranjero_pep_r"); }
            if (!pregunta1_pep_r) { errores.push("error_pregunta1_pep_r"); }
            if (!pregunta2_pep_r) { errores.push("error_pregunta2_pep_r"); }
            if (!pregunta3_pep_r) { errores.push("error_pregunta3_pep_r"); }
            if (!pregunta4_pep_r) { errores.push("error_pregunta4_pep_r"); }
            if (!pregunta5_pep_r) { errores.push("error_pregunta5_pep_r"); }
            if (!pregunta6_pep_r) { errores.push("error_pregunta6_pep_r"); }
            if (!pregunta7_pep_r) { errores.push("error_pregunta7_pep_r"); }
            if (!pregunta8_pep_r) { errores.push("error_pregunta8_pep_r"); }
            if (!pregunta9_pep_r) { errores.push("error_pregunta9_pep_r"); }
            if (!pregunta10_pep_r) { errores.push("error_pregunta10_pep_r"); }
            if (!pregunta11_pep_r) { errores.push("error_pregunta11_pep_r"); }
            if (errores.length > 0) {
                Swal.fire({
                    title: 'Representantes',
                    text: 'Existen campos en la Pestaña P.E.P. sin diligenciar. Favor verificar Información.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return false;
                    }
                })
            }
        }
        if (!tipo_rol_r) { errores.push("error_tipo_rol_r"); }
        if (!nombre_representante_r) { errores.push("error_nombre_representante_r"); }
        if (!apellido_representante_r) { errores.push("error_apellido_representante_r"); }
        if (!tipo_documento_representante_r) { errores.push("error_tipo_documento_representante_r"); }
        if (!numero_documento_representante_r) { errores.push("error_numero_documento_representante_r"); }
        if (!capital_representante_r) { errores.push("error_capital_representante_r"); }
        if (!pep_r) { errores.push("error_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formRepresentante = document.getElementById('form-representantes');
        let formDataRepresentante = new FormData(formRepresentante);
        console.log(formDataRepresentante);
        Swal.showLoading();
        authAxios.post("contraparte/register_representante", formDataRepresentante)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Representante!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_representante/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            representantes: datosRespuesta.data.data,
                            capital_representante_r: '',
                            nombre_representante_r: '',
                            apellido_representante_r: '',
                            numero_documento_representante_r: '',
                            digito_verificacion_r: '',
                            vinculo_actual_pep_r: '',
                            cargo_actividad_pep_r: '',
                            fecha_vinculacion_pep_r: '',
                            fecha_desvinculacion_pep_r: '',
                            nombre_familiar_nacional_pep_r: '',
                            numero_documento_familiar_nacional_pep_r: '',
                            razon_social_participacion_nacional_pep_r: '',
                            numero_documento_participacion_nacional_pep_r: '',
                            porcentaje_participacion_nacional_pep_r: '',
                            otros_tipo_control_participacion_nacional_pep_r: '',
                            detalle_tipo_control_participacion_nacional_pep_r: '',
                            razon_social_cuenta_extranjera_nacional_pep_r: '',
                            pais_cuenta_extranjera_nacional_pep_r: '',
                            numero_cuenta_extranjera_nacional_pep_r : '',
                            clase_propiedad_cuenta_extranjera_nacional_pep_r: '',
                            // nombre_beneficiario_extranjero_pep_r: '',
                            // numero_documento_beneficiario_extranjero_pep_r: '',
                            // vinculo_actual_beneficiario_extranjero_pep_r: '',
                            // cargo_actividad_beneficiario_extranjero_pep_r: '',
                            // fecha_vinculacion_beneficiario_extranjero_pep_r: '',
                            // fecha_desvinculacion_beneficiario_extranjero_pep_r: '',
                            nombre_familiar_extranjero_pep_r: '',
                            numero_documento_familiar_extranjero_pep_r: '',
                            razon_social_participacion_extranjero_pep_r: '',
                            numero_documento_participacion_extranjero_pep_r: '',
                            porcentaje_participacion_extranjero_pep_r: '',
                            otros_tipo_control_participacion_extranjero_pep_r: '',
                            detalle_tipo_control_participacion_extranjero_pep_r: '',
                            razon_social_cuenta_extranjera_extranjero_pep_r: '',
                            pais_cuenta_extranjera_extranjero_pep_r: '',
                            numero_cuenta_extranjera_extranjero_pep_r: '',
                            clase_propiedad_cuenta_extranjera_extranjero_pep_r: '',
                            familiaresPEPNacional: [],
                            sociedadesPEPNacional: [],
                            cuentasPEPNacional: [],
                            familiaresPEPExtranjero: [],
                            sociedadesPEPExtranjero: [],
                            cuentasPEPExtranjero: [],
                            activar_pep: false,
                            activar_sociedad_pep_nacional: false,
                            activar_cuenta_pep_nacional: false,
                            activar_sociedad_pep_extranjero: false,
                            activar_cuenta_pep_extranjero: false
                        });
                        document.getElementById('tipo_rol_r').value = '';
                        document.getElementById('pep_r').value = '';
                        document.getElementById('tipo_documento_representante_r').value = '';
                        document.getElementById('declaracion_veracidad_pep_r').value = '';
                        document.getElementById('vinculacion_activa_pep_r').value = '';
                        document.getElementById('parentesco_familiar_nacional_pep_r').value = '';
                        document.getElementById('tipo_documento_familiar_nacional_pep_r').value = '';
                        document.getElementById('pregunta_participacion_nacional_pep_r').value = '';
                        document.getElementById('tipo_control_participacion_nacional_pep_r').value = '';
                        document.getElementById('pregunta_cuenta_extranjera_nacional_pep_r').value = '';
                        document.getElementById('pregunta_beneficiario_extranjero_pep_r').value = '';
                        document.getElementById('parentesco_familiar_extranjero_pep_r').value = '';
                        document.getElementById('tipo_documento_familiar_extranjero_pep_r').value = '';
                        document.getElementById('pregunta_participacion_extranjero_pep_r').value = '';
                        document.getElementById('tipo_control_participacion_extranjero_pep_r').value = '';
                        document.getElementById('pregunta_cuenta_extranjera_extranjero_pep_r').value = '';
                        document.getElementById('pregunta1_pep_r').value = '';
                        document.getElementById('pregunta2_pep_r').value = '';
                        document.getElementById('pregunta3_pep_r').value = '';
                        document.getElementById('pregunta4_pep_r').value = '';
                        document.getElementById('pregunta5_pep_r').value = '';
                        document.getElementById('pregunta6_pep_r').value = '';
                        document.getElementById('pregunta7_pep_r').value = '';
                        document.getElementById('pregunta8_pep_r').value = '';
                        document.getElementById('pregunta9_pep_r').value = '';
                        document.getElementById('pregunta10_pep_r').value = '';
                        document.getElementById('pregunta11_pep_r').value = '';
                        document.getElementById('nav-datos_pep-tab').classList.remove('active');
                        document.getElementById('nav-datos_pep').classList.remove('show', 'active');
                        document.getElementById('nav-datos_representante-tab').classList.add('active');
                        document.getElementById('nav-datos_representante').classList.add('show', 'active')
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Representante!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosFamiliarPEPNacional = (e) => {
        e.preventDefault();
        var nombre_familiar_nacional_pep_r = document.getElementById('nombre_familiar_nacional_pep_r').value;
        var parentesco_familiar_nacional_pep_r = document.getElementById('parentesco_familiar_nacional_pep_r').value;
        var tipo_documento_familiar_nacional_pep_r = document.getElementById('tipo_documento_familiar_nacional_pep_r').value;
        var numero_documento_familiar_nacional_pep_r = document.getElementById('numero_documento_familiar_nacional_pep_r').value;
        var errores = [];
        if (!nombre_familiar_nacional_pep_r) { errores.push("error_nombre_familiar_nacional_pep_r"); }
        if (!parentesco_familiar_nacional_pep_r) { errores.push("error_parentesco_familiar_nacional_pep_r"); }
        if (!tipo_documento_familiar_nacional_pep_r) { errores.push("error_tipo_documento_familiar_nacional_pep_r"); }
        if (!numero_documento_familiar_nacional_pep_r) { errores.push("error_numero_documento_familiar_nacional_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formFamiliaresPEPNacional = document.getElementById('form-familiares_pep_nacional');
        let formDataFamiliaresPEPNacional = new FormData(formFamiliaresPEPNacional);
        if (this.state.activar_crear_familiar_pep_nacional_edicion) {
            var e_id_representante_r = document.getElementById('e_id_representante_r').value;
            formDataFamiliaresPEPNacional.append('e_id_representante_r', e_id_representante_r);
            formDataFamiliaresPEPNacional.append('edicion', 'SI');
        } else {
            formDataFamiliaresPEPNacional.append('edicion', 'NO');
        }
        console.log(formDataFamiliaresPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/register_familiar_nacional", formDataFamiliaresPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresPEPNacional: datosRespuesta.data.data,
                            editFamiliaresPEPNacional: datosRespuesta.data.data,
                            nombre_familiar_nacional_pep_r: '',
                            numero_documento_familiar_nacional_pep_r: '',
                            activar_crear_familiar_pep_nacional_edicion: false
                        });
                        document.getElementById('parentesco_familiar_nacional_pep_r').value = '';
                        document.getElementById('tipo_documento_familiar_nacional_pep_r').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_familiar_pep_nacional_edicion) {
                        var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                        authAxios.get("contraparte/view_familiares_nacional/" + e_id_representante_r)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosSociedadPEPNacional = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_nacional_pep_r = document.getElementById('razon_social_participacion_nacional_pep_r').value;
        var numero_documento_participacion_nacional_pep_r = document.getElementById('numero_documento_participacion_nacional_pep_r').value;
        var porcentaje_participacion_nacional_pep_r = document.getElementById('porcentaje_participacion_nacional_pep_r').value;
        var tipo_control_participacion_nacional_pep_r = document.getElementById('tipo_control_participacion_nacional_pep_r').value;
        if (tipo_control_participacion_nacional_pep_r === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_nacional_pep_r = document.getElementById('otros_tipo_control_participacion_nacional_pep_r').value;
            if (!otros_tipo_control_participacion_nacional_pep_r) { errores.push("error_otros_tipo_control_participacion_nacional_pep_r"); }
        }
        var detalle_tipo_control_participacion_nacional_pep_r = document.getElementById('detalle_tipo_control_participacion_nacional_pep_r').value;
        if (!razon_social_participacion_nacional_pep_r) { errores.push("error_razon_social_participacion_nacional_pep_r"); }
        if (!numero_documento_participacion_nacional_pep_r) { errores.push("error_numero_documento_participacion_nacional_pep_r"); }
        if (!porcentaje_participacion_nacional_pep_r) { errores.push("error_porcentaje_participacion_nacional_pep_r"); }
        if (!tipo_control_participacion_nacional_pep_r) { errores.push("error_tipo_control_participacion_nacional_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formSociedadesPEPNacional = document.getElementById('form-sociedades_pep_nacional');
        let formDataSociedadesPEPNacional = new FormData(formSociedadesPEPNacional);
        if (this.state.activar_crear_sociedad_pep_nacional_edicion) {
            var e_id_representante_r = document.getElementById('e_id_representante_r').value;
            formDataSociedadesPEPNacional.append('e_id_representante_r', e_id_representante_r);
            formDataSociedadesPEPNacional.append('edicion', 'SI');
        } else {
            formDataSociedadesPEPNacional.append('edicion', 'NO');
        }
        console.log(formDataSociedadesPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/register_sociedad_nacional", formDataSociedadesPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesPEPNacional: datosRespuesta.data.data,
                            editSociedadesPEPNacional: datosRespuesta.data.data,
                            razon_social_participacion_nacional_pep_r: '',
                            numero_documento_participacion_nacional_pep_r: '',
                            porcentaje_participacion_nacional_pep_r: '',
                            otros_tipo_control_participacion_nacional_pep_r: '',
                            detalle_tipo_control_participacion_nacional_pep_r: '',
                            activar_otros_tipos_control_pep_nacional: false,
                            activar_crear_sociedad_pep_nacional_edicion: false
                        });
                        document.getElementById('tipo_control_participacion_nacional_pep_r').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_sociedad_pep_nacional_edicion) {
                        var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                        authAxios.get("contraparte/view_sociedades_nacional/" + e_id_representante_r)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosCuentaPEPNacional = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_nacional_pep_r = document.getElementById('razon_social_cuenta_extranjera_nacional_pep_r').value;
        var pais_cuenta_extranjera_nacional_pep_r = document.getElementById('pais_cuenta_extranjera_nacional_pep_r').value;
        var numero_cuenta_extranjera_nacional_pep_r = document.getElementById('numero_cuenta_extranjera_nacional_pep_r').value;
        var clase_propiedad_cuenta_extranjera_nacional_pep_r = document.getElementById('clase_propiedad_cuenta_extranjera_nacional_pep_r').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_nacional_pep_r) { errores.push("error_razon_social_cuenta_extranjera_nacional_pep_r"); }
        if (!pais_cuenta_extranjera_nacional_pep_r) { errores.push("error_pais_cuenta_extranjera_nacional_pep_r"); }
        if (!numero_cuenta_extranjera_nacional_pep_r) { errores.push("error_numero_cuenta_extranjera_nacional_pep_r"); }
        if (!clase_propiedad_cuenta_extranjera_nacional_pep_r) { errores.push("error_clase_propiedad_cuenta_extranjera_nacional_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formCuentasPEPNacional = document.getElementById('form-cuentas_pep_nacional');
        let formDataCuentasPEPNacional = new FormData(formCuentasPEPNacional);
        if (this.state.activar_crear_cuenta_pep_nacional_edicion) {
            var e_id_representante_r = document.getElementById('e_id_representante_r').value;
            formDataCuentasPEPNacional.append('e_id_representante_r', e_id_representante_r);
            formDataCuentasPEPNacional.append('edicion', 'SI');
        } else {
            formDataCuentasPEPNacional.append('edicion', 'NO');
        }
        console.log(formDataCuentasPEPNacional);
        Swal.showLoading();
        authAxios.post("contraparte/register_cuenta_nacional", formDataCuentasPEPNacional)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_nacional/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasPEPNacional: datosRespuesta.data.data,
                            editCuentasPEPNacional: datosRespuesta.data.data,
                            razon_social_cuenta_extranjera_nacional_pep_r: '',
                            pais_cuenta_extranjera_nacional_pep_r: '',
                            numero_cuenta_extranjera_nacional_pep_r: '',
                            clase_propiedad_cuenta_extranjera_nacional_pep_r: '',
                            activar_crear_cuenta_pep_nacional_edicion: false
                        });
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_cuenta_pep_nacional_edicion) {
                        var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                        authAxios.get("contraparte/view_cuentas_nacional/" + e_id_representante_r)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasPEPNacional: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Nacional!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosFamiliarPEPExtranjero = (e) => {
        e.preventDefault();
        var nombre_familiar_extranjero_pep_r = document.getElementById('nombre_familiar_extranjero_pep_r').value;
        var parentesco_familiar_extranjero_pep_r = document.getElementById('parentesco_familiar_extranjero_pep_r').value;
        var tipo_documento_familiar_extranjero_pep_r = document.getElementById('tipo_documento_familiar_extranjero_pep_r').value;
        var numero_documento_familiar_extranjero_pep_r = document.getElementById('numero_documento_familiar_extranjero_pep_r').value;
        var errores = [];
        if (!nombre_familiar_extranjero_pep_r) { errores.push("error_nombre_familiar_extranjero_pep_r"); }
        if (!parentesco_familiar_extranjero_pep_r) { errores.push("error_parentesco_familiar_extranjero_pep_r"); }
        if (!tipo_documento_familiar_extranjero_pep_r) { errores.push("error_tipo_documento_familiar_extranjero_pep_r"); }
        if (!numero_documento_familiar_extranjero_pep_r) { errores.push("error_numero_documento_familiar_extranjero_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formFamiliaresPEPExtranjero = document.getElementById('form-familiares_pep_extranjero');
        let formDataFamiliaresPEPExtranjero = new FormData(formFamiliaresPEPExtranjero);
        if (this.state.activar_crear_familiar_pep_extranjero_edicion) {
            var e_id_representante_r = document.getElementById('e_id_representante_r').value;
            formDataFamiliaresPEPExtranjero.append('e_id_representante_r', e_id_representante_r);
            formDataFamiliaresPEPExtranjero.append('edicion', 'SI');
        } else {
            formDataFamiliaresPEPExtranjero.append('edicion', 'NO');
        }
        console.log(formDataFamiliaresPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/register_familiar_extranjero", formDataFamiliaresPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_familiar_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            familiaresPEPExtranjero: datosRespuesta.data.data,
                            editFamiliaresPEPExtranjero: datosRespuesta.data.data,
                            nombre_familiar_extranjero_pep_r: '',
                            numero_documento_familiar_extranjero_pep_r: '',
                            activar_crear_familiar_pep_extranjero_edicion: false
                        });
                        document.getElementById('parentesco_familiar_extranjero_pep_r').value = '';
                        document.getElementById('tipo_documento_familiar_extranjero_pep_r').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_familiar_pep_extranjero_edicion) {
                        var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                        authAxios.get("contraparte/view_familiares_extranjero/" + e_id_representante_r)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editFamiliaresPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Familiar P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosSociedadPEPExtranjero = (e) => {
        e.preventDefault();
        var errores = [];
        var razon_social_participacion_extranjero_pep_r = document.getElementById('razon_social_participacion_extranjero_pep_r').value;
        var numero_documento_participacion_extranjero_pep_r = document.getElementById('numero_documento_participacion_extranjero_pep_r').value;
        var porcentaje_participacion_extranjero_pep_r = document.getElementById('porcentaje_participacion_extranjero_pep_r').value;
        var tipo_control_participacion_extranjero_pep_r = document.getElementById('tipo_control_participacion_extranjero_pep_r').value;
        if (tipo_control_participacion_extranjero_pep_r === 'OTROS_TIPO_CONTROL') {
            var otros_tipo_control_participacion_extranjero_pep_r = document.getElementById('otros_tipo_control_participacion_extranjero_pep_r').value;
            if (!otros_tipo_control_participacion_extranjero_pep_r) { errores.push("error_otros_tipo_control_participacion_extranjero_pep_r"); }
        }
        var detalle_tipo_control_participacion_extranjero_pep_r = document.getElementById('detalle_tipo_control_participacion_extranjero_pep_r').value;
        if (!razon_social_participacion_extranjero_pep_r) { errores.push("error_razon_social_participacion_extranjero_pep_r"); }
        if (!numero_documento_participacion_extranjero_pep_r) { errores.push("error_numero_documento_participacion_extranjero_pep_r"); }
        if (!porcentaje_participacion_extranjero_pep_r) { errores.push("error_porcentaje_participacion_extranjero_pep_r"); }
        if (!tipo_control_participacion_extranjero_pep_r) { errores.push("error_tipo_control_participacion_extranjero_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formSociedadesPEPExtranjero = document.getElementById('form-sociedades_pep_extranjero');
        let formDataSociedadesPEPExtranjero = new FormData(formSociedadesPEPExtranjero);
        if (this.state.activar_crear_sociedad_pep_extranjero_edicion) {
            var e_id_representante_r = document.getElementById('e_id_representante_r').value;
            formDataSociedadesPEPExtranjero.append('e_id_representante_r', e_id_representante_r);
            formDataSociedadesPEPExtranjero.append('edicion', 'SI');
        } else {
            formDataSociedadesPEPExtranjero.append('edicion', 'NO');
        }
        console.log(formDataSociedadesPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/register_sociedad_extranjero", formDataSociedadesPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_sociedad_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            sociedadesPEPExtranjero: datosRespuesta.data.data,
                            editSociedadesPEPExtranjero: datosRespuesta.data.data,
                            razon_social_participacion_extranjero_pep_r: '',
                            numero_documento_participacion_extranjero_pep_r: '',
                            porcentaje_participacion_extranjero_pep_r: '',
                            activar_otros_tipos_control_pep_extranjero: false,
                            activar_crear_sociedad_pep_extranjero_edicion: false
                        });
                        document.getElementById('tipo_control_participacion_extranjero_pep_r').value = '';
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_sociedad_pep_extranjero_edicion) {
                        var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                        authAxios.get("contraparte/view_sociedades_extranjero/" + e_id_representante_r)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editSociedadesPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Sociedad P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosCuentaPEPExtranjero = (e) => {
        e.preventDefault();
        var razon_social_cuenta_extranjera_extranjero_pep_r = document.getElementById('razon_social_cuenta_extranjera_extranjero_pep_r').value;
        var pais_cuenta_extranjera_extranjero_pep_r = document.getElementById('pais_cuenta_extranjera_extranjero_pep_r').value;
        var numero_cuenta_extranjera_extranjero_pep_r = document.getElementById('numero_cuenta_extranjera_extranjero_pep_r').value;
        var clase_propiedad_cuenta_extranjera_extranjero_pep_r = document.getElementById('clase_propiedad_cuenta_extranjera_extranjero_pep_r').value;
        var errores = [];
        if (!razon_social_cuenta_extranjera_extranjero_pep_r) { errores.push("error_razon_social_cuenta_extranjera_extranjero_pep_r"); }
        if (!pais_cuenta_extranjera_extranjero_pep_r) { errores.push("error_pais_cuenta_extranjera_extranjero_pep_r"); }
        if (!numero_cuenta_extranjera_extranjero_pep_r) { errores.push("error_numero_cuenta_extranjera_extranjero_pep_r"); }
        if (!clase_propiedad_cuenta_extranjera_extranjero_pep_r) { errores.push("error_clase_propiedad_cuenta_extranjera_extranjero_pep_r"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formCuentasPEPExtranjero = document.getElementById('form-cuentas_pep_extranjero');
        let formDataCuentasPEPExtranjero = new FormData(formCuentasPEPExtranjero);
        if (this.state.activar_crear_cuenta_pep_extranjero_edicion) {
            var e_id_representante_r = document.getElementById('e_id_representante_r').value;
            formDataCuentasPEPExtranjero.append('e_id_representante_r', e_id_representante_r);
            formDataCuentasPEPExtranjero.append('edicion', 'SI');
        } else {
            formDataCuentasPEPExtranjero.append('edicion', 'NO');
        }
        console.log(formDataCuentasPEPExtranjero);
        Swal.showLoading();
        authAxios.post("contraparte/register_cuenta_extranjero", formDataCuentasPEPExtranjero)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_cuenta_extranjero/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            cuentasPEPExtranjero: datosRespuesta.data.data,
                            editCuentasPEPExtranjero: datosRespuesta.data.data,
                            razon_social_cuenta_extranjera_extranjero_pep_r: '',
                            pais_cuenta_extranjera_extranjero_pep_r: '',
                            numero_cuenta_extranjera_extranjero_pep_r: '',
                            clase_propiedad_cuenta_extranjera_extranjero_pep_r: '',
                            activar_crear_cuenta_pep_extranjero_edicion: false
                        });
                    })
                    .catch(console.log);
                    if (this.state.activar_crear_cuenta_pep_extranjero_edicion) {
                        var e_id_representante_r = document.getElementById('e_id_representante_r').value;
                        authAxios.get("contraparte/view_cuentas_extranjero/" + e_id_representante_r)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                this.setState({ datosCargados: true, editCuentasPEPExtranjero: datosRespuesta.data.data });
                                Swal.close();
                            })
                        .catch(console.log);
                    }
                } else {
                    Swal.close();
                    Swal.fire(
                        'Cuenta P.E.P. Extranjero!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosBeneficiarioESAL = (e) => {
        e.preventDefault();
        var banco_beneficiario_esal = document.getElementById('banco_beneficiario_esal').value;
        var tipo_cuenta_beneficiario_esal = document.getElementById('tipo_cuenta_beneficiario_esal').value;
        var numero_cuenta_beneficiario_esal = document.getElementById('numero_cuenta_beneficiario_esal').value;
        var beneficiario_esal = document.getElementById('beneficiario_esal').value;
        var tipo_documento_beneficiario_esal = document.getElementById('tipo_documento_beneficiario_esal').value;
        var numero_documento_beneficiario_esal = document.getElementById('numero_documento_beneficiario_esal').value;
        var errores = [];
        if (!banco_beneficiario_esal) { errores.push("error_banco_beneficiario_esal"); }
        if (!tipo_cuenta_beneficiario_esal) { errores.push("error_tipo_cuenta_beneficiario_esal"); }
        if (!numero_cuenta_beneficiario_esal) { errores.push("error_numero_cuenta_beneficiario_esal"); }
        if (!beneficiario_esal) { errores.push("error_beneficiario_esal"); }
        if (!tipo_documento_beneficiario_esal) { errores.push("error_tipo_documento_beneficiario_esal"); }
        if (!numero_documento_beneficiario_esal) { errores.push("error_numero_documento_beneficiario_esal"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formBeneficiarioESAL = document.getElementById('form-beneficiarios_esal');
        let formDataBeneficiarioESAL = new FormData(formBeneficiarioESAL);
        console.log(formDataBeneficiarioESAL);
        Swal.showLoading();
        authAxios.post("contraparte/register_beneficiario_esal", formDataBeneficiarioESAL)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Beneficiario!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_beneficiario_esal/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            beneficiariosESAL: datosRespuesta.data.data,
                            numero_cuenta_beneficiario_esal: '',
                            beneficiario_esal: '',
                            numero_documento_beneficiario_esal: ''
                        });
                        document.getElementById('banco_beneficiario_esal').value = '';
                        document.getElementById('tipo_cuenta_beneficiario_esal').value = '';
                        document.getElementById('tipo_documento_beneficiario_esal').value = '';
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Beneficiario!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosRepresentanteESAL = (e) => {
        e.preventDefault();
        var tipo_relacion_representante_esal = document.getElementById('tipo_relacion_representante_esal').value;
        var representante_esal = document.getElementById('representante_esal').value;
        var tipo_documento_representante_esal = document.getElementById('tipo_documento_representante_esal').value;
        var numero_documento_representante_esal = document.getElementById('numero_documento_representante_esal').value;
        var errores = [];
        if (!tipo_relacion_representante_esal) { errores.push("error_tipo_relacion_representante_esal"); }
        if (!representante_esal) { errores.push("error_representante_esal"); }
        if (!tipo_documento_representante_esal) { errores.push("error_tipo_documento_representante_esal"); }
        if (!numero_documento_representante_esal) { errores.push("error_numero_documento_representante_esal"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formRepresentanteESAL = document.getElementById('form-representantes_esal');
        let formDataRepresentanteESAL = new FormData(formRepresentanteESAL);
        console.log(formDataRepresentanteESAL);
        Swal.showLoading();
        authAxios.post("contraparte/register_representante_esal", formDataRepresentanteESAL)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Representante!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_representante_esal/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({
                            datosCargados: true,
                            representantesESAL: datosRespuesta.data.data,
                            representante_esal: '',
                            numero_documento_representante_esal: ''
                        });
                        document.getElementById('tipo_relacion_representante_esal').value = '';
                        document.getElementById('tipo_documento_representante_esal').value = '';
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Representante!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosAccionistas = (e) => {
        e.preventDefault();
        var accionista_ae = document.getElementById('accionista_ae').value;
        var tipo_documento_accionista_ae = document.getElementById('tipo_documento_accionista_ae').value;
        var numero_documento_accionista_ae = document.getElementById('numero_documento_accionista_ae').value;
        var capital_accionista_ae = document.getElementById('capital_accionista_ae').value;
        var errores = [];
        if (!accionista_ae) { errores.push("error_accionista_ae"); }
        if (!tipo_documento_accionista_ae) { errores.push("error_tipo_documento_accionista_ae"); }
        if (!numero_documento_accionista_ae) { errores.push("error_numero_documento_accionista_ae"); }
        if (!capital_accionista_ae) { errores.push("error_capital_accionista_ae"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formAccionista = document.getElementById('form-accionistas');
        let formDataAccionista = new FormData(formAccionista);
        console.log(formDataAccionista);
        Swal.showLoading();
        authAxios.post("contraparte/register_accionista", formDataAccionista)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Accionistas!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_accionista/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, accionistas: datosRespuesta.data.data });
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Accionistas!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosJuntaDirectiva = (e) => {
        e.preventDefault();
        var miembro_junta_directiva_jd = document.getElementById('miembro_junta_directiva_jd').value;
        var tipo_documento_miembro_junta_directiva_jd = document.getElementById('tipo_documento_miembro_junta_directiva_jd').value;
        var numero_documento_miembro_junta_directiva_jd = document.getElementById('numero_documento_miembro_junta_directiva_jd').value;
        var errores = [];
        if (!miembro_junta_directiva_jd) { errores.push("error_miembro_junta_directiva_jd"); }
        if (!tipo_documento_miembro_junta_directiva_jd) { errores.push("error_tipo_documento_miembro_junta_directiva_jd"); }
        if (!numero_documento_miembro_junta_directiva_jd) { errores.push("error_numero_documento_miembro_junta_directiva_jd"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formJuntaDirectiva = document.getElementById('form-junta_directiva');
        let formDataJuntaDirectiva = new FormData(formJuntaDirectiva);
        console.log(formDataJuntaDirectiva);
        Swal.showLoading();
        authAxios.post("contraparte/register_junta_directiva", formDataJuntaDirectiva)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Junta Directiva!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_junta_directiva/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, junta_directiva: datosRespuesta.data.data });
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Junta Directiva!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    cargarMunicipios(tipo_departamento) {
        console.log(tipo_departamento);
        switch (tipo_departamento) {
            case 'expedicion':
                var departamento_expedicion_dg = document.getElementById('departamento_expedicion_dg').value;
                Swal.showLoading();
                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + departamento_expedicion_dg)
                authAxios.get("formulario/ciudades/" + departamento_expedicion_dg)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, municipios_expedicion_dg: datosRespuesta.data });
                        Swal.close();
                    })
                    .catch(console.log);
                break;
            case 'funcionamiento':
                var departamento_funcionamiento_dg = document.getElementById('departamento_funcionamiento_dg').value;
                Swal.showLoading();
                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + departamento_funcionamiento_dg)
                authAxios.get("formulario/ciudades/" + departamento_funcionamiento_dg)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, municipios_funcionamiento_dg: datosRespuesta.data });
                        Swal.close();
                    })
                    .catch(console.log);
                break;
            case 'esal':
                var departamento_esal_dg = document.getElementById('departamento_esal_dg').value;
                Swal.showLoading();
                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + departamento_esal_dg)
                authAxios.get("formulario/ciudades/" + departamento_esal_dg)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, municipios_esal_dg: datosRespuesta.data });
                        Swal.close();
                    })
                    .catch(console.log);
                break;
        }
    }
    async componentDidMount() {
        try {
            const timeout = window.localStorage.getItem('timeout');
            // alert("Now: " + Date.now() + " - TimeOut: " + timeout);
            if (Date.now() > timeout) {
                // alert("Entra");
                window.location.href = "/";
                window.localStorage.clear();
                Swal.fire({
                    title: 'Formularios',
                    text: 'Sesión Expirada',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/";
                        window.localStorage.clear();
                    }
                })
            }
            Swal.showLoading();
            const date = new Date();
            const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            this.setState({ fecha_diligenciamiento : newDate });
            authAxios.get("contraparte/index_beneficiario/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, beneficiarios: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_familiar_beneficiario_nacional/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, familiaresBeneficiarioPEPNacional: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_sociedad_beneficiario_nacional/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, sociedadesBeneficiarioPEPNacional: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_cuenta_beneficiario_nacional/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, cuentasBeneficiarioPEPNacional: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_familiar_beneficiario_extranjero/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, familiaresBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_sociedad_beneficiario_extranjero/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, sociedadesBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_cuenta_beneficiario_extranjero/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, cuentasBeneficiarioPEPExtranjero: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_contacto/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, contactos: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_representante/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, representantes: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_familiar_nacional/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, familiaresPEPNacional: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_sociedad_nacional/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, sociedadesPEPNacional: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_cuenta_nacional/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, cuentasPEPNacional: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_familiar_extranjero/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, familiaresPEPExtranjero: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_sociedad_extranjero/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, sociedadesPEPExtranjero: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_cuenta_extranjero/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, cuentasPEPExtranjero: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_representante_esal/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, representantesESAL: datosRespuesta.data.data });
                })
                .catch(console.log);
            authAxios.get("contraparte/index_beneficiario_esal/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({ datosCargados: true, beneficiariosESAL: datosRespuesta.data.data });
                })
                .catch(console.log);
            axios.get("https://countriesnow.space/api/v0.1/countries/codes")
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({
                        datosCargados: true,
                        paises_esal_dg: datosRespuesta.data.data,
                        paises_dg: datosRespuesta.data.data
                    });
                })
                .catch(console.log());
            // axios.get("https://www.datos.gov.co/resource/xd7j-ypd4.json")
            authAxios.get("formulario/departamentos")
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({
                        datosCargados: true,
                        departamentos_expedicion_dg: datosRespuesta.data,
                        departamentos_funcionamiento_dg: datosRespuesta.data,
                        departamentos_esal_dg : datosRespuesta.data });
                })
                .catch(console.log());
            axios.get("https://www.datos.gov.co/resource/3vbk-w3sc.json")
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({
                        datosCargados: true,
                        bienes_servicios: datosRespuesta.data });
                })
                .catch(console.log());
            axios.get("https://www.datos.gov.co/resource/92br-9737.json")
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    this.setState({
                        datosCargados: true,
                        bancos: datosRespuesta.data,
                        bancos_esal: datosRespuesta.data
                    });
                })
                .catch(console.log());
            await authAxios.get("formulario/detail/" + this.props.match.params.id)
                .then((datosRespuesta) => {
                    console.log(datosRespuesta);
                    const datos = datosRespuesta.data.data;
                    const datosDetalle = JSON.parse(datosRespuesta.data.dataDetalle.json_campos);
                    this.setState({
                        datosCargados: true,
                        formulario: datosRespuesta.data.data,
                        formularioDetalle: JSON.parse(datosRespuesta.data.dataDetalle.json_campos),
                        tipo_formulario: datosRespuesta.data.data.primera_vez,
                        tipo_tercero: datosRespuesta.data.data.tipo_tercero,
                        tipo_sociedad: datosRespuesta.data.data.tipo_sociedad
                    });
                    switch (datos.tipo_tercero) {
                        case 1:
                            if (datosDetalle.infoDatosGenerales.tipoPersonaDG === 'PERSONA_JURIDICA') {
                                this.setState({ activar_info_empresa: true, activar_nombre: false, activar_dv: true });
                            } else {
                                if (datosDetalle.infoDatosGenerales.tipoPersonaDG === 'PERSONA_NATURAL') {
                                    this.setState({ activar_info_empresa: false, activar_nombre: true, activar_dv: false })
                                }
                            }
                            if (datosDetalle.infoDatosGenerales.paisProcedenciaDG === 'Colombia') {
                                this.setState({ activar_dpto_mpio: false });
                            } else {
                                this.setState({ activar_dpto_mpio: true });
                            }
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG === 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: true });
                            }
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG !== 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: false });
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'SI') {
                                this.setState({ activar_resolucion: true })
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'NO') {
                                this.setState({ activar_resolucion: false })
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoExpedicionDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_expedicion_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_expedicion_dg').value = datosDetalle.infoDatosGenerales.municipioExpedicionDG;
                                    })
                                    .catch(console.log);
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_funcionamiento_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_funcionamiento_dg').value = datosDetalle.infoDatosGenerales.municipioFuncionamientoDG;
                                    })
                                    .catch(console.log);
                            }
                            this.setState({
                                seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada,
                                filesDG: datosRespuesta.data.files,
                                filesUploadedRut: datosDetalle.infoFiles.fileRutDG,
                                files_rut_dg: datosDetalle.infoFiles.fileRutDG,
                                nameFilesUploadedRut: datosDetalle.infoFiles.nameFileRutDG,
                                load: true
                            })
                            if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                datosDetalle.infoBanco.seccionGuardada !== null &&
                                datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                datosDetalle.declaracionPN.seccionGuardada !== null) {
                                    this.setState({ activar_enviar: true });
                            }
                            break;
                        case 3:
                        case 4:
                            if (datosDetalle.infoDatosGenerales.paisProcedenciaDG === 'Colombia') {
                                this.setState({ activar_dpto_mpio: false });
                            } else {
                                this.setState({ activar_dpto_mpio: true });
                            }
                            this.setState({ activar_info_empresa: false, activar_nombre: true, activar_dv: false })
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG === 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: true });
                            }
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG !== 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: false });
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'SI') {
                                this.setState({ activar_resolucion: true })
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'NO') {
                                this.setState({ activar_resolucion: false })
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoExpedicionDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_expedicion_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_expedicion_dg').value = datosDetalle.infoDatosGenerales.municipioExpedicionDG;
                                    })
                                    .catch(console.log);
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_funcionamiento_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_funcionamiento_dg').value = datosDetalle.infoDatosGenerales.municipioFuncionamientoDG;
                                    })
                                    .catch(console.log);
                            }
                            this.setState({
                                seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada,
                                filesDG: datosRespuesta.data.files,
                                filesUploadedRut: datosDetalle.infoFiles.fileRutDG,
                                files_rut_dg: datosDetalle.infoFiles.fileRutDG,
                                nameFilesUploadedRut: datosDetalle.infoFiles.nameFileRutDG,
                                load: true
                            })
                            if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                datosDetalle.infoBanco.seccionGuardada !== null &&
                                datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                datosDetalle.declaracionPN.seccionGuardada !== null) {
                                    this.setState({ activar_enviar: true });
                            }
                            break;
                        case 2:
                            this.setState({ activar_dpto_mpio: false, activar_info_empresa: true, activar_nombre: false, activar_dv: true })
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG === 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: true });
                            }
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG !== 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: false });
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'SI') {
                                this.setState({ activar_resolucion: true })
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'NO') {
                                this.setState({ activar_resolucion: false })
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoExpedicionDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_expedicion_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_expedicion_dg').value = datosDetalle.infoDatosGenerales.municipioExpedicionDG;
                                    })
                                    .catch(console.log);
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_funcionamiento_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_funcionamiento_dg').value = datosDetalle.infoDatosGenerales.municipioFuncionamientoDG;
                                    })
                                    .catch(console.log);
                            }
                            this.setState({
                                seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                seccion_guardada_cc: datosDetalle.infoCompra.seccionGuardada,
                                seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada,
                                filesDG: datosRespuesta.data.files,
                                filesUploadedRut: datosDetalle.infoFiles.fileRutDG,
                                files_rut_dg: datosDetalle.infoFiles.fileRutDG,
                                nameFilesUploadedRut: datosDetalle.infoFiles.nameFileRutDG,
                                load: true
                            })
                            if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                datosDetalle.infoCompra.seccionGuardada !== null &&
                                datosDetalle.infoBanco.seccionGuardada !== null &&
                                datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                datosDetalle.declaracionPN.seccionGuardada !== null) {
                                    this.setState({ activar_enviar: true });
                            }
                            break;
                        case 5:
                            this.setState({
                                seccion_guardada_vd: datosDetalle.vendorDetails.seccionGuardada,
                                seccion_guardada_bd: datosDetalle.bankDetails.seccionGuardada,
                                seccion_guardada_ib: datosDetalle.intermediaryBankDetails.seccionGuardada,
                                load: true
                            })
                            if (datosDetalle.vendorDetails.seccionGuardada !== null &&
                                datosDetalle.bankDetails.seccionGuardada !== null &&
                                datosDetalle.intermediaryBankDetails.seccionGuardada !== null) {
                                    this.setState({ activar_enviar: true });
                            }
                            break;
                        case 6:
                            switch (datos.tipo_sociedad) {
                                case 4:
                                    if (datosDetalle.infoDatosGenerales.programaGestionRiesgoESAL === 'OTROS_PROGRAMAS') {
                                        this.setState({ activar_otros_programas_esal: true });
                                    }
                                    if (datosDetalle.infoDatosGenerales.programaGestionRiesgoESAL !== 'OTROS_PROGRAMAS') {
                                        this.setState({ activar_otros_programas_esal: false });
                                    }
                                    if (datosDetalle.infoDatosGenerales.departamentoESAL !== '') {
                                        Swal.showLoading();
                                        // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoESAL)
                                        authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoESAL)
                                            .then((datosRespuestaCiudad) => {
                                                console.log(datosRespuestaCiudad);
                                                this.setState({ datosCargados: true, municipios_esal_dg: datosRespuestaCiudad.data });
                                                document.getElementById('municipio_esal_dg').value = datosDetalle.infoDatosGenerales.municipioESAL;
                                            })
                                            .catch(console.log);
                                    }
                                    this.setState({
                                        seccion_guardada_esal_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                        seccion_guardada_rep_legal_esal: datosDetalle.infoRepresentanteLegal.seccionGuardada,
                                        seccion_guardada_ingresos_esal: datosDetalle.infoFinanciera.seccionGuardada,
                                        seccion_guardada_pep_esal: datosDetalle.infoPEP.seccionGuardada,
                                        seccion_guardada_pj_esal: datosDetalle.declaracionPJ.seccionGuardada,
                                        filesDG: datosRespuesta.data.files,
                                        filesUploadedESAL: datosDetalle.infoFiles.fileESAL,
                                        files_esal_dg: datosDetalle.infoFiles.fileESAL,
                                        load: true
                                    })
                                    if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                        datosDetalle.infoRepresentanteLegal.seccionGuardada !== null &&
                                        datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                        datosDetalle.infoPEP.seccionGuardada !== null &&
                                        datosDetalle.declaracionPJ.seccionGuardada !== null) {
                                            this.setState({ activar_enviar: true });
                                    }
                                    break;
                                default:
                                    this.setState({ activar_info_empresa: true, activar_nombre: false, activar_dv: true })
                                    if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG === 'OTROS_PROGRAMAS') {
                                        this.setState({ activar_otros_programas: true });
                                    }
                                    if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG !== 'OTROS_PROGRAMAS') {
                                        this.setState({ activar_otros_programas: false });
                                    }
                                    if (datosDetalle.infoImpuestos.autoRetenedor === 'SI') {
                                        this.setState({ activar_resolucion: true })
                                    }
                                    if (datosDetalle.infoImpuestos.autoRetenedor === 'NO') {
                                        this.setState({ activar_resolucion: false })
                                    }
                                    if (datosDetalle.infoDatosGenerales.departamentoExpedicionDG !== '') {
                                        Swal.showLoading();
                                        // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento='" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG + "'")
                                        authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                            .then((datosRespuestaCiudad) => {
                                                console.log(datosRespuestaCiudad);
                                                this.setState({ datosCargados: true, municipios_expedicion_dg: datosRespuestaCiudad.data });
                                                document.getElementById('municipio_expedicion_dg').value = datosDetalle.infoDatosGenerales.municipioExpedicionDG;
                                            })
                                            .catch(console.log);
                                    }
                                    if (datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG !== '') {
                                        Swal.showLoading();
                                        // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento='" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG + "'")
                                        authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                            .then((datosRespuestaCiudad) => {
                                                console.log(datosRespuestaCiudad);
                                                this.setState({ datosCargados: true, municipios_funcionamiento_dg: datosRespuestaCiudad.data });
                                                document.getElementById('municipio_funcionamiento_dg').value = datosDetalle.infoDatosGenerales.municipioFuncionamientoDG;
                                            })
                                            .catch(console.log);
                                    }
                                    this.setState({
                                        seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                        seccion_guardada_cc: datosDetalle.infoCompra.seccionGuardada,
                                        seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                        seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                        seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                        seccion_guardada_pj: datosDetalle.declaracionPJ.seccionGuardada,
                                        filesDG: datosRespuesta.data.files,
                                        filesUploadedRut: datosDetalle.infoFiles.fileRutDG,
                                        files_rut_dg: datosDetalle.infoFiles.fileRutDG,
                                        nameFilesUploadedRut: datosDetalle.infoFiles.nameFileRutDG,
                                        load: true
                                    })
                                    if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                        datosDetalle.infoCompra.seccionGuardada !== null &&
                                        datosDetalle.infoBanco.seccionGuardada !== null &&
                                        datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                        datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                        datosDetalle.declaracionPJ.seccionGuardada !== null) {
                                            this.setState({ activar_enviar: true });
                                    }
                                    break;
                            }
                            break;
                        case 7:
                            this.setState({ activar_dpto_mpio: false, activar_info_empresa: false, activar_nombre: true, activar_dv: false })
                            console.log("Entra en 7");
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG === 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: true });
                            }
                            if (datosDetalle.infoDatosGenerales.programaGestionRiesgoDG !== 'OTROS_PROGRAMAS') {
                                this.setState({ activar_otros_programas: false });
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'SI') {
                                this.setState({ activar_resolucion: true })
                            }
                            if (datosDetalle.infoImpuestos.autoRetenedor === 'NO') {
                                this.setState({ activar_resolucion: false })
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoExpedicionDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoExpedicionDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_expedicion_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_expedicion_dg').value = datosDetalle.infoDatosGenerales.municipioExpedicionDG;
                                    })
                                    .catch(console.log);
                            }
                            if (datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG !== '') {
                                Swal.showLoading();
                                // axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                authAxios.get("formulario/ciudades/" + datosDetalle.infoDatosGenerales.departamentoFuncionamientoDG)
                                    .then((datosRespuestaCiudad) => {
                                        console.log(datosRespuestaCiudad);
                                        this.setState({ datosCargados: true, municipios_funcionamiento_dg: datosRespuestaCiudad.data });
                                        document.getElementById('municipio_funcionamiento_dg').value = datosDetalle.infoDatosGenerales.municipioFuncionamientoDG;
                                    })
                                    .catch(console.log);
                            }
                            this.setState({
                                seccion_guardada_dg: datosDetalle.infoDatosGenerales.seccionGuardada,
                                seccion_guardada_cc: datosDetalle.infoCompra.seccionGuardada,
                                seccion_guardada_b: datosDetalle.infoBanco.seccionGuardada,
                                seccion_guardada_i: datosDetalle.infoImpuestos.seccionGuardada,
                                seccion_guardada_f: datosDetalle.infoFinanciera.seccionGuardada,
                                seccion_guardada_pn: datosDetalle.declaracionPN.seccionGuardada,
                                filesDG: datosRespuesta.data.files,
                                filesUploadedRut: datosDetalle.infoFiles.fileRutDG,
                                files_rut_dg: datosDetalle.infoFiles.fileRutDG,
                                nameFilesUploadedRut: datosDetalle.infoFiles.nameFileRutDG,
                                load: true
                            })
                            if (datosDetalle.infoDatosGenerales.seccionGuardada !== null &&
                                datosDetalle.infoCompra.seccionGuardada !== null &&
                                datosDetalle.infoBanco.seccionGuardada !== null &&
                                datosDetalle.infoImpuestos.seccionGuardada !== null &&
                                datosDetalle.infoFinanciera.seccionGuardada !== null &&
                                datosDetalle.declaracionPN.seccionGuardada !== null) {
                                    this.setState({ activar_enviar: true });
                            }
                            break;
                    }
                    Swal.close();
                })
                .catch(console.log);
        } catch (error) {

        }
    }
    render() {
        const { correo_electronico_oficial, autorizacion_datos_peronsales, compromiso_sagrilaft,
                tipo_formulario, fecha_diligenciamiento,
                ciudad,
                activar_nombre,
                activar_dv,
                activar_dpto_mpio,
                activar_otros_programas,
                activar_otros_programas_esal,
                activar_resolucion,
                activar_info_empresa,
                activar_formulario,
                activar_enviar,
                activar_pep_beneficiario,
                activar_vinculacion_beneficiario,
                activar_pep_beneficiario_editar, activar_vinculacion_beneficiario_editar, activar_sociedad_beneficiario_pep_nacional_editar,
                activar_cuenta_beneficiario_pep_nacional_editar, activar_sociedad_beneficiario_pep_extranjero_editar, activar_cuenta_beneficiario_pep_extranjero_editar,
                activar_pep_beneficiario_extranjero,
                activar_sociedad_beneficiario_pep_nacional, activar_cuenta_beneficiario_pep_nacional, activar_otros_tipos_control_beneficiario_pep_nacional,
                activar_sociedad_beneficiario_pep_extranjero, activar_cuenta_beneficiario_pep_extranjero, activar_otros_tipos_control_beneficiario_pep_extranjero,
                activar_crear_familiar_beneficiario_pep_nacional_edicion, activar_crear_sociedad_beneficiario_pep_nacional_edicion, activar_crear_cuenta_beneficiario_pep_nacional_edicion,
                activar_crear_familiar_beneficiario_pep_extranjero_edicion, activar_crear_sociedad_beneficiario_pep_extranjero_edicion, activar_crear_cuenta_beneficiario_pep_extranjero_edicion,
                activar_pep,
                activar_pep_editar,
                activar_vinculacion,
                activar_vinculacion_editar,
                activar_pep_extranjero,
                activar_sociedad_pep_nacional, activar_cuenta_pep_nacional, activar_otros_tipos_control_pep_nacional,
                activar_sociedad_pep_extranjero, activar_cuenta_pep_extranjero, activar_otros_tipos_control_pep_extranjero,
                activar_sociedad_pep_nacional_editar, activar_cuenta_pep_nacional_editar,
                activar_sociedad_pep_extranjero_editar, activar_cuenta_pep_extranjero_editar,
                seccion_guardada_dg, seccion_guardada_cc, seccion_guardada_b, seccion_guardada_f, seccion_guardada_i, seccion_guardada_pj, seccion_guardada_pn,
                seccion_guardada_bd, seccion_guardada_vd, seccion_guardada_ib, seccion_guardada_esal_dg, seccion_guardada_rep_legal_esal, seccion_guardada_ingresos_esal,
                seccion_guardada_pep_esal, seccion_guardada_pj_esal,
                accionista_ae, numero_documento_accionista_ae, digito_verificacion_ae, capital_accionista_ae,
                miembro_junta_directiva_jd, numero_documento_miembro_junta_directiva_jd, digito_verificacion_jd,
                nombre_beneficiario_b, apellido_beneficiario_b, numero_documento_beneficiario_b, telefono_beneficiario_b, telefono_movil_beneficiario_b, correo_electronico_beneficiario_b,
                pep_b, declaracion_veracidad_pep_b, vinculo_actual_pep_b, cargo_actividad_pep_b, vinculacion_activa_pep_b, fecha_vinculacion_pep_b, fecha_desvinculacion_pep_b,
                nombre_familiar_nacional_pep_b, parentesco_familiar_nacional_pep_b, tipo_documento_familiar_nacional_pep_b, numero_documento_familiar_nacional_pep_b,
                pregunta_participacion_nacional_pep_b, razon_social_participacion_nacional_pep_b, numero_documento_participacion_nacional_pep_b,
                porcentaje_participacion_nacional_pep_b, tipo_control_participacion_nacional_pep_b, otros_tipo_control_participacion_nacional_pep_b, detalle_tipo_control_participacion_nacional_pep_b,
                pregunta_cuenta_extranjera_nacional_pep_b, razon_social_cuenta_extranjera_nacional_pep_b, pais_cuenta_extranjera_nacional_pep_b, numero_cuenta_extranjera_nacional_pep_b,
                clase_propiedad_cuenta_extranjera_nacional_pep_b, pregunta_beneficiario_extranjero_pep_b, nombre_beneficiario_extranjero_pep_b, numero_documento_beneficiario_extranjero_pep_b,
                vinculo_actual_beneficiario_extranjero_pep_b, cargo_actividad_beneficiario_extranjero_pep_b, fecha_vinculacion_beneficiario_extranjero_pep_b,
                fecha_desvinculacion_beneficiario_extranjero_pep_b,
                nombre_familiar_extranjero_pep_b, parentesco_familiar_extranjero_pep_b, tipo_documento_familiar_extranjero_pep_b, numero_documento_familiar_extranjero_pep_b,
                pregunta_participacion_extranjero_pep_b, razon_social_participacion_extranjero_pep_b, numero_documento_participacion_extranjero_pep_b,
                porcentaje_participacion_extranjero_pep_b, tipo_control_participacion_extranjero_pep_b, otros_tipo_control_participacion_extranjero_pep_b, detalle_tipo_control_participacion_extranjero_pep_b,
                pregunta_cuenta_extranjera_extranjero_pep_b, razon_social_cuenta_extranjera_extranjero_pep_b, pais_cuenta_extranjera_extranjero_pep_b,
                numero_cuenta_extranjera_extranjero_pep_b, clase_propiedad_cuenta_extranjera_extranjero_pep_b, pregunta1_pep_b, pregunta2_pep_b, pregunta3_pep_b, pregunta4_pep_b,
                pregunta5_pep_b, pregunta6_pep_b, pregunta7_pep_b, pregunta8_pep_b, pregunta9_pep_b, pregunta10_pep_b, pregunta11_pep_b,
                nombre_contacto_c, apellido_contacto_c, cargo_contacto_c, correo_electronico_c, telefono_c, ext_telefono_c, telefono_movil_c,
                nombre_representante_r, apellido_representante_r, numero_documento_representante_r, digito_verificacion_r, capital_representante_r,
                declaracion_veracidad_pep_r, vinculo_actual_pep_r, cargo_actividad_pep_r, vinculacion_activa_pep_r, fecha_vinculacion_pep_r, fecha_desvinculacion_pep_r,
                nombre_familiar_nacional_pep_r, parentesco_familiar_nacional_pep_r, tipo_documento_familiar_nacional_pep_r, numero_documento_familiar_nacional_pep_r,
                pregunta_participacion_nacional_pep_r, razon_social_participacion_nacional_pep_r, numero_documento_participacion_nacional_pep_r,
                porcentaje_participacion_nacional_pep_r, tipo_control_participacion_nacional_pep_r, otros_tipo_control_participacion_nacional_pep_r, detalle_tipo_control_participacion_nacional_pep_r,
                pregunta_cuenta_extranjera_nacional_pep_r, razon_social_cuenta_extranjera_nacional_pep_r, pais_cuenta_extranjera_nacional_pep_r, numero_cuenta_extranjera_nacional_pep_r,
                clase_propiedad_cuenta_extranjera_nacional_pep_r, pregunta_beneficiario_extranjero_pep_r, nombre_beneficiario_extranjero_pep_r, numero_documento_beneficiario_extranjero_pep_r,
                vinculo_actual_beneficiario_extranjero_pep_r, cargo_actividad_beneficiario_extranjero_pep_r, fecha_vinculacion_beneficiario_extranjero_pep_r,
                fecha_desvinculacion_beneficiario_extranjero_pep_r,
                nombre_familiar_extranjero_pep_r, parentesco_familiar_extranjero_pep_r, tipo_documento_familiar_extranjero_pep_r, numero_documento_familiar_extranjero_pep_r,
                pregunta_participacion_extranjero_pep_r, razon_social_participacion_extranjero_pep_r, numero_documento_participacion_extranjero_pep_r,
                porcentaje_participacion_extranjero_pep_r, tipo_control_participacion_extranjero_pep_r, otros_tipo_control_participacion_extranjero_pep_r, detalle_tipo_control_participacion_extranjero_pep_r,
                pregunta_cuenta_extranjera_extranjero_pep_r, razon_social_cuenta_extranjera_extranjero_pep_r, pais_cuenta_extranjera_extranjero_pep_r,
                numero_cuenta_extranjera_extranjero_pep_r, clase_propiedad_cuenta_extranjera_extranjero_pep_r, pregunta1_pep_r, pregunta2_pep_r, pregunta3_pep_r, pregunta4_pep_r,
                pregunta5_pep_r, pregunta6_pep_r, pregunta7_pep_r, pregunta8_pep_r, pregunta9_pep_r, pregunta10_pep_r, pregunta11_pep_r,
                beneficiario_esal, numero_documento_beneficiario_esal, numero_cuenta_beneficiario_esal,
                representante_esal, numero_documento_representante_esal,
                formulario, formularioDetalle,
                beneficiarios, contactos, representantes,
                familiaresPEPNacional, sociedadesPEPNacional, cuentasPEPNacional, familiaresPEPExtranjero, sociedadesPEPExtranjero, cuentasPEPExtranjero,
                familiaresBeneficiarioPEPNacional, sociedadesBeneficiarioPEPNacional, cuentasBeneficiarioPEPNacional, familiaresBeneficiarioPEPExtranjero, sociedadesBeneficiarioPEPExtranjero, cuentasBeneficiarioPEPExtranjero,
                beneficiariosESAL, representantesESAL, accionistas, junta_directiva,
                bienes_servicios, bancos, bancos_esal, paises_dg, paises_esal_dg,
                departamentos_expedicion_dg, municipios_expedicion_dg,
                departamentos_funcionamiento_dg, municipios_funcionamiento_dg,
                departamentos_esal_dg, municipios_esal_dg,
                filesUploadedRut, nameFilesUploadedRut, selectedFiles, filesDG, pdfDGBase64, filesUploadedESAL, nameFilesUploadedESAL, selectedFilesESAL, filesESAL, pdfESALBase64,
                editBeneficiarios,
                editFamiliaresBeneficiarioPEPNacional, editFamiliarBeneficiarioPEPNacional,
                editSociedadesBeneficiarioPEPNacional, editSociedadBeneficiarioPEPNacional,
                editCuentasBeneficiarioPEPNacional, editCuentaBeneficiarioPEPNacional,
                editFamiliaresBeneficiarioPEPExtranjero, editFamiliarBeneficiarioPEPExtranjero,
                editSociedadesBeneficiarioPEPExtranjero, editSociedadBeneficiarioPEPExtranjero,
                editCuentasBeneficiarioPEPExtranjero, editCuentaBeneficiarioPEPExtranjero,
                editRepresentantes,
                editFamiliaresPEPNacional, editFamiliarPEPNacional,
                editSociedadesPEPNacional, editSociedadPEPNacional,
                editCuentasPEPNacional, editCuentaPEPNacional,
                editFamiliaresPEPExtranjero, editFamiliarPEPExtranjero,
                editSociedadesPEPExtranjero, editSociedadPEPExtranjero,
                editCuentasPEPExtranjero, editCuentaPEPExtranjero,
                load
            } = this.state;
        return (
            <>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <br />
                            <div className="card">
                                <div className="card-header text-center">
                                    <b>{formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? 'THIRD PARTY REGISTRATION' : 'REGISTRO DE TERCEROS'}</b>
                                </div>
                                <div className="card-body">
                                    {
                                        load ?
                                            <>
                                                {/* {formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && formularioDetalle.infoDatosGenerales.tipoPersonaDG === '2' ? this.setState({ activar_dv : true }) : ''}
                                                {formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && formularioDetalle.infoDatosGenerales.tipoPersonaDG === '1' ? this.setState({ activar_nombre : true }) : ''}
                                                {formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? this.activarResolucion(formularioDetalle.infoImpuestos.autoRetenedor) : ''} */}
                                                {/* <form onSubmit={this.enviarDatos} id='form-tercero'> */}
                                                <form id='form-tercero'>
                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            {
                                                                (() => {
                                                                    switch (formulario.tipo_sociedad) {
                                                                        case 4:
                                                                            return (
                                                                                <>
                                                                                    <button className="nav-link active" id="nav-datos_generales_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales_esal" type="button" role="tab" aria-controls="nav-datos_generales_esal" aria-selected="false">Información General</button>
                                                                                    <button className="nav-link" id="nav-beneficiarios_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-beneficiarios_esal" type="button" role="tab" aria-controls="nav-beneficiarios_esal" aria-selected="false">Información Beneficiarios</button>
                                                                                    <button className="nav-link" id="nav-representante_legal_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-representante_legal_esal" type="button" role="tab" aria-controls="nav-representante_legal_esal" aria-selected="false">Información Representante Legal</button>
                                                                                    <button className="nav-link" id="nav-inf_financiera_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-inf_financiera_esal" type="button" role="tab" aria-controls="nav-inf_financiera_esal" aria-selected="false">Información Financiera</button>
                                                                                    <button className="nav-link" id="nav-representantes_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-representantes_esal" type="button" role="tab" aria-controls="nav-representantes_esal" aria-selected="false">Información Gobierno</button>
                                                                                    <button className="nav-link" id="nav-pep_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-pep_esal" type="button" role="tab" aria-controls="nav-pep_esal" aria-selected="false">Información P.E.P.</button>
                                                                                    <button className="nav-link" id="nav-transparencia_esal-tab" data-bs-toggle="tab" data-bs-target="#nav-transparencia_esal" type="button" role="tab" aria-controls="nav-transparencia_esal" aria-selected="false">Información Transparencia</button>
                                                                                </>
                                                                            )
                                                                        default:
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        (() => {
                                                                                            switch (formulario.tipo_tercero) {
                                                                                                case 1:
                                                                                                case 3:
                                                                                                case 4:
                                                                                                    return (
                                                                                                        <>
                                                                                                            {/* <button class="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="true">General</button> */}
                                                                                                            <button className="nav-link active" id="nav-datos_generales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales" type="button" role="tab" aria-controls="nav-datos_generales" aria-selected="false">Información General</button>
                                                                                                            <button className="nav-link" id="nav-beneficiarios-tab" data-bs-toggle="tab" data-bs-target="#nav-beneficiarios" type="button" role="tab" aria-controls="nav-beneficiarios" aria-selected="false">Información Beneficiarios</button>
                                                                                                            {/* <button className="nav-link" id="nav-contactos-tab" data-bs-toggle="tab" data-bs-target="#nav-contactos" type="button" role="tab" aria-controls="nav-contactos" aria-selected="false">Información Contactos</button>
                                                                                                            <button className="nav-link" id="nav-condiciones_compra-tab" data-bs-toggle="tab" data-bs-target="#nav-condiciones_compra" type="button" role="tab" aria-controls="nav-condiciones_compra" aria-selected="false">Información Compra</button> */}
                                                                                                            <button className="nav-link" id="nav-banco-tab" data-bs-toggle="tab" data-bs-target="#nav-banco" type="button" role="tab" aria-controls="nav-banco" aria-selected="false">Información Banco</button>
                                                                                                            <button className="nav-link" id="nav-impuestos-tab" data-bs-toggle="tab" data-bs-target="#nav-impuestos" type="button" role="tab" aria-controls="nav-impuestos" aria-selected="false">Información Impuestos</button>
                                                                                                            <button className="nav-link" id="nav-financiera-tab" data-bs-toggle="tab" data-bs-target="#nav-financiera" type="button" role="tab" aria-controls="nav-financiera" aria-selected="false">Información Financiera</button>
                                                                                                            <button className="nav-link" id="nav-declaracion_transp_pn-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_transp_pn" type="button" role="tab" aria-controls="nav-declaracion_transp_pn" aria-selected="false">Declaración Transparencia</button>
                                                                                                        </>
                                                                                                    )
                                                                                                case 2:
                                                                                                    return (
                                                                                                        <>
                                                                                                            {/* <button class="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="true">General</button> */}
                                                                                                            <button className="nav-link active" id="nav-datos_generales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales" type="button" role="tab" aria-controls="nav-datos_generales" aria-selected="false">Información General</button>
                                                                                                            <button className="nav-link" id="nav-beneficiarios-tab" data-bs-toggle="tab" data-bs-target="#nav-beneficiarios" type="button" role="tab" aria-controls="nav-beneficiarios" aria-selected="false">Información Beneficiarios</button>
                                                                                                            <button className="nav-link" id="nav-contactos-tab" data-bs-toggle="tab" data-bs-target="#nav-contactos" type="button" role="tab" aria-controls="nav-contactos" aria-selected="false">Información Contactos</button>
                                                                                                            <button className="nav-link" id="nav-condiciones_compra-tab" data-bs-toggle="tab" data-bs-target="#nav-condiciones_compra" type="button" role="tab" aria-controls="nav-condiciones_compra" aria-selected="false">Información Compra</button>
                                                                                                            <button className="nav-link" id="nav-banco-tab" data-bs-toggle="tab" data-bs-target="#nav-banco" type="button" role="tab" aria-controls="nav-banco" aria-selected="false">Información Banco</button>
                                                                                                            <button className="nav-link" id="nav-impuestos-tab" data-bs-toggle="tab" data-bs-target="#nav-impuestos" type="button" role="tab" aria-controls="nav-impuestos" aria-selected="false">Información Impuestos</button>
                                                                                                            <button className="nav-link" id="nav-financiera-tab" data-bs-toggle="tab" data-bs-target="#nav-financiera" type="button" role="tab" aria-controls="nav-financiera" aria-selected="false">Información Financiera</button>
                                                                                                            <button className="nav-link" id="nav-declaracion_transp_pn-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_transp_pn" type="button" role="tab" aria-controls="nav-declaracion_transp_pn" aria-selected="false">Declaración Transparencia</button>
                                                                                                        </>
                                                                                                    )
                                                                                                case 5:
                                                                                                    return (
                                                                                                        <>
                                                                                                            <button className="nav-link active" id="nav-vendor_detail-tab" data-bs-toggle="tab" data-bs-target="#nav-vendor_detail" type="button" role="tab" aria-controls="nav-vendor_detail" aria-selected="false">Vendor Details</button>
                                                                                                            <button className="nav-link" id="nav-bank_detail-tab" data-bs-toggle="tab" data-bs-target="#nav-bank_detail" type="button" role="tab" aria-controls="nav-bank_detail" aria-selected="false">Bank Details</button>
                                                                                                            <button className="nav-link" id="nav-intermediary_bank-tab" data-bs-toggle="tab" data-bs-target="#nav-intermediary_bank" type="button" role="tab" aria-controls="nav-intermediary_bank" aria-selected="false">Intermediary Bank Details</button>
                                                                                                        </>
                                                                                                    )
                                                                                                case 6:
                                                                                                    return (
                                                                                                        <>
                                                                                                            {/* <button class="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="true">General</button> */}
                                                                                                            <button className="nav-link active" id="nav-datos_generales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales" type="button" role="tab" aria-controls="nav-datos_generales" aria-selected="false">Info. General</button>
                                                                                                            <button className="nav-link" id="nav-beneficiarios-tab" data-bs-toggle="tab" data-bs-target="#nav-beneficiarios" type="button" role="tab" aria-controls="nav-beneficiarios" aria-selected="false">Info. Beneficiarios</button>
                                                                                                            <button className="nav-link" id="nav-contactos-tab" data-bs-toggle="tab" data-bs-target="#nav-contactos" type="button" role="tab" aria-controls="nav-contactos" aria-selected="false">Info. Contactos</button>
                                                                                                            <button className="nav-link" id="nav-condiciones_compra-tab" data-bs-toggle="tab" data-bs-target="#nav-condiciones_compra" type="button" role="tab" aria-controls="nav-condiciones_compra" aria-selected="false">Info. Compra</button>
                                                                                                            <button className="nav-link" id="nav-banco-tab" data-bs-toggle="tab" data-bs-target="#nav-banco" type="button" role="tab" aria-controls="nav-banco" aria-selected="false">Info. Banco</button>
                                                                                                            <button className="nav-link" id="nav-impuestos-tab" data-bs-toggle="tab" data-bs-target="#nav-impuestos" type="button" role="tab" aria-controls="nav-impuestos" aria-selected="false">Info. Impuestos</button>
                                                                                                            <button className="nav-link" id="nav-financiera-tab" data-bs-toggle="tab" data-bs-target="#nav-financiera" type="button" role="tab" aria-controls="nav-financiera" aria-selected="false">Info Financiera</button>
                                                                                                            <button className="nav-link" id="nav-representantes-tab" data-bs-toggle="tab" data-bs-target="#nav-representantes" type="button" role="tab" aria-controls="nav-representantes" aria-selected="false">Info. Gobierno</button>
                                                                                                            <button className="nav-link" id="nav-declaracion_transp_pj-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_transp_pj" type="button" role="tab" aria-controls="nav-declaracion_transp_pj" aria-selected="false">Declaración Transparencia P.J.</button>
                                                                                                        </>
                                                                                                    )
                                                                                                case 7:
                                                                                                    return (
                                                                                                        <>
                                                                                                            {/* <button class="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="true">General</button> */}
                                                                                                            <button className="nav-link active" id="nav-datos_generales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales" type="button" role="tab" aria-controls="nav-datos_generales" aria-selected="false">Información General</button>
                                                                                                            <button className="nav-link" id="nav-contactos-tab" data-bs-toggle="tab" data-bs-target="#nav-contactos" type="button" role="tab" aria-controls="nav-contactos" aria-selected="false">Información Contactos</button>
                                                                                                            <button className="nav-link" id="nav-condiciones_compra-tab" data-bs-toggle="tab" data-bs-target="#nav-condiciones_compra" type="button" role="tab" aria-controls="nav-condiciones_compra" aria-selected="false">Información Compra</button>
                                                                                                            <button className="nav-link" id="nav-banco-tab" data-bs-toggle="tab" data-bs-target="#nav-banco" type="button" role="tab" aria-controls="nav-banco" aria-selected="false">Información Banco</button>
                                                                                                            <button className="nav-link" id="nav-impuestos-tab" data-bs-toggle="tab" data-bs-target="#nav-impuestos" type="button" role="tab" aria-controls="nav-impuestos" aria-selected="false">Información Impuestos</button>
                                                                                                            <button className="nav-link" id="nav-financiera-tab" data-bs-toggle="tab" data-bs-target="#nav-financiera" type="button" role="tab" aria-controls="nav-financiera" aria-selected="false">Información Financiera</button>
                                                                                                            <button className="nav-link" id="nav-declaracion_transp_pn-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_transp_pn" type="button" role="tab" aria-controls="nav-declaracion_transp_pn" aria-selected="false">Declaración Transparencia P.N.</button>
                                                                                                        </>
                                                                                                        
                                                                                                    )
                                                                                            }
                                                                                        })()
                                                                                    }
                                                                                    {/* <button className="nav-link" id="nav-pep-tab" data-bs-toggle="tab" data-bs-target="#nav-pep" type="button" role="tab" aria-controls="nav-pep" aria-selected="false">Información P.E.P.</button> */}
                                                                                </>
                                                                            )
                                                                    }
                                                                })()
                                                            }
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="correo_electronico_oficial">Correo Electrónico Oficial</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_oficial" id="correo_electronico_oficial" defaultValue={window.localStorage.getItem('email')}
                                                                                    className={((this.verificarError("error_correo_electronico_oficial")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} readOnly />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-datos_generales" role="tabpanel" aria-labelledby="nav-datos_generales-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div style={{ marginBottom: 0}} className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                El formato de registro/actualización de datos se divide en varias pestañas con preguntas relacionadas con distintos aspectos de información que requerimos para dar cumplimiento a las normativas internas y legales.
                                                                                            </p>
                                                                                            <p>
                                                                                                Por la extensión del formulario el sistema permite trabajar el formulario en diferentes sesiones y guarda el avance al presionar el botón guardar de cada pestaña.
                                                                                            </p>
                                                                                            <p>
                                                                                                El botón Enviar debe ser presionado únicamente cuando se complete la totalidad del formulario, de esta forma se pone en conocimiento de las empresas para su revisión y aprobación.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_formulario">Tipo Formulario</label>
                                                                            <select name="tipo_formulario"
                                                                                id="tipo_formulario"
                                                                                value={formulario.primera_vez}
                                                                                options={formulario.primera_vez}
                                                                                className={((this.verificarError("error_tipo_formulario")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Seleccione el Tipo Formulario</option>
                                                                                    <option value="1">PRIMERA VEZ</option>
                                                                                    <option value="2">ACTUALIZACIÓN</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Formulario</small>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <label htmlFor="tipo_tercero">Tipo Tercero</label>
                                                                            <select name="tipo_tercero"
                                                                                id="tipo_tercero"
                                                                                value={formulario.tipo_tercero}
                                                                                options={formulario.tipo_tercero}
                                                                                className={((this.verificarError("error_tipo_tercero")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Seleccione el Tipo Tercero</option>
                                                                                    <option value="1">ACCIONISTA</option>
                                                                                    <option value="2">CLIENTE</option>
                                                                                    <option value="3">EMPLEADO</option>
                                                                                    <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                                    <option value="5">PROVEEDOR EXTERIOR</option>
                                                                                    <option value="6">PROVEEDOR NACIONAL PERSONA JURÍDICA</option>
                                                                                    <option value="7">PROVEEDOR NACIONAL PERSONA NATURAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Tercero</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0}} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label htmlFor="tipo_sociedad">Tipo Sociedad</label>
                                                                            <select name="tipo_sociedad"
                                                                                id="tipo_sociedad"
                                                                                value={formulario.tipo_sociedad}
                                                                                options={formulario.tipo_sociedad}
                                                                                className={((this.verificarError("error_tipo_sociedad")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Seleccione el Tipo Sociedad</option>
                                                                                    <option value="1">SOCIEDAD POR ACCIONES SIMPLIFICADAS (S.A.S.)</option>
                                                                                    <option value="2">SOCIEDAD ANÓNIMA (S.A.)</option>
                                                                                    <option value="3">SOCIEDAD LIMITADA (LTDA.)</option>
                                                                                    <option value="4">ENTIDAD SIN ÁNIMO DE LUCRO (E.S.A.L.)</option>
                                                                                    <option value="5">SUCURSAL DE SOCIEDAD EXTRANJERA (S.S.E.)</option>
                                                                                    <option value="6">SOCIEDAD COLECTIVA (S.C.)</option>
                                                                                    <option value="7">SOCIEDAD EN COMANDITA POR ACCIONES (S.C.A)</option>
                                                                                    <option value="8">SOCIEDAD EN COMANDITA SIMPLE (S.C.S.)</option>
                                                                                    <option value="9">EMPRESA UNIPERSONAL</option>
                                                                                    <option value="10">SOCIEDAD ASOCIATIVA DE TRABAJO (S.A.T.)</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Sociedad</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            {/* <input type='hidden' id='seccion_guardada_dg' name='seccion_guardada_dg' defaultValue={formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_dg' name='seccion_guardada_dg' value={seccion_guardada_dg} />
                                                                            <label htmlFor="tipoPersonaDG">Tipo Persona</label>
                                                                            <select name="tipoPersonaDG"
                                                                                id="tipoPersonaDG"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.tipoPersonaDG : ''}
                                                                                className={((this.verificarError("error_tipoPersonaDG")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarDV(e);
                                                                                    this.activarInfoEmpresa(e);
                                                                                    this.activarNombre(e);
                                                                                }} >
                                                                                    {(() => {
                                                                                        switch (formulario.tipo_tercero) {
                                                                                            case 1:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Persona</option>
                                                                                                        <option value="PERSONA_NATURAL">PERSONA NATURAL</option>
                                                                                                        <option value="PERSONA_JURIDICA">PERSONA JURÍDICA</option>
                                                                                                        <option value="EXTERIOR">EXTERIOR</option>
                                                                                                    </>
                                                                                                )
                                                                                            case 3:
                                                                                            case 4:
                                                                                            case 7:
                                                                                                return (<option value="PERSONA_NATURAL">PERSONA NATURAL</option>)
                                                                                            case 2:
                                                                                            case 6:
                                                                                                return (<option value="PERSONA_JURIDICA">PERSONA JURÍDICA</option>)
                                                                                            default:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Persona</option>
                                                                                                        <option value="PERSONA_NATURAL">PERSONA NATURAL</option>
                                                                                                        <option value="PERSONA_JURIDICA">PERSONA JURÍDICA</option>
                                                                                                        <option value="EXTERIOR">EXTERIOR</option>
                                                                                                    </>
                                                                                                )
                                                                                        }
                                                                                    })()}
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Persona</small>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_documento_dg">Tipo Documento</label>
                                                                            <select name="tipo_documento_dg"
                                                                                id="tipo_documento_dg"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.tipoDocumentoDG : ''}
                                                                                className={((this.verificarError("error_tipo_documento_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    {(() => {
                                                                                        switch (formulario.tipo_tercero) {
                                                                                            case 1:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                                                        <option value="CC">C.C.</option>
                                                                                                        <option value="NIT">NIT</option>
                                                                                                        <option value="CE">C.E.</option>
                                                                                                        <option value="PP">P.P.</option>
                                                                                                        <option value="OTRO">OTRO</option>
                                                                                                    </>
                                                                                                )
                                                                                            case 3:
                                                                                            case 4:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                                                        <option value="CC">C.C.</option>
                                                                                                        <option value="CE">C.E.</option>
                                                                                                        <option value="PP">P.P.</option>
                                                                                                        <option value="OTRO">OTRO</option>
                                                                                                    </>
                                                                                                )
                                                                                            case 2:
                                                                                            case 6:
                                                                                                return (<option value="NIT">NIT</option>)
                                                                                            default:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                                                        <option value="CC">C.C.</option>
                                                                                                        <option value="NIT">NIT</option>
                                                                                                        <option value="CE">C.E.</option>
                                                                                                        <option value="PASAPORTE">PASAPORTE</option>
                                                                                                        <option value="TAX_ID">TAX ID.</option>
                                                                                                    </>
                                                                                                )
                                                                                        }
                                                                                    })()}
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0}} className='row g-3'>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_identificacion2_dg">Número Identificación</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_identificacion2_dg" id="numero_identificacion2_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.numeroIdentificacionDG : ''}
                                                                                    className={((this.verificarError("error_numero_identificacion2_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Identificación" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Identificación</small>
                                                                        </div>
                                                                        {
                                                                            activar_dv ?
                                                                                <>
                                                                                    <div className="col-md-2">
                                                                                        <label htmlFor="digito_verificacion_dg">DV</label>
                                                                                        <div className="input-group input-group-sm">
                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                            <input style={{ textTransform: 'uppercase' }} type="number" name="digito_verificacion_dg" id="digito_verificacion_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.digitoVerificacionDG : ''}
                                                                                                className={((this.verificarError("error_digito_verificacion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                                                onChange={this.cambioValor} max="37" />
                                                                                        </div>
                                                                                        <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                                    </div>
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    <div className="col-md-2"></div>
                                                                                </>
                                                                        }
                                                                        <div className="col-md-4">
                                                                            {(() => {
                                                                                switch (formulario.tipo_tercero) {
                                                                                    case 1:
                                                                                    case 2:
                                                                                    case 3:
                                                                                    case 4:
                                                                                    case 7:
                                                                                        return (<label htmlFor="fecha_expedicion_dg">Fecha Emisión</label>)
                                                                                    case 6:
                                                                                        return (<label htmlFor="fecha_expedicion_dg">Fecha Constitución</label>)
                                                                                }
                                                                            })()}
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_expedicion_dg" id="fecha_expedicion_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.fechaExpedicionDG : ''}
                                                                                    className={((this.verificarError("error_fecha_expedicion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Expedición" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Constitución</small>
                                                                        </div>
                                                                    </div>
                                                                    {(() => {
                                                                        switch (formulario.tipo_tercero) {
                                                                            case 1:
                                                                            case 3:
                                                                            case 4:
                                                                            case 7:
                                                                                return (
                                                                                    <>
                                                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                                                            <div className="col-md-10">
                                                                                                <label htmlFor="pais_procedencia_dg">País Procedencia</label>
                                                                                                <select name="pais_procedencia_dg"
                                                                                                    id="pais_procedencia_dg"
                                                                                                    defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.paisProcedenciaDG : ''}
                                                                                                    className={((this.verificarError("error_pais_procedencia_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                    onChange={e => {
                                                                                                        this.cambioValor(e);
                                                                                                        this.activarDptoMpio(e);
                                                                                                    }}>
                                                                                                        <option value="">Seleccione el País Procedencia</option>
                                                                                                    {
                                                                                                        paises_dg.map((itemPaisesDG) => (
                                                                                                            <option style={{ textTransform: 'uppercase' }} key={itemPaisesDG.code} value={itemPaisesDG.name}>{itemPaisesDG.name}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                                <small id="helpId" className="invalid-feedback">Seleccione el País Procedencia</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                        }
                                                                    })()}
                                                                    {
                                                                        activar_dpto_mpio ?
                                                                            <div style={{ marginTop: 0, marginBottom: 10 }} className="row g-3">
                                                                                <div className='col-md-5'>
                                                                                    <label htmlFor="departamento_expedicion_dg">Departamento</label>
                                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="departamento_expedicion_dg" id="departamento_expedicion_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.departamentoExpedicionDG : ''}
                                                                                            className={((this.verificarError("error_departamento_expedicion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Departamento" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                    <small id="helpId" className="invalid-feedback">Digite el Departamento</small>
                                                                                </div>
                                                                                <div className='col-md-5'>
                                                                                    <label htmlFor="municipio_expedicion_dg">Municipio</label>
                                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="municipio_expedicion_dg" id="municipio_expedicion_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.municipioExpedicionDG : ''}
                                                                                            className={((this.verificarError("error_municipio_expedicion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Municipio" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                    <small id="helpId" className="invalid-feedback">Digite el Municipio</small>
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                            <div style={{ marginTop: 0, marginBottom: 10 }} className="row g-3">
                                                                                <div className='col-md-5'>
                                                                                    <label htmlFor="departamento_expedicion_dg">Departamento</label>
                                                                                    <input type='hidden' name='id_departamento_expedicion_dg' id='id_departamento_expedicion_dg' value={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.departamentoExpedicionDG : ''} />
                                                                                    <select name="departamento_expedicion_dg"
                                                                                        id="departamento_expedicion_dg"
                                                                                        defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.departamentoExpedicionDG : ''}
                                                                                        className={((this.verificarError("error_departamento_expedicion_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={e => {
                                                                                            this.cargarMunicipios("expedicion");
                                                                                            this.cambioValor(e)
                                                                                        }}>
                                                                                            <option value="">Seleccione el Departamento</option>
                                                                                            {
                                                                                                departamentos_expedicion_dg.map((itemDepartamentosDG) => (
                                                                                                    <option style={{ textTransform: 'uppercase' }} key={itemDepartamentosDG.id_departamento} value={itemDepartamentosDG.nombre}>{itemDepartamentosDG.nombre}</option>
                                                                                                ))
                                                                                            }
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                                                </div>
                                                                                <div className='col-md-5'>
                                                                                    <label htmlFor="municipio_expedicion_dg">Municipio</label>
                                                                                    <select name="municipio_expedicion_dg"
                                                                                        id="municipio_expedicion_dg"
                                                                                        defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.municipioExpedicionDG : ''}
                                                                                        // options={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.municipioExpedicionDG : ''}
                                                                                        className={((this.verificarError("error_municipio_expedicion_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={this.cambioValor}>
                                                                                            <option value="">Seleccione el Municipio</option>
                                                                                            {
                                                                                                municipios_expedicion_dg.map((itemMunicipiosDG) => (
                                                                                                    <option style={{ textTransform: 'uppercase' }}  key={itemMunicipiosDG.id_ciudad} value={itemMunicipiosDG.ciudad}>{itemMunicipiosDG.ciudad}</option>
                                                                                                ))
                                                                                            }
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                    {
                                                                        activar_nombre ?
                                                                            <>
                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                    <div className="col-md-5">
                                                                                        <label htmlFor="primer_apellido_dg">Primer Apellido</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="primer_apellido_dg" id="primer_apellido_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.primerApellidoDG : ''}
                                                                                            className={((this.verificarError("error_primer_apellido_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Primer Apellido" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite el Primer Apellido</small>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <label htmlFor="segundo_apellido_dg">Segundo Apellido</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="segundo_apellido_dg" id="segundo_apellido_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.segundoApellidoDG : ''}
                                                                                            className={((this.verificarError("error_segundo_apellido_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Segundo Apellido" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite el Segundo Apellido</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginTop: 0, marginBottom: 10 }} className="row g-3">
                                                                                    <div className="col-md-5">
                                                                                        <label htmlFor="primer_nombre_dg">Primer Nombre</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="primer_nombre_dg" id="primer_nombre_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.primerNombreDG : ''}
                                                                                            className={((this.verificarError("error_primer_nombre_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Primer Nombre" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite el Primer Nombre</small>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <label htmlFor="segundo_nombre_dg">Segundo Nombre</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="segundo_nombre_dg" id="segundo_nombre_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.segundoNombreDG : ''}
                                                                                            className={((this.verificarError("error_segundo_nombre_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Segundo Nombre" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite el Segundo Nombre</small>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        : ''
                                                                    }
                                                                    {
                                                                        activar_info_empresa ?
                                                                            <>
                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="razon_social_dg">Razón Social</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_dg" id="razon_social_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.razonSocialDG : ''}
                                                                                            className={((this.verificarError("error_razon_social_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite la Razón Social</small>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor="tipo_empresa_dg">Tipo Empresa</label>
                                                                                        <select name="tipo_empresa_dg"
                                                                                            id="tipo_empresa_dg"
                                                                                            defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.tipoEmpresaDG : ''}
                                                                                            className={((this.verificarError("error_tipo_empresa_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                            onChange={this.cambioValor}>
                                                                                                <option value="">Seleccione el Tipo Empresa</option>
                                                                                                <option value="PRIVADA">PRIVADA</option>
                                                                                                <option value="MIXTA">MIXTA</option>
                                                                                                <option value="PUBLICA">PÚBLICA</option>
                                                                                        </select>
                                                                                        <small id="helpId" className="invalid-feedback">Seleccione el Tipo Empresa</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="nombre_comercial_dg">Nombre Comercial</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_comercial_dg" id="nombre_comercial_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.nombreComercialDG : ''}
                                                                                            className={((this.verificarError("error_nombre_comercial_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Comercial" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite el Nombre Comercial</small>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor="siglas_dg">Siglas</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="siglas_dg" id="siglas_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.siglasDG : ''}
                                                                                            className={((this.verificarError("error_siglas_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Siglas" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite las Siglas</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginTop: 0, marginBottom: 10 }} className="row g-3">
                                                                                    <div className='col-md-4'>
                                                                                        <label htmlFor="tamano_empresa_dg">Tamaño Empresa</label>
                                                                                        <select name="tamano_empresa_dg"
                                                                                            id="tamano_empresa_dg"
                                                                                            defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.tamanoEmpresaDG : ''}
                                                                                            className={((this.verificarError("error_tamano_empresa_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                            onChange={this.cambioValor}>
                                                                                                <option value="">Seleccione el Tamaño Empresa</option>
                                                                                                <option value="MICROEMPRESAS">MICROEMPRESAS</option>
                                                                                                <option value="PEQUENAS_EMPRESAS">PEQUEÑAS EMPRESAS</option>
                                                                                                <option value="MEDIANAS_EMPRESAS">MEDIANAS EMPRESAS</option>
                                                                                                <option value="GRANDES_EMPRESAS">GRANDES EMPRESAS</option>
                                                                                        </select>
                                                                                        <small id="helpId" className="invalid-feedback">Seleccione el Tamaño Empresa</small>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        : ''
                                                                    }
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                En este apartado se solicita la información general de la empresa incluyendo su dirección principal, esta información debe coincidir con los registros de cámara de comercio. 
                                                                                            </p>
                                                                                            <p>
                                                                                                Con el formulario se deben adjuntar: Certificación de camara de comercio con vigencia no mayor a 30 días y RUT actualizado.
                                                                                            </p>
                                                                                            <p>
                                                                                                Los datos tipo de persona y tipo de sociedad NO pueden ser modificados una vez se guarde este formato.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        activar_info_empresa ?
                                                                            <>
                                                                                <div style={{ marginBottom: 0 }} className="card">
                                                                                    <div className="card-header text-center">
                                                                                        AYUDA
                                                                                    </div>
                                                                                    <div className="card-body">
                                                                                        <div className="row">
                                                                                            <div className="col-xl-12">
                                                                                                <div className="row g-3">
                                                                                                    <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                                        <p>
                                                                                                            Para determinar el tamaño de la empresa, puede consultar el <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=94550'>Decreto 957 de 2019</a> de la presidencia de la republica y/o las normas que lo modifiquen o actualicen.
                                                                                                        </p>
                                                                                                        <button type='button' className="btn btn-primary btn-sm" data-bs-toggle='modal' data-bs-target="#TamanoEmpresaModal" slot='end'><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp; Tamaño Empresa</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    {/* <hr style={{ marginBottom: 0 }} /> */}
                                                                    <div style={{ width: '100%', height: 20, borderBottom: '1px solid #DEE2E6', textAlign: 'center'}}>
                                                                        <span style={{ fontSize: 23, backgroundColor: 'white', padding: '0 10px'}}>
                                                                            Dirección Principal
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 25 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-5'>
                                                                            <label htmlFor="departamento_funcionamiento_dg">Departamento</label>
                                                                            <select name="departamento_funcionamiento_dg"
                                                                                id="departamento_funcionamiento_dg"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.departamentoFuncionamientoDG : ''}
                                                                                className={((this.verificarError("error_departamento_funcionamiento_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={e => {
                                                                                    this.cargarMunicipios("funcionamiento");
                                                                                    this.cambioValor(e)
                                                                                }}>
                                                                                    <option value="">Seleccione el Departamento</option>
                                                                                    {
                                                                                        departamentos_funcionamiento_dg.map((itemDepartamentosDG) => (
                                                                                            <option style={{ textTransform: 'uppercase' }} key={itemDepartamentosDG.id_departamento} value={itemDepartamentosDG.nombre}>{itemDepartamentosDG.nombre}</option>
                                                                                        ))
                                                                                    }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                                        </div>
                                                                        <div className='col-md-5'>
                                                                            <label htmlFor="municipio_funcionamiento_dg">Municipio</label>
                                                                            <select name="municipio_funcionamiento_dg"
                                                                                id="municipio_funcionamiento_dg"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.municipioFuncionamientoDG : ''}
                                                                                // options={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.municipioFuncionamientoDG : ''}
                                                                                className={((this.verificarError("error_municipio_funcionamiento_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Municipio</option>
                                                                                    {
                                                                                        municipios_funcionamiento_dg.map((itemMunicipiosDG) => (
                                                                                            <option style={{ textTransform: 'uppercase' }}  key={itemMunicipiosDG.id_ciudad} value={itemMunicipiosDG.ciudad}>{itemMunicipiosDG.ciudad}</option>
                                                                                        ))
                                                                                    }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="id_direccion_empresa1_dg">Vía Principal</label>
                                                                            <select name="id_direccion_empresa1_dg"
                                                                                id="id_direccion_empresa1_dg"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.tipoDireccionEmpresa1DG : ''}
                                                                                className={((this.verificarError("error_id_direccion_empresa1_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione la Tipo Vía Principal</option>
                                                                                    <option value="CALLE">CALLE</option>
                                                                                    <option value="CARRERA">CARRERA</option>
                                                                                    <option value="AVENIDA">AVENIDA</option>
                                                                                    <option value="MANZANA">MANZANA</option>
                                                                                    <option value="DIAGONAL">DIAGONAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Vía Principal</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="direccion_empresa1_dg">No.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa1_dg" id="direccion_empresa1_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.direccionEmpresa1DG : ''}
                                                                                className={((this.verificarError("error_direccion_empresa1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No." aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el No.</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="complemento_direccion_empresa1_dg">Letra / Complemento</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="complemento_direccion_empresa1_dg" id="complemento_direccion_empresa1_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.complementoDireccionEmpresa1DG : ''}
                                                                                className={((this.verificarError("error_complemento_direccion_empresa1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Letra" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Letra</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        {/* <div className="col-md-4">
                                                                            <label htmlFor="id_direccion_empresa2_dg">Vía Secundaria</label>
                                                                            <select name="id_direccion_empresa2_dg"
                                                                                id="id_direccion_empresa2_dg"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.tipoDireccionEmpresa2DG : ''}
                                                                                className={((this.verificarError("error_id_direccion_empresa2_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione la Vía Secundaria</option>
                                                                                    <option value="CALLE">CALLE</option>
                                                                                    <option value="CARRERA">CARRERA</option>
                                                                                    <option value="AVENIDA">AVENIDA</option>
                                                                                    <option value="MANZANA">MANZANA</option>
                                                                                    <option value="DIAGONAL">DIAGONAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Vía Secundaria</small>
                                                                        </div> */}
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="direccion_empresa2_dg">Vía Secundaria No.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa2_dg" id="direccion_empresa2_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.direccionEmpresa2DG : ''}
                                                                                className={((this.verificarError("error_direccion_empresa2_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No." aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Vía Secundaria</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="complemento_direccion_empresa2_dg">Letra / Complemento</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="complemento_direccion_empresa2_dg" id="complemento_direccion_empresa2_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.complementoDireccionEmpresa2DG : ''}
                                                                                className={((this.verificarError("error_complemento_direccion_empresa2_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Letra" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Letra</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="direccion_empresa3_dg">No.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa3_dg" id="direccion_empresa3_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.direccionEmpresa3DG : ''}
                                                                                className={((this.verificarError("error_direccion_empresa3_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No." aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el No.</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="complemento_direccion_empresa_dg">Complemento</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="complemento_direccion_empresa_dg" id="complemento_direccion_empresa_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.complementoDireccionEmpresaDG : ''}
                                                                                className={((this.verificarError("error_complemento_direccion_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Complemento" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Complemento</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="correo_electronico_empresa_dg">Correo Electrónico</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_empresa_dg" id="correo_electronico_empresa_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.emailEmpresaDG : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="telefono1_dg">Teléfono 1</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono1_dg" id="telefono1_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.telefono1EmpresaDG : ''}
                                                                                    className={((this.verificarError("error_telefono1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono 1" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono 1</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="ext_telefono1_dg">Ext. Tel. 1</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="ext_telefono1_dg" id="ext_telefono1_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.extTelefono1EmpresaDG : ''}
                                                                                    className={((this.verificarError("error_ext_telefono1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono 1" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono 1</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0, marginBottom: 10 }} className="row g-3">
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="telefono2_dg">Teléfono 2 / Móvil</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono2_dg" id="telefono2_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.telefono2EmpresaDG : ''}
                                                                                    className={((this.verificarError("error_telefono2_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono 2" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono 1</small>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="pagina_web_dg">Página Web</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faGlobe} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="pagina_web_dg" id="pagina_web_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.websiteEmpresaDG : ''}
                                                                                    className={((this.verificarError("error_pagina_web_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Página Web" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite la Página Web</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                La dirección a ingresar debe coincidir con su domicilio fiscal registrado en su registro mercantil.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    {/* <hr style={{ marginBottom: 0 }} /> */}
                                                                    <div style={{ width: '100%', height: 20, borderBottom: '1px solid #DEE2E6', textAlign: 'center'}}>
                                                                        <span style={{ fontSize: 23, backgroundColor: 'white', padding: '0 10px'}}>
                                                                            Gestión de Riesgo
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 25 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-10'>
                                                                            <label htmlFor="programa_gestion_riesgo_dg">La persona natural, jurídica o similiar que usted representa, está obligada a tener</label>
                                                                            <select name="programa_gestion_riesgo_dg"
                                                                                id="programa_gestion_riesgo_dg"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.programaGestionRiesgoDG : ''}
                                                                                className={((this.verificarError("error_programa_gestion_riesgo_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarOtrosProgramas(e);
                                                                                }}>
                                                                                    <option value="">Seleccione el Programa</option>
                                                                                    <option value="SAGRILAFT_PTEE">SAGRILAFT Y PTEE O SU EQUIVALENTE</option>
                                                                                    <option value="REGIMEN_MEDIDAS_MINIMAS">RÉGIMEN DE MEDIDAS MÍNIMAS O SU EQUIVALENTE</option>
                                                                                    <option value="NO_OBLIGADA">NO ESTÁ OBLIGADA A TENER ESTOS PROGRAMAS</option>
                                                                                    <option value="OTROS_PROGRAMAS">OTROS</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Programa</small>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        activar_otros_programas ?
                                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                                <div className="col-md-10">
                                                                                    <label htmlFor="otros_programa_gestion_riesgo_dg">Otros Programas</label>
                                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="otros_programa_gestion_riesgo_dg" id="otros_programa_gestion_riesgo_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.otrosProgramaGestionRiesgoDG : ''}
                                                                                        className={((this.verificarError("error_otros_programa_gestion_riesgo_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Programas" aria-describedby="helpId"
                                                                                        onChange={this.cambioValor} />
                                                                                    <small id="helpId" className="invalid-feedback">Digite los Otros Programas</small>
                                                                                </div>
                                                                            </div>
                                                                        : ''
                                                                    }
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="detalle_gestion_riesgo_dg">Detalle (Opcional)</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="detalle_gestion_riesgo_dg" id="detalle_gestion_riesgo_dg" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoDatosGenerales.detalleProgramaGestionRiesgoDG : ''}
                                                                                className={((this.verificarError("error_detalle_gestion_riesgo_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5"></div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-10'>
                                                                            <label htmlFor="files_dg">Datos Adjuntos</label>
                                                                            <div className='input-group input-group-sm mb-3'>
                                                                                <label className="input-group-text label_files" htmlFor="files_dg"><FontAwesomeIcon icon={faFolderOpen} />&nbsp;&nbsp;Seleccionar Archivo(s) </label>
                                                                                <input
                                                                                    className={((this.verificarError("error_files_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                    type="file" id="files_dg" multiple accept="application/pdf"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorFiles(e);
                                                                                        this.verifyFileSize(e);
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Con el formulario como persona jurídica deben enviarse en formato digital los siguientes documentos:
                                                                                            </p>
                                                                                            <ul>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Cámara de comercio no mayor a 30 días.
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Fotocopia RUT actualizado.
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Fotocopia cédula de ciudadanía representante legal.
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Certificado de autorretenedor o gran contribuyente (si aplica).
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Certificación bancaria no mayor a 30 días.
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Referencias comerciales (2).
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Copia certificados calidad servicio / productos.
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Licencias de funcionamiento y/o disposiciones legales vigentes que afecten su funcionamiento (si aplica).
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Portafolio productos / servicios.
                                                                                                </li>
                                                                                            </ul>
                                                                                            <p>
                                                                                                Los archivos deben estar en formato PDF y tener un tamaño inferior a 5MB
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <table className="table table-hover table-condensed table-sm">
                                                                        <thead className="thead-inverse">
                                                                            <tr>
                                                                                <th className="align-middle">NOMBRE ARCHIVO</th>
                                                                                <th className="align-middle">OPCIONES</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                (() => {
                                                                                    const files = [];
                                                                                    for (let i=0; i < filesDG.length; i++) {
                                                                                        const file_name = filesDG[i];
                                                                                        files.push(
                                                                                            <tr key={i}>
                                                                                                <td>{filesDG[i]}</td>
                                                                                                <td>
                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary btn-sm"
                                                                                                            data-bs-toggle='modal'
                                                                                                            data-bs-target="#PDFFileModal"
                                                                                                            slot='end'
                                                                                                            onClick={() =>
                                                                                                                this.viewFile(file_name)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faFilePdf} />
                                                                                                        </button>&nbsp;&nbsp;
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={() =>
                                                                                                                this.borrarFiles('files_dg', file_name)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                    return files;
                                                                                })()
                                                                                // filesDG.map((file, index) => {
                                                                                //     <tr key={index}>
                                                                                //         <td>{file}</td>
                                                                                //         <td>
                                                                                //             <div className="btn-group" role="group" aria-label="">
                                                                                //                 <button
                                                                                //                     type="button"
                                                                                //                     className="btn btn-secondary btn-sm"
                                                                                //                     data-bs-toggle='modal'
                                                                                //                     data-bs-target="#PDFFileModal"
                                                                                //                     slot='end'
                                                                                //                 >
                                                                                //                     <FontAwesomeIcon icon={faPrint} />
                                                                                //                 </button>&nbsp;&nbsp;
                                                                                //                 <button
                                                                                //                     type="button"
                                                                                //                     className="btn btn-danger btn-sm"
                                                                                //                     onClick={() =>
                                                                                //                         this.borrarFiles('files_dg', file)
                                                                                //                     }
                                                                                //                 >
                                                                                //                     <FontAwesomeIcon icon={faTrashAlt} />
                                                                                //                 </button>
                                                                                //             </div>
                                                                                //         </td>
                                                                                //     </tr>
                                                                                // })
                                                                                // nameFilesUploadedRut != 'null' ?
                                                                                //     <>
                                                                                //         <td>{nameFilesUploadedRut}</td>
                                                                                //         <td>
                                                                                //             <div className="btn-group" role="group" aria-label="">
                                                                                //                 <button
                                                                                //                     type="button"
                                                                                //                     className="btn btn-secondary btn-sm"
                                                                                //                     data-bs-toggle='modal'
                                                                                //                     data-bs-target="#PDFFileModal"
                                                                                //                     slot='end'
                                                                                //                 >
                                                                                //                     <FontAwesomeIcon icon={faPrint} />
                                                                                //                 </button>&nbsp;&nbsp;
                                                                                //                 <button
                                                                                //                     type="button"
                                                                                //                     className="btn btn-danger btn-sm"
                                                                                //                     onClick={() =>
                                                                                //                         this.borrarFiles('files_dg', this.props.match.params.id)
                                                                                //                     }
                                                                                //                 >
                                                                                //                     <FontAwesomeIcon icon={faTrashAlt} />
                                                                                //                 </button>
                                                                                //             </div>
                                                                                //         </td>
                                                                                //     </>
                                                                                // : ''
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'datos_generales')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Generales</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tab-pane fade' id='nav-beneficiarios'role="tabpanel" aria-labelledby="nav-beneficiarios-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-12'>
                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoBeneficiarioModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Beneficiario</button>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                <thead className="thead-inverse">
                                                                                    <tr>
                                                                                        <th className="align-middle">NOMBRE</th>
                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                        <th className="align-middle">CORREO ELECTRÓNICO</th>
                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        beneficiarios.map((itemBeneficiarios) => (
                                                                                            <tr key={itemBeneficiarios.id}>
                                                                                                <td>{itemBeneficiarios.nombre} {itemBeneficiarios.apellido}</td>
                                                                                                <td>{itemBeneficiarios.identificacion}</td>
                                                                                                <td>{itemBeneficiarios.email}</td>
                                                                                                <td>
                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-primary btn-sm"
                                                                                                            data-bs-toggle='modal'
                                                                                                            data-bs-target="#EditarBeneficiarioModal"
                                                                                                            slot='end'
                                                                                                            onClick={() =>
                                                                                                                this.cargarDatosEditar('beneficiario', itemBeneficiarios.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                        </button>&nbsp;&nbsp;
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={() =>
                                                                                                                this.borrarRegistrosPersona('beneficiario', itemBeneficiarios.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                En cumplimiento de las normatividades exigidas por la DIAN requerimos contar con la información de los beneficiarios finales, los cuales en personas jurídicas son:
                                                                                            </p>
                                                                                            <ul>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    La persona natural que, actuando individual o conjuntamente, sea titular, directa o indirectamente, del cinco por ciento (5%) o más del capital o de los derechos de voto de la persona jurídica, y/o se beneficie en cinco por ciento (5%) o más de los activos, rendimientos o utilidades de la persona jurídica; y
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    La persona natural que, actuando individual o conjuntamente, ejerce control directo y/o indirecto sobre la persona jurídica por cualquier otro medio diferente a los establecidos en el punto anterior; o
                                                                                                </li>
                                                                                                <li style={{ listStyleType: 'disc'}}>
                                                                                                    Cuando no se identifique ningún beneficiario final bajo los criterios señalados anteriormente, se considerará como beneficiario final a la persona natural que ostente el cargo de representante legal, salvo que exista una persona natural que ostente una mayor autoridad en relación con las funciones de gestión o dirección de la persona jurídica, en cuyo caso se deberá reportar a esta última persona natural.
                                                                                                </li>
                                                                                            </ul>
                                                                                            <p>
                                                                                                El sistema solicitará por lo menos un (1) beneficiario final.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tab-pane fade' id='nav-contactos'role="tabpanel" aria-labelledby="nav-contactos-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-12'>
                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoContactoModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Contacto</button>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                <thead className="thead-inverse">
                                                                                    <tr>
                                                                                        <th className="align-middle">NOMBRE</th>
                                                                                        <th className="align-middle">CARGO</th>
                                                                                        <th className="align-middle">CORREO ELECTRÓNICO</th>
                                                                                        <th className="align-middle">TIPO CONTACTO</th>
                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        contactos.map((itemContactos) => (
                                                                                            <tr key={itemContactos.id}>
                                                                                                <td>{itemContactos.nombre}</td>
                                                                                                <td>{itemContactos.cargo}</td>
                                                                                                <td>{itemContactos.email}</td>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (itemContactos.tipo_contacto) {
                                                                                                            case 'COMERCIAL':
                                                                                                                return (<td>COMERCIAL</td>)
                                                                                                            case 'CONTABLE':
                                                                                                                return (<td>CONTABLE</td>)
                                                                                                            case 'TESORERIA':
                                                                                                                return (<td>TESORERÍA</td>)
                                                                                                            case 'FACTURACION_ELECTRONICA':
                                                                                                                return (<td>FACTURACIÓN ELECTRÓNICA</td>)
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                                <td>
                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={() =>
                                                                                                                this.borrarRegistrosPersona('contacto', itemContactos.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                En esta sección necesitamos identificar los principales contactos de su organización para fines comerciales y regulatorios.
                                                                                            </p>
                                                                                            <p>
                                                                                                Para crear un nuevo registro de contacto presione el botón verde + Nuevo Contacto, seleccione el tipo de contacto y complete la información.
                                                                                            </p>
                                                                                            <p>
                                                                                                El sistema solicitará que este registrado por lo menos un (1) contacto de cada tipo.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tab-pane fade' id='nav-condiciones_compra'role="tabpanel" aria-labelledby="nav-condiciones_compra-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-4'>
                                                                            {/* <input type='hidden' id='seccion_guardada_cc' name='seccion_guardada_cc' defaultValue={(formulario.tipo_tercero === 2 || (formulario.tipo_tercero === 6 && formulario.tipo_sociedad !== 4) || formulario.tipo_tercero === 7) ? formularioDetalle.infoCompra.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_cc' name='seccion_guardada_cc' value={seccion_guardada_cc} />
                                                                            <label htmlFor="proveedor_compra_cc">Tipo Proveedor</label>
                                                                            <select name="proveedor_compra_cc"
                                                                                id="proveedor_compra_cc"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && (formulario.tipo_tercero === 2 || formulario.tipo_tercero === 6 || formulario.tipo_tercero === 7) ? formularioDetalle.infoCompra.proveedorCompra : ''}
                                                                                className={((this.verificarError("error_proveedor_compra_cc")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Proveedor</option>
                                                                                    <option value="BIENES">BIENES</option>
                                                                                    <option value="SERVICIOS">SERVICIOS</option>
                                                                                    <option value="BIENES_SERVICIOS">BIENES Y SERVICIOS</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Proveedor</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label htmlFor="bien_servicio_compra_cc">Bien - Servicio</label>
                                                                            <select name="bien_servicio_compra_cc"
                                                                                id="bien_servicio_compra_cc"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && (formulario.tipo_tercero === 2 || formulario.tipo_tercero === 6 || formulario.tipo_tercero === 7) ? formularioDetalle.infoCompra.bienServicioCompra : ''}
                                                                                className={((this.verificarError("error_bien_servicio_compra_cc")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Bien - Servicio</option>
                                                                                    {
                                                                                        bienes_servicios.map((itemBienServicio, index) => (
                                                                                            <option style={{ textTransform: 'uppercase' }}  key={index} value={itemBienServicio.clasificacion_ciiu.toString().substring(1, 5)}>{itemBienServicio.clasificacion_ciiu.toString().replace('**', '-').substring(1)}</option>
                                                                                        ))
                                                                                    }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Bien - Servicio</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="terminos_compra_cc">Términos Negociación</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="terminos_compra_cc" id="terminos_compra_cc" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && (formulario.tipo_tercero === 2 || formulario.tipo_tercero === 6 || formulario.tipo_tercero === 7) ? formularioDetalle.infoCompra.terminosCompra : ''}
                                                                                className={((this.verificarError("error_terminos_compra_cc")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Términos Negociación" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Términos Negociación</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Por favor indique en este apartado la información correspondiente a los términos comerciales de sus actividades con las empresas del grupo.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }} className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Los <b>Términos Negociación</b> se refiere a si para su potencial selección como Proveedor considera opcional si debemos tener en cuenta que va a
                                                                                                solicitar anticipo y en que porcentaje o si está dispuesto a lo que nosotros como contraparte consideremos y si espera facturación mensual o en
                                                                                                alguna forma periódica especifica o la que asignemos.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-7'>
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="moneda_compra_cc">Moneda Pago</label>
                                                                            <select name="moneda_compra_cc"
                                                                                id="moneda_compra_cc"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && (formulario.tipo_tercero === 2 || formulario.tipo_tercero === 6 || formulario.tipo_tercero === 7) ? formularioDetalle.infoCompra.monedaCompra : ''}
                                                                                className={((this.verificarError("error_moneda_compra_cc")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione la Moneda Pago</option>
                                                                                    <option value="PESOS">PESOS (COP)</option>
                                                                                    <option value="DOLARES">DOLARES (USD)</option>
                                                                                    <option value="EUROS">EUROS (EUR)</option>
                                                                                    <option value="FRANCOS">FRANCOS (CHF)</option>
                                                                                    <option value="LIBRAS_ESTERLINAS">LIBRAS ESTERLINAS (GBP)</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Moneda Pago</small>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="poliza_garantia_cc">Polizas o Garantías</label>
                                                                            <select name="poliza_garantia_cc"
                                                                                id="poliza_garantia_cc"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 && (formulario.tipo_tercero === 2 || formulario.tipo_tercero === 6 || formulario.tipo_tercero === 7) ? formularioDetalle.infoCompra.polizaCompra : ''}
                                                                                className={((this.verificarError("error_poliza_garantia_cc")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Polizas o Garantías</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Polizas o Garantías</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                <b>Pólizas o Garantía:</b> ¿Está dispuesto a adquirir las pólizas que le solicitemos?
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'condiciones_compra')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Compra</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tab-pane fade' id='nav-banco'role="tabpanel" aria-labelledby="nav-banco-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            {/* <input type='hidden' id='seccion_guardada_b' name='seccion_guardada_b' defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_b' name='seccion_guardada_b' value={seccion_guardada_b} />
                                                                            <label htmlFor="nombre_banco_b">Banco</label>
                                                                            <select name="nombre_banco_b"
                                                                                id="nombre_banco_b"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.nombreBanco : ''}
                                                                                className={((this.verificarError("error_nombre_banco_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Banco</option>
                                                                                    {
                                                                                        bancos.map((itemBanco) => (
                                                                                            itemBanco.sector === 'BANCOS' ?
                                                                                                <option style={{ textTransform: 'uppercase' }}  key={itemBanco.id} value={itemBanco.nombre_superfinanciera_entidad}>{itemBanco.nombre_superfinanciera_entidad}</option>
                                                                                            : ''
                                                                                        ))
                                                                                    }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Banco</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_cuenta_b">No. Cuenta</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_b" id="numero_cuenta_b" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.numeroCuentaBanco : ''}
                                                                                    className={((this.verificarError("error_numero_cuenta_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No. Cuenta" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el No. Cuenta</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_cuenta_b">Tipo Cuenta</label>
                                                                            <select name="tipo_cuenta_b"
                                                                                id="tipo_cuenta_b"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.tipoCuentaBanco : ''}
                                                                                className={((this.verificarError("error_tipo_cuenta_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Tipo Cuenta</option>
                                                                                    <option value="AHORROS">AHORROS</option>
                                                                                    <option value="CORRIENTE">CORRIENTE</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Cuenta</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="valido_desde_b">Valido Desde</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="valido_desde_b" id="valido_desde_b" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.validoDesdeBanco : ''}
                                                                                    className={((this.verificarError("error_valido_desde_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Valido Desde" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Valido Desde</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="valido_hasta_b">Valido Hasta</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="valido_hasta_b" id="valido_hasta_b" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.validoHastaBanco : ''}
                                                                                    className={((this.verificarError("error_valido_hasta_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Valido Hasta" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Valido Hasta</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div style={{ marginBottom: 0 }} className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Por favor complete la información correspondiente a la cuenta que desea registrar para sus actividades
                                                                                                comerciales con las empresas del grupo, tenga presente que debe incluir una certificación de la cuenta
                                                                                                con una vigencia no mayor a 30 días.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-7'>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="nombre_titular_b">Nombre Titular</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_titular_b" id="nombre_titular_b" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.nombreTitularBanco : ''}
                                                                                className={((this.verificarError("error_nombre_titular_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Titular" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Nombre Titular</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_documento_titular_b">Tipo Documento</label>
                                                                            <select name="tipo_documento_titular_b"
                                                                                id="tipo_documento_titular_b"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.tipoDocumentoBanco : ''}
                                                                                className={((this.verificarError("error_tipo_documento_titular_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    {(() => {
                                                                                        switch (formulario.tipo_tercero) {
                                                                                            case 1:
                                                                                            case 3:
                                                                                            case 4:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                                                        <option value="CC">C.C.</option>
                                                                                                        <option value="CE">C.E.</option>
                                                                                                        <option value="PP">P.P.</option>
                                                                                                        <option value="OTRO">OTRO</option>
                                                                                                    </>
                                                                                                )
                                                                                            case 2:
                                                                                            case 6:
                                                                                                return (<option value="NIT">NIT</option>)
                                                                                            default:
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                                                        <option value="CC">C.C.</option>
                                                                                                        <option value="NIT">NIT</option>
                                                                                                        <option value="CE">C.E.</option>
                                                                                                        <option value="PASAPORTE">PASAPORTE</option>
                                                                                                        <option value="TAX_ID">TAX ID.</option>
                                                                                                    </>
                                                                                                )
                                                                                        }
                                                                                    })()}
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="documento_titular_b">Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="documento_titular_b" id="documento_titular_b" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoBanco.numeroIdentificacionBanco : ''}
                                                                                    className={((this.verificarError("error_documento_titular_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Documento" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Documento</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5">

                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'banco')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Banco</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-impuestos" role="tabpanel" aria-labelledby="nav-impuestos-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            {/* <input type='hidden' id='seccion_guardada_i' name='seccion_guardada_i' defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_i' name='seccion_guardada_i' value={seccion_guardada_i} />
                                                                            <label htmlFor="calidad_contribuyente_i">Calidad Contribuyente - Régimen de I.V.A.</label>
                                                                            <select name="calidad_contribuyente_i"
                                                                                id="calidad_contribuyente_i"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.calidadContribuyente : ''}
                                                                                className={((this.verificarError("error_calidad_contribuyente_i")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione la Calidad Contribuyente</option>
                                                                                    <option value="RESPONSABLE_IVA">REPONSABLE DE I.V.A.</option>
                                                                                    <option value="NO_RESPONSABLE_IVA">NO RESPONSABLE DE I.V.A.</option>
                                                                                    <option value="REGIMEN_SIMPLE_TRIBUTACION">RÉGIMEN SIMPLE DE TRIBUTACIÓN</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Calidad Contribuyente</small>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="gran_contribuyente_i">Gran Contribuyente</label>
                                                                            <select name="gran_contribuyente_i"
                                                                                id="gran_contribuyente_i"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.granContribuyente : ''}
                                                                                className={((this.verificarError("error_gran_contribuyente_i")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Gran Contribuyente</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Gran Contribuyente</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            <label htmlFor="regimen_renta_i">Régimen Renta</label>
                                                                            <select name="regimen_renta_i"
                                                                                id="regimen_renta_i"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.regimenRenta : ''}
                                                                                className={((this.verificarError("error_regimen_renta_i")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Régimen Renta</option>
                                                                                    <option value="DECLARANTE_RENTA">DECLARANTE DE RENTA</option>
                                                                                    <option value="NO_DECLARANTE_RENTA">NO DECLARANTE DE RENTA</option>
                                                                                    <option value="REGIMEN_ESPECIAL_ESAL">RÉGIMEN ESPECIAL (E.S.A.L)</option>
                                                                                    <option value="REGIMEN_SIMPLE">RÉGIMEN SIMPLE</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Régimen Renta</small>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="usuario_zona_franca_i">Usuario Zona Franca</label>
                                                                            <select name="usuario_zona_franca_i"
                                                                                id="usuario_zona_franca_i"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.usuarioZonaFranca : ''}
                                                                                className={((this.verificarError("error_usuario_zona_franca_i")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Usuario Zona Franca</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Usuario Zona Franca</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div style={{ marginBottom: 0 }} className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Por favor complete a continuación la información para su clasificación tributaria de acuerdo con las normas Colombianas 
                                                                                            </p>
                                                                                            <p>
                                                                                                Por favor incluya las direcciones de contacto para fines de facturación electrónica
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-7'>
                                                                    <div className="row g-3">
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="retencion_autoretenedor_i">Autoretenedor</label>
                                                                            <select name="retencion_autoretenedor_i"
                                                                                id="retencion_autoretenedor_i"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.autoRetenedor : ''}
                                                                                className={((this.verificarError("error_retencion_autoretenedor_i")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarResolucion(e);
                                                                                }} >
                                                                                    <option value="">Seleccione Autoretenedor</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Autoretenedor</small>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        activar_resolucion ?
                                                                            <>
                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="retencion_resolucion_i">Resolución del Autoretenedor</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="retencion_resolucion_i" id="retencion_resolucion_i" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.resolucionRetencion : ''}
                                                                                            className={((this.verificarError("error_retencion_resolucion_i")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Resolución" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite la Resolución del Autoretenedor</small>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor="retencion_fecha_i">Fecha de Resolución</label>
                                                                                        <div className="input-group input-group-sm">
                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                            <input style={{ textTransform: 'uppercase' }} type="date" name="retencion_fecha_i" id="retencion_fecha_i" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.fechaRetencion : ''}
                                                                                                className={((this.verificarError("error_retencion_fecha")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha" aria-describedby="helpId"
                                                                                                onChange={this.cambioValor} />
                                                                                        </div>
                                                                                        <small id="helpId" className="invalid-feedback">Seleccione la Fecha de Resolución</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                    <div className="col-md-10">
                                                                                        <label htmlFor="retencion_concepto_i">Concepto de Retención/Autoretención</label>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="retencion_concepto_i" id="retencion_concepto_i" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.conceptoRetencion : ''}
                                                                                            className={((this.verificarError("error_retencion_concepto_i")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Concepto" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} max="37" />
                                                                                        <small id="helpId" className="invalid-feedback">Digite el Concepto de Retención/Autoretención</small>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        : ''
                                                                    }
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="retencion_condicion_i">¿Alguna condición especial, para la que NO se deba aplicar retención en la fuente de renta?</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="retencion_condicion_i" id="retencion_condicion_i" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.condicionRetencion : ''}
                                                                                className={((this.verificarError("error_retencion_condicion_i")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Condición" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Condición Especial</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-7'>
                                                                    <div className="row g-3">
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="retencion_factura_electronica_i">Factura Electrónica</label>
                                                                            <select name="retencion_factura_electronica_i"
                                                                                id="retencion_factura_electronica_i"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.facturaElectronica : ''}
                                                                                className={((this.verificarError("error_retencion_factura_electronica_i")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Factura Electrónica</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Factura Electrónica</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="retencion_correo_electronico_envio_i">Correo Elec. Envío Facturación Electrónica</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="retencion_correo_electronico_envio_i" id="retencion_correo_electronico_envio_i" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.emailEnvio : ''}
                                                                                    className={((this.verificarError("error_retencion_correo_electronico_envio_i")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Envío" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Elec. Envío Facturación Electrónica</small>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="retencion_correo_electronico_rechazo_i">Correo Elec. Rechazo Facturación Electrónica</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="retencion_correo_electronico_rechazo_i" id="retencion_correo_electronico_rechazo_i" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoImpuestos.emailRechazo : ''}
                                                                                    className={((this.verificarError("error_retencion_correo_electronico_rechazo_i")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Rechazo" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Elec. Rechazo Facturación Electrónica</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>

                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'impuestos')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Impuestos</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-financiera" role="tabpanel" aria-labelledby="nav-financiera-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className="col-md-4">
                                                                            {/* <input type='hidden' id='seccion_guardada_f' name='seccion_guardada_f' defaultValue={formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_f' name='seccion_guardada_f' value={seccion_guardada_f} />
                                                                            <label htmlFor="fecha_reporte_financiera_f">Fecha Reporte</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_reporte_financiera_f" id="fecha_reporte_financiera_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.fechaReporte : ''}
                                                                                    className={((this.verificarError("error_fecha_reporte_financiera_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Reporte" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Reporte</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>

                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-7'>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="origen_ingresos_f">Origen Ingresos</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="origen_ingresos_f" id="origen_ingresos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.origenIngresos : ''}
                                                                                className={((this.verificarError("error_origen_ingresos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Origen Ingresos" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Origen Ingresos</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="total_activos_f">Total Activos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="total_activos_f" id="total_activos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.totalActivos : ''}
                                                                                    className={((this.verificarError("error_total_activos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Total Activos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Total Activos</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="total_pasivos_f">Total Pasivos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="total_pasivos_f" id="total_pasivos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.totalPasivos : ''}
                                                                                    className={((this.verificarError("error_total_pasivos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Total Pasivos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Total Pasivos</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="patrimonio_f">Patrimonio</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="patrimonio_f" id="patrimonio_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.totalPatrimonio : ''}
                                                                                    className={((this.verificarError("error_patrimonio_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Patrimonio" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Patrimonio</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="ingresos_f">Ingresos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="ingresos_f" id="ingresos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.totalIngresos : ''}
                                                                                    className={((this.verificarError("error_ingresos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Ingresos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite los Ingresos</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="egresos_f">Egresos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="egresos_f" id="egresos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.totalEgresos : ''}
                                                                                    className={((this.verificarError("error_egresos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Egresos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite los Egresos</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="otros_ingresos_f">Otros Ingresos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="otros_ingresos_f" id="otros_ingresos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.totalOtrosIngresos : ''}
                                                                                    className={((this.verificarError("error_otros_ingresos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Ingresos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Ingresos</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="origen_otros_ingresos_f">Origen Otros Ingresos</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="origen_otros_ingresos_f" id="origen_otros_ingresos_f" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero !== 5 ? formularioDetalle.infoFinanciera.origenOtrosIngresos : ''}
                                                                                className={((this.verificarError("error_origen_otros_ingresos_f")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Origen Otros Ingresos" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Origen Otros Ingresos</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Por favor indique a continuación la información financiera de acuerdo con su último reporte oficial (estados financieros).
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'financiera')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Financiera</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-representantes" role="tabpanel" aria-labelledby="nav-representantes-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-12'>
                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoRepresentanteModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Representante</button>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                <thead className="thead-inverse">
                                                                                    <tr>
                                                                                        <th className="align-middle">NOMBRE</th>
                                                                                        {/* <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                        <th className="align-middle">IDENTIFICACIÓN</th> */}
                                                                                        <th className="align-middle">TIPO ROL</th>
                                                                                        {/* <th className="align-middle">P.E.P.</th> */}
                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        representantes.map((itemRepresentantes) => (
                                                                                            <tr key={itemRepresentantes.id}>
                                                                                                <td>{itemRepresentantes.nombre}</td>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (itemRepresentantes.tipo_rol) {
                                                                                                            case 'ACCIONISTA':
                                                                                                                return (<td>ACCIONISTA</td>)
                                                                                                            case 'MIEMBRO_JUNTA_DIRECTIVA':
                                                                                                                return (<td>MIEMBRO JUNTA DIRECTIVA</td>)
                                                                                                            case 'REPRESENTANTE_LEGAL':
                                                                                                                return (<td>REPRESENTANTE LEGAL</td>)
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                                <td>
                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-primary btn-sm"
                                                                                                            data-bs-toggle='modal'
                                                                                                            data-bs-target="#EditarRepresentanteModal"
                                                                                                            slot='end'
                                                                                                            onClick={() =>
                                                                                                                this.cargarDatosEditar('representante', itemRepresentantes.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                        </button>&nbsp;&nbsp;
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={() =>
                                                                                                                this.borrarRegistrosPersona('representante', itemRepresentantes.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Por favor relacione en este apartado el o los representantes legales, socios, accionistas y/o miembros de junta directiva indicando si la persona se considera Persona Expuesta Políticamente - PEP
                                                                                            </p>
                                                                                            <p>
                                                                                                Para los socios o accionistas es necesario indicar su porcentaje de participación siempre que corresponda a por lo menos un 5% del capital social, aporte o participación.
                                                                                            </p>
                                                                                            <p>
                                                                                                El sistema solicitará que este registrado por lo menos un (1) representante legal y un accionista o socio.
                                                                                            </p>
                                                                                            <p>
                                                                                                Se puede ingresar varias veces para crear todos los representantes necesarios (Accionista, Miembro Junta Directiva y Representante Legal)
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-declaracion_transp_pn" role="tabpanel" aria-labelledby="nav-declaracion_transp_pn-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-10'>
                                                                            {/* <input type='hidden' id='seccion_guardada_pn' name='seccion_guardada_pn' defaultValue={formulario.tipo_tercero === 7 ? formularioDetalle.declaracionPN.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_pn' name='seccion_guardada_pn' value={seccion_guardada_pn} />
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta1_pn">1. Que mis recursos provienen de actividades Lícitas y están ligados al desarrollo normal de mis actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta1_pn"
                                                                                id="pregunta1_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta1 : ''}
                                                                                className={((this.verificarError("error_pregunta1_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta1_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 1</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 1</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta2_pn">2. Que yo no he efectuado transacciones u operaciones consistentes o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                            <select name="pregunta2_pn"
                                                                                id="pregunta2_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta2 : ''}
                                                                                className={((this.verificarError("error_pregunta2_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta2_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 2</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 2</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta3_pn">3. Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado con TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta3_pn"
                                                                                id="pregunta3_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta3 : ''}
                                                                                className={((this.verificarError("error_pregunta3_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta3_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 3</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 3</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta4_pn">4. Que en la ejecución del contrato o el desarrollo de las actividades en que consiste el negocio jurídico o vínculo legal con TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta4_pn"
                                                                                id="pregunta4_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta4 : ''}
                                                                                className={((this.verificarError("error_pregunta4_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta4_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 4</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 4</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta5_pn">5. Que yo no me encuentro en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, facultada para efectuar las verificaciones que considere pertinentes y para dar por terminada cualquier relación comercial o jurídica si verifica que figuro en dichas listas.</label>
                                                                            <select name="pregunta5_pn"
                                                                                id="pregunta5_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta5 : ''}
                                                                                className={((this.verificarError("error_pregunta5_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta5_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 5</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 5</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta6_pn">6. Que no existe contra mi investigaciones o procesos penales por delitos relacionados con el LA, FT y/o contrabando, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o tengo investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, frente a un riesgo legal, de contagio o de reputación en LA/FT.</label>
                                                                            <select name="pregunta6_pn"
                                                                                id="pregunta6_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta6 : ''}
                                                                                className={((this.verificarError("error_pregunta6_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta6_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 6</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 6</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta7_pn">7. Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP.</label>
                                                                            <select name="pregunta7_pn"
                                                                                id="pregunta7_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta7 : ''}
                                                                                className={((this.verificarError("error_pregunta7_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta7_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 7</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 7</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta8_pn">8. Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con TERMOMORICHAL S.A.S. es veraz y exacta, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, facultada para efectuar las verificaciones que considere pertinentes y para dar por terminada cualquier relación comercial o jurídica si dicha información no puede ser confirmada o sea inexacta.</label>
                                                                            <select name="pregunta8_pn"
                                                                                id="pregunta8_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta8 : ''}
                                                                                className={((this.verificarError("error_pregunta8_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta8_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 8</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 8</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta9_pn">9. Que ninguna otra persona natural o jurídica, tiene interés no legitimo en el contrato o negocio jurídico que motiva la suscripción de la presente declaración.</label>
                                                                            <select name="pregunta9_pn"
                                                                                id="pregunta9_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta9 : ''}
                                                                                className={((this.verificarError("error_pregunta9_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta9_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 9</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 9</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta10_pn">10. Que conozco, declaro y acepto que TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, está en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, pueda tener dudas razonables sobre mis operaciones, así como el origen de mis activos, evento en el cual suministrare las aclaraciones que sean del caso. Si estas no son satisfactorias, a juicio de TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, la autorizo para dar por terminada cualquier relación comercial o jurídica.</label>
                                                                            <select name="pregunta10_pn"
                                                                                id="pregunta10_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta10 : ''}
                                                                                className={((this.verificarError("error_pregunta10_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta10_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 10</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 10</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta11_pn">11. Que, con el diligenciamiento del presente documento, se entiende que yo como persona natural, otorgo consentimiento informado, y por lo tanto autorizo a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, para que verifique la información suministrada y en caso de ser requerida por autoridad nacional o internacional, y suministre toda la información personal, publica, privada o semiprivada que sobre mi ellas requieran.</label>
                                                                            <select name="pregunta11_pn"
                                                                                id="pregunta11_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta11 : ''}
                                                                                className={((this.verificarError("error_pregunta11_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta11_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 11</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 11</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta12_pn">12. Declaro que he sido informado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, acerca de mi obligación de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conozco las consecuencias de infringirlo.</label>
                                                                            <select name="pregunta12_pn"
                                                                                id="pregunta12_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta12 : ''}
                                                                                className={((this.verificarError("error_pregunta12_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta12_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 12</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 12</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta13_pn">13. Que en el caso de ser considerado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autorizo para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumplo con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                            <select name="pregunta13_pn"
                                                                                id="pregunta13_pn"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && (formulario.tipo_tercero !== 5 && formulario.tipo_tercero !== 6) ? formularioDetalle.declaracionPN.pregunta13 : ''}
                                                                                className={((this.verificarError("error_pregunta13_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_natural', 'pregunta13_pn')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 13</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 13</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                En cumplimiento del Manual del Sistema de Control Interno, adoptado por TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.C.A. E.S.P. y de lo señalado en el Capítulo X de la Circular Externa 100-000006, expedida por la Superintendencia de Sociedades, el suscrito representante legal de manera voluntaria y bajo la gravedad de juramento, certifico que la información aqui declarada es cierta.
                                                                                            </p>
                                                                                            <p>
                                                                                                Por favor tenga en cuenta que la no conformidad representa un punto de control para el sistema de control interno por lo cual se dentendrá el proceso.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'declaracion_transp_pn')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Transparencia P.N.</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-declaracion_transp_pj" role="tabpanel" aria-labelledby="nav-declaracion_transp_pj-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-10'>
                                                                            {/* <input type='hidden' id='seccion_guardada_pj' name='seccion_guardada_pj' defaultValue={formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_pj' name='seccion_guardada_pj' value={seccion_guardada_pj} />
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta1_pj">1. Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta1_pj"
                                                                                id="pregunta1_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta1 : ''}
                                                                                className={((this.verificarError("error_pregunta1_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta1_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 1</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 1</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta2_pj">2. Que yo o la persona jurídica que represento no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                            <select name="pregunta2_pj"
                                                                                id="pregunta2_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta2 : ''}
                                                                                className={((this.verificarError("error_pregunta2_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta2_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 2</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 2</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta3_pj">3. Que los recursos comprometidos para el pago de la ejecución del contrato o negocio jurídico acordado con TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S..A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta3_pj"
                                                                                id="pregunta3_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta3 : ''}
                                                                                className={((this.verificarError("error_pregunta3_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta3_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 3</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 3</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta4_pj">4. Que no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta4_pj"
                                                                                id="pregunta4_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta4 : ''}
                                                                                className={((this.verificarError("error_pregunta4_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta4_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 4</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 4</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta5_pj">5. Que la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo (LA/FT/FPADM) que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                            <select name="pregunta5_pj"
                                                                                id="pregunta5_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta5 : ''}
                                                                                className={((this.verificarError("error_pregunta5_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta5_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 5</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 5</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta6_pj">6. Que yo, ni la entidad que represento, ni sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, se encuentran en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas figura en dichas listas.</label>
                                                                            <select name="pregunta6_pj"
                                                                                id="pregunta6_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta6 : ''}
                                                                                className={((this.verificarError("error_pregunta6_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta6_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 6</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 6</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta7_pj">7. Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA, FT, FPADM, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                            <select name="pregunta7_pj"
                                                                                id="pregunta7_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta7 : ''}
                                                                                className={((this.verificarError("error_pregunta7_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta7_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 7</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 7</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta8_pj">8. Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS.</label>
                                                                            <select name="pregunta8_pj"
                                                                                id="pregunta8_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta8 : ''}
                                                                                className={((this.verificarError("error_pregunta8_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta8_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 8</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 8</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta9_pj">9. Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con TERMOMORICHAL S.A.S. es veraz y exacta, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que ello no es así.</label>
                                                                            <select name="pregunta9_pj"
                                                                                id="pregunta9_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta9 : ''}
                                                                                className={((this.verificarError("error_pregunta9_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta9_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 9</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 9</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta10_pj">10. Que ninguna otra persona natural o jurídica, tiene interés no legitimo en el contrato o negocio jurídico que motiva la suscripción de la presente declaración.</label>
                                                                            <select name="pregunta10_pj"
                                                                                id="pregunta10_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta10 : ''}
                                                                                className={((this.verificarError("error_pregunta10_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta10_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 10</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 10</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta11_pj">11. Que conozco, declaro y acepto que TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS está en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS pueda tener dudas razonables sobre mis operaciones o las operaciones de la persona jurídica que represento, así como el origen de nuestros activos, evento en el cual suministraremos las aclaraciones que sean del caso. Si estas no son satisfactorias, a juicio de TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, la autorizamos para dar por terminada cualquier relación comercial o jurídica.</label>
                                                                            <select name="pregunta11_pj"
                                                                                id="pregunta11_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta11 : ''}
                                                                                className={((this.verificarError("error_pregunta11_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta11_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 11</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 11</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta12_pj">12. Que con la firma del presente documento, se entiende que como persona jurídica, otorgo consentimiento informado, y por lo tanto autorizo a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS para que verifique la información suministrada y en caso de ser requerida por autoridad nacional o internacional, y suministre toda la información personal, publica, privada o semiprivada que se requiera.</label>
                                                                            <select name="pregunta12_pj"
                                                                                id="pregunta12_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta12 : ''}
                                                                                className={((this.verificarError("error_pregunta12_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta12_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 12</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 12</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta13_pj">13. La persona Jurídica declara que ha sido informada por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                            <select name="pregunta13_pj"
                                                                                id="pregunta13_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta13 : ''}
                                                                                className={((this.verificarError("error_pregunta13_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta13_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 13</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 13</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta14_pj">14. Que en el caso que la Persona Jurídica que represento sea considerado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                            <select name="pregunta14_pj"
                                                                                id="pregunta14_pj"
                                                                                defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 6 ? formularioDetalle.declaracionPJ.pregunta14 : ''}
                                                                                className={((this.verificarError("error_pregunta14_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta14_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 14</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 14</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                En cumplimiento del Manual del Sistema de Control Interno, adoptado por TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.C.A. E.S.P. y de lo señalado en el Capítulo X de la Circular Externa 100-000006, expedida por la Superintendencia de Sociedades, el suscrito representante legal de manera voluntaria y bajo la gravedad de juramento, certifico que la información aqui declarada es cierta.
                                                                                            </p>
                                                                                            <p>
                                                                                                Por favor tenga en cuenta que la no conformidad representa un punto de control para el sistema de control interno por lo cual se dentendrá el proceso.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'declaracion_transp_pj')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Transparencia P.J.</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-pep" role="tabpanel" aria-labelledby="nav-pep-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="tipo_relacion_pep_p">Tipo Relación</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="tipo_relacion_pep_p" id="tipo_relacion_pep_p" defaultValue={formulario.tipo_relacion_pep_p}
                                                                                className={((this.verificarError("error_tipo_relacion_pep_p")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Tipo Relación" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Tipo Relación</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="documento_pep_p">Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="documento_pep_p" id="documento_pep_p" defaultValue={formulario.documento_pep_p}
                                                                                    className={((this.verificarError("error_documento_pep_p")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Documento" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Documento</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="declaracion_veracidad_pep_p">Declaración Veracidad</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="declaracion_veracidad_pep_p" id="declaracion_veracidad_pep_p" defaultValue={formulario.declaracion_veracidad_pep_p}
                                                                                className={((this.verificarError("error_declaracion_veracidad_pep_p")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Declaración Veracidad" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Declaración Veracidad</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Texto de ayuda acá.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={formulario.tipo_sociedad === 4 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-datos_generales_esal" role="tabpanel" aria-labelledby="nav-datos_generales_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_formulario">Tipo Formulario</label>
                                                                            <select name="tipo_formulario"
                                                                                id="tipo_formulario"
                                                                                value={formulario.primera_vez}
                                                                                options={formulario.primera_vez}
                                                                                className={((this.verificarError("error_tipo_formulario")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Seleccione el Tipo Formulario</option>
                                                                                    <option value="1">PRIMERA VEZ</option>
                                                                                    <option value="2">ACTUALIZACIÓN</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Formulario</small>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <label htmlFor="tipo_tercero">Tipo Tercero</label>
                                                                            <select name="tipo_tercero"
                                                                                id="tipo_tercero"
                                                                                value={formulario.tipo_tercero}
                                                                                options={formulario.tipo_tercero}
                                                                                className={((this.verificarError("error_tipo_tercero")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Seleccione el Tipo Tercero</option>
                                                                                    <option value="1">ACCIONISTA</option>
                                                                                    <option value="2">CLIENTE</option>
                                                                                    <option value="3">EMPLEADO</option>
                                                                                    <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                                    <option value="5">PROVEEDOR EXTERIOR</option>
                                                                                    <option value="6">PROVEEDOR NACIONAL PERSONA JURÍDICA</option>
                                                                                    <option value="7">PROVEEDOR NACIONAL PERSONA NATURAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Tercero</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0}} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label htmlFor="tipo_sociedad">Tipo Sociedad</label>
                                                                            <select name="tipo_sociedad"
                                                                                id="tipo_sociedad"
                                                                                value={formulario.tipo_sociedad}
                                                                                options={formulario.tipo_sociedad}
                                                                                className={((this.verificarError("error_tipo_sociedad")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Seleccione el Tipo Sociedad</option>
                                                                                    <option value="1">SOCIEDAD POR ACCIONES SIMPLIFICADAS (S.A.S.)</option>
                                                                                    <option value="2">SOCIEDAD ANÓNIMA (S.A.)</option>
                                                                                    <option value="3">SOCIEDAD LIMITADA (LTDA.)</option>
                                                                                    <option value="4">ENTIDAD SIN ÁNIMO DE LUCRO (E.S.A.L.)</option>
                                                                                    <option value="5">SUCURSAL DE SOCIEDAD EXTRANJERA (S.S.E.)</option>
                                                                                    <option value="6">SOCIEDAD COLECTIVA (S.C.)</option>
                                                                                    <option value="7">SOCIEDAD EN COMANDITA POR ACCIONES (S.C.A)</option>
                                                                                    <option value="8">SOCIEDAD EN COMANDITA SIMPLE (S.C.S.)</option>
                                                                                    <option value="9">EMPRESA UNIPERSONAL</option>
                                                                                    <option value="10">SOCIEDAD ASOCIATIVA DE TRABAJO (S.A.T.)</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Sociedad</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="ciudad_esal_dg">Ciudad</label>
                                                                            {/* <input type='hidden' id='seccion_guardada_esal_dg' name='seccion_guardada_esal_dg' defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_esal_dg' name='seccion_guardada_esal_dg' value={seccion_guardada_esal_dg} />
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="ciudad_esal_dg" id="ciudad_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.ciudadESAL : ''}
                                                                                className={((this.verificarError("error_ciudad_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Ciudad" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Ciudad</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="razon_social_esal_dg">Razón Social</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_esal_dg" id="razon_social_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.razonSocialESAL : ''}
                                                                                className={((this.verificarError("error_razon_social_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Razón Social</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="tipo_persona_esal_dg">Tipo Persona</label>
                                                                            <select name="tipo_persona_esal_dg"
                                                                                id="tipo_persona_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.tipoPersonaESAL : ''}
                                                                                className={((this.verificarError("error_tipo_persona_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Tipo Persona</option>
                                                                                    <option value="PERSONA_NATURAL">PERSONA NATURAL</option>
                                                                                    <option value="PERSONA_JURIDICA">PERSONA JURÍDICA</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Persona</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-2'>
                                                                            <label htmlFor="tipo_documento_esal_dg">Tipo Documento</label>
                                                                            <select name="tipo_documento_esal_dg"
                                                                                id="tipo_documento_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.tipoDocumentoESAL : ''}
                                                                                className={((this.verificarError("error_tipo_documento_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Tipo Documento</option>
                                                                                    <option value="CC">C.C.</option>
                                                                                    <option value="CE">C.E.</option>
                                                                                    <option value="NIT">NIT</option>
                                                                                    <option value="PP">P.P.</option>
                                                                                    <option value="OTRO">OTRO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="numero_documento_esal_dg">Número Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_esal_dg" id="numero_documento_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.numeroDocumentoESAL : ''}
                                                                                    className={((this.verificarError("error_numero_documento_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <label htmlFor="digito_verificacion_esal_dg">DV</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="digito_verificacion_esal_dg" id="digito_verificacion_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.digitoVerificacionESAL : ''}
                                                                                    className={((this.verificarError("error_digito_verificacion_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="fecha_constitucion_esal_dg">Fecha Constitución</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_constitucion_esal_dg" id="fecha_constitucion_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.fechaConstitucionESAL : ''}
                                                                                    className={((this.verificarError("error_fecha_constitucion_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Constitución" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Constitución</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="pais_procedencia_esal_dg">País Procedencia</label>
                                                                            <select name="pais_procedencia_esal_dg"
                                                                                id="pais_procedencia_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.paisProcedenciaESAL : ''}
                                                                                className={((this.verificarError("error_pais_procedencia_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el País Procedencia</option>
                                                                                {
                                                                                    paises_esal_dg.map((itemPaisesESALDG) => (
                                                                                        <option style={{ textTransform: 'uppercase' }} key={itemPaisesESALDG.code} value={itemPaisesESALDG.name}>{itemPaisesESALDG.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el País Procedencia</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-3'>
                                                                            <label htmlFor="departamento_esal_dg">Departamento</label>
                                                                            <select name="departamento_esal_dg"
                                                                                id="departamento_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.departamentoESAL : ''}
                                                                                className={((this.verificarError("error_departamento_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={e => {
                                                                                    this.cargarMunicipios("esal");
                                                                                    this.cambioValor(e)
                                                                                }}>
                                                                                <option value="">Seleccione el Departamento</option>
                                                                                {
                                                                                    departamentos_esal_dg.map((itemDepartamentosESALDG) => (
                                                                                        <option style={{ textTransform: 'uppercase' }} key={itemDepartamentosESALDG.id_departamento} value={itemDepartamentosESALDG.nombre}>{itemDepartamentosESALDG.nombre}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                                        </div>
                                                                        <div className='col-md-3'>
                                                                            <label htmlFor="municipio_esal_dg">Municipio</label>
                                                                            <select name="municipio_esal_dg"
                                                                                id="municipio_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.municipioESAL : ''}
                                                                                className={((this.verificarError("error_municipio_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                <option value="">Seleccione el Municipio</option>
                                                                                {
                                                                                    municipios_esal_dg.map((itemMunicipiosESALDG) => (
                                                                                        <option style={{ textTransform: 'uppercase' }}  key={itemMunicipiosESALDG.id_ciudad} value={itemMunicipiosESALDG.ciudad}>{itemMunicipiosESALDG.ciudad}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="direccion_empresa_principal_esal_dg">Dirección Principal</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa_principal_esal_dg" id="direccion_empresa_principal_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.direccionEmpresaESAL : ''}
                                                                                className={((this.verificarError("error_direccion_empresa_principal_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Dirección Principal" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Dirección Principal</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="telefono_empresa_esal_dg">Teléfono</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_empresa_esal_dg" id="telefono_empresa_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.telefonoEmpresaESAL : ''}
                                                                                    className={((this.verificarError("error_telefono_empresa_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono</small>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <label htmlFor="extension_telefono_empresa_esal_dg">Extensión</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="extension_telefono_empresa_esal_dg" id="extension_telefono_empresa_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.extensionTelefonoEmpresaESAL : ''}
                                                                                    className={((this.verificarError("error_extension_telefono_empresa_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Extensión" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite la Extensión</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="pagina_web_empresa_esal_dg">Página Web</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faGlobe} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="pagina_web_empresa_esal_dg" id="pagina_web_empresa_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.websiteEmpresaESAL : ''}
                                                                                    className={((this.verificarError("error_pagina_web_empresa_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Página Web" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite la Página Web</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-2'>
                                                                            <label htmlFor="factura_electronica_retencion_esal_dg">Factura Electrónica</label>
                                                                            <select name="factura_electronica_retencion_esal_dg"
                                                                                id="factura_electronica_retencion_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.facturaElectronicaESAL : ''}
                                                                                className={((this.verificarError("error_factura_electronica_retencion_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Factura Electrónica</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Factura Electrónica</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="correo_electronico_envio_retencion_esal_dg">Correo Electrónico Envío</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_envio_retencion_esal_dg" id="correo_electronico_envio_retencion_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.emailEnvioESAL : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_envio_retencion_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Envío" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Envío</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="correo_electronico_rechazo_retencion_esal_dg">Correo Electrónico Rechazo</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_rechazo_retencion_esal_dg" id="correo_electronico_rechazo_retencion_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.emailRechazoESAL : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_rechazo_retencion_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Rechazo" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Rechazo</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="contacto_contabilidad_esal_dg">Contacto Contabilidad</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="contacto_contabilidad_esal_dg" id="contacto_contabilidad_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.contactoContabilidadESAL : ''}
                                                                                className={((this.verificarError("error_contacto_contabilidad_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Contabilidad" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Contacto Contabilidad</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="correo_electronico_contacto_contabilidad_esal_dg">Correo Electrónico Contacto Cont.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_contacto_contabilidad_esal_dg" id="correo_electronico_contacto_contabilidad_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.emailContactoContabilidadESAL : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_contacto_contabilidad_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Contacto Cont." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Contacto Cont.</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="descripcion_bienes_servicios_esal_dg">Descripción Bienes y Servicios</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="descripcion_bienes_servicios_esal_dg" id="descripcion_bienes_servicios_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.bienesServiciosESAL : ''}
                                                                                className={((this.verificarError("error_descripcion_bienes_servicios_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Descripción Bienes y Servicios" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite la Descripción Bienes y Servicios</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="terminos_negociacion_esal_dg">Términos Negociación</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="terminos_negociacion_esal_dg" id="terminos_negociacion_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.terminosNegociacionESAL : ''}
                                                                                className={((this.verificarError("error_terminos_negociacion_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Términos Negociación" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Términos Negociación</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="plazo_pago_facturas_esal_dg">Plazo Pago Factura</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="plazo_pago_facturas_esal_dg" id="plazo_pago_facturas_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.plazoPagoFacturaESAL : ''}
                                                                                className={((this.verificarError("error_plazo_pago_facturas_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Plazo Pago Factura" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Plazo Pago Factura</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="contacto_proveedor_esal_dg">Contacto Proveedor</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="contacto_proveedor_esal_dg" id="contacto_proveedor_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.contactoProveedorESAL : ''}
                                                                                className={((this.verificarError("error_contacto_proveedor_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Proveedor" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Contacto Proveedor</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="correo_electronico_contacto_proveedor_esal_dg">Correo Electrónico Contacto Prov.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_contacto_proveedor_esal_dg" id="correo_electronico_contacto_proveedor_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.emailContactoProveedorESAL : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_contacto_proveedor_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Contacto Prov." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Contacto Prov.</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="telefono_contacto_proveedor_esal_dg">Teléfono Contacto Prov.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_contacto_proveedor_esal_dg" id="telefono_contacto_proveedor_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.telefonoContactoProveedorESAL : ''}
                                                                                    className={((this.verificarError("error_telefono_contacto_proveedor_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Contacto Prov." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono Contacto Prov.</small>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <label htmlFor="extension_telefono_contacto_proveedor_esal_dg">Extensión</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="extension_telefono_contacto_proveedor_esal_dg" id="extension_telefono_contacto_proveedor_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.extensionContactoProveedorESAL : ''}
                                                                                    className={((this.verificarError("error_extension_telefono_contacto_proveedor_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Extensión Contacto Prov." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite la Extensión</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="celular1_contacto_proveedor_esal_dg">Celular 1 Contacto Prov.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="celular1_contacto_proveedor_esal_dg" id="celular1_contacto_proveedor_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.celular1ProveedorESAL : ''}
                                                                                    className={((this.verificarError("error_celular1_contacto_proveedor_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Celular 1 Contacto Prov." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Celular 1 Contacto Prov.</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="celular2_contacto_proveedor_esal_dg">Celular 2 Contacto Prov.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="celular2_contacto_proveedor_esal_dg" id="celular2_contacto_proveedor_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.celular2ProveedorESAL : ''}
                                                                                    className={((this.verificarError("error_celular2_contacto_proveedor_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Celular 2 Contacto Prov." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Celular 2 Contacto Prov.</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-2'>
                                                                            <label htmlFor="poliza_garantia_esal_dg">Polizas o Garantías</label>
                                                                            <select name="poliza_garantia_esal_dg"
                                                                                id="poliza_garantia_esal_dg"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.polizaGarantiaESAL : ''}
                                                                                className={((this.verificarError("error_poliza_garantia_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Polizas o Garantías</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Polizas o Garantías</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-xl-12'>
                                                                            {/* <hr style={{ marginBottom: 0 }} /> */}
                                                                            <div style={{ width: '100%', height: 20, borderBottom: '1px solid #DEE2E6', textAlign: 'center'}}>
                                                                                <span style={{ fontSize: 23, backgroundColor: 'white', padding: '0 10px'}}>
                                                                                    Gestión de Riesgo
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 15 }} className="row">
                                                                        <div className="col-xl-7">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-10'>
                                                                                    <label htmlFor="programa_gestion_riesgo_esal_dg">La persona natural, jurídica o similiar que usted representa, está obligada a tener</label>
                                                                                    <select name="programa_gestion_riesgo_esal_dg"
                                                                                        id="programa_gestion_riesgo_esal_dg"
                                                                                        defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.programaGestionRiesgoESAL : ''}
                                                                                        className={((this.verificarError("error_programa_gestion_riesgo_esal_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarOtrosProgramasEsal(e);
                                                                                        }}>
                                                                                            <option value="">Seleccione el Programa</option>
                                                                                            <option value="SAGRILAFT_PTEE">SAGRILAFT Y PTEE O SU EQUIVALENTE</option>
                                                                                            <option value="REGIMEN_MEDIDAS_MINIMAS">RÉGIMEN DE MEDIDAS MÍNIMAS O SU EQUIVALENTE</option>
                                                                                            <option value="NO_OBLIGADA">NO ESTÁ OBLIGADA A TENER ESTOS PROGRAMAS</option>
                                                                                            <option value="OTROS_PROGRAMAS">OTROS</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Programa</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_otros_programas_esal ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-10">
                                                                                            <label htmlFor="otros_programa_gestion_riesgo_esal_dg">Otros Programas</label>
                                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="otros_programa_gestion_riesgo_esal_dg" id="otros_programa_gestion_riesgo_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.otrosProgramaGestionRiesgoESAL : ''}
                                                                                                className={((this.verificarError("error_otros_programa_gestion_riesgo_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Programas" aria-describedby="helpId"
                                                                                                onChange={this.cambioValor} />
                                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Programas</small>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                                <div className="col-md-10">
                                                                                    <label htmlFor="detalle_gestion_riesgo_esal_dg">Detalle (Opcional)</label>
                                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="detalle_gestion_riesgo_esal_dg" id="detalle_gestion_riesgo_esal_dg" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoDatosGenerales.detalleProgramaGestionRiesgoESAL : ''}
                                                                                        className={((this.verificarError("error_detalle_gestion_riesgo_esal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                                        onChange={this.cambioValor} />
                                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-5"></div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-xl-12'>
                                                                        <hr style={{ marginBottom: 0 }} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 15 }} className="row">
                                                                    <div className="col-xl-7">
                                                                        <div className="row g-3">
                                                                            <div className='col-md-10'>
                                                                                <label htmlFor="files_esal">Datos Adjuntos</label>
                                                                                <div className='input-group input-group-sm mb-3'>
                                                                                    <label className="input-group-text label_files" htmlFor="files_esal"><FontAwesomeIcon icon={faFolderOpen} />&nbsp;&nbsp;Seleccionar Archivo(s) </label>
                                                                                    <input
                                                                                        className={((this.verificarError("error_files_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        type="file" id="files_esal" multiple accept="application/pdf"
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorFiles(e);
                                                                                            this.verifyFileSize(e);
                                                                                        }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-5">
                                                                        <table className="table table-hover table-condensed table-sm">
                                                                            <thead className="thead-inverse">
                                                                                <tr>
                                                                                    <th className="align-middle">NOMBRE ARCHIVO</th>
                                                                                    <th className="align-middle">OPCIONES</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    (() => {
                                                                                        const files = [];
                                                                                        for (let i=0; i < filesDG.length; i++) {
                                                                                            const file_name = filesDG[i];
                                                                                            files.push(
                                                                                                <tr key={i}>
                                                                                                    <td>{filesDG[i]}</td>
                                                                                                    <td>
                                                                                                        <div className="btn-group" role="group" aria-label="">
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-secondary btn-sm"
                                                                                                                data-bs-toggle='modal'
                                                                                                                data-bs-target="#PDFFileModal"
                                                                                                                slot='end'
                                                                                                                onClick={() =>
                                                                                                                    this.viewFile(file_name)
                                                                                                                }
                                                                                                            >
                                                                                                                <FontAwesomeIcon icon={faFilePdf} />
                                                                                                            </button>&nbsp;&nbsp;
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-danger btn-sm"
                                                                                                                onClick={() =>
                                                                                                                    this.borrarFiles('files_dg', file_name)
                                                                                                                }
                                                                                                            >
                                                                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                        return files;
                                                                                    })()
                                                                                    // filesDG.map((file, index) => {
                                                                                    //     <tr key={index}>
                                                                                    //         <td>{file}</td>
                                                                                    //         <td>
                                                                                    //             <div className="btn-group" role="group" aria-label="">
                                                                                    //                 <button
                                                                                    //                     type="button"
                                                                                    //                     className="btn btn-secondary btn-sm"
                                                                                    //                     data-bs-toggle='modal'
                                                                                    //                     data-bs-target="#PDFFileModal"
                                                                                    //                     slot='end'
                                                                                    //                 >
                                                                                    //                     <FontAwesomeIcon icon={faPrint} />
                                                                                    //                 </button>&nbsp;&nbsp;
                                                                                    //                 <button
                                                                                    //                     type="button"
                                                                                    //                     className="btn btn-danger btn-sm"
                                                                                    //                     onClick={() =>
                                                                                    //                         this.borrarFiles('files_dg', file)
                                                                                    //                     }
                                                                                    //                 >
                                                                                    //                     <FontAwesomeIcon icon={faTrashAlt} />
                                                                                    //                 </button>
                                                                                    //             </div>
                                                                                    //         </td>
                                                                                    //     </tr>
                                                                                    // })
                                                                                    // nameFilesUploadedRut != 'null' ?
                                                                                    //     <>
                                                                                    //         <td>{nameFilesUploadedRut}</td>
                                                                                    //         <td>
                                                                                    //             <div className="btn-group" role="group" aria-label="">
                                                                                    //                 <button
                                                                                    //                     type="button"
                                                                                    //                     className="btn btn-secondary btn-sm"
                                                                                    //                     data-bs-toggle='modal'
                                                                                    //                     data-bs-target="#PDFFileModal"
                                                                                    //                     slot='end'
                                                                                    //                 >
                                                                                    //                     <FontAwesomeIcon icon={faPrint} />
                                                                                    //                 </button>&nbsp;&nbsp;
                                                                                    //                 <button
                                                                                    //                     type="button"
                                                                                    //                     className="btn btn-danger btn-sm"
                                                                                    //                     onClick={() =>
                                                                                    //                         this.borrarFiles('files_dg', this.props.match.params.id)
                                                                                    //                     }
                                                                                    //                 >
                                                                                    //                     <FontAwesomeIcon icon={faTrashAlt} />
                                                                                    //                 </button>
                                                                                    //             </div>
                                                                                    //         </td>
                                                                                    //     </>
                                                                                    // : ''
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'datos_generales_esal')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Generales</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-beneficiarios_esal" role="tabpanel" aria-labelledby="nav-beneficiarios_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-12'>
                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoBeneficiarioESALModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Beneficiario</button>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                <thead className="thead-inverse">
                                                                                    <tr>
                                                                                        <th className="align-middle">NOMBRE</th>
                                                                                        <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                        <th className="align-middle">BANCO</th>
                                                                                        <th className="align-middle">TIPO CUENTA</th>
                                                                                        <th className="align-middle">NÚMERO CUENTA</th>
                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        beneficiariosESAL.map((itemBeneficiariosESAL) => (
                                                                                            <tr key={itemBeneficiariosESAL.id}>
                                                                                                <td>{itemBeneficiariosESAL.nombre}</td>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (itemBeneficiariosESAL.tipo_identificacion) {
                                                                                                            case 'CC':
                                                                                                                return (<td>C.C.</td>)
                                                                                                            case 'CE':
                                                                                                                return (<td>C.E.</td>)
                                                                                                            case 'NIT':
                                                                                                                return (<td>NIT</td>)
                                                                                                            case 'PP':
                                                                                                                return (<td>P.P.</td>)
                                                                                                            case 'OTRO':
                                                                                                                return (<td>OTRO</td>)
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                                <td>{itemBeneficiariosESAL.identificacion}</td>
                                                                                                <td>{itemBeneficiariosESAL.banco}</td>
                                                                                                <td>{itemBeneficiariosESAL.tipo_cuenta}</td>
                                                                                                <td>{itemBeneficiariosESAL.numero_cuenta}</td>
                                                                                                <td>
                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={() =>
                                                                                                                this.borrarRegistrosPersona('beneficiario_esal', itemBeneficiariosESAL.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-representante_legal_esal" role="tabpanel" aria-labelledby="nav-representante_legal_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-4">
                                                                            {/* <input type='hidden' id='seccion_guardada_rep_legal_esal' name='seccion_guardada_rep_legal_esal' defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_rep_legal_esal' name='seccion_guardada_rep_legal_esal' value={seccion_guardada_rep_legal_esal} />
                                                                            <label htmlFor="rep_legal_esal">Representante Legal</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="rep_legal_esal" id="rep_legal_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.representanteLegalESAL : ''}
                                                                                className={((this.verificarError("error_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante Legal" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Representante Legal</small>
                                                                        </div>
                                                                        <div className='col-md-2'>
                                                                            <label htmlFor="socio_rep_legal_esal">Socio</label>
                                                                            <select name="socio_rep_legal_esal"
                                                                                id="socio_rep_legal_esal"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.socioESAL : ''}
                                                                                className={((this.verificarError("error_socio_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione es Socio</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione es Socio</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-2'>
                                                                            <label htmlFor="tipo_documento_rep_legal_esal">Tipo Documento</label>
                                                                            <select name="tipo_documento_rep_legal_esal"
                                                                                id="tipo_documento_rep_legal_esal"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.tipoDocumentoRepLegalESAL : ''}
                                                                                className={((this.verificarError("error_tipo_documento_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Tipo Documento</option>
                                                                                    <option value="CC">C.C.</option>
                                                                                    <option value="CE">C.E.</option>
                                                                                    <option value="NIT">NIT</option>
                                                                                    <option value="PP">P.P.</option>
                                                                                    <option value="OTRO">OTRO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="numero_documento_rep_legal_esal">Número Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_rep_legal_esal" id="numero_documento_rep_legal_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.numeroDocumentoRepLegalESAL : ''}
                                                                                    className={((this.verificarError("error_numero_documento_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="expedicion_documento_rep_legal_esal">Lugar Expedición</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="expedicion_documento_rep_legal_esal" id="expedicion_documento_rep_legal_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.expedicionDocumentoRepLegalESAL : ''}
                                                                                className={((this.verificarError("error_expedicion_documento_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Lugar Expedición" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Lugar Expedición</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="cargo_rep_legal_esal">Cargo Representante Legal</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_rep_legal_esal" id="cargo_rep_legal_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.cargoRepLegalESAL : ''}
                                                                                className={((this.verificarError("error_cargo_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo Representante Legal" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Cargo Representante Legal</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="correo_electronico_rep_legal_esal">Correo Electrónico Representante Legal</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_rep_legal_esal" id="correo_electronico_rep_legal_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoRepresentanteLegal.emailRelLegalESAL : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_rep_legal_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Representante Legal" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Representante Legal</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'representante_legal_esal')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Representante Legal</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-inf_financiera_esal" role="tabpanel" aria-labelledby="nav-inf_financiera_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div className="col-md-2">
                                                                            {/* <input type='hidden' id='seccion_guardada_ingresos_esal' name='seccion_guardada_ingresos_esal' defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_ingresos_esal' name='seccion_guardada_ingresos_esal' value={seccion_guardada_ingresos_esal} />
                                                                            <label htmlFor="fecha_reporte_esal">Fecha Reporte</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_reporte_esal" id="fecha_reporte_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.fechaReporteESAL : ''}
                                                                                    className={((this.verificarError("error_fecha_reporte_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Reporte" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Reporte</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="total_activos_esal">Total Activos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="total_activos_esal" id="total_activos_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.totalActivosESAL : ''}
                                                                                    className={((this.verificarError("error_total_activos_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Total Activos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Total Activos</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="total_pasivos_esal">Total Pasivos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="total_pasivos_esal" id="total_pasivos_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.totalPasivosESAL : ''}
                                                                                    className={((this.verificarError("error_total_pasivos_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Total Pasivos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Total Pasivos</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="patrimonio_esal">Patrimonio</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="patrimonio_esal" id="patrimonio_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.totalPatrimonioESAL : ''}
                                                                                    className={((this.verificarError("error_patrimonio_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Patrimonio" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Patrimonio</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="ingresos_esal">Ingresos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="ingresos_esal" id="ingresos_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.totalIngresosESAL : ''}
                                                                                    className={((this.verificarError("error_ingresos_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Ingresos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite los Ingresos</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="otros_ingresos_esal">Otros Ingresos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="otros_ingresos_esal" id="otros_ingresos_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.totalOtrosIngresosESAL : ''}
                                                                                    className={((this.verificarError("error_otros_ingresos_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Ingresos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Ingresos</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="egresos_esal">Egresos</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="egresos_esal" id="egresos_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.totalEgresosESAL : ''}
                                                                                    className={((this.verificarError("error_egresos_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Egresos" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite los Egresos</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="origen_otros_ingresos_esal">Origen Otros Ingresos</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="origen_otros_ingresos_esal" id="origen_otros_ingresos_esal" defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoFinanciera.origenOtrosIngresosESAL : ''}
                                                                                className={((this.verificarError("error_origen_otros_ingresos_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Origen Otros Ingresos" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Origen Otros Ingresos</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'inf_financiera_esal')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Financiera</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-representantes_esal" role="tabpanel" aria-labelledby="nav-representantes_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-12'>
                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoRepresentanteESALModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Representante</button>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                <thead className="thead-inverse">
                                                                                    <tr>
                                                                                        <th className="align-middle">NOMBRE</th>
                                                                                        <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                        <th className="align-middle">TIPO RELACIÓN</th>
                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        representantesESAL.map((itemRepresentantesESAL) => (
                                                                                            <tr key={itemRepresentantesESAL.id}>
                                                                                                <td>{itemRepresentantesESAL.nombre}</td>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (itemRepresentantesESAL.tipo_identificacion) {
                                                                                                            case 'CC':
                                                                                                                return (<td>C.C.</td>)
                                                                                                            case 'CE':
                                                                                                                return (<td>C.E.</td>)
                                                                                                            case 'NIT':
                                                                                                                return (<td>NIT</td>)
                                                                                                            case 'PP':
                                                                                                                return (<td>P.P.</td>)
                                                                                                            case 'OTRO':
                                                                                                                return (<td>OTRO</td>)
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                                <td>{itemRepresentantesESAL.identificacion}</td>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (itemRepresentantesESAL.tipo_relacion) {
                                                                                                            case 'FUNDADOR':
                                                                                                                return (<td>FUNDADOR</td>)
                                                                                                            case 'ASOCIADO':
                                                                                                                return (<td>ASOCIADO</td>)
                                                                                                            case 'ADMINISTRADOR':
                                                                                                                return (<td>ADMINISTRADOR</td>)
                                                                                                            case 'REPRESENTANTE_LEGAL':
                                                                                                                return (<td>REPRESENTANTE LEGAL</td>)
                                                                                                            case 'BENEFICIARIO':
                                                                                                                return (<td>BENEFICIARIO</td>)
                                                                                                            case 'APORTANTE_DONANTE':
                                                                                                                return (<td>APORTANTE / DONANTE</td>)
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                                <td>
                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={() =>
                                                                                                                this.borrarRegistrosPersona('representante_esal', itemRepresentantesESAL.id)
                                                                                                            }
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-pep_esal" role="tabpanel" aria-labelledby="nav-pep_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            {/* <input type='hidden' id='seccion_guardada_pep_esal' name='seccion_guardada_pep_esal' defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoPEP.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_pep_esal' name='seccion_guardada_pep_esal' value={seccion_guardada_pep_esal} />
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="empleado_publico_esal">¿En los últimos 2 años, ha sido usted Empleado Público Nacional o Extranjero?&nbsp;
                                                                                <span>
                                                                                    <a href='https://dapre.presidencia.gov.co/normativa/normativa/DECRETO%20830%20DEL%2026%20DE%20JULIO%20' target="_blank">Enlace PEP</a>
                                                                                </span>
                                                                            </label>
                                                                            <select name="empleado_publico_esal"
                                                                                id="empleado_publico_esal"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoPEP.empleadoPublicoESAL : ''}
                                                                                className={((this.verificarError("error_empleado_publico_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Funcionario Público</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Funcionario Público</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-6'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="beneficiario_final_esal">De acuerdo con la anterior definición, indque si usted o alguno de sus asociados, accionistas, administradores, o si la sociedad registra como Beneficiarios Finales (En el sentido establecido en el art. 22 de la Ley 222 de 1995) algún PEP</label>
                                                                            <select name="beneficiario_final_esal"
                                                                                id="beneficiario_final_esal"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.infoPEP.beneficiarioFinalESAL : ''}
                                                                                className={((this.verificarError("error_beneficiario_final_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione Beneficiario Final</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione Beneficiario Final</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'pep_esal')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos P.E.P.</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-transparencia_esal" role="tabpanel" aria-labelledby="nav-transparencia_esal-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-10'>
                                                                            {/* <input type='hidden' id='seccion_guardada_pj_esal' name='seccion_guardada_pj_esal' defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_pj_esal' name='seccion_guardada_pj_esal' value={seccion_guardada_pj_esal} />
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta1_esal_pj">1. Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta1_esal_pj"
                                                                                id="pregunta1_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta1 : ''}
                                                                                className={((this.verificarError("error_pregunta1_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta1_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 1</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 1</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta2_esal_pj">2. Que yo o la persona jurídica que represento no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                            <select name="pregunta2_esal_pj"
                                                                                id="pregunta2_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta2 : ''}
                                                                                className={((this.verificarError("error_pregunta2_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta2_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 2</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 2</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta3_esal_pj">3. Que los recursos comprometidos para el pago de la ejecución del contrato o negocio jurídico acordado con TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S..A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta3_esal_pj"
                                                                                id="pregunta3_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta3 : ''}
                                                                                className={((this.verificarError("error_pregunta3_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta3_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 3</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 3</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta4_esal_pj">4. Que no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                            <select name="pregunta4_esal_pj"
                                                                                id="pregunta4_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta4 : ''}
                                                                                className={((this.verificarError("error_pregunta4_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta4_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 4</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 4</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta5_esal_pj">5. Que la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo (LA/FT/FPADM) que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                            <select name="pregunta5_esal_pj"
                                                                                id="pregunta5_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta5 : ''}
                                                                                className={((this.verificarError("error_pregunta5_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta5_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 5</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 5</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta6_esal_pj">6. Que yo, ni la entidad que represento, ni sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, se encuentran en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas figura en dichas listas.</label>
                                                                            <select name="pregunta6_esal_pj"
                                                                                id="pregunta6_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta6 : ''}
                                                                                className={((this.verificarError("error_pregunta6_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta6_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 6</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 6</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta7_esal_pj">7. Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA, FT, FPADM, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                            <select name="pregunta7_esal_pj"
                                                                                id="pregunta7_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta7 : ''}
                                                                                className={((this.verificarError("error_pregunta7_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta7_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 7</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 7</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta8_esal_pj">8. Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS.</label>
                                                                            <select name="pregunta8_esal_pj"
                                                                                id="pregunta8_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta8 : ''}
                                                                                className={((this.verificarError("error_pregunta8_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta8_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 8</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 8</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta9_esal_pj">9. Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con TERMOMORICHAL S.A.S. es veraz y exacta, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que ello no es así.</label>
                                                                            <select name="pregunta9_esal_pj"
                                                                                id="pregunta9_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta9 : ''}
                                                                                className={((this.verificarError("error_pregunta9_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta9_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 9</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 9</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta10_esal_pj">10. Que ninguna otra persona natural o jurídica, tiene interés no legitimo en el contrato o negocio jurídico que motiva la suscripción de la presente declaración.</label>
                                                                            <select name="pregunta10_esal_pj"
                                                                                id="pregunta10_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta10 : ''}
                                                                                className={((this.verificarError("error_pregunta10_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta10_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 10</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 10</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta11_esal_pj">11. Que conozco, declaro y acepto que TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS está en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS pueda tener dudas razonables sobre mis operaciones o las operaciones de la persona jurídica que represento, así como el origen de nuestros activos, evento en el cual suministraremos las aclaraciones que sean del caso. Si estas no son satisfactorias, a juicio de TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, la autorizamos para dar por terminada cualquier relación comercial o jurídica.</label>
                                                                            <select name="pregunta11_esal_pj"
                                                                                id="pregunta11_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta11 : ''}
                                                                                className={((this.verificarError("error_pregunta11_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta11_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 11</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 11</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta12_esal_pj">12. Que con la firma del presente documento, se entiende que como persona jurídica, otorgo consentimiento informado, y por lo tanto autorizo a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS para que verifique la información suministrada y en caso de ser requerida por autoridad nacional o internacional, y suministre toda la información personal, publica, privada o semiprivada que se requiera.</label>
                                                                            <select name="pregunta12_esal_pj"
                                                                                id="pregunta12_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta12 : ''}
                                                                                className={((this.verificarError("error_pregunta12_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta12_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 12</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 12</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta13_esal_pj">13. La persona Jurídica declara que ha sido informada por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                            <select name="pregunta13_esal_pj"
                                                                                id="pregunta13_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta13 : ''}
                                                                                className={((this.verificarError("error_pregunta13_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta13_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 13</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 13</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label style={{ textAlign: 'justify' }} htmlFor="pregunta14_esal_pj">14. Que en el caso que la Persona Jurídica que represento sea considerado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                            <select name="pregunta14_esal_pj"
                                                                                id="pregunta14_esal_pj"
                                                                                defaultValue={formulario.tipo_sociedad === 4 ? formularioDetalle.declaracionPJ.pregunta14 : ''}
                                                                                className={((this.verificarError("error_pregunta14_esal_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta14_esal_pj')
                                                                                }} >
                                                                                    <option value="">Seleccione la Respuesta 14</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta 14</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            AYUDA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row g-3">
                                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                                            <p>
                                                                                                Los Documentos que se solicita adjuntar que tengan una adquisición no mayor a 30 días.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'transparencia_esal')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Datos Transparencia P.J.</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-vendor_detail" role="tabpanel" aria-labelledby="nav-vendor_detail-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_formulario_ex">Form Type</label>
                                                                            <select name="tipo_formulario_ex"
                                                                                id="tipo_formulario_ex"
                                                                                value={formulario.primera_vez}
                                                                                options={formulario.primera_vez}
                                                                                className={((this.verificarError("error_tipo_formulario_ex")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Select Form Type</option>
                                                                                    <option value="1">PRIMERA VEZ</option>
                                                                                    <option value="2">ACTUALIZACIÓN</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Select Form Type</small>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <label htmlFor="tipo_tercero_ex">Third Type</label>
                                                                            <select name="tipo_tercero_ex"
                                                                                id="tipo_tercero_ex"
                                                                                value={formulario.tipo_tercero}
                                                                                options={formulario.tipo_tercero}
                                                                                className={((this.verificarError("error_tipo_tercero_ex")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Select Third Type</option>
                                                                                    <option value="1">ACCIONISTA</option>
                                                                                    <option value="2">CLIENTE</option>
                                                                                    <option value="3">EMPLEADO</option>
                                                                                    <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                                    <option value="5">PROVEEDOR EXTERIOR</option>
                                                                                    <option value="6">PROVEEDOR NACIONAL PERSONA JURÍDICA</option>
                                                                                    <option value="7">PROVEEDOR NACIONAL PERSONA NATURAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Select Third Type</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0}} className='row g-3'>
                                                                        <div className='col-md-10'>
                                                                            <label htmlFor="tipo_sociedad_ex">Company Type</label>
                                                                            <select name="tipo_sociedad_ex"
                                                                                id="tipo_sociedad_ex"
                                                                                value={formulario.tipo_sociedad}
                                                                                options={formulario.tipo_sociedad}
                                                                                className={((this.verificarError("error_tipo_sociedad_ex")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor} disabled>
                                                                                    <option value="">Select Company Type</option>
                                                                                    <option value="1">SOCIEDAD POR ACCIONES SIMPLIFICADAS (S.A.S.)</option>
                                                                                    <option value="2">SOCIEDAD ANÓNIMA (S.A.)</option>
                                                                                    <option value="3">SOCIEDAD LIMITADA (LTDA.)</option>
                                                                                    <option value="4">ENTIDAD SIN ÁNIMO DE LUCRO (E.S.A.L.)</option>
                                                                                    <option value="5">SUCURSAL DE SOCIEDAD EXTRANJERA (S.S.E.)</option>
                                                                                    <option value="6">SOCIEDAD COLECTIVA (S.C.)</option>
                                                                                    <option value="7">SOCIEDAD EN COMANDITA POR ACCIONES (S.C.A)</option>
                                                                                    <option value="8">SOCIEDAD EN COMANDITA SIMPLE (S.C.S.)</option>
                                                                                    <option value="9">EMPRESA UNIPERSONAL</option>
                                                                                    <option value="10">SOCIEDAD ASOCIATIVA DE TRABAJO (S.A.T.)</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Select Company Type</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-5">
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className='row g-3'>
                                                                        <div className="col-md-5">
                                                                            {/* <input type='hidden' id='seccion_guardada_vd' name='seccion_guardada_vd' defaultValue={formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_vd' name='seccion_guardada_vd' value={seccion_guardada_vd} />
                                                                            <label htmlFor="nombre_beneficiario_ex">Beneficiary Name</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_beneficiario_ex" id="nombre_beneficiario_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.nombreBeneficiarioEX : ''}
                                                                                className={((this.verificarError("error_nombre_beneficiario_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Name" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Name</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_identificacion_ex">Tax ID.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="numero_identificacion_ex" id="numero_identificacion_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.numeroIdentificacionEX : ''}
                                                                                    className={((this.verificarError("error_numero_identificacion_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Tax ID." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Tax ID.</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="pais_ex">Beneficiary Country</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="pais_ex" id="pais_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.paisEX : ''}
                                                                                className={((this.verificarError("error_pais_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Country" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Country</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="estado_ex">Beneficiary State</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="estado_ex" id="estado_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.estadoEX : ''}
                                                                                className={((this.verificarError("error_estado_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary State" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary State</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="ciudad_ex">Beneficiary City</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="ciudad_ex" id="ciudad_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.ciudadEX : ''}
                                                                                className={((this.verificarError("error_ciudad_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary City" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary City</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-10">
                                                                            <label htmlFor="direccion_ex">Beneficiary Address</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_ex" id="direccion_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.direccionEX : ''}
                                                                                className={((this.verificarError("error_direccion_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Address" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Address</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="telefono1_ex">Beneficiary Phone 1</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono1_ex" id="telefono1_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.telefono1EX : ''}
                                                                                    className={((this.verificarError("error_telefono1_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Phone 1" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Phone 1</small>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="telefono2_ex">Beneficiary Phone 2</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono2_ex" id="telefono2_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.telefono2EX : ''}
                                                                                    className={((this.verificarError("error_telefono2_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Phone 2" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Phone 2</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="fax_ex">Beneficiary Fax</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="fax_ex" id="fax_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.faxEX : ''}
                                                                                    className={((this.verificarError("error_fax_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Fax" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Fax</small>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label htmlFor="correo_electronico_ex">Beneficiary Email</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_ex" id="correo_electronico_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.emailEX : ''}
                                                                                    className={((this.verificarError("error_correo_electronico_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Beneficiary Email" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Beneficiary Email</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-7">
                                                                            <label htmlFor="nombre_contacto_ex">Contact Name</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_contacto_ex" id="nombre_contacto_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.nombreConactoEX : ''}
                                                                                className={((this.verificarError("error_nombre_contacto_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contact Name" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Enter Contact Name</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="codigo_postal_ex">Postal Code</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="codigo_postal_ex" id="codigo_postal_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.vendorDetails.codigoPostalEX : ''}
                                                                                    className={((this.verificarError("error_codigo_postal_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Postal Code" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Postal Code</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>

                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'vendor_detail')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Save Vendor Details</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-bank_detail" role="tabpanel" aria-labelledby="nav-bank_detail-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            {/* <input type='hidden' id='seccion_guardada_bd' name='seccion_guardada_bd' defaultValue={formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_bd' name='seccion_guardada_bd' value={seccion_guardada_bd} />
                                                                            <label htmlFor="nombre_banco_ex">Bank Name</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_banco_ex" id="nombre_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.nombreBanco : ''}
                                                                                className={((this.verificarError("error_nombre_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Name" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Name</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="codigo_banco_ex">Bank Number</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="codigo_banco_ex" id="codigo_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.codigoBanco : ''}
                                                                                    className={((this.verificarError("error_codigo_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Number" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Number</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            <label htmlFor="nombre_sucursal_ex">Branch Name</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_sucursal_ex" id="nombre_sucursal_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.nombreSucursalBanco : ''}
                                                                                className={((this.verificarError("error_nombre_sucursal_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Branch Name" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Enter Branch Name</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_sucursal_ex">Branch Number</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_sucursal_ex" id="numero_sucursal_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.numeroSucursalBanco: ''}
                                                                                    className={((this.verificarError("error_numero_sucursal_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Branch Number" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Branch Number</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            <label htmlFor="direccion_sucursal_banco_ex">Bank Branch Address</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_sucursal_banco_ex" id="direccion_sucursal_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.direccionSucursalBanco : ''}
                                                                                className={((this.verificarError("error_direccion_sucursal_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Branch Address" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Branch Address</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_cuenta_banco_ex">Bank Account Number</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_banco_ex" id="numero_cuenta_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.numeroCuentaBanco : ''}
                                                                                    className={((this.verificarError("error_numero_cuenta_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Account Number" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Account Number</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="codigo_moneda_banco_ex">Bank Account Currency Code</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="codigo_moneda_banco_ex" id="codigo_moneda_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.codigoMonedaBanco : ''}
                                                                                    className={((this.verificarError("error_codigo_moneda_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Account Currency Code" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Account Currency Code</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="codigo_swift_banco_ex">Swift Code</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="codigo_swift_banco_ex" id="codigo_swift_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.codigoSwiftBanco : ''}
                                                                                    className={((this.verificarError("error_codigo_swift_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Swift Code" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Swift Code</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_aba_banco_ex">If Us Vendor: ABA Routing Num.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_aba_banco_ex" id="numero_aba_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.numeroABABanco : ''}
                                                                                    className={((this.verificarError("error_numero_aba_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="ABA Routing Num." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter ABA Routing Num.</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_iban_banco_ex">If Europe Vendor: IBAN Number</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_iban_banco_ex" id="numero_iban_banco_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.bankDetails.numeroIBANBanco : ''}
                                                                                    className={((this.verificarError("error_numero_iban_banco_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="IBAN Number" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter IBAN Number</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'bank_detail')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Save Bank Details</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-intermediary_bank" role="tabpanel" aria-labelledby="nav-intermediary_bank-tab">
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className="col-xl-7">
                                                                    <div className="row g-3">
                                                                        <div className='col-md-6'>
                                                                            {/* <input type='hidden' id='seccion_guardada_ib' name='seccion_guardada_ib' defaultValue={formulario.tipo_tercero === 5 ? formularioDetalle.intermediaryBankDetails.seccionGuardada : ''} /> */}
                                                                            <input type='hidden' id='seccion_guardada_ib' name='seccion_guardada_ib' value={seccion_guardada_ib} />
                                                                            <label htmlFor="nombre_banco_intermediario_ex">Bank Name</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_banco_intermediario_ex" id="nombre_banco_intermediario_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.intermediaryBankDetails.nombreBancoIntermediario : ''}
                                                                                className={((this.verificarError("error_nombre_banco_intermediario_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Name" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Name</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_cuenta_banco_intermediario_ex">Bank Account Number</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_banco_intermediario_ex" id="numero_cuenta_banco_intermediario_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.intermediaryBankDetails.numeroCuentaBancoIntermediario : ''}
                                                                                    className={((this.verificarError("error_numero_cuenta_banco_intermediario_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Bank Account Number" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Bank Account Number</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="codigo_swift_banco_intermediario_ex">Swift Code</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="codigo_swift_banco_intermediario_ex" id="codigo_swift_banco_intermediario_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.intermediaryBankDetails.codigoSwiftBancoIntermediario : ''}
                                                                                    className={((this.verificarError("error_codigo_swift_banco_intermediario_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Swift Code" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter Swift Code</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_aba_banco_intermediario_ex">If Us Vendor: ABA Routing Num.</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_aba_banco_intermediario_ex" id="numero_aba_banco_intermediario_ex" defaultValue={formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? formularioDetalle.intermediaryBankDetails.numeroABABancoIntermediario : ''}
                                                                                    className={((this.verificarError("error_numero_aba_banco_intermediario_ex")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="ABA Routing Num." aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Enter ABA Routing Num.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-5'>

                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} className="row">
                                                                <div className='col-xl-12 text-center'>
                                                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => {this.preGuardarDatos(e, 'intermediary_bank')}}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Save Intermediary Banks</button>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='row'>
                                                        <div className='col-xl-12 text-center'>
                                                            <div className="btn-group" role="group" aria-label="">
                                                                <button type="submit" className="btn btn-success btn-sm" disabled={activar_enviar ? false : true} onClick={this.enviarDatos}><FontAwesomeIcon icon={faPaperPlane} />&nbsp;&nbsp;{formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? 'Send' : 'Enviar'}</button>&nbsp;&nbsp;
                                                                <Link className="btn btn-danger btn-sm" to={"/MasterTerceros"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;{formulario.tipo_sociedad !== 4 && formulario.tipo_tercero === 5 ? 'Cancel' : 'Cancelar'}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="TamanoEmpresaModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="TamanoEmpresaModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="TamanoEmpresaModalLabel">Tamaño Empresa</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-md-12">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th className="align-middle">TAMAÑO</th>
                                                <th className="align-middle">MANUFACTURA</th>
                                                <th className="align-middle">SERVICIOS</th>
                                                <th className="align-middle">COMERCIO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>MICRO</th>
                                                <td>Hasta $811 MM</td>
                                                <td>Hasta $1.131 MM</td>
                                                <td>Hasta $1.535 MM</td>
                                            </tr>
                                            <tr>
                                                <th>PEQUEÑA</th>
                                                <td>Desde $811 MM Hasta $7.027 MM</td>
                                                <td>Desde $1.131 MM Hasta $4.523 MM</td>
                                                <td>Desde $1.535 MM Hasta $14.781 MM</td>
                                            </tr>
                                            <tr>
                                                <th>MEDIANA</th>
                                                <td>Desde $7.027 MM Hasta $59.527 MM</td>
                                                <td>Desde $4.523 MM Hasta $16.558 MM</td>
                                                <td>Desde $14.781 MM Hasta $74.066 MM</td>
                                            </tr>
                                            <tr>
                                                <th>GRANDE</th>
                                                <td>Mayor $59.527 MM</td>
                                                <td>Mayor $16.558 MM</td>
                                                <td>Mayor $74.066 MM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    <p>
                                        Indique el Tamaño de la Empresa Según los Ingresos al 31 de Diciembre del año anterior al diligenciamiento de este formulario.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoBeneficiarioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoBeneficiarioModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoBeneficiarioModalLabel">Nuevo Beneficiario</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-beneficiario'>
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-datos_beneficiario-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_beneficiario" type="button" role="tab" aria-controls="nav-datos_beneficiario" aria-selected="false">Información Beneficiario</button>
                                            {
                                                activar_pep_beneficiario ?
                                                    <button style={{ backgroundColor: '#003153', color: '#FFFFFF' }} className="nav-link" id="nav-datos_pep_beneficiario-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_pep_beneficiario" type="button" role="tab" aria-controls="nav-datos_pep_beneficiario" aria-selected="false">Información P.E.P.</button>
                                                : ''
                                            }
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-datos_beneficiario" role="tabpanel" aria-labelledby="nav-datos_beneficiario-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS BENEFICIARIO
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="nombre_beneficiario_b">Nombre Beneficiario</label>
                                                                            <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_beneficiario_b" id="nombre_beneficiario_b" value={nombre_beneficiario_b}
                                                                                className={((this.verificarError("error_nombre_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Beneficiario" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Nombre Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="apellido_beneficiario_b">Apellido Beneficiario</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="apellido_beneficiario_b" id="apellido_beneficiario_b" value={apellido_beneficiario_b}
                                                                                className={((this.verificarError("error_apellido_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Apellido Beneficiario" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Apellido Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_documento_beneficiario_b">Número Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_beneficiario_b" id="numero_documento_beneficiario_b" value={numero_documento_beneficiario_b}
                                                                                    className={((this.verificarError("error_numero_documento_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="telefono_beneficiario_b">Teléfono Beneficiario</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_beneficiario_b" id="telefono_beneficiario_b" value={telefono_beneficiario_b}
                                                                                    className={((this.verificarError("error_telefono_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Beneficiario" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="telefono_movil_beneficiario_b">Teléfono Móvil Beneficiario</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_movil_beneficiario_b" id="telefono_movil_beneficiario_b" value={telefono_movil_beneficiario_b}
                                                                                    className={((this.verificarError("error_telefono_movil_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Móvil Beneficiario" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono Móvil Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="correo_electronico_beneficiario_b">Correo Elect. Beneficiario</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_beneficiario_b" id="correo_electronico_beneficiario_b" value={correo_electronico_beneficiario_b}
                                                                                    className={((this.verificarError("error_correo_electronico_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Elect. Beneficiario" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Elect. Beneficiario</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="pep_b">P.E.P.</label>
                                                                            <select name="pep_b"
                                                                                id="pep_b"
                                                                                className={((this.verificarError("error_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarPepBeneficiario(e);
                                                                                }} >
                                                                                    <option value="">Seleccione P.E.P.</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione P.E.P.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor complete el formulario con la información solicitada por cada Beneficiario Final, por favor tenga en cuenta que si el beneficiario ostenta la condición de Persona Políticamente Expuesta debe completar obligatoriamente las preguntas adicionales.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-datos_pep_beneficiario" role="tabpanel" aria-labelledby="nav-datos_pep_beneficiario-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS P.E.P. NACIONALES
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <label htmlFor="declaracion_veracidad_pep_b">Declaración Veracidad</label>
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px', marginTop: 0 }} className='col-md-12'>
                                                                            <p>
                                                                                En cumplimiento del Manual del Sistema de Control Interno; el Manual SAGRILAFT (Lavado de Activos, Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM)
                                                                                y el Manual PAEC (Programa de Anticorrupción, Ética y Cumplimiento), adoptados por las empresas TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA, PROELECTRICA S.A.S. E.S.P., CH EL EDEN S.A.S E.S.P.,
                                                                                y ENELCA S.A. E.S.P., en adelante las “Empresas”, los cuales han sido implementados mediante los programas mencionados y conforme a las directrices de la Superintendencia de Sociedades, todos los actores de la contraparte
                                                                                que ostenten la calidad de P.E.P de acuerdo con el Decreto 830 del 26 de Julio de 2021, y cualquier otra norma que lo modifique o reemplace deberán diligenciar la información solicitada en este apartado.
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ marginTop: 0 }} className="col-md-4">
                                                                            <select name="declaracion_veracidad_pep_b"
                                                                                id="declaracion_veracidad_pep_b"
                                                                                className={((this.verificarError("error_declaracion_veracidad_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarTerminos(e, 'declaracion_veracidad_beneficiario');
                                                                                }}>
                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Declaración Veracidad</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="vinculo_actual_pep_b">Vínculo actual con la Persona Jurídica</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="vinculo_actual_pep_b" id="vinculo_actual_pep_b" value={vinculo_actual_pep_b}
                                                                                className={((this.verificarError("error_vinculo_actual_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Vínculo Actual" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Vínculo Actual</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="cargo_actividad_pep_b">Cargo o Actividad que lo clasifique como P.E.P.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_actividad_pep_b" id="cargo_actividad_pep_b" value={cargo_actividad_pep_b}
                                                                                className={((this.verificarError("error_cargo_actividad_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo o Actividad" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Cargo o Actividad</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="vinculacion_activa_pep_b">Vinculación Activa</label>
                                                                            <select name="vinculacion_activa_pep_b"
                                                                                id="vinculacion_activa_pep_b"
                                                                                className={((this.verificarError("error_vinculacion_activa_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarVinculacionBeneficiario(e);
                                                                                }}>
                                                                                    <option value="">Seleccione la Vinculación</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Vinculación</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="fecha_vinculacion_pep_b">Fecha Vinculación</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_vinculacion_pep_b" id="fecha_vinculacion_pep_b" value={fecha_vinculacion_pep_b}
                                                                                    className={((this.verificarError("error_fecha_vinculacion_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Vinculación" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Vinculación</small>
                                                                        </div>
                                                                        {
                                                                            activar_vinculacion_beneficiario ?
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="fecha_desvinculacion_pep_b">Fecha Desvinculación</label>
                                                                                    <div className="input-group input-group-sm">
                                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_desvinculacion_pep_b" id="fecha_desvinculacion_pep_b" value={fecha_desvinculacion_pep_b}
                                                                                            className={((this.verificarError("error_fecha_desvinculacion_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Desvinculación" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} />
                                                                                    </div>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Fecha Desvinculación</small>
                                                                                </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            <button className="nav-link active" id="nav-datos_beneficiarios_nacionales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_beneficiarios_nacionales" type="button" role="tab" aria-controls="nav-datos_beneficiarios_nacionales" aria-selected="false">P.E.P. Nacional</button>
                                                            <button className="nav-link" id="nav-datos_beneficiarios_extranjeros-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_beneficiarios_extranjeros" type="button" role="tab" aria-controls="nav-datos_beneficiarios_extranjeros" aria-selected="false">P.E.P. Extranjero</button>
                                                            <button className="nav-link" id="nav-declaracion_beneficiario_pep-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_beneficiario_pep" type="button" role="tab" aria-controls="nav-declaracion_beneficiario_pep" aria-selected="false">Declaración Transparencia</button>
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-datos_beneficiarios_nacionales" role="tabpanel" aria-labelledby="nav-datos_beneficiarios_nacionales-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarBeneficiarioPEPNacionalModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Nacional</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                familiaresBeneficiarioPEPNacional.map((itemFamiliaresBeneficiarioPEPNacional) => (
                                                                                                    <tr key={itemFamiliaresBeneficiarioPEPNacional.id}>
                                                                                                        <td>{itemFamiliaresBeneficiarioPEPNacional.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemFamiliaresBeneficiarioPEPNacional.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemFamiliaresBeneficiarioPEPNacional.identificacion}</td>
                                                                                                        <td>{itemFamiliaresBeneficiarioPEPNacional.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarBeneficiarioPEPNacionalModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_beneficiario_pep_nacional', itemFamiliaresBeneficiarioPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('familiar_beneficiario_pep_nacional', itemFamiliaresBeneficiarioPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. NACIONAL EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_nacional_pep_b">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_nacional_pep_b"
                                                                                        id="pregunta_participacion_nacional_pep_b"
                                                                                        className={((this.verificarError("error_pregunta_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarSociedadBeneficiarioPepNacional(e);
                                                                                        }}>
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_beneficiario_pep_nacional ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadBeneficiarioPEPNacionalModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        sociedadesBeneficiarioPEPNacional.map((itemSociedadesBeneficiarioPEPNacional) => (
                                                                                                            <tr key={itemSociedadesBeneficiarioPEPNacional.id}>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPNacional.razon_social}</td>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPNacional.identificacion}</td>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPNacional.capital}</td>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPNacional.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadBeneficiarioPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_beneficiario_pep_nacional', itemSociedadesBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('sociedad_beneficiario_pep_nacional', itemSociedadesBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_nacional_pep_b">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_nacional_pep_b"
                                                                                        id="pregunta_cuenta_extranjera_nacional_pep_b"
                                                                                        className={((this.verificarError("error_pregunta_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarCuentaBeneficiarioPepNacional(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_beneficiario_pep_nacional ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaBeneficiarioPEPNacionalModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        cuentasBeneficiarioPEPNacional.map((itemCuentasBeneficiarioPEPNacional) => (
                                                                                                            <tr key={itemCuentasBeneficiarioPEPNacional.id}>
                                                                                                                <td>{itemCuentasBeneficiarioPEPNacional.razon_social}</td>
                                                                                                                <td>{itemCuentasBeneficiarioPEPNacional.pais}</td>
                                                                                                                <td>{itemCuentasBeneficiarioPEPNacional.numero_cuenta}</td>
                                                                                                                <td>{itemCuentasBeneficiarioPEPNacional.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaBeneficiarioPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_beneficiario_pep_nacional', itemCuentasBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('cuenta_beneficiario_pep_nacional', itemCuentasBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-datos_beneficiarios_extranjeros" role="tabpanel" aria-labelledby="nav-datos_beneficiarios_extranjeros-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            P.E.P. EXTRANJEROS
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_beneficiario_extranjero_pep_b">Indique si la sociedad registra como beneficiarios finales o tiene como administradores (En el sentido establecido en el art. 22 de la Ley 222 de 1995) personas identificadas como un PEP Extranjero.</label>
                                                                                            <select name="pregunta_beneficiario_extranjero_pep_b"
                                                                                                id="pregunta_beneficiario_extranjero_pep_b"
                                                                                                className={((this.verificarError("error_pregunta_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.activarPepBeneficiarioExtranjero(e);
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* {
                                                                                        activar_pep_beneficiario_extranjero ?
                                                                                            <>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="nombre_beneficiario_extranjero_pep_b">Nombre</label>
                                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_beneficiario_extranjero_pep_b" id="nombre_beneficiario_extranjero_pep_b" value={nombre_beneficiario_extranjero_pep_b}
                                                                                                            className={((this.verificarError("error_nombre_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Beneficiario" aria-describedby="helpId"
                                                                                                            onChange={this.cambioValor} />
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Nombre Beneficiario</small>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="numero_documento_beneficiario_extranjero_pep_b">Número Documento</label>
                                                                                                        <div className="input-group input-group-sm">
                                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                                            <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_beneficiario_extranjero_pep_b" id="numero_documento_beneficiario_extranjero_pep_b" value={numero_documento_beneficiario_extranjero_pep_b}
                                                                                                                className={((this.verificarError("error_numero_documento_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                                                onChange={this.cambioValor} max="37" />
                                                                                                        </div>
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-12">
                                                                                                        <label htmlFor="vinculo_actual_beneficiario_extranjero_pep_b">Vínculo actual con la Persona Jurídica</label>
                                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="vinculo_actual_beneficiario_extranjero_pep_b" id="vinculo_actual_beneficiario_extranjero_pep_b" value={vinculo_actual_beneficiario_extranjero_pep_b}
                                                                                                            className={((this.verificarError("error_vinculo_actual_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Vínculo Actual" aria-describedby="helpId"
                                                                                                            onChange={this.cambioValor} max="37" />
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Vínculo Actual</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-12">
                                                                                                        <label htmlFor="cargo_actividad_beneficiario_extranjero_pep_b">Cargo o Actividad que lo clasifique como P.E.P.</label>
                                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_actividad_beneficiario_extranjero_pep_b" id="cargo_actividad_beneficiario_extranjero_pep_b" value={cargo_actividad_beneficiario_extranjero_pep_b}
                                                                                                            className={((this.verificarError("error_cargo_actividad_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo o Actividad" aria-describedby="helpId"
                                                                                                            onChange={this.cambioValor} max="37" />
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Cargo o Actividad</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="fecha_vinculacion_beneficiario_extranjero_pep_b">Fecha Vinculación</label>
                                                                                                        <div className="input-group input-group-sm">
                                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                                            <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_vinculacion_beneficiario_extranjero_pep_b" id="fecha_vinculacion_beneficiario_extranjero_pep_b" value={fecha_vinculacion_beneficiario_extranjero_pep_b}
                                                                                                                className={((this.verificarError("error_fecha_vinculacion_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Vinculación" aria-describedby="helpId"
                                                                                                                onChange={this.cambioValor} />
                                                                                                        </div>
                                                                                                        <small id="helpId" className="invalid-feedback">Seleccione la Fecha Vinculación</small>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="fecha_desvinculacion_beneficiario_extranjero_pep_b">Fecha Desvinculación</label>
                                                                                                        <div className="input-group input-group-sm">
                                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                                            <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_desvinculacion_beneficiario_extranjero_pep_b" id="fecha_desvinculacion_beneficiario_extranjero_pep_b" value={fecha_desvinculacion_beneficiario_extranjero_pep_b}
                                                                                                                className={((this.verificarError("error_fecha_desvinculacion_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Desvinculación" aria-describedby="helpId"
                                                                                                                onChange={this.cambioValor} />
                                                                                                        </div>
                                                                                                        <small id="helpId" className="invalid-feedback">Seleccione la Fecha Desvinculación</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        : ''
                                                                                    } */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarBeneficiarioPEPExtranjeroModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Extranjero</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                familiaresBeneficiarioPEPExtranjero.map((itemFamiliaresBeneficiarioPEPExtranjero) => (
                                                                                                    <tr key={itemFamiliaresBeneficiarioPEPExtranjero.id}>
                                                                                                        <td>{itemFamiliaresBeneficiarioPEPExtranjero.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemFamiliaresBeneficiarioPEPExtranjero.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemFamiliaresBeneficiarioPEPExtranjero.identificacion}</td>
                                                                                                        <td>{itemFamiliaresBeneficiarioPEPExtranjero.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarBeneficiarioPEPExtranjeroModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_beneficiario_pep_extranjero', itemFamiliaresBeneficiarioPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('familiar_beneficiario_pep_extranjero', itemFamiliaresBeneficiarioPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. EXTRANJERO EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_extranjero_pep_b">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_extranjero_pep_b"
                                                                                        id="pregunta_participacion_extranjero_pep_b"
                                                                                        className={((this.verificarError("error_pregunta_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarSociedadBeneficiarioPepExtranjero(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_beneficiario_pep_extranjero ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadBeneficiarioPEPExtranjeroModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        sociedadesBeneficiarioPEPExtranjero.map((itemSociedadesBeneficiarioPEPExtranjero) => (
                                                                                                            <tr key={itemSociedadesBeneficiarioPEPExtranjero.id}>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPExtranjero.identificacion}</td>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPExtranjero.capital}</td>
                                                                                                                <td>{itemSociedadesBeneficiarioPEPExtranjero.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadBeneficiarioPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_beneficiario_pep_extranjero', itemSociedadesBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('sociedad_beneficiario_pep_extranjero', itemSociedadesBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_extranjero_pep_b">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_extranjero_pep_b"
                                                                                        id="pregunta_cuenta_extranjera_extranjero_pep_b"
                                                                                        className={((this.verificarError("error_pregunta_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarCuentaBeneficiarioPepExtranjero(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_beneficiario_pep_extranjero ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaBeneficiarioPEPExtranjeroModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        cuentasBeneficiarioPEPExtranjero.map((itemCuentasBeneficiarioPEPExtranjero) => (
                                                                                                            <tr key={itemCuentasBeneficiarioPEPExtranjero.id}>
                                                                                                                <td>{itemCuentasBeneficiarioPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemCuentasBeneficiarioPEPExtranjero.pais}</td>
                                                                                                                <td>{itemCuentasBeneficiarioPEPExtranjero.numero_cuenta}</td>
                                                                                                                <td>{itemCuentasBeneficiarioPEPExtranjero.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaBeneficiarioPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_beneficiario_pep_extranjero', itemCuentasBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('cuenta_beneficiario_pep_extranjero', itemCuentasBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-declaracion_beneficiario_pep" role="tabpanel" aria-labelledby="nav-declaracion_beneficiario_pep-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            DECLARACIÓN DE TRANSPARENCIA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta1_pep_b">Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta1_pep_b"
                                                                                                id="pregunta1_pep_b"
                                                                                                className={((this.verificarError("error_pregunta1_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta1_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta2_pep_b">Que la persona jurídica que represento o yo no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                                            <select name="pregunta2_pep_b"
                                                                                                id="pregunta2_pep_b"
                                                                                                className={((this.verificarError("error_pregunta2_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta2_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta3_pep_b">Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado con las empresas del Grupo Corporativo, no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta3_pep_b"
                                                                                                id="pregunta3_pep_b"
                                                                                                className={((this.verificarError("error_pregunta3_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta3_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta4_pep_b">Que en la ejecución del contrato o el desarrollo de las actividades en que consiste el negocio jurídico con las empresas del Grupo Corporativo, no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta4_pep_b"
                                                                                                id="pregunta4_pep_b"
                                                                                                className={((this.verificarError("error_pregunta4_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta4_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta5_pep_b">Que según aplique, la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                                            <select name="pregunta5_pep_b"
                                                                                                id="pregunta5_pep_b"
                                                                                                className={((this.verificarError("error_pregunta5_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta5_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta6_pep_b">Que, la entidad que represento, sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, no nos encontramos en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando las empresas del Grupo Corporativo facultadas para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas aparece en dichas listas.</label>
                                                                                            <select name="pregunta6_pep_b"
                                                                                                id="pregunta6_pep_b"
                                                                                                className={((this.verificarError("error_pregunta6_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta6_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta7_pep_b">Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA/FT/FPADM y/o contrabando, estando las empresas del Grupo Corporativo facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que Yo o alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a las empresas del Grupo Corporativo frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                                            <select name="pregunta7_pep_b"
                                                                                                id="pregunta7_pep_b"
                                                                                                className={((this.verificarError("error_pregunta7_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta7_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta8_pep_b">Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a las empresas del Grupo Corporativo.</label>
                                                                                            <select name="pregunta8_pep_b"
                                                                                                id="pregunta8_pep_b"
                                                                                                className={((this.verificarError("error_pregunta8_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta8_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta9_pep_b">Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con las empresas del Grupo Corporativo es veraz y exacta, estando las empresas del Grupo Corporativo facultadas para efectuar las validaciones y verificaciones que considere pertinentes. En todo caso, el Grupo Corporativo podrá dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que la información aportada no es veraz y exacta.</label>
                                                                                            <select name="pregunta9_pep_b"
                                                                                                id="pregunta9_pep_b"
                                                                                                className={((this.verificarError("error_pregunta9_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta9_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta10_pep_b">La persona Jurídica declara que ha sido informada por las empresas del Grupo Corporativo, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                                            <select name="pregunta10_pep_b"
                                                                                                id="pregunta10_pep_b"
                                                                                                className={((this.verificarError("error_pregunta10_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta10_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta11_pep_b">Que en el caso que la Persona Jurídica que represento sea considerado por las empresas del Grupo Corporativo, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                                            <select name="pregunta11_pep_b"
                                                                                                id="pregunta11_pep_b"
                                                                                                className={((this.verificarError("error_pregunta11_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta11_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor complete la información solicitada en el formato siguiente teniendo en cuenta los parametros definidos en el <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=167367'>Decreto 830 del 26 de Julio de 2021</a> y sus normas complementarias
                                                                            </p>
                                                                            <p>
                                                                                Es importante tener en cuenta estas consideraciones particulares:
                                                                            </p>
                                                                            <p>
                                                                                <b>Familiares del P.E.P. Nacional</b>
                                                                            </p>
                                                                            <p>
                                                                                Los datos deben ser diligenciados en relación a los familiares de cada PEP, así: Para el cónyuge o compañero permanente de hecho o de derecho y para los familiares hasta el segundo grado de consanguinidad (hijos, nietos, padres, hermanos, abuelos), primero de afinidad (hijos, nietos, padres, hermanos, abuelos del cónyuge) y primero civil (hijos adoptivos, padres adoptantes) propios o del cónyuge
                                                                            </p>
                                                                            <p>
                                                                                <b>Participaciones del PEP Nacional en Sociedades o Asociaciones, Patrimonio(s) Autónomo(s) o Fiducia(s)</b>
                                                                            </p>
                                                                            <p>
                                                                                Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los datos solicitados.
                                                                            </p>
                                                                            <p>
                                                                                <b>Cuentas financieras extranjeras del PEP Nacional </b>
                                                                            </p>
                                                                            <p>
                                                                                Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los datos solicitados.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosBeneficiario}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Beneficiario</button>&nbsp;&nbsp;
                                                <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'beneficiario')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoFamiliarBeneficiarioPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoFamiliarBeneficiarioPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoFamiliarBeneficiarioPEPNacionalModalLabel">Nuevo Familiar P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-familiares_beneficiario_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="nombre_familiar_nacional_pep_b">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_familiar_nacional_pep_b" id="nombre_familiar_nacional_pep_b" value={nombre_familiar_nacional_pep_b}
                                                                        className={((this.verificarError("error_nombre_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco_familiar_nacional_pep_b">Parentesco</label>
                                                                    <select name="parentesco_familiar_nacional_pep_b"
                                                                        id="parentesco_familiar_nacional_pep_b"
                                                                        className={((this.verificarError("error_parentesco_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_documento_familiar_nacional_pep_b">Tipo Documento</label>
                                                                    <select name="tipo_documento_familiar_nacional_pep_b"
                                                                        id="tipo_documento_familiar_nacional_pep_b"
                                                                        className={((this.verificarError("error_tipo_documento_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_familiar_nacional_pep_b">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_familiar_nacional_pep_b" id="numero_documento_familiar_nacional_pep_b" value={numero_documento_familiar_nacional_pep_b}
                                                                            className={((this.verificarError("error_numero_documento_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosFamiliarBeneficiarioPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'familiar_beneficiario_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoSociedadBeneficiarioPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoSociedadBeneficiarioPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoSociedadBeneficiarioPEPNacionalModalLabel">Nueva Sociedad P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-sociedades_beneficiario_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_participacion_nacional_pep_b">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_participacion_nacional_pep_b" id="razon_social_participacion_nacional_pep_b" value={razon_social_participacion_nacional_pep_b}
                                                                        className={((this.verificarError("error_razon_social_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_participacion_nacional_pep_b">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_participacion_nacional_pep_b" id="numero_documento_participacion_nacional_pep_b" value={numero_documento_participacion_nacional_pep_b}
                                                                            className={((this.verificarError("error_numero_documento_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="porcentaje_participacion_nacional_pep_b">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="porcentaje_participacion_nacional_pep_b" id="porcentaje_participacion_nacional_pep_b" value={porcentaje_participacion_nacional_pep_b}
                                                                            className={((this.verificarError("error_porcentaje_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control_participacion_nacional_pep_b">Tipo Control</label>
                                                                    <select name="tipo_control_participacion_nacional_pep_b"
                                                                        id="tipo_control_participacion_nacional_pep_b"
                                                                        className={((this.verificarError("error_tipo_control_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.activarOtrosTipoControlBeneficiarioPepNacional(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_beneficiario_pep_nacional ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="otros_tipo_control_participacion_nacional_pep_b">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="otros_tipo_control_participacion_nacional_pep_b" id="otros_tipo_control_participacion_nacional_pep_b" value={otros_tipo_control_participacion_nacional_pep_b}
                                                                                className={((this.verificarError("error_otros_tipo_control_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="detalle_tipo_control_participacion_nacional_pep_b">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="detalle_tipo_control_participacion_nacional_pep_b" id="detalle_tipo_control_participacion_nacional_pep_b" value={detalle_tipo_control_participacion_nacional_pep_b}
                                                                        className={((this.verificarError("error_detalle_tipo_control_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosSociedadBeneficiarioPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'sociedad_beneficiario_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoCuentaBeneficiarioPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoCuentaBeneficiarioPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoCuentaBeneficiarioPEPNacionalModalLabel">Nueva Cuenta P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-cuentas_beneficiario_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_cuenta_extranjera_nacional_pep_b">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_cuenta_extranjera_nacional_pep_b" id="razon_social_cuenta_extranjera_nacional_pep_b" value={razon_social_cuenta_extranjera_nacional_pep_b}
                                                                        className={((this.verificarError("error_razon_social_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="pais_cuenta_extranjera_nacional_pep_b">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="pais_cuenta_extranjera_nacional_pep_b" id="pais_cuenta_extranjera_nacional_pep_b" value={pais_cuenta_extranjera_nacional_pep_b}
                                                                        className={((this.verificarError("error_pais_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_cuenta_extranjera_nacional_pep_b">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_extranjera_nacional_pep_b" id="numero_cuenta_extranjera_nacional_pep_b" value={numero_cuenta_extranjera_nacional_pep_b}
                                                                            className={((this.verificarError("error_numero_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="clase_propiedad_cuenta_extranjera_nacional_pep_b">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="clase_propiedad_cuenta_extranjera_nacional_pep_b" id="clase_propiedad_cuenta_extranjera_nacional_pep_b" value={clase_propiedad_cuenta_extranjera_nacional_pep_b}
                                                                        className={((this.verificarError("error_clase_propiedad_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosCuentaBeneficiarioPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'cuenta_beneficiario_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoFamiliarBeneficiarioPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoFamiliarBeneficiarioPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoFamiliarBeneficiarioPEPExtranjeroModalLabel">Nuevo Familiar P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-familiares_beneficiario_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="nombre_familiar_extranjero_pep_b">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_familiar_extranjero_pep_b" id="nombre_familiar_extranjero_pep_b" value={nombre_familiar_extranjero_pep_b}
                                                                        className={((this.verificarError("error_nombre_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Familiar" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre Familiar</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco_familiar_extranjero_pep_b">Parentesco</label>
                                                                    <select name="parentesco_familiar_extranjero_pep_b"
                                                                        id="parentesco_familiar_extranjero_pep_b"
                                                                        className={((this.verificarError("error_parentesco_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_documento_familiar_extranjero_pep_b">Tipo Documento</label>
                                                                    <select name="tipo_documento_familiar_extranjero_pep_b"
                                                                        id="tipo_documento_familiar_extranjero_pep_b"
                                                                        className={((this.verificarError("error_tipo_documento_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_familiar_extranjero_pep_b">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_familiar_extranjero_pep_b" id="numero_documento_familiar_extranjero_pep_b" value={numero_documento_familiar_extranjero_pep_b}
                                                                            className={((this.verificarError("error_numero_documento_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosFamiliarBeneficiarioPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'familiar_beneficiario_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoSociedadBeneficiarioPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoSociedadBeneficiarioPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoSociedadBeneficiarioPEPExtranjeroModalLabel">Nueva Sociedad P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-sociedades_beneficiario_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_participacion_extranjero_pep_b">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_participacion_extranjero_pep_b" id="razon_social_participacion_extranjero_pep_b" value={razon_social_participacion_extranjero_pep_b}
                                                                        className={((this.verificarError("error_razon_social_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_participacion_extranjero_pep_b">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_participacion_extranjero_pep_b" id="numero_documento_participacion_extranjero_pep_b" value={numero_documento_participacion_extranjero_pep_b}
                                                                            className={((this.verificarError("error_numero_documento_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="porcentaje_participacion_extranjero_pep_b">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="porcentaje_participacion_extranjero_pep_b" id="porcentaje_participacion_extranjero_pep_b" value={porcentaje_participacion_extranjero_pep_b}
                                                                            className={((this.verificarError("error_porcentaje_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control_participacion_extranjero_pep_b">Tipo Control</label>
                                                                    <select name="tipo_control_participacion_extranjero_pep_b"
                                                                        id="tipo_control_participacion_extranjero_pep_b"
                                                                        className={((this.verificarError("error_tipo_control_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.activarOtrosTipoControlBeneficiarioPepExtranjero(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_beneficiario_pep_extranjero ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="otros_tipo_control_participacion_extranjero_pep_b">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="otros_tipo_control_participacion_extranjero_pep_b" id="otros_tipo_control_participacion_extranjero_pep_b" value={otros_tipo_control_participacion_extranjero_pep_b}
                                                                                className={((this.verificarError("error_otros_tipo_control_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="detalle_tipo_control_participacion_extranjero_pep_b">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="detalle_tipo_control_participacion_extranjero_pep_b" id="detalle_tipo_control_participacion_extranjero_pep_b" value={detalle_tipo_control_participacion_extranjero_pep_b}
                                                                        className={((this.verificarError("error_detalle_tipo_control_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosSociedadBeneficiarioPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'sociedad_beneficiario_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoCuentaBeneficiarioPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoCuentaBeneficiarioPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoCuentaBeneficiarioPEPExtranjeroModalLabel">Nueva Cuenta P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-cuentas_beneficiario_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_cuenta_extranjera_extranjero_pep_b">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_cuenta_extranjera_extranjero_pep_b" id="razon_social_cuenta_extranjera_extranjero_pep_b" value={razon_social_cuenta_extranjera_extranjero_pep_b}
                                                                        className={((this.verificarError("error_razon_social_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="pais_cuenta_extranjera_extranjero_pep_b">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="pais_cuenta_extranjera_extranjero_pep_b" id="pais_cuenta_extranjera_extranjero_pep_b" value={pais_cuenta_extranjera_extranjero_pep_b}
                                                                        className={((this.verificarError("error_pais_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_cuenta_extranjera_extranjero_pep_b">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_extranjera_extranjero_pep_b" id="numero_cuenta_extranjera_extranjero_pep_b" value={numero_cuenta_extranjera_extranjero_pep_b}
                                                                            className={((this.verificarError("error_numero_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="clase_propiedad_cuenta_extranjera_extranjero_pep_b">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="clase_propiedad_cuenta_extranjera_extranjero_pep_b" id="clase_propiedad_cuenta_extranjera_extranjero_pep_b" value={clase_propiedad_cuenta_extranjera_extranjero_pep_b}
                                                                        className={((this.verificarError("error_clase_propiedad_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosCuentaBeneficiarioPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'cuenta_beneficiario_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoContactoModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoContactoModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoContactoModalLabel">Nuevo Contacto</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-contacto'>
                                    <div className='row'>
                                        <div className='col-xl-8'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CONTACTO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_contacto_c">Tipo Contacto</label>
                                                                    <select name="tipo_contacto_c"
                                                                        id="tipo_contacto_c"
                                                                        className={((this.verificarError("error_tipo_contacto_c")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Contacto</option>
                                                                            <option value="COMERCIAL">COMERCIAL</option>
                                                                            <option value="CONTABLE">CONTABLE</option>
                                                                            <option value="TESORERIA">TESORERÍA</option>
                                                                            <option value="FACTURACION_ELECTRONICA">FACTURACIÓN ELECTRÓNICA</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Contacto</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="nombre_contacto_c">Nombre Contacto</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_contacto_c" id="nombre_contacto_c" value={nombre_contacto_c}
                                                                        className={((this.verificarError("error_nombre_contacto_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Contacto" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre Contacto</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="apellido_contacto_c">Apellido Contacto</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="apellido_contacto_c" id="apellido_contacto_c" value={apellido_contacto_c}
                                                                        className={((this.verificarError("error_apellido_contacto_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Apellido Contacto" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Apellido Contacto</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="cargo_contacto_c">Cargo Contacto</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_contacto_c" id="cargo_contacto_c" value={cargo_contacto_c}
                                                                        className={((this.verificarError("error_cargo_contacto_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo Contacto" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Cargo Contacto</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="telefono_c">Teléfono Contacto</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_c" id="telefono_c" value={telefono_c}
                                                                            className={((this.verificarError("error_telefono_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Contacto" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Teléfono Contacto</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="ext_telefono_c">Ext.</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="ext_telefono_c" id="ext_telefono_c" value={ext_telefono_c}
                                                                        className={((this.verificarError("error_ext_telefono_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Extensión Tel." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Extensión</small>
                                                                </div>
                                                                <div className="col-md-2"></div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="telefono_movil_c">Teléfono Móvil Contacto</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_movil_c" id="telefono_movil_c" value={telefono_movil_c}
                                                                            className={((this.verificarError("error_telefono_movil_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Móvil Contacto" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Teléfono Móvil Contacto</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="correo_electronico_c">Correo Elect. Contacto</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_c" id="correo_electronico_c" value={correo_electronico_c}
                                                                            className={((this.verificarError("error_correo_electronico_c")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Elect. Contacto" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Correo Elect. Contacto</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosContacto}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Contacto</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'contacto')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-4'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    AYUDA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                    <p>
                                                                        Por favor indique a continuación el tipo de contacto y los datos correspondientes, al completar la información presione el botón + Crear Contacto para guardar la información.
                                                                    </p>
                                                                    <p>
                                                                        El sistema guarda los datos y le permite seleccionar un nuevo tipo de contacto para avanzar.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoRepresentanteModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoRepresentanteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoRepresentanteModalLabel">Nuevo Representante</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-representantes'>
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-datos_representante-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_representante" type="button" role="tab" aria-controls="nav-datos_representante" aria-selected="false">Información Representante</button>
                                            {
                                                activar_pep ?
                                                    <button style={{ backgroundColor: '#003153', color: '#FFFFFF' }} className="nav-link" id="nav-datos_pep-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_pep" type="button" role="tab" aria-controls="nav-datos_pep" aria-selected="false">Información P.E.P.</button>
                                                : ''
                                            }
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-datos_representante" role="tabpanel" aria-labelledby="nav-datos_representante-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS REPRESENTANTE
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-5'>
                                                                            <label htmlFor="tipo_rol_r">Tipo Rol</label>
                                                                            <select name="tipo_rol_r"
                                                                                id="tipo_rol_r"
                                                                                className={((this.verificarError("error_tipo_rol_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Tipo Rol</option>
                                                                                    <option value="ACCIONISTA">ACCIONISTA</option>
                                                                                    <option value="MIEMBRO_JUNTA_DIRECTIVA">MIEMBRO JUNTA DIRECTIVA</option>
                                                                                    <option value="REPRESENTANTE_LEGAL">REPRESENTANTE LEGAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Rol</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="capital_representante_r">Capital %</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="capital_representante_r" id="capital_representante_r" value={capital_representante_r}
                                                                                    className={((this.verificarError("error_capital_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="pep_r">P.E.P.</label>
                                                                            <select name="pep_r"
                                                                                id="pep_r"
                                                                                className={((this.verificarError("error_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarPep(e);
                                                                                }} >
                                                                                    <option value="">Seleccione P.E.P.</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione P.E.P.</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="nombre_representante_r">Nombre</label>
                                                                            <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_representante_r" id="nombre_representante_r" value={nombre_representante_r}
                                                                                className={((this.verificarError("error_nombre_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="apellido_representante_r">Apellido</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="apellido_representante_r" id="apellido_representante_r" value={apellido_representante_r}
                                                                                className={((this.verificarError("error_apellido_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Apellido</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_documento_representante_r">Tipo Documento</label>
                                                                            <select name="tipo_documento_representante_r"
                                                                                id="tipo_documento_representante_r"
                                                                                className={((this.verificarError("error_tipo_documento_representante_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={this.cambioValor}>
                                                                                    <option value="">Seleccione el Tipo Documento</option>
                                                                                    <option value="CC">C.C.</option>
                                                                                    <option value="CE">C.E.</option>
                                                                                    <option value="NIT">NIT</option>
                                                                                    <option value="PP">P.P.</option>
                                                                                    <option value="OTRO">OTRO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="numero_documento_representante_r">Número Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_representante_r" id="numero_documento_representante_r" value={numero_documento_representante_r}
                                                                                    className={((this.verificarError("error_numero_documento_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="digito_verificacion_r">DV</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="digito_verificacion_r" id="digito_verificacion_r" value={digito_verificacion_r}
                                                                                    className={((this.verificarError("error_digito_verificacion_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor relacione en este apartado los socios, accionistas o miembros de junta directiva indicando su porcentaje de participación en el caso de socios o accionistas.
                                                                            </p>
                                                                            <p>
                                                                                El sistema solicitará que este registrado por lo menos un (1) representante legal y un accionista o socio.
                                                                            </p>
                                                                            <p>
                                                                                Se debe ingresar varias veces para crear todos los representantes necesarios (Accionista, Miembro Junta Directiva y Representante Legal)
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-datos_pep" role="tabpanel" aria-labelledby="nav-datos_pep-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS P.E.P. NACIONALES
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <label htmlFor="declaracion_veracidad_pep_r">Declaración Veracidad</label>
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px', marginTop: 0 }} className='col-md-12'>
                                                                            <p>
                                                                                En cumplimiento del Manual del Sistema de Control Interno; el Manual SAGRILAFT (Lavado de Activos, Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM)
                                                                                y el Manual PAEC (Programa de Anticorrupción, Ética y Cumplimiento), adoptados por las empresas TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA, PROELECTRICA S.A.S. E.S.P., CH EL EDEN S.A.S E.S.P.,
                                                                                y ENELCA S.A. E.S.P., en adelante las “Empresas”, los cuales han sido implementados mediante los programas mencionados y conforme a las directrices de la Superintendencia de Sociedades, todos los actores de la contraparte
                                                                                que ostenten la calidad de P.E.P de acuerdo con el Decreto 830 del 26 de Julio de 2021, y cualquier otra norma que lo modifique o reemplace deberán diligenciar la información solicitada en este apartado.
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ marginTop: 0 }} className="col-md-4">
                                                                            <select name="declaracion_veracidad_pep_r"
                                                                                id="declaracion_veracidad_pep_r"
                                                                                className={((this.verificarError("error_declaracion_veracidad_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.aceptarTerminos(e, 'declaracion_veracidad');
                                                                                }}>
                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Declaración Veracidad</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="vinculo_actual_pep_r">Vínculo actual con la Persona Jurídica</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="vinculo_actual_pep_r" id="vinculo_actual_pep_r" value={vinculo_actual_pep_r}
                                                                                className={((this.verificarError("error_vinculo_actual_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Vínculo Actual" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Vínculo Actual</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="cargo_actividad_pep_r">Cargo o Actividad que lo clasifique como P.E.P.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_actividad_pep_r" id="cargo_actividad_pep_r" value={cargo_actividad_pep_r}
                                                                                className={((this.verificarError("error_cargo_actividad_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo o Actividad" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Cargo o Actividad</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="vinculacion_activa_pep_r">Vinculación Activa</label>
                                                                            <select name="vinculacion_activa_pep_r"
                                                                                id="vinculacion_activa_pep_r"
                                                                                className={((this.verificarError("error_vinculacion_activa_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValor(e);
                                                                                    this.activarVinculacion(e);
                                                                                }}>
                                                                                    <option value="">Seleccione la Vinculación</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Vinculación</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="fecha_vinculacion_pep_r">Fecha Vinculación</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_vinculacion_pep_r" id="fecha_vinculacion_pep_r" value={fecha_vinculacion_pep_r}
                                                                                    className={((this.verificarError("error_fecha_vinculacion_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Vinculación" aria-describedby="helpId"
                                                                                    onChange={this.cambioValor} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Vinculación</small>
                                                                        </div>
                                                                        {
                                                                            activar_vinculacion ?
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="fecha_desvinculacion_pep_r">Fecha Desvinculación</label>
                                                                                    <div className="input-group input-group-sm">
                                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_desvinculacion_pep_r" id="fecha_desvinculacion_pep_r" value={fecha_desvinculacion_pep_r}
                                                                                            className={((this.verificarError("error_fecha_desvinculacion_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Desvinculación" aria-describedby="helpId"
                                                                                            onChange={this.cambioValor} />
                                                                                    </div>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Fecha Desvinculación</small>
                                                                                </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            <button className="nav-link active" id="nav-datos_nacionales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_nacionales" type="button" role="tab" aria-controls="nav-datos_nacionales" aria-selected="false">P.E.P. Nacional</button>
                                                            <button className="nav-link" id="nav-datos_extranjeros-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_extranjeros" type="button" role="tab" aria-controls="nav-datos_extranjeros" aria-selected="false">P.E.P. Extranjero</button>
                                                            <button className="nav-link" id="nav-declaracion_pep-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_pep" type="button" role="tab" aria-controls="nav-declaracion_pep" aria-selected="false">Declaración Transparencia</button>
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-datos_nacionales" role="tabpanel" aria-labelledby="nav-datos_nacionales-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarPEPNacionalModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Nacional</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                familiaresPEPNacional.map((itemFamiliaresPEPNacional) => (
                                                                                                    <tr key={itemFamiliaresPEPNacional.id}>
                                                                                                        <td>{itemFamiliaresPEPNacional.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemFamiliaresPEPNacional.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemFamiliaresPEPNacional.identificacion}</td>
                                                                                                        <td>{itemFamiliaresPEPNacional.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarPEPNacionalModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_pep_nacional', itemFamiliaresPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('familiar_pep_nacional', itemFamiliaresPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. NACIONAL EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_nacional_pep_r">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_nacional_pep_r"
                                                                                        id="pregunta_participacion_nacional_pep_r"
                                                                                        className={((this.verificarError("error_pregunta_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarSociedadPepNacional(e);
                                                                                        }}>
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_pep_nacional ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadPEPNacionalModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        sociedadesPEPNacional.map((itemSociedadesPEPNacional) => (
                                                                                                            <tr key={itemSociedadesPEPNacional.id}>
                                                                                                                <td>{itemSociedadesPEPNacional.razon_social}</td>
                                                                                                                <td>{itemSociedadesPEPNacional.identificacion}</td>
                                                                                                                <td>{itemSociedadesPEPNacional.capital}</td>
                                                                                                                <td>{itemSociedadesPEPNacional.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_pep_nacional', itemSociedadesPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('sociedad_pep_nacional', itemSociedadesPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_nacional_pep_r">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_nacional_pep_r"
                                                                                        id="pregunta_cuenta_extranjera_nacional_pep_r"
                                                                                        className={((this.verificarError("error_pregunta_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarCuentaPepNacional(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_pep_nacional ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaPEPNacionalModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        cuentasPEPNacional.map((itemCuentasPEPNacional) => (
                                                                                                            <tr key={itemCuentasPEPNacional.id}>
                                                                                                                <td>{itemCuentasPEPNacional.razon_social}</td>
                                                                                                                <td>{itemCuentasPEPNacional.pais}</td>
                                                                                                                <td>{itemCuentasPEPNacional.numero_cuenta}</td>
                                                                                                                <td>{itemCuentasPEPNacional.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_pep_nacional', itemCuentasPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('cuenta_pep_nacional', itemCuentasPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-datos_extranjeros" role="tabpanel" aria-labelledby="nav-datos_extranjeros-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            P.E.P. EXTRANJEROS
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_beneficiario_extranjero_pep_r">Indique si la sociedad registra como beneficiarios finales o tiene como administradores (En el sentido establecido en el art. 22 de la Ley 222 de 1995) personas identificadas como un PEP Extranjero.</label>
                                                                                            <select name="pregunta_beneficiario_extranjero_pep_r"
                                                                                                id="pregunta_beneficiario_extranjero_pep_r"
                                                                                                className={((this.verificarError("error_pregunta_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.activarPepExtranjero(e);
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* {
                                                                                        activar_pep_extranjero ?
                                                                                            <>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="nombre_beneficiario_extranjero_pep_r">Nombre</label>
                                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_beneficiario_extranjero_pep_r" id="nombre_beneficiario_extranjero_pep_r" value={nombre_beneficiario_extranjero_pep_r}
                                                                                                            className={((this.verificarError("error_nombre_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Beneficiario" aria-describedby="helpId"
                                                                                                            onChange={this.cambioValor} />
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Nombre Beneficiario</small>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="numero_documento_beneficiario_extranjero_pep_r">Número Documento</label>
                                                                                                        <div className="input-group input-group-sm">
                                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                                            <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_beneficiario_extranjero_pep_r" id="numero_documento_beneficiario_extranjero_pep_r" value={numero_documento_beneficiario_extranjero_pep_r}
                                                                                                                className={((this.verificarError("error_numero_documento_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                                                onChange={this.cambioValor} max="37" />
                                                                                                        </div>
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-12">
                                                                                                        <label htmlFor="vinculo_actual_beneficiario_extranjero_pep_r">Vínculo actual con la Persona Jurídica</label>
                                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="vinculo_actual_beneficiario_extranjero_pep_r" id="vinculo_actual_beneficiario_extranjero_pep_r" value={vinculo_actual_beneficiario_extranjero_pep_r}
                                                                                                            className={((this.verificarError("error_vinculo_actual_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Vínculo Actual" aria-describedby="helpId"
                                                                                                            onChange={this.cambioValor} max="37" />
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Vínculo Actual</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-12">
                                                                                                        <label htmlFor="cargo_actividad_beneficiario_extranjero_pep_r">Cargo o Actividad que lo clasifique como P.E.P.</label>
                                                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_actividad_beneficiario_extranjero_pep_r" id="cargo_actividad_beneficiario_extranjero_pep_r" value={cargo_actividad_beneficiario_extranjero_pep_r}
                                                                                                            className={((this.verificarError("error_cargo_actividad_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo o Actividad" aria-describedby="helpId"
                                                                                                            onChange={this.cambioValor} max="37" />
                                                                                                        <small id="helpId" className="invalid-feedback">Digite el Cargo o Actividad</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ marginTop: 0 }} className="row g-3">
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="fecha_vinculacion_beneficiario_extranjero_pep_r">Fecha Vinculación</label>
                                                                                                        <div className="input-group input-group-sm">
                                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                                            <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_vinculacion_beneficiario_extranjero_pep_r" id="fecha_vinculacion_beneficiario_extranjero_pep_r" value={fecha_vinculacion_beneficiario_extranjero_pep_r}
                                                                                                                className={((this.verificarError("error_fecha_vinculacion_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Vinculación" aria-describedby="helpId"
                                                                                                                onChange={this.cambioValor} />
                                                                                                        </div>
                                                                                                        <small id="helpId" className="invalid-feedback">Seleccione la Fecha Vinculación</small>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="fecha_desvinculacion_beneficiario_extranjero_pep_r">Fecha Desvinculación</label>
                                                                                                        <div className="input-group input-group-sm">
                                                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                                            <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_desvinculacion_beneficiario_extranjero_pep_r" id="fecha_desvinculacion_beneficiario_extranjero_pep_r" value={fecha_desvinculacion_beneficiario_extranjero_pep_r}
                                                                                                                className={((this.verificarError("error_fecha_desvinculacion_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Desvinculación" aria-describedby="helpId"
                                                                                                                onChange={this.cambioValor} />
                                                                                                        </div>
                                                                                                        <small id="helpId" className="invalid-feedback">Seleccione la Fecha Desvinculación</small>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        : ''
                                                                                    } */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarPEPExtranjeroModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Extranjero</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                familiaresPEPExtranjero.map((itemFamiliaresPEPExtranjero) => (
                                                                                                    <tr key={itemFamiliaresPEPExtranjero.id}>
                                                                                                        <td>{itemFamiliaresPEPExtranjero.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemFamiliaresPEPExtranjero.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemFamiliaresPEPExtranjero.identificacion}</td>
                                                                                                        <td>{itemFamiliaresPEPExtranjero.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarPEPExtranjeroModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_pep_extranjero', itemFamiliaresPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('familiar_pep_extranjero', itemFamiliaresPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. EXTRANJERO EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_extranjero_pep_r">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_extranjero_pep_r"
                                                                                        id="pregunta_participacion_extranjero_pep_r"
                                                                                        className={((this.verificarError("error_pregunta_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarSociedadPepExtranjero(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_pep_extranjero ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadPEPExtranjeroModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        sociedadesPEPExtranjero.map((itemSociedadesPEPExtranjero) => (
                                                                                                            <tr key={itemSociedadesPEPExtranjero.id}>
                                                                                                                <td>{itemSociedadesPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemSociedadesPEPExtranjero.identificacion}</td>
                                                                                                                <td>{itemSociedadesPEPExtranjero.capital}</td>
                                                                                                                <td>{itemSociedadesPEPExtranjero.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_pep_extranjero', itemSociedadesPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('sociedad_pep_extranjero', itemSociedadesPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_extranjero_pep_r">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_extranjero_pep_r"
                                                                                        id="pregunta_cuenta_extranjera_extranjero_pep_r"
                                                                                        className={((this.verificarError("error_pregunta_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValor(e);
                                                                                            this.activarCuentaPepExtranjero(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_pep_extranjero ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaPEPExtranjeroModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        cuentasPEPExtranjero.map((itemCuentasPEPExtranjero) => (
                                                                                                            <tr key={itemCuentasPEPExtranjero.id}>
                                                                                                                <td>{itemCuentasPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemCuentasPEPExtranjero.pais}</td>
                                                                                                                <td>{itemCuentasPEPExtranjero.numero_cuenta}</td>
                                                                                                                <td>{itemCuentasPEPExtranjero.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_pep_extranjero', itemCuentasPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('cuenta_pep_extranjero', itemCuentasPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-declaracion_pep" role="tabpanel" aria-labelledby="nav-declaracion_pep-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            DECLARACIÓN DE TRANSPARENCIA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta1_pep_r">Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta1_pep_r"
                                                                                                id="pregunta1_pep_r"
                                                                                                className={((this.verificarError("error_pregunta1_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta1_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta2_pep_r">Que la persona jurídica que represento o yo no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                                            <select name="pregunta2_pep_r"
                                                                                                id="pregunta2_pep_r"
                                                                                                className={((this.verificarError("error_pregunta2_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta2_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta3_pep_r">Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado con las empresas del Grupo Corporativo, no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta3_pep_r"
                                                                                                id="pregunta3_pep_r"
                                                                                                className={((this.verificarError("error_pregunta3_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta3_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta4_pep_r">Que en la ejecución del contrato o el desarrollo de las actividades en que consiste el negocio jurídico con las empresas del Grupo Corporativo, no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta4_pep_r"
                                                                                                id="pregunta4_pep_r"
                                                                                                className={((this.verificarError("error_pregunta4_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta4_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta5_pep_r">Que según aplique, la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                                            <select name="pregunta5_pep_r"
                                                                                                id="pregunta5_pep_r"
                                                                                                className={((this.verificarError("error_pregunta5_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta5_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta6_pep_r">Que, la entidad que represento, sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, no nos encontramos en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando las empresas del Grupo Corporativo facultadas para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas aparece en dichas listas.</label>
                                                                                            <select name="pregunta6_pep_r"
                                                                                                id="pregunta6_pep_r"
                                                                                                className={((this.verificarError("error_pregunta6_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta6_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta7_pep_r">Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA/FT/FPADM y/o contrabando, estando las empresas del Grupo Corporativo facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que Yo o alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a las empresas del Grupo Corporativo frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                                            <select name="pregunta7_pep_r"
                                                                                                id="pregunta7_pep_r"
                                                                                                className={((this.verificarError("error_pregunta7_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta7_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta8_pep_r">Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a las empresas del Grupo Corporativo.</label>
                                                                                            <select name="pregunta8_pep_r"
                                                                                                id="pregunta8_pep_r"
                                                                                                className={((this.verificarError("error_pregunta8_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta8_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta9_pep_r">Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con las empresas del Grupo Corporativo es veraz y exacta, estando las empresas del Grupo Corporativo facultadas para efectuar las validaciones y verificaciones que considere pertinentes. En todo caso, el Grupo Corporativo podrá dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que la información aportada no es veraz y exacta.</label>
                                                                                            <select name="pregunta9_pep_r"
                                                                                                id="pregunta9_pep_r"
                                                                                                className={((this.verificarError("error_pregunta9_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta9_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta10_pep_r">La persona Jurídica declara que ha sido informada por las empresas del Grupo Corporativo, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                                            <select name="pregunta10_pep_r"
                                                                                                id="pregunta10_pep_r"
                                                                                                className={((this.verificarError("error_pregunta10_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta10_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta11_pep_r">Que en el caso que la Persona Jurídica que represento sea considerado por las empresas del Grupo Corporativo, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                                            <select name="pregunta11_pep_r"
                                                                                                id="pregunta11_pep_r"
                                                                                                className={((this.verificarError("error_pregunta11_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValor(e);
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'pregunta11_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor complete la información solicitada en el formato siguiente teniendo en cuenta los parametros definidos en el <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=167367'>Decreto 830 del 26 de Julio de 2021</a> y sus normas complementarias
                                                                            </p>
                                                                            <p>
                                                                                Es importante tener en cuenta estas consideraciones particulares:
                                                                            </p>
                                                                            <p>
                                                                                <b>Familiares del P.E.P. Nacional</b>
                                                                            </p>
                                                                            <p>
                                                                                Los datos deben ser diligenciados en relación a los familiares de cada PEP, así: Para el cónyuge o compañero permanente de hecho o de derecho y para los familiares hasta el segundo grado de consanguinidad, primero de afinidad y primero civil.
                                                                            </p>
                                                                            <p>
                                                                                <b>Participaciones del PEP Nacional en Sociedades o Asociaciones, Patrimonio(s) Autónomo(s) o Fiducia(s)</b>
                                                                            </p>
                                                                            <p>
                                                                                Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los datos solicitados.
                                                                            </p>
                                                                            <p>
                                                                                <b>Cuentas financieras extranjeras del PEP Nacional </b>
                                                                            </p>
                                                                            <p>
                                                                                Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los datos solicitados.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosRepresentante}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Representante</button>&nbsp;&nbsp;
                                                <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'gobierno')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoFamiliarPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoFamiliarPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoFamiliarPEPNacionalModalLabel">Nuevo Familiar P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-familiares_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="nombre_familiar_nacional_pep_r">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_familiar_nacional_pep_r" id="nombre_familiar_nacional_pep_r" value={nombre_familiar_nacional_pep_r}
                                                                        className={((this.verificarError("error_nombre_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco_familiar_nacional_pep_r">Parentesco</label>
                                                                    <select name="parentesco_familiar_nacional_pep_r"
                                                                        id="parentesco_familiar_nacional_pep_r"
                                                                        className={((this.verificarError("error_parentesco_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_documento_familiar_nacional_pep_r">Tipo Documento</label>
                                                                    <select name="tipo_documento_familiar_nacional_pep_r"
                                                                        id="tipo_documento_familiar_nacional_pep_r"
                                                                        className={((this.verificarError("error_tipo_documento_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_familiar_nacional_pep_r">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_familiar_nacional_pep_r" id="numero_documento_familiar_nacional_pep_r" value={numero_documento_familiar_nacional_pep_r}
                                                                            className={((this.verificarError("error_numero_documento_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosFamiliarPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'familiar_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoSociedadPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoSociedadPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoSociedadPEPNacionalModalLabel">Nueva Sociedad P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-sociedades_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_participacion_nacional_pep_r">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_participacion_nacional_pep_r" id="razon_social_participacion_nacional_pep_r" value={razon_social_participacion_nacional_pep_r}
                                                                        className={((this.verificarError("error_razon_social_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_participacion_nacional_pep_r">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_participacion_nacional_pep_r" id="numero_documento_participacion_nacional_pep_r" value={numero_documento_participacion_nacional_pep_r}
                                                                            className={((this.verificarError("error_numero_documento_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="porcentaje_participacion_nacional_pep_r">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="porcentaje_participacion_nacional_pep_r" id="porcentaje_participacion_nacional_pep_r" value={porcentaje_participacion_nacional_pep_r}
                                                                            className={((this.verificarError("error_porcentaje_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control_participacion_nacional_pep_r">Tipo Control</label>
                                                                    <select name="tipo_control_participacion_nacional_pep_r"
                                                                        id="tipo_control_participacion_nacional_pep_r"
                                                                        className={((this.verificarError("error_tipo_control_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.activarOtrosTipoControlPepNacional(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_pep_nacional ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="otros_tipo_control_participacion_nacional_pep_r">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="otros_tipo_control_participacion_nacional_pep_r" id="otros_tipo_control_participacion_nacional_pep_r" value={otros_tipo_control_participacion_nacional_pep_r}
                                                                                className={((this.verificarError("error_otros_tipo_control_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="detalle_tipo_control_participacion_nacional_pep_r">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="detalle_tipo_control_participacion_nacional_pep_r" id="detalle_tipo_control_participacion_nacional_pep_r" value={detalle_tipo_control_participacion_nacional_pep_r}
                                                                        className={((this.verificarError("error_detalle_tipo_control_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosSociedadPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'sociedad_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoCuentaPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoCuentaPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoCuentaPEPNacionalModalLabel">Nueva Cuenta P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-cuentas_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_cuenta_extranjera_nacional_pep_r">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_cuenta_extranjera_nacional_pep_r" id="razon_social_cuenta_extranjera_nacional_pep_r" value={razon_social_cuenta_extranjera_nacional_pep_r}
                                                                        className={((this.verificarError("error_razon_social_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="pais_cuenta_extranjera_nacional_pep_r">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="pais_cuenta_extranjera_nacional_pep_r" id="pais_cuenta_extranjera_nacional_pep_r" value={pais_cuenta_extranjera_nacional_pep_r}
                                                                        className={((this.verificarError("error_pais_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_cuenta_extranjera_nacional_pep_r">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_extranjera_nacional_pep_r" id="numero_cuenta_extranjera_nacional_pep_r" value={numero_cuenta_extranjera_nacional_pep_r}
                                                                            className={((this.verificarError("error_numero_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="clase_propiedad_cuenta_extranjera_nacional_pep_r">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="clase_propiedad_cuenta_extranjera_nacional_pep_r" id="clase_propiedad_cuenta_extranjera_nacional_pep_r" value={clase_propiedad_cuenta_extranjera_nacional_pep_r}
                                                                        className={((this.verificarError("error_clase_propiedad_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosCuentaPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'cuenta_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoFamiliarPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoFamiliarPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoFamiliarPEPExtranjeroModalLabel">Nuevo Familiar P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-familiares_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="nombre_familiar_extranjero_pep_r">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_familiar_extranjero_pep_r" id="nombre_familiar_extranjero_pep_r" value={nombre_familiar_extranjero_pep_r}
                                                                        className={((this.verificarError("error_nombre_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Familiar" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre Familiar</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco_familiar_extranjero_pep_r">Parentesco</label>
                                                                    <select name="parentesco_familiar_extranjero_pep_r"
                                                                        id="parentesco_familiar_extranjero_pep_r"
                                                                        className={((this.verificarError("error_parentesco_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_documento_familiar_extranjero_pep_r">Tipo Documento</label>
                                                                    <select name="tipo_documento_familiar_extranjero_pep_r"
                                                                        id="tipo_documento_familiar_extranjero_pep_r"
                                                                        className={((this.verificarError("error_tipo_documento_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_familiar_extranjero_pep_r">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_familiar_extranjero_pep_r" id="numero_documento_familiar_extranjero_pep_r" value={numero_documento_familiar_extranjero_pep_r}
                                                                            className={((this.verificarError("error_numero_documento_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosFamiliarPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'familiar_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoSociedadPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoSociedadPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoSociedadPEPExtranjeroModalLabel">Nueva Sociedad P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-sociedades_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_participacion_extranjero_pep_r">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_participacion_extranjero_pep_r" id="razon_social_participacion_extranjero_pep_r" value={razon_social_participacion_extranjero_pep_r}
                                                                        className={((this.verificarError("error_razon_social_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="numero_documento_participacion_extranjero_pep_r">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_participacion_extranjero_pep_r" id="numero_documento_participacion_extranjero_pep_r" value={numero_documento_participacion_extranjero_pep_r}
                                                                            className={((this.verificarError("error_numero_documento_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="porcentaje_participacion_extranjero_pep_r">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="porcentaje_participacion_extranjero_pep_r" id="porcentaje_participacion_extranjero_pep_r" value={porcentaje_participacion_extranjero_pep_r}
                                                                            className={((this.verificarError("error_porcentaje_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control_participacion_extranjero_pep_r">Tipo Control</label>
                                                                    <select name="tipo_control_participacion_extranjero_pep_r"
                                                                        id="tipo_control_participacion_extranjero_pep_r"
                                                                        className={((this.verificarError("error_tipo_control_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.activarOtrosTipoControlPepExtranjero(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_pep_extranjero ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="otros_tipo_control_participacion_extranjero_pep_r">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="otros_tipo_control_participacion_extranjero_pep_r" id="otros_tipo_control_participacion_extranjero_pep_r" value={otros_tipo_control_participacion_extranjero_pep_r}
                                                                                className={((this.verificarError("error_otros_tipo_control_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="detalle_tipo_control_participacion_extranjero_pep_r">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="detalle_tipo_control_participacion_extranjero_pep_r" id="detalle_tipo_control_participacion_extranjero_pep_r" value={detalle_tipo_control_participacion_extranjero_pep_r}
                                                                        className={((this.verificarError("error_detalle_tipo_control_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosSociedadPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'sociedad_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoCuentaPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoCuentaPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoCuentaPEPExtranjeroModalLabel">Nueva Cuenta P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-cuentas_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="razon_social_cuenta_extranjera_extranjero_pep_r">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_cuenta_extranjera_extranjero_pep_r" id="razon_social_cuenta_extranjera_extranjero_pep_r" value={razon_social_cuenta_extranjera_extranjero_pep_r}
                                                                        className={((this.verificarError("error_razon_social_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="pais_cuenta_extranjera_extranjero_pep_r">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="pais_cuenta_extranjera_extranjero_pep_r" id="pais_cuenta_extranjera_extranjero_pep_r" value={pais_cuenta_extranjera_extranjero_pep_r}
                                                                        className={((this.verificarError("error_pais_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_cuenta_extranjera_extranjero_pep_r">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_extranjera_extranjero_pep_r" id="numero_cuenta_extranjera_extranjero_pep_r" value={numero_cuenta_extranjera_extranjero_pep_r}
                                                                            className={((this.verificarError("error_numero_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="clase_propiedad_cuenta_extranjera_extranjero_pep_r">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="clase_propiedad_cuenta_extranjera_extranjero_pep_r" id="clase_propiedad_cuenta_extranjera_extranjero_pep_r" value={clase_propiedad_cuenta_extranjera_extranjero_pep_r}
                                                                        className={((this.verificarError("error_clase_propiedad_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosCuentaPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Registrar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'cuenta_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoBeneficiarioESALModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoBeneficiarioESALModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoBeneficiarioESALModalLabel">Nuevo Beneficiario</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-beneficiarios_esal'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS BENEFICIARIO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="banco_beneficiario_esal">Banco</label>
                                                                    <select name="banco_beneficiario_esal"
                                                                        id="banco_beneficiario_esal"
                                                                        className={((this.verificarError("error_banco_beneficiario_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Banco</option>
                                                                            {
                                                                                bancos_esal.map((itemBancoEsal) => (
                                                                                    itemBancoEsal.sector === 'BANCOS' ?
                                                                                        <option style={{ textTransform: 'uppercase' }}  key={itemBancoEsal.id} value={itemBancoEsal.nombre_superfinanciera_entidad}>{itemBancoEsal.nombre_superfinanciera_entidad}</option>
                                                                                    : ''
                                                                                ))
                                                                            }
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Banco</small>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_cuenta_beneficiario_esal">Tipo Cuenta</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <select name="tipo_cuenta_beneficiario_esal"
                                                                        id="tipo_cuenta_beneficiario_esal"
                                                                        className={((this.verificarError("error_tipo_cuenta_beneficiario_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Cuenta</option>
                                                                            <option value="AHORROS">AHORROS</option>
                                                                            <option value="CORRIENTE">CORRIENTE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_cuenta_beneficiario_esal">No. Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_cuenta_beneficiario_esal" id="numero_cuenta_beneficiario_esal" value={numero_cuenta_beneficiario_esal}
                                                                            className={((this.verificarError("error_numero_cuenta_beneficiario_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No. Cuenta" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el No. Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <hr style={{ marginBottom: 0 }} />
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="beneficiario_esal">Beneficiario</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="beneficiario_esal" id="beneficiario_esal" value={beneficiario_esal}
                                                                        className={((this.verificarError("error_beneficiario_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Beneficiario" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Beneficiario</small>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_documento_beneficiario_esal">Tipo Documento</label>
                                                                    <select name="tipo_documento_beneficiario_esal"
                                                                        id="tipo_documento_beneficiario_esal"
                                                                        className={((this.verificarError("error_tipo_documento_beneficiario_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_documento_beneficiario_esal">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_beneficiario_esal" id="numero_documento_beneficiario_esal" value={numero_documento_beneficiario_esal}
                                                                            className={((this.verificarError("error_numero_documento_beneficiario_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosBeneficiarioESAL}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Beneficiario</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'beneficiario_esal')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoRepresentanteESALModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoRepresentanteESALModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoRepresentanteESALModalLabel">Nuevo Gobierno</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-representantes_esal'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS GOBIERNO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_relacion_representante_esal">Tipo Relación</label>
                                                                    <select name="tipo_relacion_representante_esal"
                                                                        id="tipo_relacion_representante_esal"
                                                                        className={((this.verificarError("error_tipo_relacion_representante_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Relación</option>
                                                                            <option value="FUNDADOR">FUNDADOR</option>
                                                                            <option value="ASOCIADO">ASOCIADO</option>
                                                                            <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                                                                            <option value="REPRESENTANTE_LEGAL">REPRESENTANTE LEGAL</option>
                                                                            <option value="BENEFICIARIO">BENEFICIARIO</option>
                                                                            <option value="APORTANTE_DONANTE">APORTANTE / DONANTE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Relación</small>
                                                                </div>
                                                            </div>
                                                            <hr style={{ marginBottom: 0 }} />
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="representante_esal">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="representante_esal" id="representante_esal" value={representante_esal}
                                                                        className={((this.verificarError("error_representante_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Representante</small>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_documento_representante_esal">Tipo Documento</label>
                                                                    <select name="tipo_documento_representante_esal"
                                                                        id="tipo_documento_representante_esal"
                                                                        className={((this.verificarError("error_tipo_documento_representante_esal")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_documento_representante_esal">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_representante_esal" id="numero_documento_representante_esal" value={numero_documento_representante_esal}
                                                                            className={((this.verificarError("error_numero_documento_representante_esal")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosRepresentanteESAL}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Representante</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'representante_esal')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoAccionistaModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoAccionistaModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoAccionistaModalLabel">Nuevo Accionista</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-accionistas'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS ACCIONISTA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="accionista_ae">Accionista</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="accionista_ae" id="accionista_ae" value={accionista_ae}
                                                                        className={((this.verificarError("error_accionista_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Accionista" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Accionista</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_documento_accionista_ae">Tipo Documento</label>
                                                                    <select name="tipo_documento_accionista_ae"
                                                                        id="tipo_documento_accionista_ae"
                                                                        className={((this.verificarError("error_tipo_documento_accionista_ae")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="1">C.C.</option>
                                                                            <option value="2">C.E.</option>
                                                                            <option value="3">NIT</option>
                                                                            <option value="4">P.P.</option>
                                                                            <option value="5">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_documento_accionista_ae">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_accionista_ae" id="numero_documento_accionista_ae" value={numero_documento_accionista_ae}
                                                                            className={((this.verificarError("error_numero_documento_accionista_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="digito_verificacion_ae">DV</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="digito_verificacion_ae" id="digito_verificacion_ae" value={digito_verificacion_ae}
                                                                            className={((this.verificarError("error_digito_verificacion_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="capital_accionista_ae">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="capital_accionista_ae" id="capital_accionista_ae" value={capital_accionista_ae}
                                                                            className={((this.verificarError("error_capital_accionista_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosAccionistas}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Accionista</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoMiembroJuntaDirectivaModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoMiembroJuntaDirectivaModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoMiembroJuntaDirectivaModalLabel">Nuevo Miembro Junta Directiva</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-junta_directiva'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS MIEMBRO JUNTA DIRECTIVA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="miembro_junta_directiva_jd">Miembro Junta Directiva</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="miembro_junta_directiva_jd" id="miembro_junta_directiva_jd" value={miembro_junta_directiva_jd}
                                                                        className={((this.verificarError("error_miembro_junta_directiva_jd")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Miembro Junta Directiva" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Miembro Junta Directiva</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_documento_miembro_junta_directiva_jd">Tipo Documento</label>
                                                                    <select name="tipo_documento_miembro_junta_directiva_jd"
                                                                        id="tipo_documento_miembro_junta_directiva_jd"
                                                                        className={((this.verificarError("error_tipo_documento_miembro_junta_directiva_jd")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="1">C.C.</option>
                                                                            <option value="2">C.E.</option>
                                                                            <option value="3">NIT</option>
                                                                            <option value="4">P.P.</option>
                                                                            <option value="5">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_documento_miembro_junta_directiva_jd">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="numero_documento_miembro_junta_directiva_jd" id="numero_documento_miembro_junta_directiva_jd" value={numero_documento_miembro_junta_directiva_jd}
                                                                            className={((this.verificarError("error_numero_documento_miembro_junta_directiva_jd")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="digito_verificacion_jd">DV</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="digito_verificacion_jd" id="digito_verificacion_jd" value={digito_verificacion_jd}
                                                                            className={((this.verificarError("error_digito_verificacion_jd")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosJuntaDirectiva}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Miembro Junta Directiva</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="PDFFileModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="PDFFileModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="PDFFileModalLabel">Archivo Subido</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-xl-12' style={{marginBottom: '30px'}}>
                                        {/* <iframe style={{ width: '100%', height: '600px'}} src={`data:application/pdf;base64,${pdfDGBase64}`}></iframe> */}
                                        <iframe style={{ width: '100%', height: '600px'}} src={pdfDGBase64}></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarBeneficiarioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarBeneficiarioModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarBeneficiarioModalLabel">Editar Beneficiario</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_beneficiario'>
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-editar_datos_beneficiario-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_beneficiario" type="button" role="tab" aria-controls="nav-editar_datos_beneficiario" aria-selected="false">Información Beneficiario</button>
                                            {
                                                activar_pep_beneficiario_editar ?
                                                    <button style={{ backgroundColor: '#003153', color: '#FFFFFF' }} className="nav-link" id="nav-editar_datos_pep_beneficiario-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_pep_beneficiario" type="button" role="tab" aria-controls="nav-editar_datos_pep_beneficiario" aria-selected="false">Información P.E.P.</button>
                                                : ''
                                            }
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-editar_datos_beneficiario" role="tabpanel" aria-labelledby="nav-editar_datos_beneficiario-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS BENEFICIARIO
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_nombre_beneficiario_b">Nombre Beneficiario</label>
                                                                            <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                            <input type="hidden" name='e_id_beneficiario_b' id='e_id_beneficiario_b' defaultValue={editBeneficiarios.id} />
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_nombre_beneficiario_b" id="e_nombre_beneficiario_b" defaultValue={editBeneficiarios.nombre}
                                                                                className={((this.verificarError("error_e_nombre_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Beneficiario" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Nombre Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_apellido_beneficiario_b">Apellido Beneficiario</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_apellido_beneficiario_b" id="e_apellido_beneficiario_b" defaultValue={editBeneficiarios.apellido}
                                                                                className={((this.verificarError("error_e_apellido_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Apellido Beneficiario" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Apellido Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_numero_documento_beneficiario_b">Número Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_beneficiario_b" id="e_numero_documento_beneficiario_b" defaultValue={editBeneficiarios.identificacion}
                                                                                    className={((this.verificarError("error_e_numero_documento_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'beneficiario')
                                                                                    }} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_telefono_beneficiario_b">Teléfono Beneficiario</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="e_telefono_beneficiario_b" id="e_telefono_beneficiario_b" defaultValue={editBeneficiarios.telefono}
                                                                                    className={((this.verificarError("error_e_telefono_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Beneficiario" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'beneficiario')
                                                                                    }} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_telefono_movil_beneficiario_b">Teléfono Móvil Beneficiario</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="e_telefono_movil_beneficiario_b" id="e_telefono_movil_beneficiario_b" defaultValue={editBeneficiarios.telefono_movil}
                                                                                    className={((this.verificarError("error_e_telefono_movil_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Móvil Beneficiario" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'beneficiario')
                                                                                    }} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono Móvil Beneficiario</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_correo_electronico_beneficiario_b">Correo Elect. Beneficiario</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="e_correo_electronico_beneficiario_b" id="e_correo_electronico_beneficiario_b" defaultValue={editBeneficiarios.email}
                                                                                    className={((this.verificarError("error_e_correo_electronico_beneficiario_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Elect. Beneficiario" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'beneficiario')
                                                                                    }} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Elect. Beneficiario</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="pep">P.E.P.</label>
                                                                            <select name="pep"
                                                                                id="e_pep_b"
                                                                                value={editBeneficiarios.pep}
                                                                                options={editBeneficiarios.pep}
                                                                                className={((this.verificarError("error_e_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                    this.activarPepBeneficiarioEditar(e);
                                                                                }} >
                                                                                    <option value="">Seleccione P.E.P.</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione P.E.P.</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor complete el formulario con la información solicitada por cada Beneficiario Final, por favor tenga en cuenta que si el beneficiario ostenta la condición de Persona Políticamente Expuesta debe completar obligatoriamente las preguntas adicionales.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-editar_datos_pep_beneficiario" role="tabpanel" aria-labelledby="nav-editar_datos_pep_beneficiario-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS P.E.P. NACIONALES
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <label htmlFor="declaracion_veracidad_pep">Declaración Veracidad</label>
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px', marginTop: 0 }} className='col-md-12'>
                                                                            <p>
                                                                                En cumplimiento del Manual del Sistema de Control Interno; el Manual SAGRILAFT (Lavado de Activos, Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM)
                                                                                y el Manual PAEC (Programa de Anticorrupción, Ética y Cumplimiento), adoptados por las empresas TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA, PROELECTRICA S.A.S. E.S.P., CH EL EDEN S.A.S E.S.P.,
                                                                                y ENELCA S.A. E.S.P., en adelante las “Empresas”, los cuales han sido implementados mediante los programas mencionados y conforme a las directrices de la Superintendencia de Sociedades, todos los actores de la contraparte
                                                                                que ostenten la calidad de P.E.P de acuerdo con el Decreto 830 del 26 de Julio de 2021, y cualquier otra norma que lo modifique o reemplace deberán diligenciar la información solicitada en este apartado.
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ marginTop: 0 }} className="col-md-4">
                                                                            <select name="declaracion_veracidad_pep"
                                                                                id="e_declaracion_veracidad_pep_b"
                                                                                value={editBeneficiarios.declaracion_veracidad_pep}
                                                                                options={editBeneficiarios.declaracion_veracidad_pep}
                                                                                className={((this.verificarError("error_e_declaracion_veracidad_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario')
                                                                                    this.aceptarTerminos(e, 'declaracion_veracidad_beneficiario');
                                                                                }}>
                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Declaración Veracidad</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_vinculo_actual_pep_b">Vínculo actual con la Persona Jurídica</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_vinculo_actual_pep_b" id="e_vinculo_actual_pep_b" defaultValue={editBeneficiarios.vinculo_actual_pep}
                                                                                className={((this.verificarError("error_e_vinculo_actual_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Vínculo Actual" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario')
                                                                                }} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Vínculo Actual</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_cargo_actividad_pep_b">Cargo o Actividad que lo clasifique como P.E.P.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_cargo_actividad_pep_b" id="e_cargo_actividad_pep_b" defaultValue={editBeneficiarios.cargo_actividad_pep}
                                                                                className={((this.verificarError("error_e_cargo_actividad_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo o Actividad" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario')
                                                                                }} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Cargo o Actividad</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="vinculacion_activa_pep">Vinculación Activa</label>
                                                                            <select name="vinculacion_activa_pep"
                                                                                id="e_vinculacion_activa_pep_b"
                                                                                value={editBeneficiarios.vinculacion_activa_pep}
                                                                                options={editBeneficiarios.vinculacion_activa_pep}
                                                                                className={((this.verificarError("error_e_vinculacion_activa_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                    this.activarVinculacionBeneficiarioEditar(e);
                                                                                }}>
                                                                                    <option value="">Seleccione la Vinculación</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Vinculación</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_fecha_vinculacion_pep_b">Fecha Vinculación</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="e_fecha_vinculacion_pep_b" id="e_fecha_vinculacion_pep_b" defaultValue={editBeneficiarios.fecha_vinculacion_pep}
                                                                                    className={((this.verificarError("error_e_fecha_vinculacion_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Vinculación" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'beneficiario')
                                                                                    }} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Vinculación</small>
                                                                        </div>
                                                                        {
                                                                            activar_vinculacion_beneficiario_editar ?
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="e_fecha_desvinculacion_pep_b">Fecha Desvinculación</label>
                                                                                    <div className="input-group input-group-sm">
                                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="date" name="e_fecha_desvinculacion_pep_b" id="e_fecha_desvinculacion_pep_b" defaultValue={editBeneficiarios.fecha_desvinculacion_pep}
                                                                                            className={((this.verificarError("error_e_fecha_desvinculacion_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Desvinculación" aria-describedby="helpId"
                                                                                            onChange={(e) => {
                                                                                                this.cambioValorEditar(e, 'beneficiario')
                                                                                            }} />
                                                                                    </div>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Fecha Desvinculación</small>
                                                                                </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            <button className="nav-link active" id="nav-editar_datos_beneficiarios_nacionales-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_beneficiarios_nacionales" type="button" role="tab" aria-controls="nav-editar_datos_beneficiarios_nacionales" aria-selected="false">P.E.P. Nacional</button>
                                                            <button className="nav-link" id="nav-editar_datos_beneficiarios_extranjeros-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_beneficiarios_extranjeros" type="button" role="tab" aria-controls="nav-editar_datos_beneficiarios_extranjeros" aria-selected="false">P.E.P. Extranjero</button>
                                                            <button className="nav-link" id="nav-editar_declaracion_beneficiario_pep-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_declaracion_beneficiario_pep" type="button" role="tab" aria-controls="nav-editar_declaracion_beneficiario_pep" aria-selected="false">Declaración Transparencia</button>
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-editar_datos_beneficiarios_nacionales" role="tabpanel" aria-labelledby="nav-editar_datos_beneficiarios_nacionales-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarBeneficiarioPEPNacionalModal" slot='end' onClick={() => this.activarEdicion('familiar_beneficiario_pep_nacional')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Nacional</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                editFamiliaresBeneficiarioPEPNacional.map((itemEditFamiliaresBeneficiarioPEPNacional) => (
                                                                                                    <tr key={itemEditFamiliaresBeneficiarioPEPNacional.id}>
                                                                                                        <td>{itemEditFamiliaresBeneficiarioPEPNacional.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemEditFamiliaresBeneficiarioPEPNacional.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemEditFamiliaresBeneficiarioPEPNacional.identificacion}</td>
                                                                                                        <td>{itemEditFamiliaresBeneficiarioPEPNacional.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarBeneficiarioPEPNacionalModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_beneficiario_pep_nacional', itemEditFamiliaresBeneficiarioPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('edit_familiar_beneficiario_pep_nacional', itemEditFamiliaresBeneficiarioPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. NACIONAL EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_nacional_pep">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_nacional_pep"
                                                                                        id="e_pregunta_participacion_nacional_pep_b"
                                                                                        value={editBeneficiarios.pregunta_participacion_nacional_pep}
                                                                                        options={editBeneficiarios.pregunta_participacion_nacional_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'beneficiario');
                                                                                            this.activarSociedadBeneficiarioPepNacionalEditar(e);
                                                                                        }}>
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_beneficiario_pep_nacional_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadBeneficiarioPEPNacionalModal" slot='end' onClick={() => this.activarEdicion('sociedad_beneficiario_pep_nacional')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editSociedadesBeneficiarioPEPNacional.map((itemEditSociedadesBeneficiarioPEPNacional) => (
                                                                                                            <tr key={itemEditSociedadesBeneficiarioPEPNacional.id}>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPNacional.razon_social}</td>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPNacional.identificacion}</td>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPNacional.capital}</td>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPNacional.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadBeneficiarioPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_beneficiario_pep_nacional', itemEditSociedadesBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_sociedad_beneficiario_pep_nacional', itemEditSociedadesBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_nacional_pep">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_nacional_pep"
                                                                                        id="e_pregunta_cuenta_extranjera_nacional_pep_b"
                                                                                        value={editBeneficiarios.pregunta_cuenta_extranjera_nacional_pep}
                                                                                        options={editBeneficiarios.pregunta_cuenta_extranjera_nacional_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'beneficiario');
                                                                                            this.activarCuentaBeneficiarioPepNacionalEditar(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_beneficiario_pep_nacional_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaBeneficiarioPEPNacionalModal" slot='end' onClick={() => this.activarEdicion('cuenta_beneficiario_pep_nacional')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editCuentasBeneficiarioPEPNacional.map((itemEditCuentasBeneficiarioPEPNacional) => (
                                                                                                            <tr key={itemEditCuentasBeneficiarioPEPNacional.id}>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPNacional.razon_social}</td>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPNacional.pais}</td>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPNacional.numero_cuenta}</td>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPNacional.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaBeneficiarioPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_beneficiario_pep_nacional', itemEditCuentasBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_cuenta_beneficiario_pep_nacional', itemEditCuentasBeneficiarioPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-editar_datos_beneficiarios_extranjeros" role="tabpanel" aria-labelledby="nav-editar_datos_beneficiarios_extranjeros-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            P.E.P. EXTRANJEROS
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_beneficiario_extranjero_pep">Indique si la sociedad registra como beneficiarios finales o tiene como administradores (En el sentido establecido en el art. 22 de la Ley 222 de 1995) personas identificadas como un PEP Extranjero.</label>
                                                                                            <select name="pregunta_beneficiario_extranjero_pep"
                                                                                                id="e_pregunta_beneficiario_extranjero_pep_b"
                                                                                                value={editBeneficiarios.pregunta_beneficiario_extranjero_pep}
                                                                                                options={editBeneficiarios.pregunta_beneficiario_extranjero_pep}
                                                                                                className={((this.verificarError("error_e_pregunta_beneficiario_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.activarPepBeneficiarioExtranjero(e);
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarBeneficiarioPEPExtranjeroModal" slot='end' onClick={() => this.activarEdicion('familiar_beneficiario_pep_extranjero')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Extranjero</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                editFamiliaresBeneficiarioPEPExtranjero.map((itemEditFamiliaresBeneficiarioPEPExtranjero) => (
                                                                                                    <tr key={itemEditFamiliaresBeneficiarioPEPExtranjero.id}>
                                                                                                        <td>{itemEditFamiliaresBeneficiarioPEPExtranjero.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemEditFamiliaresBeneficiarioPEPExtranjero.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemEditFamiliaresBeneficiarioPEPExtranjero.identificacion}</td>
                                                                                                        <td>{itemEditFamiliaresBeneficiarioPEPExtranjero.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarBeneficiarioPEPExtranjeroModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_beneficiario_pep_extranjero', itemEditFamiliaresBeneficiarioPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('edit_familiar_beneficiario_pep_extranjero', itemEditFamiliaresBeneficiarioPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. EXTRANJERO EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_extranjero_pep">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_extranjero_pep"
                                                                                        id="e_pregunta_participacion_extranjero_pep_b"
                                                                                        value={editBeneficiarios.pregunta_participacion_extranjero_pep}
                                                                                        options={editBeneficiarios.pregunta_participacion_extranjero_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'beneficiario');
                                                                                            this.activarSociedadBeneficiarioPepExtranjeroEditar(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_beneficiario_pep_extranjero_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadBeneficiarioPEPExtranjeroModal" slot='end' onClick={() => this.activarEdicion('sociedad_beneficiario_pep_extranjero')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editSociedadesBeneficiarioPEPExtranjero.map((itemEditSociedadesBeneficiarioPEPExtranjero) => (
                                                                                                            <tr key={itemEditSociedadesBeneficiarioPEPExtranjero.id}>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPExtranjero.identificacion}</td>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPExtranjero.capital}</td>
                                                                                                                <td>{itemEditSociedadesBeneficiarioPEPExtranjero.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadBeneficiarioPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_beneficiario_pep_extranjero', itemEditSociedadesBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_sociedad_beneficiario_pep_extranjero', itemEditSociedadesBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_extranjero_pep">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_extranjero_pep"
                                                                                        id="e_pregunta_cuenta_extranjera_extranjero_pep_b"
                                                                                        value={editBeneficiarios.pregunta_cuenta_extranjera_extranjero_pep}
                                                                                        options={editBeneficiarios.pregunta_cuenta_extranjera_extranjero_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'beneficiario');
                                                                                            this.activarCuentaBeneficiarioPepExtranjeroEditar(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_beneficiario_pep_extranjero_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaBeneficiarioPEPExtranjeroModal" slot='end' onClick={() => this.activarEdicion('cuenta_beneficiario_pep_extranjero')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editCuentasBeneficiarioPEPExtranjero.map((itemEditCuentasBeneficiarioPEPExtranjero) => (
                                                                                                            <tr key={itemEditCuentasBeneficiarioPEPExtranjero.id}>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPExtranjero.pais}</td>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPExtranjero.numero_cuenta}</td>
                                                                                                                <td>{itemEditCuentasBeneficiarioPEPExtranjero.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaBeneficiarioPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_beneficiario_pep_extranjero', itemEditCuentasBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_cuenta_beneficiario_pep_extranjero', itemEditCuentasBeneficiarioPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-editar_declaracion_beneficiario_pep" role="tabpanel" aria-labelledby="nav-editar_declaracion_beneficiario_pep-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            DECLARACIÓN DE TRANSPARENCIA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta1_pep">Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta1_pep"
                                                                                                id="e_pregunta1_pep_b"
                                                                                                value={editBeneficiarios.pregunta1_pep}
                                                                                                options={editBeneficiarios.pregunta1_pep}
                                                                                                className={((this.verificarError("error_e_pregunta1_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta1_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta2_pep">Que la persona jurídica que represento o yo no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                                            <select name="pregunta2_pep"
                                                                                                id="e_pregunta2_pep_b"
                                                                                                value={editBeneficiarios.pregunta2_pep}
                                                                                                options={editBeneficiarios.pregunta2_pep}
                                                                                                className={((this.verificarError("error_e_pregunta2_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta2_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta3_pep">Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado con las empresas del Grupo Corporativo, no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta3_pep"
                                                                                                id="e_pregunta3_pep_b"
                                                                                                value={editBeneficiarios.pregunta3_pep}
                                                                                                options={editBeneficiarios.pregunta3_pep}
                                                                                                className={((this.verificarError("error_e_pregunta3_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta3_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta4_pep">Que en la ejecución del contrato o el desarrollo de las actividades en que consiste el negocio jurídico con las empresas del Grupo Corporativo, no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta4_pep"
                                                                                                id="e_pregunta4_pep_b"
                                                                                                value={editBeneficiarios.pregunta4_pep}
                                                                                                options={editBeneficiarios.pregunta4_pep}
                                                                                                className={((this.verificarError("error_e_pregunta4_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta4_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta5_pep">Que según aplique, la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                                            <select name="pregunta5_pep"
                                                                                                id="e_pregunta5_pep_b"
                                                                                                value={editBeneficiarios.pregunta5_pep}
                                                                                                options={editBeneficiarios.pregunta5_pep}
                                                                                                className={((this.verificarError("error_e_pregunta5_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta5_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta6_pep">Que, la entidad que represento, sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, no nos encontramos en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando las empresas del Grupo Corporativo facultadas para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas aparece en dichas listas.</label>
                                                                                            <select name="pregunta6_pep"
                                                                                                id="e_pregunta6_pep_b"
                                                                                                value={editBeneficiarios.pregunta6_pep}
                                                                                                options={editBeneficiarios.pregunta6_pep}
                                                                                                className={((this.verificarError("error_e_pregunta6_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta6_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta7_pep">Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA/FT/FPADM y/o contrabando, estando las empresas del Grupo Corporativo facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que Yo o alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a las empresas del Grupo Corporativo frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                                            <select name="pregunta7_pep"
                                                                                                id="e_pregunta7_pep_b"
                                                                                                value={editBeneficiarios.pregunta7_pep}
                                                                                                options={editBeneficiarios.pregunta7_pep}
                                                                                                className={((this.verificarError("error_e_pregunta7_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta7_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta8_pep">Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a las empresas del Grupo Corporativo.</label>
                                                                                            <select name="pregunta8_pep"
                                                                                                id="e_pregunta8_pep_b"
                                                                                                value={editBeneficiarios.pregunta8_pep}
                                                                                                options={editBeneficiarios.pregunta8_pep}
                                                                                                className={((this.verificarError("error_e_pregunta8_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta8_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta9_pep">Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con las empresas del Grupo Corporativo es veraz y exacta, estando las empresas del Grupo Corporativo facultadas para efectuar las validaciones y verificaciones que considere pertinentes. En todo caso, el Grupo Corporativo podrá dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que la información aportada no es veraz y exacta.</label>
                                                                                            <select name="pregunta9_pep"
                                                                                                id="e_pregunta9_pep_b"
                                                                                                value={editBeneficiarios.pregunta9_pep}
                                                                                                options={editBeneficiarios.pregunta9_pep}
                                                                                                className={((this.verificarError("error_e_pregunta9_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta9_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta10_pep">La persona Jurídica declara que ha sido informada por las empresas del Grupo Corporativo, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                                            <select name="pregunta10_pep"
                                                                                                id="e_pregunta10_pep_b"
                                                                                                value={editBeneficiarios.pregunta10_pep}
                                                                                                options={editBeneficiarios.pregunta10_pep}
                                                                                                className={((this.verificarError("error_e_pregunta10_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta10_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta11_pep">Que en el caso que la Persona Jurídica que represento sea considerado por las empresas del Grupo Corporativo, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                                            <select name="pregunta11_pep"
                                                                                                id="e_pregunta11_pep_b"
                                                                                                value={editBeneficiarios.pregunta11_pep}
                                                                                                options={editBeneficiarios.pregunta11_pep}
                                                                                                className={((this.verificarError("error_e_pregunta11_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'beneficiario');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta11_pep_b')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor complete la información solicitada en el formato siguiente teniendo en cuenta los parametros definidos en el <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=167367'>Decreto 830 del 26 de Julio de 2021</a> y sus normas complementarias
                                                                            </p>
                                                                            <p>
                                                                                Es importante tener en cuenta estas consideraciones particulares:
                                                                            </p>
                                                                            <p>
                                                                                <b>Familiares del P.E.P. Nacional</b>
                                                                            </p>
                                                                            <p>
                                                                                Los datos deben ser diligenciados en relación a los familiares de cada PEP, así: Para el cónyuge o compañero permanente de hecho o de derecho y para los familiares hasta el segundo grado de consanguinidad, primero de afinidad y primero civil.
                                                                            </p>
                                                                            <p>
                                                                                <b>Participaciones del PEP Nacional en Sociedades o Asociaciones, Patrimonio(s) Autónomo(s) o Fiducia(s)</b>
                                                                            </p>
                                                                            <p>
                                                                                Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los datos solicitados.
                                                                            </p>
                                                                            <p>
                                                                                <b>Cuentas financieras extranjeras del PEP Nacional </b>
                                                                            </p>
                                                                            <p>
                                                                                Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los datos solicitados.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosBeneficiario}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Beneficiario</button>&nbsp;&nbsp;
                                                <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_beneficiario')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarFamiliarBeneficiarioPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarFamiliarBeneficiarioPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarFamiliarBeneficiarioPEPNacionalModalLabel">Editar Familiar P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_familiar_beneficiario_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="e_nombre_familiar_nacional_pep_b">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_beneficiario' id='id_beneficiario' value={editFamiliarBeneficiarioPEPNacional.id_beneficiario} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editFamiliarBeneficiarioPEPNacional.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_nombre_familiar_nacional_pep_b" id="e_nombre_familiar_nacional_pep_b" defaultValue={editFamiliarBeneficiarioPEPNacional.nombre}
                                                                        className={((this.verificarError("error_e_nombre_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_beneficiario_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco">Parentesco</label>
                                                                    <select name="parentesco"
                                                                        id="e_parentesco_familiar_nacional_pep_b"
                                                                        value={editFamiliarBeneficiarioPEPNacional.parentesco}
                                                                        options={editFamiliarBeneficiarioPEPNacional.parentesco}
                                                                        className={((this.verificarError("error_e_parentesco_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_beneficiario_pep_nacional')
                                                                        }} >
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_identificacion">Tipo Documento</label>
                                                                    <select name="tipo_identificacion"
                                                                        id="e_tipo_documento_familiar_nacional_pep_b"
                                                                        value={editFamiliarBeneficiarioPEPNacional.tipo_identificacion}
                                                                        options={editFamiliarBeneficiarioPEPNacional.tipo_identificacion}
                                                                        className={((this.verificarError("error_e_tipo_documento_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_beneficiario_pep_nacional')
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_familiar_nacional_pep_b">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_familiar_nacional_pep_b" id="e_numero_documento_familiar_nacional_pep_b" defaultValue={editFamiliarBeneficiarioPEPNacional.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_familiar_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'familiar_beneficiario_pep_nacional') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosFamiliarBeneficiarioPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_familiar_beneficiario_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarSociedadBeneficiarioPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarSociedadBeneficiarioPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarSociedadBeneficiarioPEPNacionalModalLabel">Editar Sociedad P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_sociedades_beneficiario_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_participacion_nacional_pep_b">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_beneficiario' id='id_beneficiario' value={editSociedadBeneficiarioPEPNacional.id_beneficiario} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editSociedadBeneficiarioPEPNacional.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_participacion_nacional_pep_b" id="e_razon_social_participacion_nacional_pep_b" defaultValue={editSociedadBeneficiarioPEPNacional.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_beneficiario_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_participacion_nacional_pep_b">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_participacion_nacional_pep_b" id="e_numero_documento_participacion_nacional_pep_b" defaultValue={editSociedadBeneficiarioPEPNacional.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_beneficiario_pep_nacional') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_porcentaje_participacion_nacional_pep_b">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_porcentaje_participacion_nacional_pep_b" id="e_porcentaje_participacion_nacional_pep_b" defaultValue={editSociedadBeneficiarioPEPNacional.capital}
                                                                            className={((this.verificarError("error_e_porcentaje_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_beneficiario_pep_nacional') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control">Tipo Control</label>
                                                                    <select name="tipo_control"
                                                                        id="e_tipo_control_participacion_nacional_pep_b"
                                                                        value={editSociedadBeneficiarioPEPNacional.tipo_control}
                                                                        options={editSociedadBeneficiarioPEPNacional.tipo_control}
                                                                        className={((this.verificarError("error_e_tipo_control_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_beneficiario_pep_nacional');
                                                                            this.activarOtrosTipoControlBeneficiarioPepNacional(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_beneficiario_pep_nacional ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_otros_tipo_control_participacion_nacional_pep_b">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_otros_tipo_control_participacion_nacional_pep_b" id="e_otros_tipo_control_participacion_nacional_pep_b" defaultValue={editSociedadBeneficiarioPEPNacional.otros_tipo_control}
                                                                                className={((this.verificarError("error_e_otros_tipo_control_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'sociedad_beneficiario_pep_nacional')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_detalle_tipo_control_participacion_nacional_pep_b">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_detalle_tipo_control_participacion_nacional_pep_b" id="e_detalle_tipo_control_participacion_nacional_pep_b" defaultValue={editSociedadBeneficiarioPEPNacional.detalle_tipo_control}
                                                                        className={((this.verificarError("error_e_detalle_tipo_control_participacion_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_beneficiario_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosSociedadBeneficiarioPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_sociedad_beneficiario_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarCuentaBeneficiarioPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarCuentaBeneficiarioPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarCuentaBeneficiarioPEPNacionalModalLabel">Editar Cuenta P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_cuentas_beneficiario_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_cuenta_extranjera_nacional_pep_b">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_beneficiario' id='id_beneficiario' value={editCuentaBeneficiarioPEPNacional.id_beneficiario} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editCuentaBeneficiarioPEPNacional.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_cuenta_extranjera_nacional_pep_b" id="e_razon_social_cuenta_extranjera_nacional_pep_b" defaultValue={editCuentaBeneficiarioPEPNacional.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_beneficiario_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_pais_cuenta_extranjera_nacional_pep_b">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_pais_cuenta_extranjera_nacional_pep_b" id="e_pais_cuenta_extranjera_nacional_pep_b" defaultValue={editCuentaBeneficiarioPEPNacional.pais}
                                                                        className={((this.verificarError("error_e_pais_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_beneficiario_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_numero_cuenta_extranjera_nacional_pep_b">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_numero_cuenta_extranjera_nacional_pep_b" id="e_numero_cuenta_extranjera_nacional_pep_b" defaultValue={editCuentaBeneficiarioPEPNacional.numero_cuenta}
                                                                            className={((this.verificarError("error_e_numero_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={(e) => {
                                                                                this.cambioValorEditar(e, 'cuenta_beneficiario_pep_nacional')
                                                                            }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_clase_propiedad_cuenta_extranjera_nacional_pep_b">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_clase_propiedad_cuenta_extranjera_nacional_pep_b" id="e_clase_propiedad_cuenta_extranjera_nacional_pep_b" defaultValue={editCuentaBeneficiarioPEPNacional.clase_propiedad}
                                                                        className={((this.verificarError("error_e_clase_propiedad_cuenta_extranjera_nacional_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_beneficiario_pep_nacional')
                                                                        }} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosCuentaBeneficiarioPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_cuenta_beneficiario_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarFamiliarBeneficiarioPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarFamiliarBeneficiarioPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarFamiliarBeneficiarioPEPExtranjeroModalLabel">Editar Familiar P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_familiares_beneficiario_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="e_nombre_familiar_extranjero_pep_b">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_beneficiario' id='id_beneficiario' value={editFamiliarBeneficiarioPEPExtranjero.id_beneficiario} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editFamiliarBeneficiarioPEPExtranjero.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_nombre_familiar_extranjero_pep_b" id="e_nombre_familiar_extranjero_pep_b" defaultValue={editFamiliarBeneficiarioPEPExtranjero.nombre}
                                                                        className={((this.verificarError("error_e_nombre_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Familiar" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_beneficiario_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre Familiar</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco">Parentesco</label>
                                                                    <select name="parentesco"
                                                                        id="e_parentesco_familiar_extranjero_pep_b"
                                                                        value={editFamiliarBeneficiarioPEPExtranjero.parentesco}
                                                                        options={editFamiliarBeneficiarioPEPExtranjero.parentesco}
                                                                        className={((this.verificarError("error_e_parentesco_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_beneficiario_pep_extranjero')
                                                                        }} >
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_identificacion">Tipo Documento</label>
                                                                    <select name="tipo_identificacion"
                                                                        id="e_tipo_documento_familiar_extranjero_pep_b"
                                                                        value={editFamiliarBeneficiarioPEPExtranjero.tipo_identificacion}
                                                                        options={editFamiliarBeneficiarioPEPExtranjero.tipo_identificacion}
                                                                        className={((this.verificarError("error_e_tipo_documento_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_beneficiario_pep_extranjero')
                                                                        }} >
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_familiar_extranjero_pep_b">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_familiar_extranjero_pep_b" id="e_numero_documento_familiar_extranjero_pep_b" defaultValue={editFamiliarBeneficiarioPEPExtranjero.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_familiar_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'familiar_beneficiario_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosFamiliarBeneficiarioPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_familiar_beneficiario_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarSociedadBeneficiarioPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarSociedadBeneficiarioPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarSociedadBeneficiarioPEPExtranjeroModalLabel">Editar Sociedad P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_sociedades_beneficiario_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_participacion_extranjero_pep_b">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_beneficiario' id='id_beneficiario' value={editSociedadBeneficiarioPEPExtranjero.id_beneficiario} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editSociedadBeneficiarioPEPExtranjero.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_participacion_extranjero_pep_b" id="e_razon_social_participacion_extranjero_pep_b" defaultValue={editSociedadBeneficiarioPEPExtranjero.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_beneficiario_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_participacion_extranjero_pep_b">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_participacion_extranjero_pep_b" id="e_numero_documento_participacion_extranjero_pep_b" defaultValue={editSociedadBeneficiarioPEPExtranjero.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_beneficiario_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_porcentaje_participacion_extranjero_pep_b">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_porcentaje_participacion_extranjero_pep_b" id="e_porcentaje_participacion_extranjero_pep_b" defaultValue={editSociedadBeneficiarioPEPExtranjero.capital}
                                                                            className={((this.verificarError("error_e_porcentaje_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_beneficiario_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control">Tipo Control</label>
                                                                    <select name="tipo_control"
                                                                        id="e_tipo_control_participacion_extranjero_pep_b"
                                                                        value={editSociedadBeneficiarioPEPExtranjero.tipo_control}
                                                                        options={editSociedadBeneficiarioPEPExtranjero.tipo_control}
                                                                        className={((this.verificarError("error_e_tipo_control_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_beneficiario_pep_extranjero')
                                                                            this.activarOtrosTipoControlBeneficiarioPepExtranjero(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_beneficiario_pep_extranjero ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_otros_tipo_control_participacion_extranjero_pep_b">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_otros_tipo_control_participacion_extranjero_pep_b" id="e_otros_tipo_control_participacion_extranjero_pep_b" defaultValue={editSociedadBeneficiarioPEPExtranjero.otros_tipo_control}
                                                                                className={((this.verificarError("error_e_otros_tipo_control_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'sociedad_beneficiario_pep_extranjero')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_detalle_tipo_control_participacion_extranjero_pep_b">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_detalle_tipo_control_participacion_extranjero_pep_b" id="e_detalle_tipo_control_participacion_extranjero_pep_b" defaultValue={editSociedadBeneficiarioPEPExtranjero.detalle_tipo_control}
                                                                        className={((this.verificarError("error_e_detalle_tipo_control_participacion_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_beneficiario_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosSociedadBeneficiarioPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_sociedad_beneficiario_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarCuentaBeneficiarioPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarCuentaBeneficiarioPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarCuentaBeneficiarioPEPExtranjeroModalLabel">Editar Cuenta P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_cuentas_beneficiario_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_cuenta_extranjera_extranjero_pep_b">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_beneficiario' id='id_beneficiario' value={editCuentaBeneficiarioPEPExtranjero.id_beneficiario} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editCuentaBeneficiarioPEPExtranjero.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_cuenta_extranjera_extranjero_pep_b" id="e_razon_social_cuenta_extranjera_extranjero_pep_b" defaultValue={editCuentaBeneficiarioPEPExtranjero.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_beneficiario_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_pais_cuenta_extranjera_extranjero_pep_b">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_pais_cuenta_extranjera_extranjero_pep_b" id="e_pais_cuenta_extranjera_extranjero_pep_b" defaultValue={editCuentaBeneficiarioPEPExtranjero.pais}
                                                                        className={((this.verificarError("error_e_pais_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_beneficiario_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_numero_cuenta_extranjera_extranjero_pep_b">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_numero_cuenta_extranjera_extranjero_pep_b" id="e_numero_cuenta_extranjera_extranjero_pep_b" defaultValue={editCuentaBeneficiarioPEPExtranjero.numero_cuenta}
                                                                            className={((this.verificarError("error_e_numero_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'cuenta_beneficiario_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_clase_propiedad_cuenta_extranjera_extranjero_pep_b">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_clase_propiedad_cuenta_extranjera_extranjero_pep_b" id="e_clase_propiedad_cuenta_extranjera_extranjero_pep_b" defaultValue={editCuentaBeneficiarioPEPExtranjero.clase_propiedad}
                                                                        className={((this.verificarError("error_e_clase_propiedad_cuenta_extranjera_extranjero_pep_b")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_beneficiario_pep_extranjero')
                                                                        }} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosCuentaBeneficiarioPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_cuenta_beneficiario_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarRepresentanteModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarRepresentanteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarRepresentanteModalLabel">Editar Representante</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_representante'>
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-editar_datos_representante-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_representante" type="button" role="tab" aria-controls="nav-editar_datos_representante" aria-selected="false">Información Representante</button>
                                            {
                                                activar_pep_editar ?
                                                    <button style={{ backgroundColor: '#003153', color: '#FFFFFF' }} className="nav-link" id="nav-editar_datos_pep-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_pep" type="button" role="tab" aria-controls="nav-editar_datos_pep" aria-selected="false">Información P.E.P.</button>
                                                : ''
                                            }
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-editar_datos_representante" role="tabpanel" aria-labelledby="nav-editar_datos_representante-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS REPRESENTANTE
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className='row g-3'>
                                                                        <div className='col-md-5'>
                                                                            <label htmlFor="tipo_rol">Tipo Rol</label>
                                                                            <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                            <input type="hidden" name='e_id_representante_r' id='e_id_representante_r' defaultValue={editRepresentantes.id} />
                                                                            <select name="tipo_rol"
                                                                                id="e_tipo_rol_r"
                                                                                value={editRepresentantes.tipo_rol}
                                                                                options={editRepresentantes.tipo_rol}
                                                                                className={((this.verificarError("error_e_tipo_rol_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante')
                                                                                }}>
                                                                                    <option value="">Seleccione el Tipo Rol</option>
                                                                                    <option value="ACCIONISTA">ACCIONISTA</option>
                                                                                    <option value="MIEMBRO_JUNTA_DIRECTIVA">MIEMBRO JUNTA DIRECTIVA</option>
                                                                                    <option value="REPRESENTANTE_LEGAL">REPRESENTANTE LEGAL</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Rol</small>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label htmlFor="e_capital_representante_r">Capital %</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="e_capital_representante_r" id="e_capital_representante_r" defaultValue={editRepresentantes.capital}
                                                                                    className={((this.verificarError("error_e_capital_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'representante')
                                                                                    }} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="pep">P.E.P.</label>
                                                                            <select name="pep"
                                                                                id="e_pep_r"
                                                                                value={editRepresentantes.pep}
                                                                                options={editRepresentantes.pep}
                                                                                className={((this.verificarError("error_e_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                    this.activarPepEditar(e);
                                                                                }} >
                                                                                    <option value="">Seleccione P.E.P.</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione P.E.P.</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="e_nombre_representante_r">Nombre</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_nombre_representante_r" id="e_nombre_representante_r" defaultValue={editRepresentantes.nombre}
                                                                                className={((this.verificarError("error_e_nombre_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="e_apellido_representante_r">Apellido</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_apellido_representante_r" id="e_apellido_representante_r" defaultValue={editRepresentantes.nombre}
                                                                                className={((this.verificarError("error_e_apellido_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Apellido</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                                        <div className='col-md-4'>
                                                                            <label htmlFor="tipo_identificacion">Tipo Documento</label>
                                                                            <select name="tipo_identificacion"
                                                                                id="e_tipo_documento_representante_r"
                                                                                value={editRepresentantes.tipo_identificacion}
                                                                                options={editRepresentantes.tipo_identificacion}
                                                                                className={((this.verificarError("error_e_tipo_documento_representante_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante')
                                                                                }}>
                                                                                    <option value="">Seleccione el Tipo Documento</option>
                                                                                    <option value="CC">C.C.</option>
                                                                                    <option value="CE">C.E.</option>
                                                                                    <option value="NIT">NIT</option>
                                                                                    <option value="PP">P.P.</option>
                                                                                    <option value="OTRO">OTRO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_numero_documento_representante_r">Número Documento</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_representante_r" id="e_numero_documento_representante_r" defaultValue={editRepresentantes.identificacion}
                                                                                    className={((this.verificarError("error_e_numero_documento_representante_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'representante')
                                                                                    }} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label htmlFor="e_digito_verificacion_r">DV</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="number" name="e_digito_verificacion_r" id="e_digito_verificacion_r" defaultValue={editRepresentantes.digito_verificacion}
                                                                                    className={((this.verificarError("error_e_digito_verificacion_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'representante')
                                                                                    }} max="37" />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor relacione en este apartado los socios, accionistas o miembros de junta directiva indicando su porcentaje de participación en el caso de socios o accionistas.
                                                                            </p>
                                                                            <p>
                                                                                El sistema solicitará que este registrado por lo menos un (1) representante de cada tipo. 
                                                                            </p>
                                                                            <p>
                                                                                Se debe ingresar varias veces para crear todos los representantes necesarios (Accionista, Miembro Junta Directiva y Representante Legal)
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-editar_datos_pep" role="tabpanel" aria-labelledby="nav-editar_datos_pep-tab">
                                            <div style={{ marginTop: 15}} className="row">
                                                <div className='col-xl-8'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            DATOS P.E.P. NACIONALES
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <label htmlFor="declaracion_veracidad_pep">Declaración Veracidad</label>
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px', marginTop: 0 }} className='col-md-12'>
                                                                            <p>
                                                                                En cumplimiento del Manual del Sistema de Control Interno; el Manual SAGRILAFT (Lavado de Activos, Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM)
                                                                                y el Manual PAEC (Programa de Anticorrupción, Ética y Cumplimiento), adoptados por las empresas TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA, PROELECTRICA S.A.S. E.S.P., CH EL EDEN S.A.S E.S.P.,
                                                                                y ENELCA S.A. E.S.P., en adelante las “Empresas”, los cuales han sido implementados mediante los programas mencionados y conforme a las directrices de la Superintendencia de Sociedades, todos los actores de la contraparte
                                                                                que ostenten la calidad de P.E.P de acuerdo con el Decreto 830 del 26 de Julio de 2021, y cualquier otra norma que lo modifique o reemplace deberán diligenciar la información solicitada en este apartado.
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ marginTop: 0 }} className="col-md-4">
                                                                            <select name="declaracion_veracidad_pep"
                                                                                id="e_declaracion_veracidad_pep_r"
                                                                                value={editRepresentantes.declaracion_veracidad_pep}
                                                                                options={editRepresentantes.declaracion_veracidad_pep}
                                                                                className={((this.verificarError("error_e_declaracion_veracidad_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                    this.aceptarTerminos(e, 'declaracion_veracidad');
                                                                                }}>
                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Declaración Veracidad</small>
                                                                        </div>
                                                                    </div>
                                                                    <hr style={{ marginBottom: 0 }} />
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_vinculo_actual_pep_r">Vínculo actual con la Persona Jurídica</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_vinculo_actual_pep_r" id="e_vinculo_actual_pep_r" defaultValue={editRepresentantes.vinculo_actual_pep}
                                                                                className={((this.verificarError("error_e_vinculo_actual_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Vínculo Actual" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante')
                                                                                }} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Vínculo Actual</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_cargo_actividad_pep_r">Cargo o Actividad que lo clasifique como P.E.P.</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_cargo_actividad_pep_r" id="e_cargo_actividad_pep_r" defaultValue={editRepresentantes.cargo_actividad_pep}
                                                                                className={((this.verificarError("error_e_cargo_actividad_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo o Actividad" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante')
                                                                                }} max="37" />
                                                                            <small id="helpId" className="invalid-feedback">Digite el Cargo o Actividad</small>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="vinculacion_activa_pep">Vinculación Activa</label>
                                                                            <select name="vinculacion_activa_pep"
                                                                                id="e_vinculacion_activa_pep_r"
                                                                                value={editRepresentantes.vinculacion_activa_pep}
                                                                                options={editRepresentantes.vinculacion_activa_pep}
                                                                                className={((this.verificarError("error_e_vinculacion_activa_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                    this.activarVinculacionEditar(e);
                                                                                }}>
                                                                                    <option value="">Seleccione la Vinculación</option>
                                                                                    <option value="SI">SI</option>
                                                                                    <option value="NO">NO</option>
                                                                            </select>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Vinculación</small>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor="e_fecha_vinculacion_pep_r">Fecha Vinculación</label>
                                                                            <div className="input-group input-group-sm">
                                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="e_fecha_vinculacion_pep_r" id="e_fecha_vinculacion_pep_r" defaultValue={editRepresentantes.fecha_vinculacion_pep}
                                                                                    className={((this.verificarError("error_e_fecha_vinculacion_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Vinculación" aria-describedby="helpId"
                                                                                    onChange={(e) => {
                                                                                        this.cambioValorEditar(e, 'representante')
                                                                                    }} />
                                                                            </div>
                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Vinculación</small>
                                                                        </div>
                                                                        {
                                                                            activar_vinculacion_editar ?
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="e_fecha_desvinculacion_pep_r">Fecha Desvinculación</label>
                                                                                    <div className="input-group input-group-sm">
                                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                                        <input style={{ textTransform: 'uppercase' }} type="date" name="e_fecha_desvinculacion_pep_r" id="e_fecha_desvinculacion_pep_r" defaultValue={editRepresentantes.fecha_desvinculacion_pep}
                                                                                            className={((this.verificarError("error_e_fecha_desvinculacion_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Desvinculación" aria-describedby="helpId"
                                                                                            onChange={(e) => {
                                                                                                this.cambioValorEditar(e, 'representante')
                                                                                            }} />
                                                                                    </div>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Fecha Desvinculación</small>
                                                                                </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            <button className="nav-link active" id="nav-editar_datos_nacionales-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_nacionales" type="button" role="tab" aria-controls="nav-editar_datos_nacionales" aria-selected="false">P.E.P. Nacional</button>
                                                            <button className="nav-link" id="nav-editar_datos_extranjeros-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_datos_extranjeros" type="button" role="tab" aria-controls="nav-editar_datos_extranjeros" aria-selected="false">P.E.P. Extranjero</button>
                                                            <button className="nav-link" id="nav-editar_declaracion_pep-tab" data-bs-toggle="tab" data-bs-target="#nav-editar_declaracion_pep" type="button" role="tab" aria-controls="nav-editar_declaracion_pep" aria-selected="false">Declaración Transparencia</button>
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-editar_datos_nacionales" role="tabpanel" aria-labelledby="nav-editar_datos_nacionales-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarPEPNacionalModal" slot='end' onClick={() => this.activarEdicion('familiar_pep_nacional')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Nacional</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                editFamiliaresPEPNacional.map((itemEditFamiliaresPEPNacional) => (
                                                                                                    <tr key={itemEditFamiliaresPEPNacional.id}>
                                                                                                        <td>{itemEditFamiliaresPEPNacional.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemEditFamiliaresPEPNacional.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemEditFamiliaresPEPNacional.identificacion}</td>
                                                                                                        <td>{itemEditFamiliaresPEPNacional.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarPEPNacionalModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_pep_nacional', itemEditFamiliaresPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('edit_familiar_pep_nacional', itemEditFamiliaresPEPNacional.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. NACIONAL EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_nacional_pep">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_nacional_pep"
                                                                                        id="e_pregunta_participacion_nacional_pep_r"
                                                                                        value={editRepresentantes.pregunta_participacion_nacional_pep}
                                                                                        options={editRepresentantes.pregunta_participacion_nacional_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'representante');
                                                                                            this.activarSociedadPepNacionalEditar(e);
                                                                                        }}>
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_pep_nacional_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadPEPNacionalModal" slot='end' onClick={() => this.activarEdicion('sociedad_pep_nacional')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editSociedadesPEPNacional.map((itemEditSociedadesPEPNacional) => (
                                                                                                            <tr key={itemEditSociedadesPEPNacional.id}>
                                                                                                                <td>{itemEditSociedadesPEPNacional.razon_social}</td>
                                                                                                                <td>{itemEditSociedadesPEPNacional.identificacion}</td>
                                                                                                                <td>{itemEditSociedadesPEPNacional.capital}</td>
                                                                                                                <td>{itemEditSociedadesPEPNacional.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_pep_nacional', itemEditSociedadesPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_sociedad_pep_nacional', itemEditSociedadesPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. NACIONAL
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_nacional_pep">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_nacional_pep"
                                                                                        id="e_pregunta_cuenta_extranjera_nacional_pep_r"
                                                                                        value={editRepresentantes.pregunta_cuenta_extranjera_nacional_pep}
                                                                                        options={editRepresentantes.pregunta_cuenta_extranjera_nacional_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'representante');
                                                                                            this.activarCuentaPepNacionalEditar(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_pep_nacional_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaPEPNacionalModal" slot='end' onClick={() => this.activarEdicion('cuenta_pep_nacional')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Nacional</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editCuentasPEPNacional.map((itemEditCuentasPEPNacional) => (
                                                                                                            <tr key={itemEditCuentasPEPNacional.id}>
                                                                                                                <td>{itemEditCuentasPEPNacional.razon_social}</td>
                                                                                                                <td>{itemEditCuentasPEPNacional.pais}</td>
                                                                                                                <td>{itemEditCuentasPEPNacional.numero_cuenta}</td>
                                                                                                                <td>{itemEditCuentasPEPNacional.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaPEPNacionalModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_pep_nacional', itemEditCuentasPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_cuenta_pep_nacional', itemEditCuentasPEPNacional.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-editar_datos_extranjeros" role="tabpanel" aria-labelledby="nav-editar_datos_extranjeros-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            P.E.P. EXTRANJEROS
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_beneficiario_extranjero_pep">Indique si la sociedad registra como beneficiarios finales o tiene como administradores (En el sentido establecido en el art. 22 de la Ley 222 de 1995) personas identificadas como un PEP Extranjero.</label>
                                                                                            <select name="pregunta_beneficiario_extranjero_pep"
                                                                                                id="e_pregunta_beneficiario_extranjero_pep_r"
                                                                                                value={editRepresentantes.pregunta_beneficiario_extranjero_pep}
                                                                                                options={editRepresentantes.pregunta_beneficiario_extranjero_pep}
                                                                                                className={((this.verificarError("error_e_pregunta_beneficiario_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.activarPepExtranjero(e);
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            FAMILIARES DE P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row g-3">
                                                                                <div className='col-md-12'>
                                                                                    <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoFamiliarPEPExtranjeroModal" slot='end' onClick={() => this.activarEdicion('familiar_pep_extranjero')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Familiar P.E.P. Extranjero</button>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-hover table-condensed table-sm">
                                                                                        <thead className="thead-inverse">
                                                                                            <tr>
                                                                                                <th className="align-middle">NOMBRE</th>
                                                                                                <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                <th className="align-middle">PARENTESCO</th>
                                                                                                <th className="align-middle">OPCIONES</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                editFamiliaresPEPExtranjero.map((itemEditFamiliaresPEPExtranjero) => (
                                                                                                    <tr key={itemEditFamiliaresPEPExtranjero.id}>
                                                                                                        <td>{itemEditFamiliaresPEPExtranjero.nombre}</td>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                switch (itemEditFamiliaresPEPExtranjero.tipo_identificacion) {
                                                                                                                    case 'CC':
                                                                                                                        return (<td>C.C.</td>)
                                                                                                                    case 'CE':
                                                                                                                        return (<td>C.E.</td>)
                                                                                                                    case 'NIT':
                                                                                                                        return (<td>NIT</td>)
                                                                                                                    case 'PP':
                                                                                                                        return (<td>P.P.</td>)
                                                                                                                    case 'OTRO':
                                                                                                                        return (<td>OTRO</td>)
                                                                                                                }
                                                                                                            })()
                                                                                                        }
                                                                                                        <td>{itemEditFamiliaresPEPExtranjero.identificacion}</td>
                                                                                                        <td>{itemEditFamiliaresPEPExtranjero.parentesco}</td>
                                                                                                        <td>
                                                                                                            <div className="btn-group" role="group" aria-label="">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm"
                                                                                                                    data-bs-toggle='modal'
                                                                                                                    data-bs-target="#EditarFamiliarPEPExtranjeroModal"
                                                                                                                    slot='end'
                                                                                                                    onClick={() =>
                                                                                                                        this.cargarDatosEditar('familiar_pep_extranjero', itemEditFamiliaresPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                                                </button>&nbsp;&nbsp;
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={() =>
                                                                                                                        this.borrarRegistrosPersona('edit_familiar_pep_extranjero', itemEditFamiliaresPEPExtranjero.id)
                                                                                                                    }
                                                                                                                >
                                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            PARTICIPACIONES DEL P.E.P. EXTRANJERO EN SOCIEDADES
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_participacion_extranjero_pep">Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los siguientes datos.</label>
                                                                                    <select name="pregunta_participacion_extranjero_pep"
                                                                                        id="e_pregunta_participacion_extranjero_pep_r"
                                                                                        value={editRepresentantes.pregunta_participacion_extranjero_pep}
                                                                                        options={editRepresentantes.pregunta_participacion_extranjero_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'representante');
                                                                                            this.activarSociedadPepExtranjeroEditar(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_sociedad_pep_extranjero_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoSociedadPEPExtranjeroModal" slot='end' onClick={() => this.activarEdicion('sociedad_pep_extranjero')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Sociedad P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">RAZÓN SOCIAL</th>
                                                                                                        <th className="align-middle">IDENTIFICACIÓN</th>
                                                                                                        <th className="align-middle">CAPITAL %</th>
                                                                                                        <th className="align-middle">TIPO CONTROL</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editSociedadesPEPExtranjero.map((itemEditSociedadesPEPExtranjero) => (
                                                                                                            <tr key={itemEditSociedadesPEPExtranjero.id}>
                                                                                                                <td>{itemEditSociedadesPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemEditSociedadesPEPExtranjero.identificacion}</td>
                                                                                                                <td>{itemEditSociedadesPEPExtranjero.capital}</td>
                                                                                                                <td>{itemEditSociedadesPEPExtranjero.tipo_control}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarSociedadPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('sociedad_pep_extranjero', itemEditSociedadesPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_sociedad_pep_extranjero', itemEditSociedadesPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            CUENTAS FINANCIERAS EXTRANJERAS DEL P.E.P. EXTRANJERO
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className='row g-3'>
                                                                                <div className="col-md-12">
                                                                                    <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta_cuenta_extranjera_extranjero_pep">Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los siguientes datos.</label>
                                                                                    <select name="pregunta_cuenta_extranjera_extranjero_pep"
                                                                                        id="e_pregunta_cuenta_extranjera_extranjero_pep_r"
                                                                                        value={editRepresentantes.pregunta_cuenta_extranjera_extranjero_pep}
                                                                                        options={editRepresentantes.pregunta_cuenta_extranjera_extranjero_pep}
                                                                                        className={((this.verificarError("error_e_pregunta_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                        onChange={(e) => {
                                                                                            this.cambioValorEditar(e, 'representante');
                                                                                            this.activarCuentaPepExtranjeroEditar(e);
                                                                                        }} >
                                                                                            <option value="">Seleccione la Respuesta</option>
                                                                                            <option value="SI">SI</option>
                                                                                            <option value="NO">NO</option>
                                                                                    </select>
                                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                activar_cuenta_pep_extranjero_editar ?
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className='col-md-12'>
                                                                                            <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoCuentaPEPExtranjeroModal" slot='end' onClick={() => this.activarEdicion('cuenta_pep_extranjero')}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Cuenta P.E.P. Extranjero</button>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <table className="table table-hover table-condensed table-sm">
                                                                                                <thead className="thead-inverse">
                                                                                                    <tr>
                                                                                                        <th className="align-middle">BANCO</th>
                                                                                                        <th className="align-middle">PAIS</th>
                                                                                                        <th className="align-middle">NO. CUENTA</th>
                                                                                                        <th className="align-middle">CLASE PROPIEDAD</th>
                                                                                                        <th className="align-middle">OPCIONES</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        editCuentasPEPExtranjero.map((itemEditCuentasPEPExtranjero) => (
                                                                                                            <tr key={itemEditCuentasPEPExtranjero.id}>
                                                                                                                <td>{itemEditCuentasPEPExtranjero.razon_social}</td>
                                                                                                                <td>{itemEditCuentasPEPExtranjero.pais}</td>
                                                                                                                <td>{itemEditCuentasPEPExtranjero.numero_cuenta}</td>
                                                                                                                <td>{itemEditCuentasPEPExtranjero.clase_propiedad}</td>
                                                                                                                <td>
                                                                                                                    <div className="btn-group" role="group" aria-label="">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-primary btn-sm"
                                                                                                                            data-bs-toggle='modal'
                                                                                                                            data-bs-target="#EditarCuentaPEPExtranjeroModal"
                                                                                                                            slot='end'
                                                                                                                            onClick={() =>
                                                                                                                                this.cargarDatosEditar('cuenta_pep_extranjero', itemEditCuentasPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                                                        </button>&nbsp;&nbsp;
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="btn btn-danger btn-sm"
                                                                                                                            onClick={() =>
                                                                                                                                this.borrarRegistrosPersona('edit_cuenta_pep_extranjero', itemEditCuentasPEPExtranjero.id)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav-editar_declaracion_pep" role="tabpanel" aria-labelledby="nav-editar_declaracion_pep-tab">
                                                            <div style={{ marginTop: 15}} className="row">
                                                                <div className='col-xl-12'>
                                                                    <div className="card">
                                                                        <div className="card-header text-center">
                                                                            DECLARACIÓN DE TRANSPARENCIA
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-xl-12">
                                                                                    <div className='row g-3'>
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta1_pep">Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta1_pep"
                                                                                                id="e_pregunta1_pep_r"
                                                                                                value={editRepresentantes.pregunta1_pep}
                                                                                                options={editRepresentantes.pregunta1_pep}
                                                                                                className={((this.verificarError("error_e_pregunta1_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta1_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta2_pep">Que la persona jurídica que represento o yo no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                                            <select name="pregunta2_pep"
                                                                                                id="e_pregunta2_pep_r"
                                                                                                value={editRepresentantes.pregunta2_pep}
                                                                                                options={editRepresentantes.pregunta2_pep}
                                                                                                className={((this.verificarError("error_e_pregunta2_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta2_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta3_pep">Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado con las empresas del Grupo Corporativo, no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta3_pep"
                                                                                                id="e_pregunta3_pep_r"
                                                                                                value={editRepresentantes.pregunta3_pep}
                                                                                                options={editRepresentantes.pregunta3_pep}
                                                                                                className={((this.verificarError("error_e_pregunta3_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta3_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta4_pep">Que en la ejecución del contrato o el desarrollo de las actividades en que consiste el negocio jurídico con las empresas del Grupo Corporativo, no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                                            <select name="pregunta4_pep"
                                                                                                id="e_pregunta4_pep_r"
                                                                                                value={editRepresentantes.pregunta4_pep}
                                                                                                options={editRepresentantes.pregunta4_pep}
                                                                                                className={((this.verificarError("error_e_pregunta4_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta4_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta5_pep">Que según aplique, la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva, LA/FT/FPADM que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                                            <select name="pregunta5_pep"
                                                                                                id="e_pregunta5_pep_r"
                                                                                                value={editRepresentantes.pregunta5_pep}
                                                                                                options={editRepresentantes.pregunta5_pep}
                                                                                                className={((this.verificarError("error_e_pregunta5_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta5_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta6_pep">Que, la entidad que represento, sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, no nos encontramos en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando las empresas del Grupo Corporativo facultadas para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas aparece en dichas listas.</label>
                                                                                            <select name="pregunta6_pep"
                                                                                                id="e_pregunta6_pep_r"
                                                                                                value={editRepresentantes.pregunta6_pep}
                                                                                                options={editRepresentantes.pregunta6_pep}
                                                                                                className={((this.verificarError("error_e_pregunta6_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta6_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta7_pep">Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA/FT/FPADM y/o contrabando, estando las empresas del Grupo Corporativo facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que Yo o alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a las empresas del Grupo Corporativo frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                                            <select name="pregunta7_pep"
                                                                                                id="e_pregunta7_pep_r"
                                                                                                value={editRepresentantes.pregunta7_pep}
                                                                                                options={editRepresentantes.pregunta7_pep}
                                                                                                className={((this.verificarError("error_e_pregunta7_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta7_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta8_pep">Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a las empresas del Grupo Corporativo.</label>
                                                                                            <select name="pregunta8_pep"
                                                                                                id="e_pregunta8_pep_r"
                                                                                                value={editRepresentantes.pregunta8_pep}
                                                                                                options={editRepresentantes.pregunta8_pep}
                                                                                                className={((this.verificarError("error_e_pregunta8_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta8_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta9_pep">Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con las empresas del Grupo Corporativo es veraz y exacta, estando las empresas del Grupo Corporativo facultadas para efectuar las validaciones y verificaciones que considere pertinentes. En todo caso, el Grupo Corporativo podrá dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que la información aportada no es veraz y exacta.</label>
                                                                                            <select name="pregunta9_pep"
                                                                                                id="e_pregunta9_pep_r"
                                                                                                value={editRepresentantes.pregunta9_pep}
                                                                                                options={editRepresentantes.pregunta9_pep}
                                                                                                className={((this.verificarError("error_e_pregunta9_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta9_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta10_pep">La persona Jurídica declara que ha sido informada por las empresas del Grupo Corporativo, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                                            <select name="pregunta10_pep"
                                                                                                id="e_pregunta10_pep_r"
                                                                                                value={editRepresentantes.pregunta10_pep}
                                                                                                options={editRepresentantes.pregunta10_pep}
                                                                                                className={((this.verificarError("error_e_pregunta10_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta10_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                                        <div className="col-md-12">
                                                                                            <label style={{ textAlign: 'justify', fontWeight: 'normal' }} htmlFor="pregunta11_pep">Que en el caso que la Persona Jurídica que represento sea considerado por las empresas del Grupo Corporativo, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                                            <select name="pregunta11_pep"
                                                                                                id="e_pregunta11_pep_r"
                                                                                                value={editRepresentantes.pregunta11_pep}
                                                                                                options={editRepresentantes.pregunta11_pep}
                                                                                                className={((this.verificarError("error_e_pregunta11_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                                                onChange={(e) => {
                                                                                                    this.cambioValorEditar(e, 'representante');
                                                                                                    this.aceptarPreguntas(e, 'persona_juridica', 'e_pregunta11_pep_r')
                                                                                                }} >
                                                                                                    <option value="">Seleccione la Respuesta</option>
                                                                                                    <option value="SI">SI</option>
                                                                                                    <option value="NO">NO</option>
                                                                                            </select>
                                                                                            <small id="helpId" className="invalid-feedback">Seleccione la Respuesta</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-4'>
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            AYUDA
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Por favor complete la información solicitada en el formato siguiente teniendo en cuenta los parametros definidos en el <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=167367'>Decreto 830 del 26 de Julio de 2021</a> y sus normas complementarias
                                                                            </p>
                                                                            <p>
                                                                                Es importante tener en cuenta estas consideraciones particulares:
                                                                            </p>
                                                                            <p>
                                                                                <b>Familiares del P.E.P. Nacional</b>
                                                                            </p>
                                                                            <p>
                                                                                Los datos deben ser diligenciados en relación a los familiares de cada PEP, así: Para el cónyuge o compañero permanente de hecho o de derecho y para los familiares hasta el segundo grado de consanguinidad, primero de afinidad y primero civil.
                                                                            </p>
                                                                            <p>
                                                                                <b>Participaciones del PEP Nacional en Sociedades o Asociaciones, Patrimonio(s) Autónomo(s) o Fiducia(s)</b>
                                                                            </p>
                                                                            <p>
                                                                                Cuando el PEP tenga sociedades o asociaciones con personas jurídicas o naturales, sea propietario directa o indirectamente de alguna participación o ejerza el control de sociedades, asociaciones, persona(s) jurídica(s), Patrimonio(s) Autónomo(s) o Fiducia(s) en los términos del artículo 261 del Código de Comercio, debe diligenciar los datos solicitados.
                                                                            </p>
                                                                            <p>
                                                                                <b>Cuentas financieras extranjeras del PEP Nacional </b>
                                                                            </p>
                                                                            <p>
                                                                                Si la persona PEP, tiene propiedad o alguna forma de derecho, control o poder de firma u otra índole sobre cuentas financieras en el exterior, debe registrar los datos solicitados.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosRepresentante}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Representante</button>&nbsp;&nbsp;
                                                <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_representante')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarFamiliarPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarFamiliarPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarFamiliarPEPNacionalModalLabel">Editar Familiar P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_familiar_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="e_nombre_familiar_nacional_pep_r">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_representante' id='id_representante' value={editFamiliarPEPNacional.id_representante} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editFamiliarPEPNacional.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_nombre_familiar_nacional_pep_r" id="e_nombre_familiar_nacional_pep_r" defaultValue={editFamiliarPEPNacional.nombre}
                                                                        className={((this.verificarError("error_e_nombre_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco">Parentesco</label>
                                                                    <select name="parentesco"
                                                                        id="e_parentesco_familiar_nacional_pep_r"
                                                                        value={editFamiliarPEPNacional.parentesco}
                                                                        options={editFamiliarPEPNacional.parentesco}
                                                                        className={((this.verificarError("error_e_parentesco_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_pep_nacional')
                                                                        }} >
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_identificacion">Tipo Documento</label>
                                                                    <select name="tipo_identificacion"
                                                                        id="e_tipo_documento_familiar_nacional_pep_r"
                                                                        value={editFamiliarPEPNacional.tipo_identificacion}
                                                                        options={editFamiliarPEPNacional.tipo_identificacion}
                                                                        className={((this.verificarError("error_e_tipo_documento_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_pep_nacional')
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_familiar_nacional_pep_r">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_familiar_nacional_pep_r" id="e_numero_documento_familiar_nacional_pep_r" defaultValue={editFamiliarPEPNacional.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_familiar_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'familiar_pep_nacional') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosFamiliarPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_familiar_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarSociedadPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarSociedadPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarSociedadPEPNacionalModalLabel">Editar Sociedad P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_sociedades_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_participacion_nacional_pep_r">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_representante' id='id_representante' value={editSociedadPEPNacional.id_representante} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editSociedadPEPNacional.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_participacion_nacional_pep_r" id="e_razon_social_participacion_nacional_pep_r" defaultValue={editSociedadPEPNacional.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_participacion_nacional_pep_r">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_participacion_nacional_pep_r" id="e_numero_documento_participacion_nacional_pep_r" defaultValue={editSociedadPEPNacional.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_pep_nacional') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_porcentaje_participacion_nacional_pep_r">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_porcentaje_participacion_nacional_pep_r" id="e_porcentaje_participacion_nacional_pep_r" defaultValue={editSociedadPEPNacional.capital}
                                                                            className={((this.verificarError("error_e_porcentaje_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_pep_nacional') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control">Tipo Control</label>
                                                                    <select name="tipo_control"
                                                                        id="e_tipo_control_participacion_nacional_pep_r"
                                                                        value={editSociedadPEPNacional.tipo_control}
                                                                        options={editSociedadPEPNacional.tipo_control}
                                                                        className={((this.verificarError("error_e_tipo_control_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_pep_nacional');
                                                                            this.activarOtrosTipoControlPepNacional(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_pep_nacional ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_otros_tipo_control_participacion_nacional_pep_r">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_otros_tipo_control_participacion_nacional_pep_r" id="e_otros_tipo_control_participacion_nacional_pep_r" defaultValue={editSociedadPEPNacional.otros_tipo_control}
                                                                                className={((this.verificarError("error_e_otros_tipo_control_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'sociedad_pep_nacional')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_detalle_tipo_control_participacion_nacional_pep_r">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_detalle_tipo_control_participacion_nacional_pep_r" id="e_detalle_tipo_control_participacion_nacional_pep_r" defaultValue={editSociedadPEPNacional.detalle_tipo_control}
                                                                        className={((this.verificarError("error_e_detalle_tipo_control_participacion_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosSociedadPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_sociedad_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarCuentaPEPNacionalModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarCuentaPEPNacionalModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarCuentaPEPNacionalModalLabel">Editar Cuenta P.E.P. Nacional</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_cuentas_pep_nacional'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. NACIONAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_cuenta_extranjera_nacional_pep_r">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_representante' id='id_representante' value={editCuentaPEPNacional.id_representante} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editCuentaPEPNacional.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_cuenta_extranjera_nacional_pep_r" id="e_razon_social_cuenta_extranjera_nacional_pep_r" defaultValue={editCuentaPEPNacional.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_pais_cuenta_extranjera_nacional_pep_r">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_pais_cuenta_extranjera_nacional_pep_r" id="e_pais_cuenta_extranjera_nacional_pep_r" defaultValue={editCuentaPEPNacional.pais}
                                                                        className={((this.verificarError("error_e_pais_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_pep_nacional')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_numero_cuenta_extranjera_nacional_pep_r">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_numero_cuenta_extranjera_nacional_pep_r" id="e_numero_cuenta_extranjera_nacional_pep_r" defaultValue={editCuentaPEPNacional.numero_cuenta}
                                                                            className={((this.verificarError("error_e_numero_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={(e) => {
                                                                                this.cambioValorEditar(e, 'cuenta_pep_nacional')
                                                                            }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_clase_propiedad_cuenta_extranjera_nacional_pep_r">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_clase_propiedad_cuenta_extranjera_nacional_pep_r" id="e_clase_propiedad_cuenta_extranjera_nacional_pep_r" defaultValue={editCuentaPEPNacional.clase_propiedad}
                                                                        className={((this.verificarError("error_e_clase_propiedad_cuenta_extranjera_nacional_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_pep_nacional')
                                                                        }} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosCuentaPEPNacional}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_cuenta_pep_nacional')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarFamiliarPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarFamiliarPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarFamiliarPEPExtranjeroModalLabel">Editar Familiar P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_familiares_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS FAMILIAR P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="e_nombre_familiar_extranjero_pep_r">Nombre</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_representante' id='id_representante' value={editFamiliarPEPExtranjero.id_representante} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editFamiliarPEPExtranjero.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_nombre_familiar_extranjero_pep_r" id="e_nombre_familiar_extranjero_pep_r" defaultValue={editFamiliarPEPExtranjero.nombre}
                                                                        className={((this.verificarError("error_e_nombre_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Familiar" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre Familiar</small>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="parentesco">Parentesco</label>
                                                                    <select name="parentesco"
                                                                        id="e_parentesco_familiar_extranjero_pep_r"
                                                                        value={editFamiliarPEPExtranjero.parentesco}
                                                                        options={editFamiliarPEPExtranjero.parentesco}
                                                                        className={((this.verificarError("error_e_parentesco_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_pep_extranjero')
                                                                        }} >
                                                                            <option value="">Seleccione el Parentesco</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD">PRIMERO CONSANGUINIDAD</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD">SEGUNDO CONSANGUINIDAD</option>
                                                                            <option value="PRIMERO_AFINIDAD">PRIMERO AFINIDAD</option>
                                                                            <option value="SEGUNDO_AFINIDAD">SEGUNDO AFINIDAD</option>
                                                                            <option value="CONYUGE">CONYUGE</option>
                                                                            <option value="PRIMERO_CONSANGUINIDAD_CONYUGE">PRIMERO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_CONSANGUINIDAD_CONYUGE">SEGUNDO CONSANGUINIDAD CONYUGE</option>
                                                                            <option value="PRIMERO_AFINIDAD_CONYUGE">PRIMERO AFINIDAD CONYUGE</option>
                                                                            <option value="SEGUNDO_AFINIDAD_CONYUGE">SEGUNDO AFINIDAD CONYUGE</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Parentesco</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="tipo_identificacion">Tipo Documento</label>
                                                                    <select name="tipo_identificacion"
                                                                        id="e_tipo_documento_familiar_extranjero_pep_r"
                                                                        value={editFamiliarPEPExtranjero.tipo_identificacion}
                                                                        options={editFamiliarPEPExtranjero.tipo_identificacion}
                                                                        className={((this.verificarError("error_e_tipo_documento_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'familiar_pep_extranjero')
                                                                        }} >
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="CC">C.C.</option>
                                                                            <option value="CE">C.E.</option>
                                                                            <option value="NIT">NIT</option>
                                                                            <option value="PP">P.P.</option>
                                                                            <option value="OTRO">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_familiar_extranjero_pep_r">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_familiar_extranjero_pep_r" id="e_numero_documento_familiar_extranjero_pep_r" defaultValue={editFamiliarPEPExtranjero.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_familiar_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'familiar_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosFamiliarPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Familiar</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_familiar_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarSociedadPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarSociedadPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarSociedadPEPExtranjeroModalLabel">Editar Sociedad P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_sociedades_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS SOCIEDAD P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_participacion_extranjero_pep_r">Razón Social Sociedad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_representante' id='id_representante' value={editSociedadPEPExtranjero.id_representante} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editSociedadPEPExtranjero.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_participacion_extranjero_pep_r" id="e_razon_social_participacion_extranjero_pep_r" defaultValue={editSociedadPEPExtranjero.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Sociedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Sociedad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_numero_documento_participacion_extranjero_pep_r">No. Identificación (NIT o Similar)</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="e_numero_documento_participacion_extranjero_pep_r" id="e_numero_documento_participacion_extranjero_pep_r" defaultValue={editSociedadPEPExtranjero.identificacion}
                                                                            className={((this.verificarError("error_e_numero_documento_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_porcentaje_participacion_extranjero_pep_r">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_porcentaje_participacion_extranjero_pep_r" id="e_porcentaje_participacion_extranjero_pep_r" defaultValue={editSociedadPEPExtranjero.capital}
                                                                            className={((this.verificarError("error_e_porcentaje_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'sociedad_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_control">Tipo Control</label>
                                                                    <select name="tipo_control"
                                                                        id="e_tipo_control_participacion_extranjero_pep_r"
                                                                        value={editSociedadPEPExtranjero.tipo_control}
                                                                        options={editSociedadPEPExtranjero.tipo_control}
                                                                        className={((this.verificarError("error_e_tipo_control_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_pep_extranjero')
                                                                            this.activarOtrosTipoControlPepExtranjero(e);
                                                                        }}>
                                                                            <option value="">Seleccione el Tipo Control</option>
                                                                            <option value="FINANCIERO">FINANCIERO</option>
                                                                            <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                                                                            <option value="ESTRUCTURAL">ESTRUCTURAL</option>
                                                                            <option value="OTROS_TIPO_CONTROL">OTROS</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Control</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                activar_otros_tipos_control_pep_extranjero ?
                                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="e_otros_tipo_control_participacion_extranjero_pep_r">Otros Tipos Control</label>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="e_otros_tipo_control_participacion_extranjero_pep_r" id="e_otros_tipo_control_participacion_extranjero_pep_r" defaultValue={editSociedadPEPExtranjero.otros_tipo_control}
                                                                                className={((this.verificarError("error_e_otros_tipo_control_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Tipos Control" aria-describedby="helpId"
                                                                                onChange={(e) => {
                                                                                    this.cambioValorEditar(e, 'sociedad_pep_extranjero')
                                                                                }} />
                                                                            <small id="helpId" className="invalid-feedback">Digite los Otros Tipos Control</small>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            }
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_detalle_tipo_control_participacion_extranjero_pep_r">Detalle (Opcional)</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_detalle_tipo_control_participacion_extranjero_pep_r" id="e_detalle_tipo_control_participacion_extranjero_pep_r" defaultValue={editSociedadPEPExtranjero.detalle_tipo_control}
                                                                        className={((this.verificarError("error_e_detalle_tipo_control_participacion_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Detalle" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'sociedad_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Detalle</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosSociedadPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Participación en Sociedad</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_sociedad_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditarCuentaPEPExtranjeroModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="EditarCuentaPEPExtranjeroModalLabel" aria-hidden="true" style={{ zIndex: '1060' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="EditarCuentaPEPExtranjeroModalLabel">Editar Cuenta P.E.P. Extranjero</h4>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <form id='form-editar_cuentas_pep_extranjero'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS CUENTA P.E.P. EXTRANJERO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-5">
                                                                    <label htmlFor="e_razon_social_cuenta_extranjera_extranjero_pep_r">Razón Social Banco o Entidad</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input type="hidden" name='id_representante' id='id_representante' value={editCuentaPEPExtranjero.id_representante} />
                                                                    <input type="hidden" name='id' id='id' defaultValue={editCuentaPEPExtranjero.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_razon_social_cuenta_extranjera_extranjero_pep_r" id="e_razon_social_cuenta_extranjera_extranjero_pep_r" defaultValue={editCuentaPEPExtranjero.razon_social}
                                                                        className={((this.verificarError("error_e_razon_social_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social Banco o Entidad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Razón Social Banco o Entidad</small>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="e_pais_cuenta_extranjera_extranjero_pep_r">País Cuenta</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_pais_cuenta_extranjera_extranjero_pep_r" id="e_pais_cuenta_extranjera_extranjero_pep_r" defaultValue={editCuentaPEPExtranjero.pais}
                                                                        className={((this.verificarError("error_e_pais_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="País Cuenta" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_pep_extranjero')
                                                                        }} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el País Cuenta</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="e_numero_cuenta_extranjera_extranjero_pep_r">Número Cuenta</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="e_numero_cuenta_extranjera_extranjero_pep_r" id="e_numero_cuenta_extranjera_extranjero_pep_r" defaultValue={editCuentaPEPExtranjero.numero_cuenta}
                                                                            className={((this.verificarError("error_e_numero_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Cuenta" aria-describedby="helpId"
                                                                            onChange={(e) => { this.cambioValorEditar(e, 'cuenta_pep_extranjero') }} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Cuenta</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="e_clase_propiedad_cuenta_extranjera_extranjero_pep_r">Especifique que clase de propiedad, forma de derecho, control, poder de firma o relación similar tiene</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="e_clase_propiedad_cuenta_extranjera_extranjero_pep_r" id="e_clase_propiedad_cuenta_extranjera_extranjero_pep_r" defaultValue={editCuentaPEPExtranjero.clase_propiedad}
                                                                        className={((this.verificarError("error_e_clase_propiedad_cuenta_extranjera_extranjero_pep_r")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Clase Propiedad" aria-describedby="helpId"
                                                                        onChange={(e) => {
                                                                            this.cambioValorEditar(e, 'cuenta_pep_extranjero')
                                                                        }} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite la Clase Propiedad</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.editarDatosCuentaPEPExtranjero}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Editar Cuenta en el Exterior</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={(e) => {this.resetFields(e, 'editar_cuenta_pep_extranjero')}}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CrearTerceros;