import React from 'react'
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEdit, faHashtag, faTimes } from '@fortawesome/free-solid-svg-icons';

class Editar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        usuario: [],
        roles: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state.usuario;
        state[e.target.name] = e.target.value;
        this.setState({ usuario: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    validateEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email);
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const tipo_documento = document.getElementById('identification_type').value;
        const identificacion = document.getElementById('identificacion').value;
        const nombre = document.getElementById('nombre').value;
        const estado = document.getElementById('blocked').value;
        const cargo = document.getElementById('cargo').value;
        const password1 = document.getElementById('password1').value;
        const password2 = document.getElementById('password2').value;
        const correo_electronico = document.getElementById('correo_electronico').value;
        var errores = [];
        if (!tipo_documento) { errores.push("error_tipo_documento"); }
        if (!identificacion) { errores.push("error_identificacion"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        if (!cargo) { errores.push("error_usuario"); }
        if (!password1) { errores.push("error_password1"); }
        if (!password2) { errores.push("error_password2"); }
        if (!correo_electronico) { errores.push("error_correo_electronico"); }
        if (!this.validateEmail(correo_electronico)) {
            errores.push("error_correo_electronico");
            Swal.fire({
                title: 'Formularios',
                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    errores.push("error_correo_electronico");
                }
            })
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        if (password1 !== password2) {
            Swal.fire(
                '',
                'Las Contraseñan no coinciden.',
                'error'
            )
            return false
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        console.log(formData);
        authAxios.post("usuario/update_user", formData)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Usuario!',
                    datosRespuesta.data.msj,
                    'success'
                )
                this.props.history.push("/MasterUsuarios");
            })
            .catch(console.log)
    }
    componentDidMount() {
        const timeout = window.localStorage.getItem('timeout');
        // alert("Now: " + Date.now() + " - TimeOut: " + timeout);
        if (Date.now() > timeout) {
            // alert("Entra");
            window.location.href = "/";
            window.localStorage.clear();
            Swal.fire({
                title: 'Formularios',
                text: 'Sesión Expirada',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                    window.localStorage.clear();
                }
            })
        }
        Swal.showLoading();
        authAxios.get("usuario/detail/" + this.props.match.params.id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, usuario: datosRespuesta.data.data, load: '' });
            })
            .catch(console.log);
        authAxios.get("usuario/roles")
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, roles: datosRespuesta.data.data, load: '' });
                Swal.close();
            })
            .catch(console.log);
    }
    render() {
        const { load, usuario, roles } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                {load ? (<b>{load}</b>) : (<b>EDITAR USUARIO</b>)}
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos} id="form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-2">
                                                    <input type="hidden" name="id" value={this.props.match.params.id} />
                                                    <input type="hidden" name="id_empresa" value={window.localStorage.getItem('id_empresa')} />
                                                    <label htmlFor="identification_type">T. Identificación</label>
                                                    <select name="identification_type"
                                                        id="identification_type"
                                                        value={usuario.identification_type}
                                                        options={usuario.identification_type}
                                                        className={((this.verificarError("error_tipo_documento")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el T.I.</option>
                                                        <option value="1">C.C.</option>
                                                        <option value="2">C.E.</option>
                                                        <option value="3">NIT</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el T.I.</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="identificacion">Identificación</label>
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                        <input type="text" name="identificacion" id="identificacion" defaultValue={usuario.identification}
                                                            className={((this.verificarError("error_identificacion")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} maxLength={20} placeholder="IDENTIFICACIÓN" aria-describedby="helpId"
                                                            onChange={this.cambioValor}
                                                        />
                                                    </div>
                                                    <small id="helpId" className="invalid-feedback">Digite la Identificación</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre" id="nombre" defaultValue={usuario.name}
                                                        className={((this.verificarError("error_nombre")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="NOMBRE" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="blocked">Estado</label>
                                                    <select name="blocked"
                                                        id="blocked"
                                                        value={usuario.blocked}
                                                        options={usuario.blocked}
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">ACTIVO</option>
                                                        <option value="1">INACTIVO</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="id_rol">Rol</label>
                                                    <select name="id_rol"
                                                        id="id_rol"
                                                        value={usuario.id_rol}
                                                        options={usuario.id_rol}
                                                        className={((this.verificarError("error_rol")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Rol</option>
                                                        {
                                                            roles.map((itemRoles) => (
                                                                <option key={itemRoles.id} value={itemRoles.id}>{itemRoles.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Rol</small>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                <div className="col-md-2">
                                                    <label htmlFor="cargo">Cargo</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="cargo" id="cargo" defaultValue={usuario.position}
                                                        className={((this.verificarError("error_cargo")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} maxLength={20} placeholder="CARGO" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el cargo</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password1">Password</label>
                                                    <input type="password" name="password1" id="password1"
                                                        className={((this.verificarError("error_password1")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="PASSWORD" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password2">Confirmar Password</label>
                                                    <input type="password" name="password2" id="password2"
                                                        className={((this.verificarError("error_password2")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="CONFIRMAR PASS." aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="correo_electronico">Correo Electrónico</label>
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                        <input style={{ textTransform: 'lowercase' }} type="text" name="correo_electronico" id="correo_electronico" defaultValue={usuario.email}
                                                            className={((this.verificarError("error_correo_electronico")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="ejemplo@dominio.com" aria-describedby="helpId"
                                                            onChange={this.cambioValor}
                                                        />
                                                    </div>
                                                    <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Editar Usuario</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={"/MasterUsuarios"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;