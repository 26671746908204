import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faHashtag, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

class Crear extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        tipo_identificacion: '',    
        identificacion: '',
        nombre: '',
        estado: '',
        cargo: '',
        password1: '',
        password2: '',
        correo_electronico : '',
        id_rol: '',
        roles: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    validateEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email);
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { tipo_identificacion, identificacion, nombre, estado, cargo, password1, password2, correo_electronico, id_rol } = this.state;
        var errores = [];
        if (!tipo_identificacion) { errores.push("error_tipo_identificacion"); }
        if (!identificacion) { errores.push("error_identificacion"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        if (!cargo) { errores.push("error_cargo"); }
        if (!password1) { errores.push("error_password1"); }
        if (!password2) { errores.push("error_password2"); }
        if (!correo_electronico) { errores.push("error_correo_electronico"); }
        if (!id_rol) { errores.push("error_id_rol"); }
        if (!this.validateEmail(correo_electronico)) {
            errores.push("error_correo_electronico");
            Swal.fire({
                title: 'Formularios',
                text: 'El correo electrónico no tiene el formato aceptado. Favor verificar Información.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    errores.push("error_correo_electronico");
                }
            })
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        if (password1 !== password2) {
            Swal.fire(
                '',
                'Las Contraseñan no coinciden.',
                'error'
            )
            return false
        }
        var datosEnviar = {
            tipo_identificacion: tipo_identificacion,
            identificacion: identificacion,
            nombre: nombre,
            estado: estado,
            cargo: cargo,
            password1: password1,
            password2: password2,
            id_empresa: window.localStorage.getItem('id_empresa'),
            correo_electronico: correo_electronico,
            id_rol: id_rol
        }
        authAxios.post("usuario/register", datosEnviar)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'Usuarios!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    this.props.history.push("/MasterUsuarios");
                } else {
                    Swal.fire(
                        'Usuarios!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    componentDidMount() {
        const timeout = window.localStorage.getItem('timeout');
        // alert("Now: " + Date.now() + " - TimeOut: " + timeout);
        if (Date.now() > timeout) {
            // alert("Entra");
            window.location.href = "/";
            window.localStorage.clear();
            Swal.fire({
                title: 'Formularios',
                text: 'Sesión Expirada',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                    window.localStorage.clear();
                }
            })
        }
        Swal.showLoading();
        authAxios.get("usuario/roles")
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, roles: datosRespuesta.data.data, load: '' });
                Swal.close();
            })
            .catch(console.log);
    }
    render() {
        const { nombre, identificacion, cargo, password1, password2, correo_electronico, roles } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE USUARIOS</b>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-2">
                                                    <label htmlFor="tipo_identificacion">T. Identificación</label>
                                                    <select name="tipo_identificacion"
                                                        id="tipo_identificacion"
                                                        className={((this.verificarError("error_tipo_identificacion")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el T.I.</option>
                                                        <option value="1">C.C.</option>
                                                        <option value="2">C.E.</option>
                                                        <option value="3">NIT</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el T.I.</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="identificacion">Identificación</label>
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                        <input className={((this.verificarError("error_identificacion")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} maxLength={20} type="text" name="identificacion" id="identificacion" value={identificacion} placeholder="IDENTIFICACIÓN" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    </div>
                                                    <small id="helpId" className="invalid-feedback">Digite la Identificación</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_nombre")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="NOMBRE" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">ACTIVO</option>
                                                        <option value="1">INACTIVO</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="id_rol">Rol</label>
                                                    <select name="id_rol"
                                                        id="id_rol"
                                                        className={((this.verificarError("error_id_rol")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Rol</option>
                                                        {
                                                            roles.map((itemRoles) => (
                                                                <option key={itemRoles.id} value={itemRoles.id}>{itemRoles.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Rol</small>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                <div className="col-md-2">
                                                    <label htmlFor="cargo">Cargo</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_cargo")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} maxLength={20} type="text" name="cargo" id="cargo" value={cargo} placeholder="CARGO" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Cargo</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password1">Password</label>
                                                    <input className={((this.verificarError("error_password1")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} type="password" name="password1" id="password1" value={password1} placeholder="PASSWORD" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password2">Confirmar Password</label>
                                                    <input className={((this.verificarError("error_password2")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} type="password" name="password2" id="password2" value={password2} placeholder="CONFIRMAR PASS." aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="correo_electronico">Correo Electrónico</label>
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                        <input style={{ textTransform: 'lowercase' }} className={((this.verificarError("error_correo_electronico")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} type="text" name="correo_electronico" id="correo_electronico" value={correo_electronico} placeholder="ejemplo@dominio.com" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    </div>
                                                    <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Crear Usuario</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={"/MasterUsuarios"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;